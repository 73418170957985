<template>
  <div class="body">
    <myheader></myheader>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <sideframe></sideframe>
      <div class="input-box">
        <el-input placeholder="搜索" v-model="searchMessage" class="condition">
          <el-select
            v-model="price"
            slot="prepend"
            clearable
            placeholder="搜索条件"
          >
            <el-option label="收费" value="0"></el-option>
            <el-option label="免费" value="1"></el-option>
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchCourse()"
            style="
              background: #dab96b;
              color: #fff;
              border-radius: 0px;
              border: 1px solid #dab96b;
            "
          ></el-button>
        </el-input>
      </div>
      <div class="select-Body">
        <div class="select-Box">
          <span class="crosshead">月份</span>
          <span
            class="crossmain"
            @click="selectGrade('all', '')"
            v-bind:class="{ crossActive: teacherGrade == 'all' }"
            >全部</span
          >
          <span
            class="crossmain"
            v-for="msg in inData"
            :key="msg.length"
            v-bind:class="{ crossActive: teacherGrade == msg.industryName }"
            @click="selectGrade(msg.industryName, msg.id)"
            >{{ msg.industryName }}</span
          >
        </div>
        <div class="select-Box">
          <span class="crosshead">类别</span>
          <span
            class="crossmain"
            @click="selectSubject('all', '')"
            v-bind:class="{ crossActive: teacherSubject == 'all' }"
            >全部</span
          >
          <span
            class="crossmain"
            v-for="msg in intypeData"
            :key="msg.length"
            @click="selectSubject(msg.industryTypeName, msg.id)"
            v-bind:class="{
              crossActive: teacherSubject == msg.industryTypeName,
            }"
            >{{ msg.industryTypeName }}</span
          >
        </div>
      </div>

      <div class="course-body">
        <el-empty
          v-if="courseData.length == 0"
          description="暂无课程"
        ></el-empty>
        <div v-else class="courses-body">
          <div
            class="course-box"
            v-for="msg in courseData"
            :key="msg.id"
            @click="courseRouter(msg)"
          >
            <el-image
              class="img-box"
              :src="msg.courseImg"
              fit="fill"
            ></el-image>
            <hr />
            <div style="padding: 8px">
              <p class="courseName">
                {{ msg.courseName }}
              </p>
              <div v-if="msg.aboutInstructor != null">
                <el-tooltip
                  class="item"
                  effect="light"
                  placement="top-start"
                  v-if="msg.aboutInstructor.length > 12"
                >
                  <div
                    slot="content"
                    v-for="(item, index) in content(msg.aboutInstructor)"
                    :key="index"
                  >
                    {{ item }}<br />
                  </div>
                  <p style="text-align: left; margin: 5px auto">
                    教师：{{ msg.aboutInstructor.substr(0, 6) + "..." }}
                  </p>
                </el-tooltip>
                <p v-else style="text-align: left; margin: 5px auto">
                  教师：{{ msg.aboutInstructor }}
                </p>
              </div>
              <p v-else style="text-align: left; margin: 5px auto">
                教师：{{ msg.aboutInstructor }}
              </p>
              <div v-if="msg.courseIntroduction != null">
                <el-tooltip
                  class="item"
                  effect="light"
                  placement="top-start"
                  v-if="msg.courseIntroduction.length > 12"
                >
                  <div
                    slot="content"
                    v-for="(item, index) in content(msg.courseIntroduction)"
                    :key="index"
                  >
                    {{ item }}<br />
                  </div>
                  <p style="text-align: left; margin: 5px auto">
                    课程介绍：{{ msg.courseIntroduction.substr(0, 6) + "..." }}
                  </p>
                </el-tooltip>
                <p v-else style="text-align: left; margin: 5px auto">
                  课程介绍：{{ msg.courseIntroduction }}
                </p>
              </div>
              <p v-else style="text-align: left; margin: 5px auto">
                课程介绍：{{ msg.courseIntroduction }}
              </p>
              <p style="text-align: left; margin: 10px auto">开课时间：</p>
              <p style="text-align: left; margin: 10px auto">
                {{ msg.courseTime }}
              </p>
              <div class="type-box">
                <span
                  class="courseSign"
                  :style="msg.industryTypeName == null ? 'border:0' : ''"
                  >{{ msg.industryTypeName }}</span
                >
                <span class="coursePrice">￥{{ msg.coursePrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-if="courseData.length !== 0"
        :child-msg="pageparm"
        @call="callFather"
      ></Pagination>
    </div>
    <myflooter></myflooter>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Flooter from "../flooter.vue";
import SideFrame from "@/components/sideframe.vue";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";

export default {
  data() {
    return {
      titleData: {
        titleName: "直播课程",
        data: [
          {
            id: nanoid(),
            name: "商学院",
            href: "",
          },
          {
            id: nanoid(),
            name: "直播课程",
            href: "",
          },
        ],
      },
      selection: {
        pagesize: 6,
        pageindex: 1,
      },
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10,
      },
      formInline: {
        page: 1,
        limit: 5,
        courseEntity: {
          curriculumTypeId: "2",
          courseName: "",
          coursePrice: "",
          courseTime: "",
          aboutInstructor: "",
          courseIntroduction: "",
          industryTypeId: "",
          industryId: "",
          charge: "",
          state: "1",
        },
      },
      courseData: [],
      inData: [],
      intypeData: [],
      teacherGrade: "all",
      teacherSubject: "all",
      teacherGradeId: "",
      teacherSubjectId: "",
      buttonShow: true,
      searchMessage: "",
      price: "",
    };
  },
  created() {
    this.getCourseData();
    this.selectGrade();
    this.selectSubject();
  },
  computed: {
    content() {
      return (val) => {
        let a = [];
        for (let i = 0; i * 16 < val.length; i++) {
          a.push(val.substring(i * 16, i * 16 + 16));
        }
        return a;
      };
    },
  },
  components: {
    myheader: Header,
    myflooter: Flooter,
    sideframe: SideFrame,
    Pagination,
    Breadcrumb,
  },
  methods: {
    async callFather(parm) {
      this.formInline.page = parm.currentPage;
      this.formInline.limit = parm.pageSize;

      const res = await this.axios.post(
        "/course/selectAllState",
        this.formInline
      );
      if (res.code == 200) {
        this.courseData = res.object.list;
        this.pageparm.total = Number(res.object.total);
        this.pageparm.pageNum = res.object.pageNum;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async getCourseData() {
      const res = await this.axios.post(
        "/course/selectAllState",
        this.formInline
      );
      if (res.code == 200) {
        this.courseData = res.object.list;
        this.courseData.forEach((item) => {
          const a = this.moment(item.courseTime).format("YYYY-MM-DD HH:mm:ss");
          if (a === "Invalid date") {
            item.courseTime = "待定";
          } else {
            item.courseTime = a;
          }
        });
        this.pageparm.total = Number(res.object.total);
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    addList() {
      this.selection.pageindex++;
      this.getCourseData();
    },
    courseRouter(coursemessage) {
      let data = {
        couresMessage: coursemessage,
        title: "直播课程",
        path: this.$route.path,
      };
      window.localStorage.setItem("courseData", JSON.stringify(data));
      this.$router.push({
        path: "/couresMessage",
      });
    },
    async getCourseData() {
      const res = await this.axios.post(
        "/course/selectAllState",
        this.formInline
      );
      if (res.code == 200) {
        this.courseData = res.object.list;
        this.courseData.forEach((item) => {
          const a = this.moment(item.courseTime).format("YYYY-MM-DD HH:mm:ss");
          if (a === "Invalid date") {
            item.courseTime = "待定";
          } else {
            item.courseTime = a;
          }
        });
        this.pageparm.total = Number(res.object.total);
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async selectGrade(value, id) {
      const res = await this.axios.get("/industry/selectAllState");
      if (res.code == 200) {
        if (value == undefined) {
          value = "all";
        }
        this.inData = res.object.list;
        this.pageparm.total = Number(res.object.total);
        this.teacherGrade = value;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
      if (id == undefined) {
        this.getCourseData();
      } else {
        this.selindustryTypeId = id;
        this.formInline.courseEntity.industryId = this.selindustryId;
        this.formInline.courseEntity.industryTypeId = this.selindustryTypeId;
        this.getCourseData();
      }
    },
    async selectSubject(value, id) {
      const res = await this.axios.get("/industryType/selectAllState");
      if (res.code == 200) {
        if (value == undefined) {
          value = "all";
        }
        this.intypeData = res.object.list;
        this.pageparm.total = Number(res.object.total);
        this.teacherSubject = value;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
      if (id == undefined) {
        this.getCourseData();
      } else {
        this.selindustryId = id;
        this.formInline.courseEntity.industryId = this.selindustryId;
        this.formInline.courseEntity.industryTypeId = this.selindustryTypeId;
        this.getCourseData();
      }
    },
  },
};
</script>


<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
.condition >>> .el-input-group__prepend {
  border-radius: 0px !important;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(218, 185, 107);
  color: #fff;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgb(218, 185, 107);
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
.el-icon-plus:hover {
  color: #dab96b;
}
::v-deep .el-button:hover {
  border: 1px solid #dab96b;
  color: #dab96b;
}
::v-deep .el-button:focus {
  border: 1px solid #dab96b;
  color: #dab96b;
}
::v-deep .el-input-group {
  padding: 15px;
}

.input-box {
  width: 30%;
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
.crossActive {
  background: #dab96b;
  color: #fff;
}
.course-body {
  padding: 10px 0;
  width: 90%;
  margin: auto;
}
.courses-body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.course-box {
  width: 14%;
  padding: 10px;
  border-radius: 2%;
  margin: 10px 2%;
  cursor: pointer;
  box-shadow: 0px 0px 2px 1px rgb(125, 125, 133, 0.5);
}
.course-box:hover {
  box-shadow: 0px 0px 5px 1px rgb(125, 125, 133, 0.5);
}
.courseName {
  font-size: 20px;
  text-align: left;
  margin: 5px 0;
}
.title {
  min-height: 180px;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 15px;
}
.type-box {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}
.courseSign {
  height: 30px;
  padding: 0 5px;
  line-height: 30px;
  border: 1px solid #dab96b;
  font-size: 13px;
  color: #dab96b;
}
.coursePrice {
  font-size: 32px;
  color: #dab96b;
}
.coursePrice::first-letter {
  font-size: 18px;
}
.select-Body {
  width: 100%;
  min-height: 80px;
  padding: 15px 0 15px 30px;
  font-size: 12px;
  border-radius: 4px;
  border-bottom: 1px dashed #e4e7ed;
  display: flex;
  flex-wrap: wrap;
}
.select-Body > .el-row {
  margin: 10px 0px;
}
.select-Box {
  height: 40px;
  line-height: 40px;
  width: 100%;
  text-align: left;
}
.crosshead {
  margin-right: 40px;
  font-size: 18px;
  color: #909399;
}
.crossmain {
  margin-right: 5px;
  color: #606266;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
}

.el-select {
  width: 110px;
}
.img-box {
  height: 200px;
  width: 100%;
}
::v-deep .el-button {
  border-radius: 0px !important;
}
@media (max-width: 768px) {
  .course-body {
    padding: 10px 0;
    width: 100%;
  }
  .course-box {
    font-size: 48px;
    width: 46%;
    padding: 10px;
    border-radius: 2%;
    margin: 10px 1%;
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px rgb(125, 125, 133, 0.5);
  }
  .courses-body {
    justify-content: left;
  }
  .courseName {
    font-size: 68px;
  }
  .img-box {
    height: 800px;
  }
  .courseSign {
    height: 80px;
    line-height: 80px;
    padding: 0 5px;
    border: 1px solid #dab96b;
    font-size: 48px;
    color: #dab96b;
  }
  .coursePrice {
    font-size: 68px;
    color: #dab96b;
    height: 80px;
    line-height: 80px;
  }
  .coursePrice::first-letter {
    font-size: 38px;
  }
  .type-box {
    height: 80px;
    line-height: 80px;
  }
  .select-Body {
    width: 100%;
    min-height: 180px;
    padding: 15px 0 15px 30px;
    font-size: 68px;
    border-radius: 4px;
    border-bottom: 1px dashed #e4e7ed;
  }
  .select-Body > .el-row {
    margin: 10px 0px;
  }
  .select-Box {
    height: 360px;
    line-height: 180px;
    width: 100%;
  }
  .crosshead {
    margin-right: 40px;
    font-size: 68px;
  }
  .input-box {
    height: 180px;
    width: 60%;
  }
  ::v-deep .el-input-group {
    padding: 10px;
  }
  ::v-deep .el-input-group input {
    height: 100%;
    font-size: 68px;
  }
  ::v-deep .el-input-group__prepend {
    width: 320px;
    text-align: left;
  }
  ::v-deep .el-input-group__prepend input {
    width: 320px;
  }
  ::v-deep .el-input-group__append button.el-button {
    width: 180px;
  }
  ::v-deep .el-input-group__append button.el-button i {
    font-size: 48px;
  }
  ::v-deep .el-input__prefix,
  .el-input__suffix {
    right: 0;
  }
  ::v-deep .el-select .el-input .el-select__caret {
    font-size: 48px;
  }
  ::v-deep .el-select > .el-input {
    width: 360px;
  }
  ::v-deep .el-select-dropdown__item {
    width: 360px;
  }
  ::v-deep .el-button {
    border-radius: 0px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .course-box {
    width: 30%;
    padding: 10px;
    border-radius: 2%;
    margin: 10px 1%;
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px rgb(125, 125, 133, 0.5);
  }
  .img-box {
    height: 400px;
  }
  .select-Body {
    width: 100%;
    min-height: 80px;
    padding: 15px 0 15px 30px;
    font-size: 24px;
    border-radius: 4px;
    border-bottom: 1px dashed #e4e7ed;
    display: flex;
    flex-wrap: wrap;
  }
  .crosshead {
    margin-right: 40px;
    font-size: 24px;
  }
  .courseName {
    font-size: 48px;
  }
  .courseSign {
    height: 50px;
    line-height: 50px;
    padding: 0 5px;
    border: 1px solid #dab96b;
    font-size: 32px;
    color: #dab96b;
  }
  .coursePrice {
    font-size: 48px;
    color: #dab96b;
    height: 80px;
    line-height: 80px;
  }
  .coursePrice::first-letter {
    font-size: 32px;
  }
  .course-box {
    font-size: 32px;
    width: 44%;
    padding: 20px;
    border-radius: 2%;
    margin: 10px 1%;
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px rgb(125, 125, 133, 0.5);
  }
  .input-box {
    width: 60%;
  }
  .select-Box {
    height: 100px;
    line-height: 100px;
    width: 100%;
  }
  ::v-deep .el-select .el-input .el-select__caret {
    font-size: 48px;
  }
  ::v-deep .el-select > .el-input {
    width: 240px;
  }
  ::v-deep .el-select-dropdown__item {
    width: 240px;
  }
  ::v-deep .el-input-group {
    padding: 10px;
  }
  ::v-deep .el-input-group input {
    height: 80px;
    font-size: 42px;
  }
  ::v-deep .el-input-group__prepend {
    width: 200px;
    text-align: left;
    height: 100%;
  }
  ::v-deep .el-input-group__prepend input {
    width: 240px;
  }
  ::v-deep .el-input-group__append button.el-button {
    width: 120px;
  }
  ::v-deep .el-input-group__append button.el-button i {
    font-size: 48px;
  }
  ::v-deep .el-input__prefix,
  .el-input__suffix {
    right: 0;
  }
  ::v-deep .el-button {
    border-radius: 0px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1524px) {
  .course-box {
    width: 20%;
    padding: 22px;
    border-radius: 2%;
    font-size: 28px;
    margin: 10px 1%;
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px rgb(125, 125, 133, 0.5);
  }
  .img-box {
    height: 300px;
  }
  .courseName {
    font-size: 38px;
  }
  .courseSign {
    height: 50px;
    line-height: 50px;
    padding: 0 5px;
    border: 1px solid #dab96b;
    font-size: 32px;
    color: #dab96b;
  }
  .coursePrice {
    font-size: 38px;
    color: #dab96b;
    height: 80px;
    line-height: 80px;
  }
  .coursePrice::first-letter {
    font-size: 30px;
  }
  .select-Body {
    width: 100%;
    min-height: 80px;
    padding: 15px 0 15px 30px;
    font-size: 32px;
    border-radius: 4px;
    border-bottom: 1px dashed #e4e7ed;
  }
  .select-Body > .el-row {
    margin: 10px 0px;
  }
  .select-Box {
    height: 100px;
    line-height: 100px;
    width: 100%;
  }
  .crosshead {
    margin-right: 40px;
    font-size: 32px;
  }
  .input-box {
    height: 110px;
    width: 60%;
  }
  ::v-deep .el-select .el-input .el-select__caret {
    font-size: 32px;
  }
  ::v-deep .el-select > .el-input {
    width: 200px;
  }
  ::v-deep .el-select-dropdown__item {
    width: 280px;
  }
  ::v-deep .el-input-group {
    padding: 10px;
  }
  ::v-deep .el-input-group input {
    height: 80px;
    font-size: 32px;
  }
  ::v-deep .el-input-group__prepend {
    width: 160px;
    text-align: left;
    height: 100%;
  }
  ::v-deep .el-input-group__prepend input {
    width: 240px;
  }
  ::v-deep .el-input-group__append button.el-button {
    width: 120px;
  }
  ::v-deep .el-input-group__append button.el-button i {
    font-size: 38px;
  }
  ::v-deep .el-input__prefix,
  .el-input__suffix {
    right: 0;
  }
  ::v-deep .el-button {
    border-radius: 0px !important;
    height: 80px;
  }
}
</style>



