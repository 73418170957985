<template>
  <div class="index-body">
    <sideframe></sideframe>
    <Myheader :shoppingCarNum="shoppingCarNum"></Myheader>
    <div class="carousel-box">
      <el-carousel>
        <el-carousel-item v-for="item in slideshow" :key="item.id">
          <img :src="item.imgPath" />
          <p class="des1">{{ item.title }}</p>
          <p class="des">{{ item.text }}</p>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="Tbody">
      <div class="contentbody" ref="box">
        <el-row class="boxbody">
          <transition-group
            appear
            style="height: fit-content"
            name="animate__animated animate__bounce animate__delay-1s"
            enter-active-class="animate__fadeInLeftBig"
            leave-active-class="animate__fadeOut"
          >
            <div class="card-menu" v-show="isShow1" key="1">
              <div class="col" @click="toTalentStrategy()">
                <el-image
                  style="border-radius: 5px 5px 0 0"
                  :src="courseImage2"
                  fit="fill"
                ></el-image>
                <div class="contentText">
                  <span>人才战略</span>
                </div>
              </div>
            </div>
          </transition-group>
          <transition-group
            appear
            style="height: fit-content"
            name="animate__animated animate__bounce animate__delay-1s"
            enter-active-class="animate__fadeInDownBig"
            leave-active-class="animate__fadeOut"
          >
            <div class="card-menu" v-show="isShow1" key="2">
              <div class="col" @click="toManagementTechnology()">
                <el-image
                  style="border-radius: 5px 5px 0 0"
                  :src="courseImage3"
                  fit="fill"
                ></el-image>
                <div class="contentText">
                  <span>管理技术</span>
                </div>
              </div>
            </div>
          </transition-group>
          <transition-group
            appear
            style="height: fit-content"
            name="animate__animated animate__bounce animate__delay-1s"
            enter-active-class="animate__fadeInRightBig"
            leave-active-class="animate__fadeOut"
          >
            <div class="card-menu" v-show="isShow1" key="3">
              <div class="col" @click="toCrossBorderLearning()">
                <el-image
                  style="border-radius: 5px 5px 0 0"
                  :src="courseImage4"
                  fit="fill"
                >
                </el-image>
                <div class="contentText">
                  <span @click="toCrossBorderLearning()">跨界学习</span>
                </div>
              </div>
            </div>
          </transition-group>
          <transition-group
            appear
            style="height: fit-content"
            name="animate__animated animate__bounce animate__delay-1s"
            enter-active-class="animate__fadeInBottomLeft"
            leave-active-class="animate__fadeOut"
          >
            <div class="card-menu" v-show="isShow1" key="4">
              <div class="col" @click="toTrainingCamp()">
                <el-image
                  style="border-radius: 5px 5px 0 0"
                  :src="courseImage5"
                  fit="fill"
                ></el-image>
                <div class="contentText">
                  <span>益学训练营</span>
                </div>
              </div>
            </div>
          </transition-group>
          <transition-group
            appear
            style="height: fit-content"
            name="animate__animated animate__bounce animate__delay-1s"
            enter-active-class="animate__fadeInUpBig"
            leave-active-class="animate__fadeOut"
          >
            <div class="card-menu" v-show="isShow1" key="5">
              <div class="col" @click="tocourselist()">
                <el-image
                  style="border-radius: 5px 5px 0 0"
                  :src="courseImage6"
                  fit="fill"
                ></el-image>
                <div class="contentText">
                  <span>线上学习中心</span>
                </div>
              </div>
            </div>
          </transition-group>
          <transition-group
            appear
            name="animate__animated animate__bounce animate__delay-1s"
            enter-active-class="animate__fadeInTopRight"
            leave-active-class="animate__fadeOut"
          >
            <div class="card-menu" v-show="isShow1" key="6">
              <div class="col" @click="toHumanCapitalizationDisplay()">
                <el-image
                  style="border-radius: 5px 5px 0 0"
                  :src="courseImage7"
                  fit="fill"
                ></el-image>
                <div class="contentText">
                  <span>人力资本化</span>
                </div>
              </div>
            </div>
          </transition-group>
        </el-row>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="logo-box">
      <el-row class="logobody">
        <div ref="logo">
          <span class="hezuo"> 合作伙伴 </span>
        </div>
      </el-row>
      <el-row style="margin: 5px 0px 20px 0">
        <el-divider>
          <span class="hezuo2"> 我们的客户来自全国标杆企业和成长型企业 </span>
        </el-divider>
      </el-row>
      <div class="come" ref="sortMenu">
        <el-row class="cards-body">
          <div class="cards red">
            <template v-for="(item, index) in imgsrc">
              <div
                class="card animate__animated animate__delay-0.1s"
                :key="item.id"
                v-if="index < showCount"
              >
                <p class="tip">{{ item.company }}</p>
              </div>
            </template>
          </div>
        </el-row>
      </div>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import "animate.css";
import Flooter from "./flooter.vue";
import header from "./Header.vue";
import Sideframe from "../components/sideframe.vue";
import { nanoid } from "nanoid";
import Template from "../../../yixuefangce-BackgroundManagement/src/components/template.vue";
export default {
  components: {
    Myheader: header,
    Flooter,
    Sideframe,
    Template,
  },
  data() {
    return {
      isShow: false,
      isShow1: true,
      informationData: [],
      fits: ["fill", "contain", "cover", "none", "scale-down"],
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      courseImage2:
        "https://sunledge.com/apis/pic/20231221/49625f48-81cc-4f57-aa55-070ce63c809b.png",
      courseImage3:
        "https://sunledge.com/apis/pic/20231221/8314eeb4-78d4-4821-8e71-6eaa2b2f9031.png",
      courseImage4:
        "https://sunledge.com/apis/pic/20231221/48d81170-3fa4-44ea-aca7-27255a473a75.png",
      courseImage5:
        "https://sunledge.com/apis/pic/20231221/21a77b02-b71f-4f76-bf0c-2e9cbb68da51.png",
      courseImage6:
        "https://sunledge.com/apis/pic/20231221/5b6faa74-649a-4ea2-93ea-3a45d4adbdba.png",
      courseImage7:
        "https://sunledge.com/apis/pic/20231221/bcd9bbf4-d3d8-401b-9a59-d2d666427316.png",
      token: "",
      userData: [],
      imgsrc: [],
      slideshow: [],
      showCount: 0,
      timer: null,
      shoppingCarNum: 0,
    };
  },
  created() {
    this.fetchData();
    this.slideshowData();
    this.shoppingCarNum = window.localStorage.getItem("shoppingCarNum") - 0;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var clientheight = document.documentElement.clientHeight;
      var scrollHeight = document.documentElement.scrollHeight;
      let bodyHeight = this.$refs["box"].getBoundingClientRect().top;
      let logoHeight = this.$refs["logo"].getBoundingClientRect().top;
      if (scrollTop > bodyHeight) {
        this.isShow1 = true;
        this.isShow = true;
      } else if (scrollTop === 0) {
        this.isShow1 = false;
      }
      if (scrollTop > logoHeight) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    async fetchData() {
      const that = this;
      const res = await this.axios.get("/logo/selectStateLogo");
      if (res) {
        let a = {
          id: nanoid(),
          company: ". . .",
        };
        this.imgsrc = res.object;
        this.imgsrc.push(a);
        this.showCount = 0;
        this.timer = setInterval(() => {
          if (this.showCount < this.imgsrc.length) {
            this.showCount++;
          } else {
            clearInterval(this.timer);
          }
        }, 1);
      } else {
        this.$message({
          message: "合作伙伴logo加载失败！",
          type: "error",
        });
      }
    },
    async slideshowData() {
      const that = this;
      const res = await this.axios.get("/Rotation/selectStateRotation");
      if (res) {
        this.slideshow = res.object;
      } else {
        this.$message({
          message: "轮播图加载失败！",
          type: "error",
        });
      }
    },
    toTalentStrategy() {
      this.$router.push({
        path: "/TalentStrategy",
      });
    },
    toManagementTechnology() {
      this.$router.push({
        path: "/ManagementTechnology",
      });
    },
    toCrossBorderLearning() {
      this.$router.push({
        path: "/CrossBorderLearning",
      });
    },
    toTrainingCamp() {
      this.$router.push({
        path: "/TrainingCamp",
      });
    },
    tocourselist() {
      this.$router.push({
        path: "/courselist",
      });
    },
    toHumanCapitalizationDisplay() {
      this.$router.push({
        path: "/HumanCapitalizationDisplay",
      });
    },
    goPage(num) {
      switch (num) {
        case 1:
          //  行业资讯页面跳转
          this.$router.push({
            path: "/industryinformationlist",
          });
          break;
        case 2:
          //  查看课程页面跳转
          this.$router.push({
            path: "/curriculavariable",
          });
          break;
      }
    },
  },
};
</script>
<style>
body {
  overflow-x: hidden;
}
::selection {
  background: rgba(218, 185, 107, 0.8);
}

::selection {
  color: #fff;
  text-shadow: none;
}
.el-carousel__item:nth-child(2n) {
  background-color: #fff !important;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #fff !important;
}
</style>

<style scoped>
.carousel-body {
  background: rgba(244, 243, 243, 0.7);
}

.el-divider__text {
  background: rgba(247, 247, 247);
}

.index-body {
  background-color: rgb(15, 14, 14);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cards {
  width: 80%;
  gap: 15px;
  margin: auto;
}

.red {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.cards .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 80px;
  width: 24%;
  border-radius: 10px;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  -webkit-background-clip: text;
  color: transparent;
  cursor: pointer;
  transition: 400ms;
}
.cards .logoend {
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 80px;
  width: 25%;
  border-radius: 10px;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  　　-webkit-background-clip: text;
  　　background-clip: text;
  　　color: transparent;
  cursor: pointer;
  transition: 400ms;
  font-family: "方正大标宋简体";
  font-weight: bold;
  font-size: 1em;
  position: relative;
  font-weight: 700;
  text-shadow: 1.6px 1.5px rgba(136, 133, 133, 0.3);
}
::v-deep .el-carousel__container img {
  filter: brightness(65%);
  width: 100vw;
  height: 100vh;
  background-size: cover;
  z-index: auto;
}
.cards .card p.tip {
  font-size: 1em;
  font-weight: 700;
  font-family: "方正大标宋简体";
}

.cards .card p.second-text {
  font-size: 0.7em;
}

.cards .card:hover {
  transform: scale(1.1, 1.1);
}

.cards:hover > .card:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}
.logobody {
  margin: 50px 0px 10px 0;
  padding: 50px;
}
.hezuo {
  text-align: center;
  font-size: 32px;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 400;
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hezuo2 {
  z-index: 5;
  padding: 0 40px;
  font-size: 14px;
  color: #999;
  font-weight: normal;
}
.Tbody {
  background: rgba(244, 243, 243, 0.7);
  padding: 50px 0px;
  margin: 20px 0 80px 0;
}
.logo-box {
  padding: 40px;
  background: rgba(244, 243, 243, 0.7);
}
.cards-body {
  min-height: 100vh;
}
.contentbody {
  width: 90vw;
  margin: auto;
  height: 100vh;
}
.boxbody {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.boxbody span {
  height: 45%;
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.card-menu {
  padding: 20px;
  height: fit-content;
  width: 90%;
}
.col {
  box-shadow: 0px 0px 5px 1px rgb(125, 125, 133, 0.5);
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  height: fit-content;
}

.col:hover {
  box-shadow: 0px 0px 5px 2px rgb(125, 125, 133);
}

.col .el-image {
  width: 100%;
  max-height: 300px;
}
.contentText {
  padding: 14px;
  font-weight: 100;
  border: 1px solid rgba(136, 133, 133, 0.2);
  border-right: 0;
  border-top: 0px;
  border-radius: 5px;
}
.contentText span {
  font-size: 20px;
  width: 100%;
}
.logodiv {
  margin: 0 10px 0 70px;
}
.logo {
  height: 60px;
  width: 200px;
  opacity: 0.8;
}

@keyframes load89234 {
  100% {
    background-position: -100% 0;
  }
}
@keyframes load89234 {
  100% {
    background-position: -100% 0;
  }
}
.logo:hover {
  box-shadow: 0px 0px 5px 1px rgba(12, 12, 12, 0.3);
}
/* Chrome, Safari, Opera */
@-webkit-keyframes mymove {
  50% {
    outline-width: 50px;
  }
}

/* Standard syntax */
@keyframes mymove {
  50% {
    outline-width: 50px;
  }
}
::v-deep .el-carousel__container {
  height: 100vh;
}
span {
  font-weight: bolder;
  font-size: 18px;
}
.Myheader {
  position: fixed;
  z-index: 99;
}
.el-carousel {
  /* margin-top: 80px; */
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
}
.el-carousel .des1 {
  transform: translate3d(-11418px, 0px, 0px);
  transition: all 0s ease 0s;
  position: absolute;
  color: white;
  /* top: 0; 
    left: 0;*/
  /* font-family :楷体,sans-serif; */
  font-size: 42px;
  font-weight: normal;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -70%);
  -webkit-transform: translate(-50%, -70%);
  transform: translate(-50%, -70%);
  margin: 0;
  padding: 0;
}
.el-carousel .des {
  position: absolute;
  color: white;
  /* top: 0; 
    left: 0;*/
  font-weight: normal;
  font-size: 20px;
  top: 55%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
}

.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
::v-deep .el-carousel__arrow--right {
  right: 16px;
  line-height: 100%;
}
::v-deep .el-carousel__arrow--left {
  right: 16px;
  line-height: 100%;
}
::v-deep .el-carousel__container h1 {
  height: 100vh;
}
::v-deep .el-carousel__container h1 img {
  height: 100vh;
  width: 100%;
  filter: brightness(80%);
}
.block {
  width: 25%;
  margin: 0;
}
.titleFont {
  color: #909399;
  cursor: pointer;
  font-size: 16px;
  line-height: 29px;
}
.title {
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  line-height: 29px;
}
.el-carousel__item h1 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.titleFont:hover {
  text-decoration: none;
  color: #e7d4a2;
}
@media only screen and (min-width: 1279px) and (max-width: 1535px) {
  ::v-deep .el-carousel__arrow {
    height: 4.25rem;
    width: 4.25rem;
    font-size: 24px;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1280px) {
  ::v-deep .el-carousel__arrow {
    height: 4.25rem;
    width: 4.25rem;
    font-size: 24px;
  }
}
@media only screen and (min-width: 661px) and (max-width: 959px) {
  ::v-deep .el-carousel__arrow {
    height: 6.25rem;
    width: 6.25rem;
    font-size: 32px;
  }
  .col .el-image {
    width: 100%;
    max-height: 800px;
  }
  .el-carousel .des1 {
    font-size: 98px;
  }
  .el-carousel .des {
    font-size: 68px;
    top: 62%;
  }
  .Tbody {
    height: fit-content;
    padding: 50px 0px;
    margin: 80px 0 80px 0;
  }

  .contentbody {
    height: fit-content;
    width: 90vw;
    margin: auto;
  }
  .boxbody {
    height: fit-content;
    width: 100%;
  }
  .boxbody span {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .card-menu {
    border-radius: 15px;
  }
  .col {
    width: 100%;
    border-radius: 15px;
  }
  .contentText {
    padding: 14px;
    font-weight: 100;
    border: 1px solid rgba(136, 133, 133, 0.2);
    border-right: 0;
    border-top: 0px;
    border-radius: 15px;
  }
  .contentText span {
    font-size: 78px;
    width: 100%;
  }
  .logo-box {
    padding: 0;
  }
  .hezuo {
    font-size: 88px;
  }

  .hezuo2 {
    font-size: 58px;
  }
  .cards-body {
    padding: 40px;
  }
  .cards {
    justify-content: space-between;
  }

  .cards .card {
    height: 280px;
    width: 49%;
    border-radius: 10px;
  }
  .cards .card p.tip {
    font-size: 4em;
  }
  .cards .logoend {
    height: 280px;
    width: 50%;
    border-radius: 10px;
    font-size: 4em;
  }
}
@media only screen and (max-width: 660px) {
  ::v-deep .el-carousel__arrow {
    height: 10.25rem;
    width: 10.25rem;
    font-size: 56px;
  }
  .col .el-image {
    width: 100%;
    max-height: 1000px;
  }
  ::v-deep .el-carousel__container img {
    width: 3000px;
    position: absolute; /* 设置图片为绝对定位 */
    top: 50%; /* 将图片向上偏移50% */
    left: 50%; /* 将图片向左偏移50% */
    transform: translate(-50%, -50%);
  }
  .el-carousel .des1 {
    font-size: 98px;
  }
  .el-carousel .des {
    font-size: 78px;
    top: 58%;
  }
  .Tbody {
    height: fit-content;
    padding: 50px 0px;
    margin: 80px 0 80px 0;
  }

  .contentbody {
    height: fit-content;
    width: 90vw;
    margin: auto;
  }
  .boxbody {
    height: fit-content;
    width: 100%;
  }
  .boxbody span {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .card-menu {
    border-radius: 15px;
  }
  .col {
    width: 100%;
    border-radius: 15px;
  }
  .contentText {
    padding: 14px;
    font-weight: 100;
    border: 1px solid rgba(136, 133, 133, 0.2);
    border-right: 0;
    border-top: 0px;
    border-radius: 15px;
  }
  .contentText span {
    font-size: 78px;
    width: 100%;
  }
  .logo-box {
    padding: 0;
  }
  .hezuo {
    font-size: 88px;
  }

  .hezuo2 {
    font-size: 58px;
  }
  .cards-body {
    padding: 40px;
  }
  .cards {
    justify-content: space-between;
  }

  .cards .card {
    height: 280px;
    width: 49%;
    border-radius: 10px;
  }
  .cards .card p.tip {
    font-size: 4em;
  }
  .cards .logoend {
    height: 280px;
    width: 50%;
    border-radius: 10px;
    font-size: 4em;
  }
}
</style>