<template>
  <div>
    <p class="content">剩余支付时间(超时自动关闭)</p>
    <p class="time"><i class="el-icon-time"></i> {{ min }}分钟 {{ sec }}秒</p>

    <div class="select-box">
      <el-radio-group v-model="payType">
        <el-radio-button label="1">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-zhifubao"></use>
          </svg>
          支付宝</el-radio-button
        >
        <el-radio-button label="2"
          ><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-weixin"></use></svg
          >微信
        </el-radio-button>
        <el-radio-button label="3"
          ><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-yinlian"></use></svg
          >银联</el-radio-button
        >
      </el-radio-group>
    </div>

    <template v-if="payType === '2' || payType === '3' || payType === '1'">
      <qrcode :id="'QrCode'" :text="codeUrl" v-if="codeUrl !== ''"></qrcode>
      <div class="whitebox" v-show="codeUrl === ''" v-loading="loading"></div>
      <!-- <iframe
            v-if="payType === '1'"
            :srcdoc="srcdoc"
            frameborder="no"
            border="0"
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            width="200"
            height="200"
            style="overflow: hidden;"
          >
          </iframe> -->
      <p class="content" v-show="codeUrl !== ''">扫描上方二维码进行支付</p>
      <p class="content">（请勿刷新或跳转页面，否则将造成支付失败）</p>
    </template>
  </div>
</template>

<script>
import qrcode from "../views/humanCapitalization/components/Qrcode.vue";
export default {
  inject: ["reload"],
  name: "payCode",
  props: {
    userData: {
      type: Object,
      require: true,
    },
    startTime: {
      type: Number,
      default: 0,
    },
    endTime: {
      type: Number,
      default: 0,
    },
    orderNumber: {
      type: String,
      default: "",
    },
  },
  components: { qrcode },
  data() {
    return {
      payTitle: "支付宝支付",
      payType: "1",
      loading: true,
      countdownType: true,
      timer: "",
      min: "",
      sec: "",
      codeUrl: "",
      outTradeNo: "",
    };
  },
  created() {
    const formData = new FormData();
    formData.append("orderNumber", this.orderNumber);
    this.axios
      .post("/AlipayFaceToFaceController/ZFBPreorderAction", formData)
      .then((res) => {
        console.log(res.qrcode);
        this.codeUrl = res.qrcode;
        this.outTradeNo = res.outTradeNo;
      });
    this.payTitle = "支付宝支付";
  },
  mounted() {
    this.countdown();
  },
  watch: {
    payType(val) {
      if (val === "1") {
        const formData = new FormData();
        formData.append("orderNumber", this.orderNumber);
        this.axios
          .post("/AlipayFaceToFaceController/ZFBPreorderAction", formData)
          .then((res) => {
            this.codeUrl = res.qrcode;
            this.outTradeNo = res.outTradeNo;
          });
        this.payTitle = "支付宝支付";
      } else if (val === "2") {
        this.axios
          .get("/weChatPay/pay", {
            params: {
              orderNumber: this.orderNumber,
            },
          })
          .then((res) => {
            this.codeUrl = res.object.QrCode;
            this.outTradeNo = res.object.out_trade_no;
          });
        this.payTitle = "微信支付";
      } else if (val === "3") {
        this.payTitle = "银联支付";
        alert("银联支付功能待完善");
      }
      if (this.outTradeNo !== "") {
        clearInterval(this.timer);
        this.timer = "";
        this.timer = setInterval(() => {
          this.getStatus(this.outTradeNo);
        }, 2000);
      }
    },
    codeUrl(val) {},
    outTradeNo(val) {},
    startTime(val) {},
    endTime(val) {},
  },
  methods: {
    countdown() {
      const now = new Date().getTime();
      const msec = this.endTime - now;
      if (msec < 0) {
        this.axios
          .get("/order/updateOrder", {
            params: {
              orderNumber: this.orderNumber,
            },
          })
          .then((res) => {
            this.reload();
          });
        return;
      }

      let day = parseInt(msec / 1000 / 60 / 60 / 24);
      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      let sec = parseInt((msec / 1000) % 60);
      let time = {
        min: 0,
        sec: 0,
      };
      this.day = day;
      this.hr = hr > 9 ? hr : "0" + hr;
      this.min = min > 9 ? min : "0" + min;
      this.sec = sec > 9 ? sec : "0" + sec;
      time.min = this.min;
      time.sec = this.sec;

      const that = this;
      if (min >= 0 && sec >= 0) {
        //倒计时结束关闭订单
        if (min <= 0 && sec <= 0) {
          this.axios
            .get("/order/updateOrder", {
              params: {
                orderNumber: this.orderNumber,
              },
            })
            .then((res) => {
              this.outTradeNo = "";
              this.reload();
            });
          return false;
        } else if (!this.countdownType) {
          return false;
        }
        setTimeout(function () {
          that.countdown();
        }, 1000);
      }
    },
    //查看支付状态
    getStatus(out_trade_no) {
      if (this.payType === "1") {
        this.axios
          .get("/AlipayFaceToFaceController/findZFB_tradeAction", {
            params: {
              outTradeNo: this.outTradeNo,
            },
          })
          .then((res) => {
            //成功之后的相关请求
            if (res.code === "10000" && res.data === "yes-payment") {
              this.countdownType = false;
              setTimeout(() => {
                this.$message.success("支付成功!");
                this.outTradeNo = "";
                this.reload();
                this.innerDrawer = !this.innerDrawer;
              }, 500);
            }
          });
      } else if (this.payType === "2") {
        this.axios
          .get("/weChatPay/payState", {
            params: {
              outTradeNo: this.outTradeNo,
            },
          })
          .then((res) => {
            //成功之后的相关请求
            if (res.OrderCode === "SUCCESS") {
              this.countdownType = false;
              setTimeout(() => {
                this.$message.success("支付成功!");
                this.outTradeNo = "";
                this.reload();
                this.innerDrawer = !this.innerDrawer;
              }, 500);
            }
          });
      } else {
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  display: flex;
  justify-content: center;
}
.whitebox {
  width: 100%;
  height: 200px;
}
.icon {
  width: 40px;
  height: 22px;
  display: flex;
  margin: 5px 0;
}
.time {
  color: red;
}
::v-deep .el-radio-button__inner:hover {
  color: black !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #dab96b !important;
  border-color: #dab96b;
  box-shadow: #dab96b;
}
@media (max-width: 768px) {
  .icon {
    width: 100%;
    height: 98px;
    display: flex;
    margin: 20px 0;
  }
  .whitebox {
    height: 400px;
  }
  ::v-deep .el-radio-button__inner {
    width: 280px;
    height: 240px;
    font-size: 68px;
  }
  .content {
    font-size: 68px;
  }
  .time {
    font-size: 68px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .icon {
    width: 100%;
    height: 98px;
    display: flex;
    margin: 20px 0;
  }
  .whitebox {
    height: 400px;
  }
  ::v-deep .el-radio-button__inner {
    width: 280px;
    height: 240px;
    font-size: 48px;
  }
  .content {
    font-size: 48px;
  }
  .time {
    font-size: 48px;
  }
}

@media (min-width: 1025px) {
}
</style>