<template>
  <div class="header">
    <div
      class="menuBox-body"
      :style="
        scrollTop == 0 && this.$route.path === '/home'
          ? 'background-color:rgba(51, 51, 51,0)'
          : 'background-color:rgba(51, 51, 51,0.9)'
      "
    >
      <a id="img" href="/home">
        <img src="https://sunledge.com/apis/pic/202402/20240221101639262.png" />
      </a>
      <div class="icon-box" v-show="!menuBoxShow">
        <i @click="menuBoxShow = !menuBoxShow" class="el-icon-menu"></i>
      </div>
    </div>
    <el-menu
      v-show="menuBoxShow"
      :default-active="this.$route.path"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="rgba(51, 51, 51,0.8)"
      text-color="#fff"
      active-text-color="#DAB96B"
    >
      <div class="icon-box">
        <i @click="menuBoxShow = !menuBoxShow" class="el-icon-menu"></i>
      </div>
      <template v-for="(item, index) in menuData">
        <el-menu-item
          :index="item.routerIndex"
          @click.native="routerIndex(item.routerIndex)"
          id="index-home"
          v-if="item.menuEntity === null"
          :key="item.id"
        >
          {{ item.indexName }}
        </el-menu-item>
        <el-submenu v-else :index="(index + 1).toString()" :key="item.id">
          <template slot="title">{{ item.indexName }}</template>
          <div v-for="(it, index1) in item.menuEntity" :key="it.id">
            <el-menu-item
              v-if="it.menuEntity === null"
              @click.native="routerIndex(it.routerIndex)"
              :index="it.routerIndex"
              >{{ it.indexName }}</el-menu-item
            >
            <el-submenu
              v-else
              :index="(index + 1).toString() + '-' + (index1 + 1).toString()"
              :key="it.id"
            >
              <template slot="title">{{ it.indexName }}</template>
              <div v-for="(i, index2) in it.menuEntity" :key="i.id">
                <el-menu-item
                  @click.native="routerIndex(i.routerIndex)"
                  :index="i.routerIndex"
                >
                  {{ i.indexName }}</el-menu-item
                >
              </div>
            </el-submenu>
          </div>
        </el-submenu>
      </template>
      <el-menu-item
        id="index-home"
        v-if="userData == null || userData == undefined"
        index="7"
        @click.native="routerIndex('/login')"
      >
        登录/注册</el-menu-item
      >
      <el-submenu v-else index="7">
        <template slot="title">
          <span v-if="userData.nickname !== ''">
            欢迎你,{{ userData.nickname }}
            <span
              v-show="userData.member === 1"
              class="icon iconfont icon-VIP"
            ></span>
          </span>
          <span v-else>
            欢迎你,益学学员<span
              v-show="userData.member === 1"
              class="icon iconfont icon-VIP"
            ></span>
          </span>
        </template>
        <el-menu-item index="7-1" @click.native="getusermsg()"
          >个人中心</el-menu-item
        >
        <el-menu-item index="7-2" @click.native="getuserShoppingCart()"
          >购物车<el-badge v-if="carNum > 0" :value="carNum" class="item">
          </el-badge
        ></el-menu-item>
        <el-menu-item index="7-3" @click.native="backLogin()"
          >退出登录</el-menu-item
        >
      </el-submenu>
    </el-menu>
    <el-menu
      :default-active="this.$route.path"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      router
      style="width: 100%"
      background-color="rgba(51, 51, 51,0.9)"
      text-color="#fff"
      active-text-color="#DAB96B"
      :class="{ active: scrollTop == 0 }"
      v-cloak
    >
      <a id="img" href="/home">
        <img src="https://sunledge.com/apis/pic/202402/20240221101639262.png" />
      </a>
      <template v-for="(item, index) in menuData">
        <el-menu-item
          :index="item.routerIndex"
          @click.native="routerIndex(item.routerIndex)"
          id="index-home"
          v-if="item.menuEntity === null"
          :key="item.id"
        >
          {{ item.indexName }}
        </el-menu-item>
        <el-submenu v-else :index="(index + 1).toString()" :key="item.id">
          <template slot="title">{{ item.indexName }}</template>
          <div v-for="(it, index1) in item.menuEntity" :key="it.id">
            <el-menu-item
              v-if="it.menuEntity === null"
              @click.native="routerIndex(it.routerIndex)"
              :index="it.routerIndex"
              >{{ it.indexName }}</el-menu-item
            >
            <el-submenu
              v-else
              class="t1"
              :index="(index + 1).toString() + '-' + (index1 + 1).toString()"
              :key="it.id"
            >
              <template slot="title">{{ it.indexName }}</template>
              <div v-for="(i, index2) in it.menuEntity" :key="i.id">
                <el-menu-item
                  @click.native="routerIndex(i.routerIndex)"
                  :index="i.routerIndex"
                >
                  {{ i.indexName }}</el-menu-item
                >
              </div>
            </el-submenu>
          </div>
        </el-submenu>
      </template>
      <el-col :span="5" :offset="1">
        <router-link
          v-if="userData == null || userData == undefined"
          to="/login"
          >登录/注册</router-link
        >
        <el-dropdown v-else>
          <span class="userlink" v-if="userData.nickname !== ''">
            欢迎你,{{ userData.nickname }}
            <span
              v-show="userData.member === 1"
              class="icon iconfont icon-VIP"
            ></span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <span class="userlink" v-else>
            欢迎你,益学学员<span
              v-show="userData.member === 1"
              class="icon iconfont icon-VIP"
            ></span
            ><i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              class="a"
              icon="el-icon-s-tools"
              @click.native="getusermsg()"
              >个人中心</el-dropdown-item
            >

            <el-dropdown-item
              class="a"
              icon="el-icon-shopping-cart-full"
              @click.native="getuserShoppingCart()"
            >
              购物车
              <el-badge
                v-if="shoppingCarNum > 0"
                :value="shoppingCarNum"
                class="item"
              >
              </el-badge>
            </el-dropdown-item>

            <el-dropdown-item
              class="a"
              icon="el-icon-switch-button"
              @click.native="backLogin()"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-menu>
  </div>
</template>

<script>
// @ is an alias to /src
import Cookies from "js-cookie";
import vipLogo from "../components/vipLogo.vue";
import { nanoid } from "nanoid";
import Template from "../../../yixuefangce-BackgroundManagement/src/components/template.vue";
import { number } from "echarts";
export default {
  components: { vipLogo, Template },
  data() {
    return {
      isCollapse: true,
      scrollTop: 0,
      activeIndex: "",
      uname: "",
      token: "",
      userData: {},
      bodywidth: 0,
      menuData: [],
      menuBoxShow: false,
      carNum: 0,
    };
  },
  props: {
    shoppingCarNum: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.gettoken();
    this.getuserData();
    this.getbodywidth();
    if (localStorage.getItem("menuData")) {
      let storedData = JSON.parse(localStorage.getItem("menuData"));
      if (storedData.expiration === undefined) {
        localStorage.removeItem("menuData");
      } else {
        let currentTime = new Date().getTime();
        if (currentTime > storedData.expiration) {
          // 数据已过期，清除
          localStorage.removeItem("menuData");
          storedData = null;
          this.getMenuData();
        } else {
          this.menuData = storedData.value;
        }
      }
    } else {
      this.getMenuData();
    }

    window.addEventListener("scroll", this.scroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll, true);
  },
  watch: {
    $router(val) {
      this.handleSelect(this.activeIndex);
    },
  },
  methods: {
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.userData = null;
        this.token = null;
      }
    },
    async getuserData() {
      if (this.token == null) {
      } else {
        const res = await this.axios.get("/basicUser/tokenByUser", {
          params: {
            token: this.token,
          },
        });
        if (res.code == 200) {
          this.userData = res.object;
          window.localStorage.setItem(
            "userData",
            JSON.stringify(this.userData)
          );
        } else {
          this.userData = null;
        }
      }
    },
    getMenuData() {
      this.axios
        .get("/menu/selectAllMenu", {
          params: {
            startpage: 1,
            pagesize: 600,
            state: 1,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.menuData = res.object.records;
            let expiration = new Date().getTime() + 1 * 60 * 60 * 1000;
            localStorage.setItem(
              "menuData",
              JSON.stringify({ value: this.menuData, expiration: expiration })
            );
          } else {
            this.$message({
              type: "error",
              message: "获取菜单数据失败，请稍后再试",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
          });
        });
    },
    handleSelect(index) {
      this.activeIndex = index;
    },
    scroll() {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.bodywidth = document.body.clientWidth;
    },
    getbodywidth() {
      this.bodywidth = document.body.clientWidth;
    },
    backLogin() {
      Cookies.remove("token");
      this.userData = null;
      this.$router.push({ path: "/login" });
    },

    getusermsg() {
      this.$router.push({ path: "/personalCenter" });
    },

    getuseroder() {
      this.$router.push({ path: "/userOder" });
    },
    routerIndex(val) {
      this.$router.push({
        path: val,
      });
    },
    getuserShoppingCart() {
      this.$router.push({ path: "/userShoppingCart" });
    },

    getuserEvaluationResults() {
      this.$router.push({ path: "/EvaluationResults" });
    },

    getuserCouser() {
      this.$router.push({ path: "/userCouser" });
    },
  },
};
</script>

<style  scoped>
@keyframes load89234 {
  100% {
    background-position: -100% 0;
  }
}
[v-clock] {
  display: none;
}
.a:hover {
  color: #dab96b !important;
  background-color: rgba(255, 215, 0, 0.2) !important;
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0) !important;
  /* box-shadow: 0px 1px 10px; */
}
.header {
  padding: 0;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  position: fixed;
  z-index: 99;
  width: 100%;
  flex: 1;
}
::v-deep .el-menu {
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  top: 0;
  border-bottom: 0px solid !important;
}
a:visited {
  color: white;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: #dab96b;
}
a {
  text-decoration: none;
  float: right;
}
a:link {
  color: white;
}
.icon-VIP:before {
  content: "\e876";
  font-size: 14px;
  color: rgba(255, 215, 0, 0.8);
}
::v-deep .el-col-5 {
  width: 15%;
}
::v-deep .el-dropdown-menu__item:hover {
  color: #dab96b;
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(84, 92, 100, 0.6) !important;
}

.userlink {
  background-color: rgba(84, 92, 100, 0) !important;
  color: white;
  font-size: 20px;
  padding: 0 20px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  font-weight: 100;
}

#img {
  float: left;
  margin-left: 5%;
}
#index-home {
  color: rgb(255, 255, 255);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: rgba(84, 92, 100, 0) !important;
  font-weight: normal;
}
::v-deep li .el-submenu__title {
  background-color: rgba(84, 92, 100, 0) !important;
  color: white;
  cursor: pointer;
  -webkit-transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  font-weight: normal;
}
#index-home:hover {
  background-color: rgba(84, 92, 100, 0.6) !important;
}
h1 {
  font-size: 80px;
  font-family: talldeco;
  line-height: 1;
  margin: 0;
  padding: 0;
  display: table-cell;
  vertical-align: middle;
}

::v-deep .el-menu-item {
  height: 80px;
  line-height: 90px;
  border-left: 0px solid;
  font-weight: bolder;
}
.el-col {
  background-color: "#545c64";
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  float: left;
  /* font-family :楷体,sans-serif; */
  font-weight: 100;
  margin-left: 10%;
}
.el-menu-vertical-demo {
  display: none;
}
.menuBox-body {
  display: none;
}
@media (max-width: 768px) {
  .header {
    width: 100%;
  }
  #img {
    padding: 10px;
    margin: 0;
  }
  #img img {
    width: 500px;
    height: 290px;
  }
  .el-menu-demo {
    display: none;
  }
  .menuBox-body {
    width: 50%;
    display: block;
    text-align: left;
    position: fixed;
    left: 0;
    height: fit-content;
    width: 100%;
    padding: 10px 0;
    background-color: rgba(51, 51, 51, 0.9);
  }
  .menuBox-body .icon-box {
    text-align: right;
  }
  .el-menu-vertical-demo {
    overflow: auto !important;
    display: block;
    position: fixed;
    right: 0;
    height: 100%;
    width: 50%;
    background-color: rgba(51, 51, 51, 0.9) !important;
  }
  .icon-box {
    padding: 10px 0;
    margin: 0 60px 0 0;
    height: 180px;
    text-align: left;
  }
  .icon-box i {
    font-size: 168px;
    color: #fff;
    cursor: pointer;
  }
  #index-home {
    text-align: left;
    margin: 0px;
    font-size: 68px;
    font-weight: 100;
    padding-left: 20px !important;
    height: 200px;
    line-height: 200px;
  }
  ::v-deep li .el-submenu__icon-arrow {
    font-size: 68px;
    line-height: 20px;
  }
  .icon-VIP:before {
    font-size: 68px;
  }
  ::v-deep .el-dropdown {
    white-space: nowrap;
  }
  .userlink {
    width: 100%;
    font-size: 68px;
  }
  ::v-deep li .el-submenu__title {
    font-size: 68px;
    text-align: left;
    padding-left: 20px !important;
    font-weight: normal;
    height: 200px;
    line-height: 200px;
  }
  ::v-deep .el-menu-item-group__title {
    font-size: 68px;
    text-align: left;
    height: fit-content;
  }
  ::v-deep li .el-menu-item {
    height: 200px;
    line-height: 200px;
    height: fit-content;
    font-size: 68px;
    text-align: left;
    padding-left: 20px !important;
    font-weight: normal;
    background: rgba(84, 92, 100, 0) !important;
  }
  ::v-deep .el-badge__content {
    height: 50px;
    width: 50px;
    font-size: 48px;
    line-height: 50px;
    border-radius: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .el-menu-demo {
    display: none;
  }
  .el-menu-vertical-demo {
    overflow: auto !important;
    display: block;
    position: fixed;
    right: 0;
    height: 100%;
    width: 25% !important;
    background-color: rgba(51, 51, 51, 0.9) !important;
  }
  ::v-deep li .el-submenu__icon-arrow {
    font-size: 48px;
    line-height: 20px;
  }
  ::v-deep li .el-submenu__title {
    font-size: 48px;
    text-align: left;
    padding-left: 20px !important;
    font-weight: normal;
    height: 120px;
    line-height: 120px;
  }
  ::v-deep .el-menu-item-group__title {
    font-size: 48px;
    text-align: left;
    height: 120px;
    line-height: 120px;
  }
  ::v-deep li .el-menu-item {
    height: 120px;
    line-height: 120px;
    font-size: 48px;
    text-align: left;
    padding-left: 20px !important;
    font-weight: normal;
    background: rgba(84, 92, 100, 0) !important;
  }
  #img {
    margin: 0;
  }
  #img img {
    padding: 10px;
    width: 240px;
    height: 140px;
  }
  #index-home {
    text-align: left;
    margin: 0px;
    font-size: 48px;
    font-weight: 100;
    padding-left: 20px !important;
    height: 120px;
    line-height: 120px;
  }
  .icon-VIP:before {
    font-size: 48px;
  }
  ::v-deep .el-dropdown {
    white-space: nowrap;
  }
  .userlink {
    width: 100%;
    font-size: 32px;
  }
  .el-menu-vertical-demo .icon-box {
    justify-content: left;
  }
  .icon-box {
    padding: 10px;
    height: 80px;
    margin: 0 30px 0 0;
    text-align: left;
    display: flex;
    justify-content: right;
    align-items: center;
  }
  .icon-box i {
    font-size: 88px;
    color: #fff;
    cursor: pointer;
  }
  .menuBox-body {
    display: block;
    text-align: left;
    position: fixed;
    left: 0;
    height: fit-content;
    width: 100%;
    padding: 10px 0;
    background-color: rgba(51, 51, 51, 0.9);
  }
  .header {
    width: 100%;
  }
  .menuBox-body .icon-box {
    text-align: right;
  }
  ::v-deep .el-badge__content {
    height: 30px;
    width: 20px;
    font-size: 28px;
    line-height: 30px;
    border-radius: 50%;
  }
}

@media (min-width: 1025px) {
  .icon-VIP:before {
    font-size: 24px;
  }
  .userlink {
    font-size: 24px;
  }
  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 24px;
    height: 80px !important;
    line-height: 80px;
  }

  #index-home {
    margin-left: 50px;
    font-size: 24px;
    height: 80px;
    line-height: 80px;
  }
  #img {
    max-height: 80px;
  }
  #img img {
    width: 150px;
    max-height: 74px;
  }
  .el-col {
    line-height: 80px;
    font-size: 24px;
  }
  .el-menu-vertical-demo {
    display: none;
  }
  .el-dropdown {
    height: 80px;
    font-size: 24px;
  }
  .t1 {
    background: #353535;
  }
  ::v-deep .el-menu-item {
    font-weight: normal;
  }
}
</style>
