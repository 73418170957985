
<template>
  <div>
    <div>
      <el-row style="text-align: center; margin: 20px auto">
        <el-descriptions class="usermessage" :column="2">
          <template slot="extra">
            <el-button type="text" @click="setTitle('修改')" size="small"
              >修改/完善</el-button
            >
            <el-button type="text" @click="setTitle('密码')" size="small"
              >密码修改</el-button
            >
            <el-button type="text" @click="logout()" size="small"
              >账号注销</el-button
            >
          </template>
          <el-descriptions-item label="用户名">{{
            form.nickname
          }}</el-descriptions-item>
          <el-descriptions-item label="真实姓名">{{
            form.username
          }}</el-descriptions-item>
          <el-descriptions-item label="企业名称">{{
            form.enterpriseName
          }}</el-descriptions-item>
          <el-descriptions-item label="所在部门">{{
            form.department
          }}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{
            form.phone
          }}</el-descriptions-item>
          <el-descriptions-item label="出生日期">{{
            form.birthDate
          }}</el-descriptions-item>
          <!-- <el-descriptions-item label="文交所单号" :span="2">{{
            form.culturalExchangeNum
          }}</el-descriptions-item> -->
          <el-descriptions-item
            
            label="性别"
          >
            <span v-if="form.sex == 1">男</span>
            <span v-if="form.sex == 0">女</span>
          </el-descriptions-item>
          <el-descriptions-item label="联系地址">{{
            form.address
          }}</el-descriptions-item>
        </el-descriptions>
        <el-dialog
          :title="title === '修改' ? '用户信息修改' : '密码修改'"
          :visible.sync="dialogFormVisible"
        >
          <div
            style="
              min-height: 220px;
              padding: 25px 0px;
              margin: 0px auto;
              width: 700px;
            "
          >
            <div v-if="title === '密码'">
              <div style="display: flex; justify-content: center; width: 100%">
                <div class="wrapper">
                  <div class="option">
                    <input
                      class="input"
                      type="radio"
                      name="btn"
                      v-model="passwordType"
                      value="1"
                      checked=""
                    />
                    <div class="btn">
                      <span class="span">账号密码</span>
                    </div>
                  </div>
                  <div class="option">
                    <input
                      v-model="passwordType"
                      class="input"
                      type="radio"
                      name="btn"
                      value="2"
                    />
                    <div class="btn">
                      <span class="span">组织密码</span>
                    </div>
                  </div>
                  <div class="option">
                    <input
                      v-model="passwordType"
                      class="input"
                      type="radio"
                      name="btn"
                      value="3"
                    />
                    <div class="btn">
                      <span class="span">培训班密码</span>
                    </div>
                  </div>
                </div>
              </div>
              <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-width="80px"
                v-if="passwordType === '1'"
              >
                <el-form-item
                  label="原密码"
                  prop="pass"
                  style="margin: 100px 0 0 0"
                >
                  <el-input
                    type="password"
                    v-model="ruleForm.pass"
                    autocomplete="off"
                    placeholder="请输入原密码"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="新密码"
                  prop="checkPass"
                  style="margin: 100px 0 0 0"
                >
                  <el-input
                    type="password"
                    v-model="ruleForm.checkPass"
                    autocomplete="off"
                    placeholder="请输入新密码"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-width="80px"
                v-if="passwordType === '2'"
              >
                <el-form-item
                  label="原密码"
                  prop="pass"
                  style="margin: 100px 0 0 0"
                >
                  <el-input
                    type="password"
                    v-model="ruleForm.pass"
                    autocomplete="off"
                    placeholder="请输入原密码"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="新密码"
                  prop="checkPass"
                  style="margin: 100px 0 0 0"
                >
                  <el-input
                    type="password"
                    v-model="ruleForm.checkPass"
                    autocomplete="off"
                    placeholder="请输入新密码"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-width="80px"
                v-if="passwordType === '3'"
              >
                <el-form-item
                  label="原密码"
                  prop="pass"
                  style="margin: 100px 0 0 0"
                >
                  <el-input
                    type="password"
                    v-model="ruleForm.pass"
                    autocomplete="off"
                    placeholder="请输入原密码"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="新密码"
                  prop="checkPass"
                  style="margin: 100px 0 0 0"
                >
                  <el-input
                    type="password"
                    v-model="ruleForm.checkPass"
                    autocomplete="off"
                    placeholder="请输入新密码"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-button
                style="margin-top: 100px"
                @click="updatepassword(ruleForm.checkPass)"
                >确认修改</el-button
              >
            </div>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="80px"
              v-if="title === '修改'"
            >
              <!-- <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item> -->
              <el-form-item label="用户名" prop="nickname">
                <el-input
                  v-model.trim="form.nickname"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="真实姓名" prop="username">
                <el-input
                  v-model.trim="form.username"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-select
                  v-model="form.sex"
                  placeholder="请选择性别"
                  style="float: left; margin-left: 10px"
                >
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="省份" prop="province">
                    <el-select
                      v-model="form.province"
                      placeholder="请选择所在省"
                    >
                      <el-option
                        v-for="item in provinces"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select> </el-form-item
                ></el-col>
                <el-col :span="8"
                  ><el-form-item label="城市" prop="city">
                    <el-select v-model="form.city" placeholder="请选择所在市">
                      <el-option
                        v-for="item in cities"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select> </el-form-item
                ></el-col>
                <el-col :span="8"
                  ><el-form-item label="区县" prop="area">
                    <el-select v-model="form.area" placeholder="请选择所在区">
                      <el-option
                        v-for="item in areas"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select> </el-form-item
                ></el-col>
              </el-row>
              <el-form-item label="地址" prop="address">
                <el-input
                  v-model="form.address"
                  readonly
                  placeholder="请选择"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业名称" prop="enterpriseName">
                <el-input
                  v-model="form.enterpriseName"
                  placeholder="请输入企业名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="所在部门" prop="department">
                <el-input
                  v-model="form.department"
                  placeholder="请输入所在部门"
                ></el-input>
              </el-form-item>
              <el-form-item label="出生日期">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="form.birthDate"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <!-- <el-button type="primary" @click="submitForm('form')">确认修改</el-button> -->
                <!-- <el-button @click="backLogin()">返回登录</el-button> -->
              </el-form-item>
            </el-form>
          </div>
          <div
            style="
              width: 450px;
              height: 520px;
              padding: 100px 0px;
              margin: 0px auto;
            "
            v-if="flag"
          ></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              @click="dialogFormVisible = false"
              v-if="title === '修改'"
              >取 消</el-button
            >
            <el-button type="primary" @click="submit" v-if="title === '修改'"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </el-row>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Cookies from "js-cookie";
import { setTimeout } from "timers";
export default {
  inject: ["reload"],
  components: { Header },
  data() {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    const validatePass1 = (rule, value, callback) => {
      const reg = /^[a-z|A-Z]+\d+$/;
      const falg = reg.test(value);
      if (!value) {
        callback(new Error("请输入新密码"));
      } else if (value.length < 6 || value.length > 18) {
        callback(new Error("密码长度在6-18位"));
      } else if (falg === false) {
        callback(new Error("密码请以字母开头数字结尾"));
      } else if (value === this.ruleForm.pass) {
        callback(new Error("新旧密码一致!"));
      } else {
        callback();
      }
    };
    return {
      activeName: "first",
      dialogFormVisible: false,
      active: 0,
      passwordType: "1",
      pass: "",
      checkPass: "",
      oldPassword: "",
      password: "",
      userData: [],
      options: [
        {
          id: 1,
          value: "培训班密码修改",
        },
        {
          id: 2,
          value: "组织测评密码修改",
        },
      ],
      title: "",
      token: "",
      updatepassfalg: false,
      flag: false,
      times2: 15,
      timer2: null,
      validationFlag: false,
      // 省数据
      provinces: [],
      // 市数据
      cities: [],
      // 区数据
      areas: [],
      provincename: "",
      citiename: "",
      areaname: "",
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      form: {
        oldPassword: "",
        password: "",
        phone: "",
        areaId: "",
        cityId: "",
        provinceId: "",
        nickname: "", //用户名
        username: "", //真实姓名
        sex: "", //性别
        state: 1,
        birthDate: "", //生日
        province: "", //省
        city: "", //市
        area: "", //区
        address: "",
        enterpriseName: "", //企业名称
        department: "",
        trainingPassword: "", //培训班二级密码
        oldTrainingPassword: "",
        culturalExchangeNum: "", //文交所单号
      },
      //年级数据
      gradeDatas: [],
      rules: {
        phone: [
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass1, trigger: "blur" }],
        nickname: [
          { required: true, message: "昵称不可为空", trigger: "blur" },
          { min: 2, max: 8, message: "长度在 2 到 8 个字符", trigger: "blur" },
        ],
        username: [
          { required: true, message: "真实姓名不可为空", trigger: "blur" },
        ],
        enterpriseName: [
          { required: true, message: "企业名称不可为空", trigger: "blur" },
        ],
        department: [
          { required: true, message: "所在部门不可为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.gettoken();
    this.getuserData();
    this.gertprovinces();
  },
  methods: {
    logout() {
      alert("功能开发中。。。");
    },
    async updatepassword(value) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.password = value;
          if (this.passwordType === "3") {
            this.password = value;
            this.oldPassword = this.ruleForm.pass;
            this.updatepassword2();
          } else if (this.passwordType === "2") {
            this.password = value;
            this.oldPassword = this.ruleForm.pass;
            this.updatepassword3();
          } else {
            this.form.oldPassword = this.ruleForm.pass;
            this.updatepassword1();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async updatepassword1() {
      const res = await this.axios.post(
        "/basicUser/updateBasicUser",
        this.form
      );
      if (res.code == 200) {
        this.dialogFormVisible = !this.dialogFormVisible;
        this.$message({
          showClose: true,
          message: "修改成功",
          type: "success",
        });
        this.updatepassfalg = false;
        setTimeout(() => {
          Cookies.remove("token");
          this.userData = null;
          this.$router.push({ path: "/home" });
        }, 1000);
      } else if (res.code == 400) {
        this.$message({
          showClose: true,
          message: res.object,
          type: "error",
        });
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async updatepassword2() {
      const res = await this.axios.get("/basicUser/updatePassword", {
        params: {
          id: this.form.id,
          password: this.password,
          oldPassword: this.oldPassword,
        },
      });
      if (res.code == 200) {
        this.dialogFormVisible = !this.dialogFormVisible;
        this.$message({
          showClose: true,
          message: "修改成功",
          type: "success",
        });
      } else if (res.code === 400) {
        this.$message({
          showClose: true,
          message: res.object,
          type: "error",
        });
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async updatepassword3() {
      const res = await this.axios.get("/group/updatePassword", {
        params: {
          id: this.id,
          password: this.password,
        },
      });
      if (res.code == 200) {
        this.dialogFormVisible = !this.dialogFormVisible;
        this.$message({
          showClose: true,
          message: "修改成功",
          type: "success",
        });
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    next(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.active++ > 2) this.active = 0;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    async getuserData() {
      const res = await this.axios.get("/basicUser/tokenByUser", {
        params: {
          token: this.token,
        },
      });
      if (res.code == 200) {
        this.userData = res.object;
        this.form = this.userData;
        this.form.sex = this.form.sex.toString();
        const a = this.userData.address.split("/");
        this.citiename = a[0] + a[1];
        if (this.form.birthDate) {
          let b = this.moment(this.form.birthDate).format("YYYY-MM-DD");
          this.form.birthDate = b;
        }
        // this.form.province=this.form.provinceId
        // this.form.city=this.form.cityId
        // this.form.area=this.form.areaId
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    setTitle(value) {
      this.dialogFormVisible = true;
      this.title = value;
      this.$nextTick(() => {
        let formIndex;
        value === "修改" ? (formIndex = "form") : (formIndex = "ruleForm");
        this.$refs[formIndex].clearValidate();
      });
    },
    getValidation(e) {
      let phone = {
        loginType: 3,
        identity: this.form.phone, //手机号
        certificate: this.form.password, //密码
      };
      let data = {
        phone: this.form.phone,
        address: this.provincename + this.citiename + this.areaname,
        login: [], //注册信息
        birthDate: this.form.birthDate,
        enterpriseName: this.form.enterpriseName,
        userName: this.form.userName,
        nickName: this.form.nickName,
        sex: this.form.sex,
        vCode: {
          verifyCode: e.code,
          verifyName: e.verifyName,
        },
      };
      data.login.push(phone);
      if (this.$route.params.access_token !== null) {
        let wechat = {
          loginType: 5,
          certificate: this.$route.params.access_token, //access_token
        };
        data.login.push(wechat);
      }
      clientRegister(data).then((res) => {
        if (res !== null) {
          this.validationFlag = false;
          this.times2 = 15;
          this.flag = true;
          this.dialogFormVisible = false;
          this.timer2 = setInterval(() => {
            this.times2--;
            if (this.times2 == 0) {
              clearInterval(this.timer2);
              this.backLogin();
            }
          }, 1000);
        }
      });
    },
    //验证的模态框关闭
    flagchange() {
      this.validationFlag = false;
    },
    //立即注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit();
          this.dialogFormVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async submit() {
      if (
        this.form.birthDate !== null &&
        this.form.birthDate instanceof Object
      ) {
        this.form.birthDate =
          this.form.birthDate.getFullYear() +
          "-" +
          (this.form.birthDate.getMonth() + 1) +
          "-" +
          this.form.birthDate.getDate() +
          " " +
          this.form.birthDate.getHours() +
          ":" +
          this.form.birthDate.getMinutes() +
          ":" +
          this.form.birthDate.getSeconds();
      }
      if (this.provincename !== null && this.provincename !== "") {
        this.form.address =
          this.provincename + "/" + this.citiename + "/" + this.areaname;
      }
      this.form.password = "";
      this.form.oldPassword = "";
      const res = await this.axios.post(
        "/basicUser/updateBasicUser",
        this.form
      );
      if (res.code == 200) {
        this.$message({
          showClose: true,
          message: "修改成功",
          type: "success",
        });
        this.dialogFormVisible = false;
        this.reload();
      } else if (res.code == 400) {
        this.$message({
          showClose: true,
          message: "该手机号已被注册！",
          type: "error",
        });
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async gertprovinces() {
      const res = await this.axios.get("/city/selectAllProvince");
      if (res.code == 200) {
        this.provinces = res.object;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async getcities(id) {
      const res = await this.axios.get("/city/selectAllCity", {
        params: {
          id: id,
        },
      });
      if (res.code == 200) {
        this.cities = res.object;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async getareas(id) {
      const res = await this.axios.get("/city/selectAllArea", {
        params: {
          id: id,
        },
      });
      if (res.code == 200) {
        this.areas = res.object;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    //返回登录页面
    backLogin() {
      this.$router.push({ path: "/login" });
    },
  },
  watch: {
    passwordType(val) {
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
      this.form.password = null;
      this.ruleForm.pass = null;
      this.ruleForm.checkPass = null;
    },
    // 监听省
    "form.province"(newName, oldName) {
      // 初始化城市列表
      for (let i = 0; i < this.provinces.length; i++) {
        if (newName == this.provinces[i].id) {
          this.provincename = this.provinces[i].name;
          this.form.provinceId = newName;
          this.citiename = "";
          this.areaname = "";
          this.form.address = this.provincename;
          this.getcities(newName);
        }
      }
    },
    // 监听市
    "form.city"(newName, oldName) {
      for (let i = 0; i < this.cities.length; i++) {
        if (newName == this.cities[i].id) {
          this.citiename = this.cities[i].name;
          this.form.cityId = this.newName;
          this.areaname = "";
          this.form.address = this.provincename + "/" + this.citiename;
          this.getareas(newName);
        }
      }
    },
    // 监听区
    "form.area"(newName, oldName) {
      for (let i = 0; i < this.areas.length; i++) {
        if (newName == this.areas[i].id) {
          this.areaname = this.areas[i].name;
          this.form.address =
            this.provincename + "/" + this.citiename + "/" + this.areaname;
          this.form.areaId = this.newName;
        }
      }
    },
  },
};
</script>

<style scoped>
::v-deep .boy-style {
  background: #d8e6f7;
}
::v-deep .girl-style {
  background: #fde2e2;
}
.wrapper {
  --font-color-dark: #323232;
  --font-color-light: #fff;
  --bg-color: #fff;
  --main-color: #323232;
  position: relative;
  width: 250px;
  height: 36px;
  background-color: var(--bg-color);
  border: 2px solid var(--main-color);
  border-radius: 34px;
  display: flex;

  flex-direction: row;
  box-shadow: 4px 4px var(--main-color);
}

.option {
  width: 80.5px;
  height: 28px;
  position: relative;
  top: 2px;
  left: 2px;
}

.input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  appearance: none;
  cursor: pointer;
}

.btn {
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span {
  color: var(--font-color-dark);
}

.input:checked + .btn {
  background-color: var(--main-color);
}

.input:checked + .btn .span {
  color: var(--font-color-light);
}
.el-tab-pane {
  padding: 25px;
}
.usermessage {
  font-family: "宋体";
  font-size: 24px;
}
::v-deep .el-descriptions__body {
    padding: 20px;
  }
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}

.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
.el-breadcrumb {
  font-size: 16px;
  margin-right: 40%;
  float: right;
  height: 90px;
  line-height: 90px;
}
h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}
.background {
  background-color: #f7f7f7;
  height: 100px;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #dab96b;
  border-color: #dab96b;
}
@media (max-width: 768px) {
  .usermessage {
    font-family: "宋体";
    font-size: 62px;
  }
  ::v-deep .el-descriptions-row {
    display: flex;
    flex-wrap: wrap;
  }
  ::v-deep .el-descriptions-item {
    width: 100%;
    padding: 20px 0;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .usermessage {
    font-family: "宋体";
    font-size: 42px;
  }
  ::v-deep .el-descriptions__body {
    padding: 40px;
  }
}

@media (min-width: 1025px) {
}
</style>