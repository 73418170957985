<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <!-- <iframe id="inlineFrameExample"
    title="Inline Frame Example"
    width="100%"
    height="400"
    src="https://map.baidu.com/search/%E5%8C%97%E4%BA%AC%E5%B8%82%E6%B5%B7%E6%B7%80%E5%8C%BA%E5%96%84%E7%BC%98%E8%A1%971%E5%8F%B7%E7%AB%8B%E6%96%B9%E5%BA%AD%E5%A4%A7%E5%8E%A6/@12948284.400384087,4836294.061240001,20.82z?querytype=s&da_src=shareurl&wd=%E5%8C%97%E4%BA%AC%E5%B8%82%E6%B5%B7%E6%B7%80%E5%8C%BA%E5%96%84%E7%BC%98%E8%A1%971%E5%8F%B7%E7%AB%8B%E6%96%B9%E5%BA%AD%E5%A4%A7%E5%8E%A6&c=76&src=0&pn=0&sug=0&l=13&b=(11558154,3544899;11619594,3574883)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1">
</iframe> -->
      <!-- <baidu-map class="bm-view" @ready="init" @touchstart="onParentTouchStart"></baidu-map>
       -->
       <div class="bm-view"></div>
      <div class="content-body">
        <div class="content-box">
          <h1 class="corporatename">{{ corporate.name }}</h1>
          <p class="content">{{ corporate.content }}</p>
          <p class="content" style="font-size: 24px; font-family: '宋体'">
            北京
          </p>
          <p class="content">地址：北京市海淀区善缘街1号立方庭大厦</p>
          <p class="content">电话：010-8260 2616</p>
        </div>
        <div class="content-box">
          <h1 class="corporatename">发送反馈信息</h1>
          <p class="content">
            发送反馈信息,我们将在收到信息的第一时间回复您。所有标记为星号（*）的都是必填项。
          </p>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="phone">
              <el-input v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="主题" prop="title">
              <el-input v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="正文" prop="content">
              <el-input type="textarea" v-model="ruleForm.content"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
              <el-button type="primary" @click="resetForm('ruleForm')"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Sideframe from "../../components/sideframe.vue";
import Cookies from "js-cookie";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";
export default {
  components: { Header, Flooter, Sideframe, Breadcrumb },
  data() {
    return {
      token: "",
      titleData: {
        titleName: "联系方式",
        data: [
          {
            id: nanoid(),
            name: "关于我们",
            href: "",
          },
          {
            id: nanoid(),
            name: "联系方式",
            href: "",
          },
        ],
      },
      ruleForm: {
        name: "",
        phone: "",
        title: "",
        content: "",
        state: 0,
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        title: [{ required: true, message: "请输入主题名称", trigger: "blur" }],
        content: [{ required: true, message: "请填写正文", trigger: "blur" }],
      },
      corporate: {
        _id: 5,
        name: "北京益学方策管理咨询有限公司",
        img: "http://www.sunledge.com/images/stories/about.jpg",
        content: "益学方策愿与您携手，为开创美好未来而共同努力！",
      },
    };
  },
  created() {
    this.gettoken();
    this.getuserData();
  },
  mounted() {},
  methods: {
    onParentTouchStart(event) {
      event.preventDefault();
    },
    // init({ BMap, map }) {
    //   this.map = map;
    //   const point = new BMap.Point(116.315595, 39.986712); //设置城市经纬度坐标
    //   map.centerAndZoom(point, 20); //
    //   map.setCurrentCity("北京"); // 设置地图显示的城市 此项是必须设置的
    //   map.enableScrollWheelZoom(true); //鼠标滚动缩放

    //   const marker = new BMap.Marker(point); // 创建标注
    //   map.addOverlay(marker); // 将标注添加到地图中
    //   const opts = {
    //     width: 300, // 信息窗口宽度
    //     height: 250, // 信息窗口高度
    //   };
    //   const infoWindow = new BMap.InfoWindow(
    //     `
    //     <h6 style="color:#CC5522;font-size:18px;margin:5px 0">北京益学方策管理咨询有限公司</h6>
    //         <p style="font-size:18px">电话：010-8260 2616</p>
    //         <p style="font-size:18px">地址：北京市海淀区善缘街1号立方庭大厦</p>
    //         <img src="https://image2.5i5j.com/group2/M00/01/2F/CgqJNFptk2KAVhBAABVuaK7eYGo534.jpg" width="50%"
    //         >`,
    //     opts
    //   ); // 创建信息窗口对象

    //   map.openInfoWindow(infoWindow, point); //打开信息窗口
    //   //监听事件
    //   map.addEventListener("click", function (e) {
    //     map.openInfoWindow(infoWindow, point);
    //   });
    // },
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.userData = null;
        this.token = null;
      }
    },
    async getuserData() {
      if (this.token == null) {

      } else {
        const res = await this.axios.get("/basicUser/tokenByUser", {
          params: {
            token: this.token,
          },
        });
        if (res.code == 200) {
          this.userData = res.object;
        } else {
          this.userData = null;
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addUserFeedback();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async addUserFeedback() {
      console.log(this.userData);
      if (this.userData == null) {
        this.$message({
          showClose: true,
          message: "请登录！",
          type: "info",
        });
      } else {
        const res = await this.axios.post(
          "/userFeedback/addUserFeedback",
          this.ruleForm
        );
        if (res.code == 200) {
          this.$notify({
            title: "成功",
            message: "反馈成功",
            type: "success",
            position: "bottom-right",
          });
        } else {
          this.$message({
            showClose: true,
            message: "请求错误，请稍后重试！",
            type: "error",
          });
        }
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}

.bm-view {
  height: 450px;
  background: url('../../assets/image/ditu.png') no-repeat;
  background-size: cover;
}
.content-body {
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.content-box {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}

::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

.corporate {
  display: block;
  padding-left: 20px;
  font-weight: normal;
  font-size: 28px;
  text-align: left;
  margin: 50px 50px 10px;
  float: left;
}

.corporatename {
  font-family: "方正大标宋简体";
  float: left;
  width: 60%;
  padding-left: 20px;
  font-weight: normal;
  font-size: 28px;
  text-align: left;
  margin: 50px 50px 10px;
}

.content {
  margin: 0 0 40px 0;
  float: left;
  width: 90%;
  height: 55px;
  line-height: 1.875;
  padding-left: 70px;
  text-align: left;
}
.demo-ruleForm {
  width: 90% !important;
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #daac4e;
  border-color: rgb(218, 185, 107);
}

::v-deep .el-button--primary:hover {
  color: #fff;
  background-color: #eddaaf;
  border-color: #f3e4c0;
}

::v-deep .el-button--primary:active {
  color: #fff;
  background-color: #c8951e;
  border-color: #e4a717;
}

h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}

hr {
  margin: 0;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  .bm-view {
    height: 1850px;
  }
  .content-body {
    width: 100%;
    margin: auto;
    display: flex;
  }
  .content-box {
    width: 100% !important;
    margin: 50px 20px;
  }
  .corporatename {
    font-family: "方正大标宋简体";
    text-align: center;
    width: 100%;
    padding: 0px;
    font-weight: normal;
    font-size: 98px;
    margin: 20px 0;
  }

  .content {
    text-align: center;
    margin: 20px 0;
    float: left;
    width: 100%;
    font-size: 58px;
    height: fit-content;
    line-height: 1.875;
    padding: 0;
  }
  .demo-ruleForm {
    width: 90% !important;
  }
  ::v-deep .el-input__inner {
    height: 140px;
    line-height: 1.875;
    font-size: 58px;
  }
  ::v-deep .el-textarea__inner {
    height: 420px;
    line-height: 1.875;
    font-size: 58px;
  }
  ::v-deep .el-button {
    width: 240px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .bm-view {
    height: 950px;
  }
  .content-body {
    width: 90%;
    margin: auto;
    display: flex;
  }
  .content-box {
    width: 100% !important;
    margin: 50px 20px;
  }
  .corporatename {
    font-family: "方正大标宋简体";
    float: left;
    width: 100%;
    padding: 0px;
    font-weight: normal;
    font-size: 48px;
    text-align: center;
    margin: 20px 0;
  }

  .content {
    margin: 20px 0;
    float: left;
    width: 100%;
    font-size: 38px;
    height: fit-content;
    line-height: 1.875;
    padding: 0;
    text-align: center;
  }
  ::v-deep .el-input__inner {
    height: 80px;
    line-height: 1.875;
    font-size: 38px;
  }
  ::v-deep .el-textarea__inner {
    height: 420px;
    line-height: 1.875;
    font-size: 38px;
  }
  ::v-deep .el-button {
    width: 240px;
  }
}
</style>