<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
    "
  >
    <!-- <transition-group
      appear
      style="height: fit-content"
      name="animate__animated animate__bounce animate__delay-1s"
      enter-active-class="animate__fadeIn"
      leave-active-class="animate__fadeOut"
    > -->
    <div class="backImg">
      <div class="cloud_one"></div>
      <div class="cloud_two"></div>
      <div class="cloud_three"></div>
      <!-- <remote-js
          src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"
        ></remote-js> -->
    </div>
    <!-- </transition-group> -->
    <div class="titile">
      <!-- <h2>益学方策</h2> -->
      <img
        @click="goHome"
        class="titleImg"
        src="https://sunledge.com/apis/pic/20231222/e25f0505-d971-4bda-b347-42f5b0049e0f.png"
        alt="益学方策"
      />
    </div>
    <div class="foot-body">
      <div class="foot">
        <span>联系电话：13011015353 (余老师)</span>
        <span>Copyright © 2021 益学方策</span>
        <span>技术支持：电子科技大学</span>
        <a target="_blank" href="https://beian.miit.gov.cn/">
          京ICP备15062349号-1
        </a>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033446"
        >
        </a>
      </div>
    </div>
    <div id="loginPage">
      <div class="header" v-show="!qrcode">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane
            label="手机验证登录"
            name="first"
            v-bind:class="{ active: !loginType }"
            @click="selectLoginType()"
          >
            <el-main v-show="!qrcode">
              <el-form :rules="rules" ref="ruleForm" :model="form">
                <el-form-item prop="phone">
                  <el-input
                    prefix-icon="el-icon-s-custom"
                    placeholder="请输入手机号"
                    v-model="form.phone"
                  ></el-input>
                </el-form-item>
                <transition name="slide-fade">
                  <el-form-item v-if="loginType" prop="verifyCode">
                    <el-input
                      prefix-icon="el-icon-lock"
                      placeholder="请输入验证码"
                      v-model="form.verifyCode"
                      @keyup.enter.native="login"
                    >
                      <el-button
                        v-if="available"
                        slot="append"
                        @click="getCode"
                        class="verifyCode"
                      >
                        获取验证码
                      </el-button>
                      <el-button
                        v-else
                        slot="append"
                        class="verifyCode"
                        disabled
                      >
                        {{ times }}后重新获取
                      </el-button>
                    </el-input>
                  </el-form-item>
                </transition>
                <el-form-item>
                  <el-button
                    type="primary"
                    style="width: 100%"
                    @click="phoneLoginOrR"
                    >登 录</el-button
                  >
                </el-form-item>
                <el-footer class="loginFooter">
                  <span class="dengluStyle">其他登录方式：</span>
                  <div class="logo">
                    <svg
                      class="icon svg-icon"
                      aria-hidden="true"
                      @click="qqLogin"
                    >
                      <use xlink:href="#icon-QQ"></use>
                    </svg>
                  </div>
                  <div class="logo">
                    <svg
                      class="icon svg-icon"
                      aria-hidden="true"
                      @click="weiboLogin"
                    >
                      <use xlink:href="#icon-weibo"></use>
                    </svg>
                  </div>
                  <div class="logo">
                    <svg
                      class="icon svg-icon"
                      aria-hidden="true"
                      @click="zhifubaoLogin"
                    >
                      <use xlink:href="#icon-zhifubao"></use>
                    </svg>
                  </div>
                  <div class="logo">
                    <svg
                      class="icon svg-icon"
                      aria-hidden="true"
                      @click="weixinLogin"
                    >
                      <use xlink:href="#icon-weixin"></use>
                    </svg>
                  </div>
                </el-footer>
                <div class="denglu">
                  <transition name="slide-fade">
                    <router-link
                      to="/findpassword"
                      v-show="!loginType"
                      style="color: #dab96b; margin-right: 10px"
                      >忘记密码</router-link
                    >
                  </transition>
                  <transition name="slide-fade">
                    <router-link
                      to="/logonmessage"
                      v-show="userType == 2"
                      style="float: right; color: #dab96b"
                      >账号注册</router-link
                    >
                  </transition>
                </div>
              </el-form>
            </el-main>
          </el-tab-pane>
          <el-tab-pane
            label="账号密码登录"
            name="second"
            v-bind:class="{ active: loginType }"
            @click="selectLoginType()"
          >
            <el-main>
              <el-form :rules="rules" ref="ruleForm" :model="form">
                <el-form-item prop="phone">
                  <el-input
                    prefix-icon="el-icon-s-custom"
                    placeholder="请输入手机号"
                    v-model="form.phone"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password" v-if="!loginType">
                  <el-input
                    show-password
                    v-model="form.password"
                    prefix-icon="el-icon-lock"
                    type="password"
                    placeholder="请输入密码"
                    @keyup.enter.native="login"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    style="width: 100%"
                    @click="loginAccount"
                    >登 录</el-button
                  >
                </el-form-item>
                <el-footer class="loginFooter">
                  <span class="dengluStyle">其他登录方式：</span>
                  <div class="logo">
                    <svg
                      class="icon svg-icon"
                      aria-hidden="true"
                      @click="qqLogin"
                    >
                      <use xlink:href="#icon-QQ"></use>
                    </svg>
                  </div>
                  <div class="logo">
                    <svg
                      class="icon svg-icon"
                      aria-hidden="true"
                      @click="weiboLogin"
                    >
                      <use xlink:href="#icon-weibo"></use>
                    </svg>
                  </div>
                  <div class="logo">
                    <svg
                      class="icon svg-icon"
                      aria-hidden="true"
                      @click="zhifubaoLogin"
                    >
                      <use xlink:href="#icon-zhifubao"></use>
                    </svg>
                  </div>
                  <div class="logo">
                    <svg
                      class="icon svg-icon"
                      aria-hidden="true"
                      @click="weixinLogin"
                    >
                      <use xlink:href="#icon-weixin"></use>
                    </svg>
                  </div>
                </el-footer>
                <div class="denglu">
                  <transition name="slide-fade">
                    <router-link
                      to="/findpassword"
                      v-show="!loginType"
                      style="color: #dab96b; margin-right: 10px"
                      >忘记密码</router-link
                    >
                  </transition>
                  <transition name="slide-fade">
                    <router-link
                      to="/logonmessage"
                      v-show="userType == 2"
                      style="float: right; color: #dab96b"
                      >账号注册</router-link
                    >
                  </transition>
                </div>
              </el-form>
            </el-main>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-main v-if="qrcode">
        <div
          style="display: flex; font-size: 24px; cursor: pointer"
          @click="qrcode = !qrcode"
        >
          <i class="el-icon-back"></i>
        </div>
        <p>{{ loginState }}</p>
        <qrcode :id="'QrCode'" :text="codeUrl" v-if="codeUrl !== ''"></qrcode>
      </el-main>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import qrcode from "./humanCapitalization/components/Qrcode.vue";
import { nanoid } from "nanoid";
export default {
  data() {
    return {
      codeUrl: "http://naver.com",
      activeName: "first",
      backImg: "backImg4",
      qrcode: false,
      loginState: null,
      userType: 2,
      loginType: true, //登錄方式：true手机验证，false账号密码
      form: {
        //手机号
        phone: "",
        //密码
        password: "",
        //验证码
        verifyCode: "",
      },
      isShow: true,
      phoneData: {
        phone: "",
        verifyCode: "",
      },
      times: 0,
      //可以发送验证码
      available: true,
      accountData: {
        phone: "",
        password: "",
        // userType: "2",
        // loginType: 3,
        // loginMethod: 1,
      },
      backImgurl: {},
      BackgroundImg: [],
      rules: {
        //校验规则
        phone: [
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "密码为必填项", trigger: "change" },
        ],
        verifyCode: [
          { required: true, message: "验证码为必填项", trigger: "change" },
        ],
      },
    };
  },
  created() {
    // this.getBackGroundImg();
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.times);
  },
  methods: {
    getBackGroundImg() {
      let formInline = {
        page: 1,
        limit: 5000,
        backgroundEntity: {
          id: "",
          picType: "背景图片",
        },
      };
      this.axios.post("/background/search", formInline).then((res) => {
        if (res.object.list.length > 0) {
          res.object.list.forEach((item) => {
            let img = {
              id: nanoid(),
              imgPath: item.imgPath,
            };
            this.BackgroundImg.push(img);
          });
          this.isShow = !this.isShow;
          let a = 6;
          this.changeBackgroundImg(a);
          this.times = setInterval(() => {
            this.isShow = !this.isShow;
            a++;
            if (a > this.BackgroundImg.length - 1) {
              a = 0;
            }
            this.changeBackgroundImg(a);
          }, 10000);
        } else {
          this.$message.error("背景图片加载失败");
        }
      });
    },
    changeBackgroundImg(val) {
      this.isShow = !this.isShow;
      this.backImgurl = this.BackgroundImg[val];
    },
    handleClick(tab, event) {
      if (tab.name === "first") {
        if (this.loginType === false) {
          this.selectLoginType();
        } else {
          return false;
        }
      } else {
        if (this.loginType === true) {
          this.selectLoginType();
        } else {
          return false;
        }
      }
    },
    //返回首页
    goHome() {
      this.$router.push({ path: "/" });
    },
    userTypeChange(num) {
      this.userType = num;
      this.wechatLogin = false;
    },
    //微信登录
    weixinLogin() {
      this.loginState = "微信扫码登录";
      this.qrcode = !this.qrcode;
      let obj = new WxLogin({
        self_redirect: false,
        id: "login_container",
        appid: "wxb468ea98f44f3801",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(
          this.$store.state.localUrl + "#/weixinLogin/code"
        ),
        style: "black",
      });
    },
    qqLogin() {
      this.loginState = "QQ扫码登录";
      this.qrcode = !this.qrcode;
      let obj = new WxLogin({
        self_redirect: false,
        id: "login_container",
        appid: "wxb468ea98f44f3801",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(
          this.$store.state.localUrl + "#/weixinLogin/code"
        ),
        style: "black",
      });
    },
    weiboLogin() {
      this.loginState = "微博扫码登录";
      this.qrcode = !this.qrcode;
      let obj = new WxLogin({
        self_redirect: false,
        id: "login_container",
        appid: "wxb468ea98f44f3801",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(
          this.$store.state.localUrl + "#/weixinLogin/code"
        ),
        style: "black",
      });
    },
    zhifubaoLogin() {
      this.loginState = "支付宝扫码登录";
      this.qrcode = !this.qrcode;
      let obj = new WxLogin({
        self_redirect: false,
        id: "login_container",
        appid: "wxb468ea98f44f3801",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(
          this.$store.state.localUrl + "#/weixinLogin/code"
        ),
        style: "black",
      });
    },
    //pc登录
    pclogin() {
      this.wechatLogin = false;
    },
    //短信验证登录
    phoneLoginOrR() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loginCode();
        } else {
          // 表单校验失败
          return false;
        }
      });
    },
    async loginCode() {
      await this.axios
        .get("/Val/signIn", {
          params: {
            telephoneCode: this.form.verifyCode,
            telephoneNumber: this.form.phone,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            Cookies.set("token", res.object, {
              expires: 1,
            });
            this.axios
              .get("/basicUser/tokenByUser", {
                params: {
                  token: res.object,
                },
              })
              .then((res) => {
                if (res.object.charge === "1") {
                  this.$router.push({ path: "/HumanCapitalizationAssessment" });
                } else if (res.object.charge1 === "1") {
                  this.$router.push({
                    path: "/ManpowerCapitalizationImprovementPlan",
                  });
                } else {
                  this.$router.push({ path: "/home" });
                }
              });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              offset: 180,
              duration: 6000,
            });
          }
        });
    },
    //账号密码登录
    loginAccount() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          // 表单校验失败
          return false;
        }
      });
    },
    async login() {
      const res = await this.axios.get("/basicUser/login", {
        params: {
          phone: this.form.phone,
          password: this.form.password,
        },
      });
      if (res.code == 200) {
        //登录成功
        Cookies.set("token", res.object, {
          expires: 1,
        });
        this.$notify({
          title: "登录成功",
          type: "success",
          offset: 100,
        });
        setTimeout(() => {
          this.logining = false;
          this.axios
            .get("/basicUser/tokenByUser", {
              params: {
                token: res.object,
              },
            })
            .then((res) => {
              this.axios
                .get("/shoppingCart/selectOrderByUserId", {
                  params: {
                    byUserId: res.object.id,
                  },
                })
                .then((res) => {
                  window.localStorage.setItem(
                    "shoppingCarNum",
                    res.object.length
                  );
                });

              if (res.object.charge === "1") {
                this.$router.push({ path: "/HumanCapitalizationAssessment" });
              } else if (res.object.charge1 === "1") {
                this.$router.push({
                  path: "/ManpowerCapitalizationImprovementPlan",
                });
              } else if (res.object.charge === "2") {
                this.$router.push({
                  path: "/EvaluationOfHumanCapitalInTrainingClass",
                });
              } else if (res.object.charge1 === "2") {
                this.$router.push({
                  path: "/TrainingCourseHumanCapitalImprovementPlan",
                });
              } else if (res.object.charge === "3") {
                this.$router.push({ path: "/OrganizeHumanCapitalEvaluation" });
              } else if (res.object.charge1 === "3") {
                this.$router.push({
                  path: "/EvaluationOfOrganizationalManpowerCapitalization",
                });
              } else {
                this.$router.push({ path: "/home" });
              }
            });
          this.$store.commit("login", "true");
        }, 1000);
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 180,
          duration: 6000,
        });
      }
    },
    async getCode() {
      if (this.form.phone !== null && this.form.phone !== "") {
        this.times = 60;
        this.available = false;
        this.timer = setInterval(() => {
          this.times--;
          if (this.times == 0) {
            this.available = true;
            clearInterval(this.timer);
          }
        }, 1000);
        await this.axios
          .get("/Val/getCode", {
            params: {
              telephoneNumber: this.form.phone,
            },
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message({
                message: res.msg,
                type: "success",
                offset: 180,
                duration: 6000,
              });
            } else {
              this.$message({
                message: res.msg,
                type: "error",
                offset: 180,
                duration: 6000,
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err,
              type: "error",
            });
          });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    selectLoginType() {
      this.loginType = !this.loginType;
    },

    //教师注册
    teacherRegister() {
      this.$router.push({ path: "/teacherRegister" });
    },
  },
  components: {
    qrcode,
    "remote-js": {
      render(createElement) {
        return createElement("script", {
          attrs: { type: "text/javascript", src: this.src },
        });
      },
      props: {
        src: { type: String, required: true },
      },
    },
  },
};
</script>
<style scoped>
@keyframes sky {
  0% {
    background-color: skyblue;
  }
  50% {
    background-color: #000;
  }
  100% {
    background-color: skyblue;
  }
}
@keyframes cloud {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-42%);
  }
}
::v-deep .el-tabs__nav-wrap {
  height: fit-content;
}
#loginPage {
  overflow: hidden;
  position: relative;
  height: fit-content;
  background: #fff;
  z-index: 1999;
  /* border-radius: 10px 100px / 120px; */
  border-radius: 2%;
}
.backImg {
  background-color: skyblue;
  animation: sky 10s linear infinite;
  width: 100vw;
  height: 100vh;
  z-index: auto;
  position: absolute;
}
.backImg .cloud_one {
  width: 300%;
  height: 100%;
  background: url("../assets/image/cloud_one.png") repeat-x;
  position: fixed;
  background-size:40% 60% ;
  top: 0;
  left: 0;
  animation: cloud 60s linear infinite;
  animation-fill-mode: forwards;
}
.backImg .cloud_two {
  width: 300%;
  height: 100%;
  background: url("../assets/image/cloud_two.png") repeat-x;
  position: fixed;
  background-size:50% 60% ;
  top: 0;
  left: 0;
  animation: cloud 80s linear infinite;
  animation-fill-mode: forwards;
}
.backImg .cloud_three {
  width: 300%;
  height: 100%;
  background: url("../assets/image/cloud_three.png") repeat-x;
  position: fixed;
  top: 0;
  left: 0;
  animation: cloud 100s linear infinite;
  animation-fill-mode: forwards;
}
@media only screen and (min-width: 1536px) and (max-width: 2980px) {
  #loginPage {
    overflow: hidden;
    position: relative;
    top: 25%;
    right: 40%;
    height: fit-content;
    width: 380px;
    background: #fff;
    margin-left: 80%;
    box-shadow: 0px 0px 5px 5px rgb(125, 125, 133, 0.2);
    z-index: 1999;
  }
  .icon {
    width: 20px;
    height: 20px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    cursor: pointer;
    padding: 0 5px;
  }
  ::v-deep .el-link.el-link--info {
    color: #dab96b;
    line-height: 20px;
    font-size: 13px;
    float: left;
  }
  .tabs {
    position: absolute;
    left: -60px;
    width: 60px;
    height: 40px;
    border-radius: 25px 0px 0px 25px;
    background: #ecf5ff;
    color: #c0c4cc;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .loginFooter {
    background-color: #ffff;
    height: 25px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    padding: 0;
  }
  .tabs-student {
    top: 70px;
  }
  .tabs-teacher {
    top: 120px;
  }
  .tabactive {
    left: -80px;
    width: 80px;
    background: #409eff;
    color: #fff;
  }
  .foot-body {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
        to bottom,
        #323232 0%,
        #3f3f3f 40%,
        #1c1c1c 150%
      ),
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.25) 200%
      );
    background-blend-mode: multiply;
  }
  .foot {
    padding: 20px;
    margin: 0px auto;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-content: center;
    font-weight: normal;
    font-size: 16px;
  }
  .foot span {
    color: #ffff;
    font-weight: normal;
    font-size: 15px;
    border-right: 1px solid rgba(224, 230, 237, 0.5);
    padding: 0 10px 0 10px;
  }
  a {
    text-decoration: none;
  }
  .router-link-active {
    text-decoration: none;
  }
  .foot a {
    color: #ffff;
    font-weight: normal;
    font-size: 15px;
    padding: 0 10px 0 10px;
  }

  foot a:hover {
    text-decoration: none;
    color: #e21919;
  }

  .dengluStyle {
    font-size: 12px;
    font-weight: normal;
  }
  .denglu {
    padding: 20px 0 0 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: right;
    font-size: 12px;
    font-weight: normal;
  }

  .el-icon-s-promotion {
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    font-weight: bolder;
  }
  .titile {
    position: fixed;
    top: 0;
    left: 0;
    color: #dab96b;
    font-size: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
  }
  .titile h2 {
    height: fit-content;
    width: fit-content;
    margin: 15px 0 15px 30px;
  }
  .titleImg {
    cursor: pointer;
    margin-left: 50px;
    height: 70px;
    padding: 10px;
  }
  .el-button--primary {
    color: #fff;
    background-color: #dab96b;
    border-color: #dab96b;
  }
}
@media only screen and (min-width: 1279px) and (max-width: 1535px) {
  #loginPage {
    overflow: hidden;
    position: relative;
    top: 25%;
    right: 40%;
    height: fit-content;
    width: 480px;
    background: #fff;
    margin-left: 80%;
    box-shadow: 0px 0px 5px 5px rgb(125, 125, 133, 0.2);
    z-index: 1999;
  }
  .backImg {
    background-position: center center;
  }
  ::v-deep .el-tabs__item {
    font-size: 24px !important;
    height: 60px !important;
    line-height: 60px !important;
  }

  ::v-deep .el-input {
    font-size: 24px !important;
  }

  ::v-deep .el-input__inner {
    height: 60px !important;
    line-height: 60px !important;
  }
  ::v-deep .el-button span {
    height: 40px !important;
    line-height: 40px !important;
    font-size: 24px !important;
  }
  ::v-deep .el-form-item {
    margin-bottom: 28px !important;
  }
  ::v-deep .el-form-item__error {
    font-size: 20px !important;
  }
  ::v-deep .el-link.el-link--info {
    color: #dab96b;
    line-height: 20px;
    font-size: 13px;
    float: left;
  }
  .tabs {
    position: absolute;
    left: -60px;
    width: 60px;
    height: 40px;
    border-radius: 25px 0px 0px 25px;
    background: #ecf5ff;
    color: #c0c4cc;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .loginFooter {
    background-color: #ffff;
    height: 25px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    padding: 0;
  }
  .tabs-student {
    top: 70px;
  }
  .tabs-teacher {
    top: 120px;
  }
  .tabactive {
    left: -80px;
    width: 80px;
    background: #409eff;
    color: #fff;
  }
  .foot-body {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
        to bottom,
        #323232 0%,
        #3f3f3f 40%,
        #1c1c1c 150%
      ),
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.25) 200%
      );
    background-blend-mode: multiply;
  }
  .foot {
    padding: 20px;
    margin: 0px auto;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-content: center;
    font-weight: normal;
    font-size: 16px;
  }
  .foot span {
    color: #ffff;
    font-weight: normal;
    font-size: 18px;
    border-right: 1px solid rgba(224, 230, 237, 0.5);
    padding: 0 10px 0 10px;
  }
  a {
    text-decoration: none;
    font-size: 18px;
  }
  .router-link-active {
    text-decoration: none;
  }
  .foot a {
    color: #ffff;
    font-weight: normal;
    font-size: 18px;
    padding: 0 10px 0 10px;
  }

  foot a:hover {
    text-decoration: none;
    color: #e21919;
  }

  .dengluStyle {
    font-size: 18px;
    font-weight: normal;
  }
  .icon {
    width: 25px;
    height: 25px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    cursor: pointer;
    padding: 0 5px;
  }
  .denglu {
    padding: 20px 0 0 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: right;
    font-size: 12px;
    font-weight: normal;
  }
  .el-icon-s-promotion {
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    font-weight: bolder;
  }
  .titile {
    position: fixed;
    top: 0;
    left: 0;
    color: #dab96b;
    font-size: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
  }
  .titile h2 {
    height: fit-content;
    width: fit-content;
    margin: 15px 0 15px 30px;
  }
  .titleImg {
    cursor: pointer;
    margin-left: 50px;
    height: 70px;
    padding: 20px;
  }
  .el-button--primary {
    color: #fff;
    background-color: #dab96b;
    border-color: #dab96b;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1280px) {
  #loginPage {
    overflow: hidden;
    position: relative;
    top: 25%;
    right: 50%;
    height: fit-content;
    width: 650px;
    background: #fff;
    margin-left: 80%;
    box-shadow: 0px 0px 5px 5px rgb(125, 125, 133, 0.2);
    z-index: 1999;
  }
  .backImg {
    background-position: center center;
  }
  ::v-deep .el-tabs__item {
    font-size: 24px !important;
    height: 80px !important;
    line-height: 80px !important;
  }
  ::v-deep .el-input {
    font-size: 24px !important;
  }

  ::v-deep .el-input__inner {
    height: 60px !important;
    line-height: 60px !important;
  }
  ::v-deep .el-input__icon {
    line-height: 60px !important;
    width: 30px !important;
  }
  ::v-deep .el-button span {
    height: 40px !important;
    line-height: 40px !important;
    font-size: 24px !important;
  }
  ::v-deep .el-form-item {
    margin-bottom: 28px !important;
  }
  ::v-deep .el-form-item__error {
    font-size: 20px !important;
  }
  ::v-deep .el-link.el-link--info {
    color: #dab96b;
    line-height: 20px;
    font-size: 13px;
    float: left;
  }
  .tabs {
    position: absolute;
    left: -60px;
    width: 60px;
    height: 40px;
    border-radius: 25px 0px 0px 25px;
    background: #ecf5ff;
    color: #c0c4cc;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .loginFooter {
    background-color: #ffff;
    height: 25px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    padding: 0;
  }
  .tabs-student {
    top: 70px;
  }
  .tabs-teacher {
    top: 120px;
  }
  .tabactive {
    left: -80px;
    width: 80px;
    background: #409eff;
    color: #fff;
  }
  .foot-body {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
        to bottom,
        #323232 0%,
        #3f3f3f 40%,
        #1c1c1c 150%
      ),
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.25) 200%
      );
    background-blend-mode: multiply;
  }
  .foot {
    padding: 30px;
    margin: 0px auto;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-content: center;
    font-weight: normal;
    font-size: 16px;
  }
  .foot span {
    color: #ffff;
    font-weight: normal;
    font-size: 24px;
    border-right: 1px solid rgba(224, 230, 237, 0.5);
    padding: 0 10px 0 10px;
  }
  a {
    text-decoration: none;
    font-size: 20px;
  }
  .router-link-active {
    text-decoration: none;
  }
  .foot a {
    color: #ffff;
    font-weight: normal;
    font-size: 24px;
    padding: 0 10px 0 10px;
  }

  foot a:hover {
    text-decoration: none;
    color: #e21919;
  }

  .dengluStyle {
    font-size: 20px;
    font-weight: normal;
  }
  .icon {
    width: 30px;
    height: 30px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    cursor: pointer;
    padding: 0 5px;
  }
  .denglu {
    padding: 20px 0 0 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: right;
    font-size: 12px;
    font-weight: normal;
  }
  .el-icon-s-promotion {
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    font-weight: bolder;
  }
  .titile {
    position: fixed;
    top: 0;
    left: 0;
    color: #dab96b;
    font-size: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
  }
  .titile h2 {
    height: fit-content;
    width: fit-content;
    margin: 15px 0 15px 30px;
  }
  .titleImg {
    cursor: pointer;
    margin-left: 50px;
    height: 90px;
    padding: 20px;
  }
  .el-button--primary {
    color: #fff;
    background-color: #dab96b;
    border-color: #dab96b;
  }
}
@media only screen and (min-width: 661px) and (max-width: 959px) {
  #loginPage {
    overflow: hidden;
    position: relative;
    top: 25%;
    left: 25%;
    height: fit-content;
    width: 60rem;
    background: #fff;
    box-shadow: 0px 0px 5px 5px rgb(125, 125, 133, 0.2);
    z-index: 1999;
  }
  .backImg {
    background-position: center center;
  }
  ::v-deep .el-tabs__item {
    font-size: 38px !important;
    height: 120px !important;
    line-height: 120px !important;
  }
  ::v-deep .el-input {
    font-size: 32px !important;
  }

  ::v-deep .el-input__inner {
    height: 90px !important;
    line-height: 90px !important;
    padding-left: 80px !important;
  }
  ::v-deep .el-input__icon {
    line-height: 90px !important;
    width: 80px !important;
  }
  ::v-deep .el-button span {
    height: 70px !important;
    line-height: 70px !important;
    font-size: 32px !important;
  }
  ::v-deep .el-form-item {
    margin-bottom: 32px !important;
  }
  ::v-deep .el-form-item__error {
    font-size: 28px !important;
  }
  ::v-deep .el-link.el-link--info {
    color: #dab96b;
    line-height: 20px;
    font-size: 13px;
    float: left;
  }
  .tabs {
    position: absolute;
    left: -60px;
    width: 60px;
    height: 40px;
    border-radius: 25px 0px 0px 25px;
    background: #ecf5ff;
    color: #c0c4cc;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .loginFooter {
    background-color: #ffff;
    height: 25px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    padding: 0;
  }
  .tabs-student {
    top: 70px;
  }
  .tabs-teacher {
    top: 120px;
  }
  .tabactive {
    left: -80px;
    width: 80px;
    background: #409eff;
    color: #fff;
  }
  .foot-body {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
        to bottom,
        #323232 0%,
        #3f3f3f 40%,
        #1c1c1c 150%
      ),
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.25) 200%
      );
    background-blend-mode: multiply;
  }
  .foot {
    padding: 40px;
    margin: 0px auto;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-content: center;
    font-weight: normal;
    font-size: 18px;
  }
  .foot span {
    color: #ffff;
    font-weight: normal;
    font-size: 32px;
    border-right: 1px solid rgba(224, 230, 237, 0.5);
    padding: 0 10px 0 10px;
  }
  a {
    text-decoration: none;
    font-size: 32px;
  }
  .router-link-active {
    text-decoration: none;
  }
  .foot a {
    color: #ffff;
    font-weight: normal;
    font-size: 32px;
    padding: 0 10px 0 10px;
  }

  foot a:hover {
    text-decoration: none;
    color: #e21919;
  }

  .dengluStyle {
    font-size: 32px;
    font-weight: normal;
  }
  .icon {
    width: 50px;
    height: 50px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    cursor: pointer;
    padding: 0 5px;
  }
  .denglu {
    padding: 20px 0 0 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: right;
    font-size: 32px;
    font-weight: normal;
  }
  .el-icon-s-promotion {
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    font-weight: bolder;
  }
  .titile {
    position: fixed;
    top: 0;
    left: 0;
    color: #dab96b;
    font-size: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
  }
  .titile h2 {
    height: fit-content;
    width: fit-content;
    margin: 15px 0 15px 30px;
  }
  .titleImg {
    cursor: pointer;
    margin-left: 50px;
    height: 100px;
    padding: 20px;
  }
  .el-button--primary {
    color: #fff;
    background-color: #dab96b;
    border-color: #dab96b;
  }
}
@media only screen and (max-width: 660px) {
  #loginPage {
    top: 25%;
    right: 70%;
    width: 100rem;
    margin-left: 80%;
    box-shadow: 0px 0px 5px 5px rgb(125, 125, 133, 0.2);
    z-index: 1999;
  }
  .backImg {
    background-position: center center;
  }
  ::v-deep .el-tabs__item {
    font-size: 58px !important;
    height: 210px !important;
    line-height: 210px !important;
  }

  ::v-deep .el-input {
    font-size: 58px !important;
    padding: 10px 0;
  }

  ::v-deep .el-input__inner {
    height: 190px !important;
    line-height: 190px !important;
    padding-left: 80px !important;
    border-radius: 20px;
  }
  ::v-deep .el-input__icon {
    line-height: 190px !important;
    width: 80px !important;
  }
  ::v-deep .el-button span {
    height: 170px !important;
    line-height: 170px !important;
    font-size: 58px !important;
  }
  ::v-deep .el-form-item {
    margin-bottom: 58px !important;
  }
  ::v-deep .el-form-item__error {
    font-size: 54px !important;
  }

  ::v-deep .el-link.el-link--info {
    color: #dab96b;
    line-height: 20px;
    font-size: 13px;
    float: left;
  }
  .tabs {
    position: absolute;
    left: -60px;
    width: 60px;
    height: 40px;
    border-radius: 25px 0px 0px 25px;
    background: #ecf5ff;
    color: #c0c4cc;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .loginFooter {
    background-color: #ffff;
    height: 200px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    padding: 20px 0;
  }
  .tabs-student {
    top: 70px;
  }
  .tabs-teacher {
    top: 120px;
  }
  .tabactive {
    left: -80px;
    width: 80px;
    background: #409eff;
    color: #fff;
  }
  .foot-body {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
        to bottom,
        #323232 0%,
        #3f3f3f 40%,
        #1c1c1c 150%
      ),
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.25) 200%
      );
    background-blend-mode: multiply;
  }
  .foot {
    padding: 40px;
    margin: 0px auto;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-content: center;
    font-weight: normal;
    font-size: 18px;
  }
  .foot span {
    color: #ffff;
    font-weight: normal;
    font-size: 48px;
    border-right: 1px solid rgba(224, 230, 237, 0.5);
    padding: 0 10px 0 10px;
  }
  a {
    text-decoration: none;
    font-size: 58px;
  }
  .router-link-active {
    text-decoration: none;
  }
  .foot a {
    color: #ffff;
    font-weight: normal;
    font-size: 48px;
    padding: 0 10px 0 10px;
  }

  foot a:hover {
    text-decoration: none;
    color: #e21919;
  }

  .dengluStyle {
    font-size: 58px;
    font-weight: normal;
  }
  .icon {
    width: 80px;
    height: 80px;
    margin: 0 20px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    cursor: pointer;
    padding: 0 5px;
  }
  .denglu {
    padding: 20px 0 0 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: right;
    font-size: 32px;
    font-weight: normal;
  }
  .el-icon-s-promotion {
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    font-weight: bolder;
  }
  .titile {
    position: fixed;
    top: 0;
    left: 0;
    color: #dab96b;
    font-size: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
  }
  .titile h2 {
    height: fit-content;
    width: fit-content;
    margin: 15px 0 15px 30px;
  }
  .titleImg {
    cursor: pointer;
    margin-left: 50px;
    height: 380px;
    padding: 20px;
  }
  .el-button--primary {
    color: #fff;
    background-color: #dab96b;
    border-color: #dab96b;
  }
}
</style>    