import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'

import Index from '@/views/Index.vue'
import flooter from '@/views/flooter.vue'
import asidetitle from '@/components/asidetitle.vue'
import login from '@/views/login.vue'
import logonmessage from '@/views/logonmessage.vue'
import EvaluationResults from '@/views/userMessage/EvaluationResults.vue'
import teachercertification from '@/views/corporateTraining/teachercertification.vue'
import QRcode from '@/views/learning/QRcode.vue'
import findpassword from '@/views/findpassword.vue'
import courselist from '@/views/learning/courselist.vue'
import VideoList from '@/views/learning/VideoList.vue'
import corporateInformation from '@/views/aboutUs/corporate-information.vue'
import ContactInformation from '@/views/aboutUs/Contact-information.vue'
import LearningPower from '@/views/managementConsulting/Learning-power.vue'
import TalentStrategy from '@/views/managementConsulting/Talent-strategy.vue'
import StrategicConsensusCamp from '@/views/managementConsulting/Strategic-Consensus-Camp.vue'
import Exquisitedesignprojects from '@/views/managementConsulting/Exquisite-design-projects.vue'
import Learnaboutprojectdesign from '@/views/managementConsulting/Learn-about-project-design.vue'
import Learnpathplanning from '@/views/managementConsulting/Learn-path-planning.vue'
import TheConstructionOfTheLearningSystem from '@/views/managementConsulting/The-construction-of-the-learning-system.vue'
import InternalTrainerManagementSystem from '@/views/managementConsulting/Internal-trainer-management-system.vue'
import HybridProjectDesign from '@/views/managementConsulting/Hybrid-project-design.vue'
import salesManagement from '@/views/corporateTraining/sales-management.vue'
import UniversalQuality from '@/views/corporateTraining/Universal-quality.vue'
import PublicClass from '@/views/corporateTraining/Public-class.vue'
import ManagementTechnology from '@/views/corporateTraining/Management-technology.vue'
import Leadership from '@/views/corporateTraining/Leadership.vue'
import CrossBorderLearning from '@/views/corporateTraining/Cross-border-learning.vue'
const ProductionManagement = () => import('@/views/corporateTraining/ProductionManagement.vue')
import CourseDevelopmentAndInstructorDevelopment from '@/views/corporateTraining/Course-development-and-instructor-development.vue'
import HumanCapitalizationAssessment from '@/views/humanCapitalization/Human-capitalization-assessment.vue'
import ManpowerCapitalizationImprovementPlan from '@/views/humanCapitalization/ManpowerCapitalizationImprovementPlan.vue'
import OrganizeHumanCapitalEvaluation from '@/views/humanCapitalization/OrganizeHumanCapitalEvaluation.vue'
import EvaluationOfOrganizationalManpowerCapitalization from '@/views/humanCapitalization/EvaluationOfOrganizationalManpowerCapitalization.vue'
import EvaluationOfHumanCapitalInTrainingClass from '@/views/humanCapitalization/EvaluationOfHumanCapitalInTrainingClass.vue'
import TrainingCourseHumanCapitalImprovementPlan from '@/views/humanCapitalization/TrainingCourseHumanCapitalImprovementPlan.vue'
const publicClassContent = () => import('@/views/corporateTraining/artContent/publicClassContent')
const HumanCapitalizationDisplay = () => import('@/views/humanCapitalization/Human-capitalization-display.vue')
const HumanCapitalizationDisplayC = () => import('@/views/humanCapitalization/Human-capitalization-displayC.vue')
import TheValueOfHumanCapital from '@/views/humanCapitalization/The-value-of-human-capital.vue'
import TrainingCamp from '@/views/learning/TrainingCamp.vue'
import Article from '@/views/corporateTraining/artContent/Article.vue'
import teacherMessage from '@/views/corporateTraining/artContent/teacherMessage.vue'
import courseMessage from '@/views/learning/courseMessage/courseMessage.vue'
import userMessage from '@/views/userMessage/userMessage.vue'
import userCouser from '@/views/userMessage/userCouser.vue'
import userOder from '@/views/userMessage/userOder.vue'
import userShoppingCart from '@/views/userMessage/userShoppingCart.vue'
import couresVideo from '@/views/learning/courseMessage/couresVideo.vue'
import questions from '@/views/humanCapitalization/questions/questions.vue';
import result from '@/views/humanCapitalization/questions/result.vue';
import IntroductionToHumanCapitalProducts from '@/views/humanCapitalization/IntroductionToHumanCapitalProducts.vue'
import humanCp from '@/views/humanCapitalization/humanMessage/humancp.vue'
import humanCv from '@/views/humanCapitalization/humanMessage/humancv.vue'
import personalCenter from '@/views/userMessage/personalCenter.vue'


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  /**自动跳转 */
  {
    path: '/',
    redirect: '/home',

  },
  /**主页 */
  {
    path: '/home',
    name: 'Index',
    component: Index,

  },
  /**页脚 */
  {
    path: '/flooter',
    name: 'flooter',
    component: flooter,

  },
  /**快捷导航组件 */
  {
    path: '/asidetitle',
    name: 'asidetitle',
    component: asidetitle,

  },
  /**用户信息管理 */
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: personalCenter,
    meta: { isAuth: true }
  },
  {
    path: '/userMessage',
    name: 'userMessage',
    component: userMessage,
    meta: { isAuth: true }
  },
  {
    path: '/userOder',
    name: 'userOder',
    component: userOder,
    meta: { isAuth: true }
  },
  {
    path: '/userCouser',
    name: 'userCouser',
    component: userCouser,
    meta: { isAuth: true }
  },
  {
    path: '/userShoppingCart',
    name: 'userShoppingCart',
    component: userShoppingCart,
    meta: { isAuth: true }
  },
  {
    path: '/EvaluationResults',
    name: 'EvaluationResults',
    component: EvaluationResults,
    meta: { isAuth: true }
  },
  /**管理咨询 */
  {
    path: '/TalentStrategy',
    name: 'TalentStrategy',
    component: TalentStrategy,

  },
  {
    path: '/HybridProjectDesign',
    name: 'HybridProjectDesign',
    component: HybridProjectDesign,

  },
  {
    path: '/InternalTrainerManagementSystem',
    name: 'InternalTrainerManagementSystem',
    component: InternalTrainerManagementSystem,

  },
  {
    path: '/TheConstructionOfTheLearningSystem',
    name: 'TheConstructionOfTheLearningSystem',
    component: TheConstructionOfTheLearningSystem,

  },
  {
    path: '/Learnpathplanning',
    name: 'Learnpathplanning',
    component: Learnpathplanning,

  },
  {
    path: '/Learnaboutprojectdesign',
    name: 'Learnaboutprojectdesign',
    component: Learnaboutprojectdesign,

  },
  {
    path: '/Exquisitedesignprojects',
    name: 'Exquisitedesignprojects',
    component: Exquisitedesignprojects,

  },
  {
    path: '/StrategicConsensusCamp',
    name: 'StrategicConsensusCamp',
    component: StrategicConsensusCamp,

  },
  {
    path: '/LearningPower',
    name: 'LearningPower',
    component: LearningPower,

  },
  /**企业培训 */
  {
    path: '/salesManagement',
    name: 'salesManagement',
    component: salesManagement,

  },
  {
    path: '/UniversalQuality',
    name: 'UniversalQuality',
    component: UniversalQuality,

  },
  {
    path: '/PublicClass',
    name: 'PublicClass',
    component: PublicClass,

  },
  {
    path: '/publicClassContent',
    name: 'publicClassContent',
    component: publicClassContent,
  },
  {
    path: '/ProductionManagement',
    name: 'ProductionManagement',
    component : ProductionManagement
  },
  {
    path: '/ManagementTechnology',
    name: 'ManagementTechnology',
    component: ManagementTechnology,

  },
  {
    path: '/Leadership',
    name: 'Leadership',
    component: Leadership,

  },
  {
    path: '/CrossBorderLearning',
    name: 'CrossBorderLearning',
    component: CrossBorderLearning,

  },
  {
    path: '/CourseDevelopmentAndInstructorDevelopment',
    name: 'CourseDevelopmentAndInstructorDevelopment',
    component: CourseDevelopmentAndInstructorDevelopment,

  },
  {
    path: '/teachercertification',
    name: 'teachercertification',
    component: teachercertification,

  },
  {
    path: '/teacherMessage',
    name: 'teacherMessage',
    component: teacherMessage,

  },
  /**文章内容详情 */
  {
    path: '/Article',
    name: 'Article',
    component: Article,

  },
  /**人力资本化 */
  {
    path: '/HumanCapitalizationAssessment',
    name: 'HumanCapitalizationAssessment',
    component: HumanCapitalizationAssessment,
    meta: { isAuth: true }
  },
  {
    path: '/ManpowerCapitalizationImprovementPlan',
    name: 'ManpowerCapitalizationImprovementPlan',
    component: ManpowerCapitalizationImprovementPlan,
    meta: { isAuth: true }
  },
  {
    path: '/OrganizeHumanCapitalEvaluation',
    name: 'OrganizeHumanCapitalEvaluation',
    component: OrganizeHumanCapitalEvaluation,
    meta: { isAuth: true }
  },
  {
    path: '/EvaluationOfOrganizationalManpowerCapitalization',
    name: 'EvaluationOfOrganizationalManpowerCapitalization',
    component: EvaluationOfOrganizationalManpowerCapitalization,
    meta: { isAuth: true }
  },
  {
    path: '/HumanCapitalizationDisplay',
    name: 'HumanCapitalizationDisplay',
    component: HumanCapitalizationDisplay,

  },
  {
    path: '/EvaluationOfHumanCapitalInTrainingClass',
    name: 'EvaluationOfHumanCapitalInTrainingClass',
    component: EvaluationOfHumanCapitalInTrainingClass,
    meta: { isAuth: true }
  },
  {
    path: '/TrainingCourseHumanCapitalImprovementPlan',
    name: 'TrainingCourseHumanCapitalImprovementPlan',
    component: TrainingCourseHumanCapitalImprovementPlan,
    meta: { isAuth: true }
  },
  {
    path: '/HumanCapitalizationDisplay/subData',
    name: 'HumanCapitalizationDisplayC',
    component: HumanCapitalizationDisplayC,

  },
  , {
    path: '/TheValueOfHumanCapital',
    name: 'TheValueOfHumanCapital',
    component: TheValueOfHumanCapital,

  },
  {
    path: '/IntroductionToHumanCapitalProducts',
    name: 'IntroductionToHumanCapitalProducts',
    component: IntroductionToHumanCapitalProducts,

  },
  {
    path: '/questions',
    name: 'questions',
    component: questions,
    meta: { isAuth: true }
  },
  {
    path: '/result',
    name: 'result',
    component: result,
    meta: { isAuth: true }
  },
  {
    path: '/humanCp',
    name: 'humanCp',
    component: humanCp,

  },
  {
    path: '/humanCv',
    name: 'humanCv',
    component: humanCv,
  },
  /**益学研习社 */
  {
    path: '/TrainingCamp',
    name: 'TrainingCamp',
    component: TrainingCamp,

  },
  {
    path: '/courseVideo',
    name: 'courseVideo',
    component: couresVideo,
    meta: { isAuth: true }
  },
  {
    path: '/couresMessage',
    name: 'courseMessage',
    component: courseMessage,

  },
  /**线上学习中心 */
  {
    path: '/courselist',
    name: 'courselist',
    component: courselist,

  },
  {
    path: '/VideoList',
    name: 'VideoList',
    component: VideoList,

  },
  {
    path: '/QRcode',
    naem: 'QRcode',
    component: QRcode,

  },
  /**关于我们 */
  {
    path: '/corporateInformation',
    name: 'corporateInformation',
    component: corporateInformation,

  },
  {
    path: '/ContactInformation',
    name: 'ContactInformation',
    component: ContactInformation,

  },
  /**找回密码 */
  {
    path: '/findpassword',
    name: 'findpassword',
    component: findpassword,

  },
  /**注册 */
  {
    path: '/logonmessage',
    name: 'logonmessagee',
    component: logonmessage,

  },
  /**登录 */
  {
    path: '/login',
    name: 'login',
    component: login,

  },
  /**报错 */
  {
    path: '*',
    name: 'Error',
    component: () => import('@/views/error/Error.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: "history"
})

const vm = new Vue()

//全局前置路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.isAuth) {
    let atoken = Cookies.get("token");
    if (atoken) {
      vm.axios.get("/basicUser/tokenByUser", {
        params: {
          token: atoken
        }
      }).then(res => {
        if (res) {
          next()
        } else {
          this.$message({
            showClose: true,
            message: "请先登录后进行操作",
            type: "info"
          });
          next({
            path: "/login",
            query: {
              redirect: to.fullPath // 把要跳转的页面路径作为参数传到登录页面
            }
          });
        }
      })
    } else {
      vm.$message({
        showClose: true,
        message: "请先登录后进行操作",
        type: "info"
      });
      next({
        path: "/login",
        query: {
          redirect: to.fullPath // 把要跳转的页面路径作为参数传到登录页面
        }
      });
    }
  } else {
    next()
  }


})

export default router
