<template>
    <div>
      <el-row style="text-align: center">
        <el-form :inline="true" :model="courseEnitity" class="user-search">
          <el-form-item label="搜索：">
            <el-input
              size="small"
              v-model="courseEnitity.courseName"
              placeholder="输入课程名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="value"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" icon="el-icon-search" @click="search"
              >搜索</el-button
            >
            <el-button size="small" icon="el-icon-delete-solid" @click="empty"
              >清空</el-button
            >
            <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button> -->
          </el-form-item>
        </el-form>
        <el-empty
          description="你还没有购买任何课程"
          v-if="courseData.length === 0"
        >
          <el-button type="warning" plain @click="toCourse()">去看看</el-button>
        </el-empty>
        <el-table
          v-else
          :data="courseData"
          style="width: 100%"
          max-height="500"
        >
          <el-table-column prop="courseName" label="课程名"> </el-table-column>
          <el-table-column prop="coursePrice" label="购买价格(¥)">
          </el-table-column>
          <el-table-column prop="aboutInstructor" label="讲师">
          </el-table-column>
          <el-table-column prop="courseIntroduction" label="课程介绍">
          </el-table-column>
          <el-table-column prop="courseTime" label="开课时间">
          </el-table-column>

          <el-table-column prop="courseImg" label="课程图片">
            <template slot-scope="scope">
              <img :src="scope.row.courseImg" style="height: 100px" />
            </template>
          </el-table-column>
          <el-table-column prop="addTime" label="购买时间"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-if="courseData.length !== 0"
          :child-msg="pageparm"
          @call="callFather"
        ></Pagination>
      </el-row>
    </div>
</template>

<script>
import Header from "../Header.vue";
import Cookies from "js-cookie";
import Pagination from "../../components/Pagination";
export default {
  components: { Header, Pagination },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value: "",
      userData: [],
      pageparm: {
        currentPage: 1,
        pageSize: 5,
        total: 10,
      },
      courseEnitity: {
        aboutInstructor: "",
        addTime: "",
        charge: "",
        courseImg: "",
        courseIntroduction: "",
        courseName: "",
        coursePrice: "",
        courseTime: "",
        courseTypeName: "",
        curriculumTypeId: "",
        endTime: "",
        id: "",
        industryId: "",
        industryName: "",
        industryTypeId: "",
        industryTypeName: "",
        repeat: "",
        startTime: "",
        state: "",
        updateTime: "",
        userId: "",
      },
      token: "",
      userId: "",
      courseData: [],
      state: "",
    };
  },
  created() {
    this.gettoken();
    this.getuserData();
  },
  methods: {
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.token = null;
        this.$router.push({
          path: "/login",
        });
      }
    },
    async getuserData() {
      const res = await this.axios.get("/basicUser/tokenByUser", {
        params: {
          token: this.token,
        },
      });
      if (res.code == 200) {
        this.userData = res.object;
        this.getcourseData();
      } else {
        this.userData = null;
        this.$router.push({
          path: "/login",
        });
        // this.$message({
        // 	showClose: true,
        // 	message: '请求错误，请稍后重试！',
        // 	type: 'error'
        // 	});
      }
    },
    async getcourseData() {
      const res = await this.axios.get("/userCourse/selectAll", {
        params: {
          userId: this.userData.id,
        },
      });
      if (res.code == 200) {
        console.log(res);
        if (res.object) {
          this.courseData = res.object;
          this.courseData.forEach((item) => {
            if (item.courseTime !== null) {
              const a = this.moment(item.addTime).format("YYYY-MM-DD HH:mm:ss");
              item.addTime = a;
              const a1 = this.moment(item.courseTime).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              item.courseTime = a1;
            } else {
              item.courseTime = "待定";
            }
          });
        }
      } else if (res.code == 400) {
        this.courseData = [];
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    toCourse() {
      this.$router.push({
        path: "/Videolist",
      });
    },
    async search() {
      this.courseEnitity.userId = this.userData.id;
      if (this.value.length > 0) {
        const a = this.moment(this.value[0]).format("YYYY-MM-DD HH:mm:ss");
        this.courseEnitity.startTime = a;
        const a1 = this.moment(this.value[1]).format("YYYY-MM-DD HH:mm:ss");
        this.courseEnitity.endTime = a1;
      }
      this.axios.post("/userCourse/search", this.courseEnitity).then(
        (res) => {
          this.courseData = res.object;
          this.pageparm.total = Number(res.object.total);
          this.pageparm.pageNum = res.object.pageNum;
        },
        (error) => {
          this.$message({
            showClose: true,
            message: error.msg,
            type: "error",
          });
        }
      );
    },
    async callFather(parm) {
      this.formInline.page = parm.currentPage;
      this.formInline.limit = parm.pageSize;
      if (this.value.length > 0) {
        const a = this.moment(this.value[0]).format("YYYY-MM-DD HH:mm:ss");
        this.courseEnitity.startTime = a;
        const a1 = this.moment(this.value[1]).format("YYYY-MM-DD HH:mm:ss");
        this.courseEnitity.endTime = a1;
      }
      const res = await this.axios.post("/userCourse/search", this.formInline);
      if (res.code == 200) {
        this.courseData = res.object;
        this.pageparm.total = Number(res.object.total);
        this.pageparm.pageNum = res.object.pageNum;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    empty() {
      this.courseEnitity.courseName = "";
      this.value1 = "";
    },
    handleClick(row) {
      console.log(row);
      this.$router.push({
        path: "/courseVideo",
        query: {
          coursemessage: JSON.stringify(row),
        },
      });
    },
  },
};
</script>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
::v-deep.el-table {
    width: 90% !important;
    margin: auto;
  }
hr {
  margin: 0;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  ::v-deep .el-form-item {
    line-height: 100px !important;
    margin: auto!important;
  }
  ::v-deep.el-table {
    font-size: 64px !important;
  }
  ::v-deep.el-table .cell {
    line-height: 168px !important;
  }
  ::v-deep.el-tag {
    font-size: 64px !important;
    height: 68px !important;
    line-height: 68px !important;
  }
  ::v-deep.el-table .el-checkbox__inner {
    height: 64px !important;
    width: 64px !important;
  }
  ::v-deep.el-button i {
    font-size: 42px!important;
  }
  ::v-deep.el-descriptions__body {
    padding: 40px !important;
  }
  ::v-deep.el-input--small .el-input__inner {
    height: 88px !important;
    line-height: 88px !important;
    font-size: 64px;
  }
  ::v-deep.el-input__inner {
    padding: 20px;
    height: 88px !important;
    line-height: 88px !important;
    font-size: 64px !important;
    width: 1088px !important;
  }
  ::v-deep.el-input__inner i {
    font-size: 64px !important;
    line-height: 88px !important;
    height: 88px !important;
  }
  ::v-deep.el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 64px !important;
    line-height: 70px !important;
    height: 70px !important;
    width: 360px !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  ::v-deep.el-table {
    font-size: 42px !important;
  }
  ::v-deep.el-table .cell {
    line-height: 88px !important;
  }
  ::v-deep.el-tag {
    font-size: 42px !important;
    height: 48px !important;
    line-height: 48px !important;
  }
  ::v-deep.el-table .el-checkbox__inner {
    height: 48px !important;
    width: 48px !important;
  }
  ::v-deep.el-button i {
    font-size: 42px!important;
  }
  ::v-deep.el-input--small .el-input__inner {
    height: 48px !important;
    line-height: 48px !important;
    font-size: 42px;
  }
  ::v-deep.el-input__inner {
    height: 48px !important;
    line-height: 48px !important;
    font-size: 42px !important;
    width: 688px !important;
  }
  ::v-deep.el-input__inner i {
    font-size: 48px !important;
    line-height: 44px !important;
    height: 44px !important;
  }
  ::v-deep.el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 42px !important;
    line-height: 44px !important;
    height: 44px !important;
    width: 200px !important;
    margin-left: 40px !important;
  }
}
@media (min-width: 1025px) {
  ::v-deep.el-table {
    font-size: 24px !important;
    width: 90% !important;
    margin: auto;
  }
}
</style>