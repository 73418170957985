<template>
  <div>
    <div>
      <el-row style="text-align: center">
        <!-- 搜索筛选 -->
        <el-form
          :inline="true"
          :model="formInline"
          class="user-search"
          v-show="!payType"
        >
          <el-form-item label="搜索：">
            <el-input
              size="small"
              v-model="formInline.orderEntity.commodityName"
              placeholder="输入项目名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="formInline.orderEntity.payment"
              filterable
              size="small"
              clearable
              placeholder="订单状态"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="value1"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              icon="el-icon-search"
              @click="callFather(pageparm)"
              >搜索</el-button
            >
            <el-button size="small" icon="el-icon-delete-solid" @click="empty"
              >清空</el-button
            >
          </el-form-item>
        </el-form>
        <el-empty description="无订单" v-if="orderData.length === 0">
          <!-- <el-button type="warning" plain @click="toCourse()">去下单</el-button> -->
        </el-empty>
        <el-table
          v-else
          v-show="!payType"
          :data="orderData"
          max-height="500"
          style="width: 100%"
          row-key="id"
          :default-expand-all="false"
          :tree-props="{ children: 'children' }"
        >
          <el-table-column prop="orderNumber" label="订单编号">
          </el-table-column>
          <el-table-column prop="commodityName" label="订单名">
          </el-table-column>
          <el-table-column prop="commodityPrice" label="购买价格(¥)">
          </el-table-column>
          <el-table-column prop="generationTime" label="下单时间">
          </el-table-column>
          <el-table-column align="center" prop="payName" label="支付状态">
          </el-table-column>
          <el-table-column align="center" prop="mode" label="支付方式">
            <template slot-scope="scope">
              <p v-if="scope.row.mode === 3">
                <el-tag type="danger">银联</el-tag>
              </p>
              <p v-if="scope.row.mode === 2">
                <el-tag type="success">微信</el-tag>
              </p>
              <p v-if="scope.row.mode === 1">
                <el-tag type="primary">支付宝</el-tag>
              </p>
              <p v-if="scope.row.mode === 0"><el-tag>无</el-tag></p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <p v-if="scope.row.payment === 0" class="time">
                <i class="el-icon-time"></i> {{ min }}分钟 {{ sec }}秒
              </p>
              <el-button
                @click="PayClick(scope.row)"
                v-if="scope.row.payment === 0"
                type="text"
                size="small"
                >付款</el-button
              >
              <el-button
                @click.native.prevent="deleteRow(scope.$index, orderData)"
                type="text"
                size="small"
                v-show="scope.row.state === 1 && scope.row.payment !== 0"
              >
                删除订单
              </el-button>
              <el-button
                @click.native.prevent="CancelRow(scope.$index, scope.row)"
                type="text"
                size="small"
                v-show="scope.row.payment === 0"
                >取消订单
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <template>
          <el-table
            v-show="payType"
            :data="paycourse"
            style="width: 100%"
            :row-class-name="tableRowClassName"
          >
            <el-table-column prop="commodityName" label="商品名" width="180">
            </el-table-column>
            <el-table-column prop="aboutInstructor" label="讲师" width="180">
            </el-table-column>
            <el-table-column prop="courseTime" label="开课时间">
            </el-table-column>
            <el-table-column
              prop="courseIntroduction"
              label="课程介绍"
              width="300"
            >
            </el-table-column>
            <el-table-column prop="commodityPrice" label="价格">
            </el-table-column>
          </el-table>
        </template>
        <payCode
          v-if="payType"
          style="padding: 15px"
          :orderNumber="orderNumber"
          :userData="userData"
          :endTime="endTime"
        ></payCode>
        <el-button v-if="payType" @click="payType = !payType">返回</el-button>
        <Pagination
          v-if="orderData.length !== 0"
          v-show="!payType"
          :child-msg="pageparm"
          @call="callFather"
        ></Pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Cookies from "js-cookie";
import Pagination from "../../components/Pagination";
import payCode from "../../components/payCode.vue";
export default {
  inject: ["reload"],
  components: { Header, Pagination, payCode },
  data() {
    return {
      payType: false,
      min: "",
      sec: "",
      paycourse: [],
      endTime: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      userData: [],
      pageparm: {
        currentPage: 1,
        pageSize: 5,
        total: 10,
      },
      options: [
        {
          value: 0,
          label: "未完成",
        },
        {
          value: 1,
          label: "已完成",
        },
      ],
      token: "",
      byUserId: "",
      orderData: [],
      payment: "",
      value1: "", //时间搜索的值
      formInline: {
        page: 1,
        limit: 5,
        orderEntity: {
          basicUserId: "",
          code: "",
          commodityName: "",
          commodityPrice: "",
          endTime: "",
          generationTime: "",
          purchaseTime: "",
          id: "",
          mode: "",
          children: [
            {
              commodityId: "",
              commodityName: "",
              commodityPrice: "",
              id: "",
              orderNumber: "",
              typeId: "",
            },
          ],
          orderNumber: "",
          payment: "",
          generationTime: "",
          startTime: "",
          state: "",
          userName: "",
        },
      },
    };
  },
  created() {
    this.gettoken();
    this.getuserData();
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {});
  },
  methods: {
    countdown() {
      const now = new Date().getTime();
      const msec = this.endTime - now;

      if (msec < 0) {
        this.axios
          .get("/order/updateOrder", {
            params: {
              orderNumber: this.orderNumber,
            },
          })
          .then((res) => {
            this.outTradeNo = "";
            this.reload();
          });
        return;
      }

      let day = parseInt(msec / 1000 / 60 / 60 / 24);
      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      let sec = parseInt((msec / 1000) % 60);
      let time = {
        min: 0,
        sec: 0,
      };
      this.day = day;
      this.hr = hr > 9 ? hr : "0" + hr;
      this.min = min > 9 ? min : "0" + min;
      this.sec = sec > 9 ? sec : "0" + sec;
      time.min = this.min;
      time.sec = this.sec;

      const that = this;
      if (min >= 0 && sec >= 0) {
        //倒计时结束关闭订单
        if (min <= 0 && sec <= 0) {
          this.axios
            .get("/order/updateOrder", {
              params: {
                orderNumber: this.orderNumber,
              },
            })
            .then((res) => {
              this.outTradeNo = "";
              this.reload();
            });
          return false;
        } else if (this.payType) {
          return false;
        }
        setTimeout(function () {
          that.countdown();
        }, 1000);
      }
    },
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.token = null;
        this.$router.push({
          path: "/login",
        });
      }
    },
    tableRowClassName(row, rowIndex) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 0) {
        return "success-row";
      }
      return "";
    },
    async getuserData() {
      const res = await this.axios.get("/basicUser/tokenByUser", {
        params: {
          token: this.token,
        },
      });
      if (res.code == 200) {
        this.userData = res.object;
        this.formInline.orderEntity.basicUserId = this.userData.id;
        this.getOrderData();
      } else {
        this.userData = null;
        this.$router.push({
          path: "/login",
        });
        // this.$message({
        // 	showClose: true,
        // 	message: '请求错误，请稍后重试！',
        // 	type: 'error'
        // 	});
      }
    },
    empty() {
      this.formInline.orderEntity.commodityName = "";
      this.value1 = "";
      this.formInline.orderEntity.payment = "";
      this.callFather(this.pageparm);
    },
    async getOrderData() {
      const res = await this.axios.post("/order/search", this.formInline);
      if (res.code == 200) {
        res.object.list.forEach((it) => {
          const a = this.moment(it.generationTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          it.generationTime = a;
          if (it.payment === 0) {
            this.endTime = it.expirationTime;
            this.orderNumber = it.orderNumber;
            this.countdown();
            this.getpayType(this.orderNumber);
            it.payName = "未支付";
          } else if (it.payment === 1) {
            it.payName = "已完成";
          } else if (it.payment === 2) {
            it.payName = "已取消";
          }
        });
        this.orderData = res.object.list;
        this.pageparm.total = Number(res.object.total);
        this.pageparm.pageNum = res.object.pageNum;
      } else if (res.code == 400) {
        this.orderData = null;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async getpayType(val) {
      this.axios
        .get("/AlipayFaceToFaceController/findZFB_tradeAction", {
          params: {
            outTradeNo: val,
          },
        })
        .then(
          (res) => {
            if (res.code === "10000" && res.data === "yes-payment") {
              this.reload();
            }
          },
          (error) => {
            this.$message({
              showClose: true,
              message: "请求错误，请稍后重试！",
              type: "error",
            });
          }
        );
    },
    async callFather(parm) {
      if (this.value1.length > 0) {
        const a = this.moment(this.value1[0]).format("YYYY-MM-DD HH:mm:ss");
        this.formInline.orderEntity.startTime = a;
        const a1 = this.moment(this.value1[1]).format("YYYY-MM-DD HH:mm:ss");
        this.formInline.orderEntity.endTime = a1;
      }

      this.formInline.page = parm.currentPage;
      this.formInline.limit = parm.pageSize;
      this.formInline.orderEntity.basicUserId = this.userData.id;
      const res = await this.axios.post("/order/search", this.formInline);
      if (res.code == 200) {
        res.object.list.forEach((it) => {
          const a = this.moment(it.generationTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          it.generationTime = a;
          if (it.payment === 0) {
            this.endTime = it.expirationTime;
            this.orderNumber = it.orderNumber;
            this.countdown();
            this.getpayType(this.orderNumber);
            it.payName = "未支付";
          } else if (it.payment === 1) {
            it.payName = "已完成";
          } else if (it.payment === 2) {
            it.payName = "已取消";
          }
        });
        this.orderData = res.object.list;
        this.pageparm.total = Number(res.object.total);
        this.pageparm.pageNum = res.object.pageNum;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    deleteRow(index, rows) {
      console.log(rows);
      rows.splice(index, 1);
    },
    async CancelRow(index, rows) {
      console.log(rows);
      this.axios
        .get("/order/updateOrder", {
          params: {
            orderNumber: rows.orderNumber,
          },
        })
        .then((res) => {
          this.$message({
            showClose: true,
            message: "取消成功",
            type: "success",
          });
          this.getOrderData();
        });
    },
    PayClick(row) {
      this.paycourse = row.children;
      this.endTime = row.expirationTime;
      this.orderNumber = row.orderNumber;
      this.payType = !this.payType;
    },
  },
};
</script>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
html,
body {
  height: 100%;
}
.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.time {
  color: red;
  font-size: 12px;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
::v-deep.el-table {
  width: 90% !important;
  margin: auto;
}
@media (max-width: 768px) {
  ::v-deep .el-form-item {
    line-height: 100px !important;
    margin: auto !important;
  }
  ::v-deep.el-table {
    font-size: 64px !important;
  }
  ::v-deep.el-table .cell {
    line-height: 168px !important;
  }
  ::v-deep.el-tag {
    font-size: 64px !important;
    height: 68px !important;
    line-height: 68px !important;
  }
  ::v-deep.el-table .el-checkbox__inner {
    height: 64px !important;
    width: 64px !important;
  }
  ::v-deep.el-button i {
    font-size: 42px !important;
  }
  ::v-deep.el-descriptions__body {
    padding: 40px !important;
  }
  ::v-deep .el-input--small .el-input__inner {
    height: 88px !important;
    line-height: 88px !important;
    font-size: 64px !important;
    width: 1088px !important;
  }
  ::v-deep.el-input__inner {
    padding: 20px;
    height: 88px !important;
    line-height: 88px !important;
    font-size: 64px !important;
    width: 1088px !important;
  }
  ::v-deep.el-input__inner i {
    font-size: 64px !important;
    line-height: 88px !important;
    height: 88px !important;
  }
  ::v-deep.el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 64px !important;
    line-height: 70px !important;
    height: 70px !important;
    width: 360px !important;
  }
  .time {
    color: red;
    font-size: 58px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  ::v-deep .el-table {
    font-size: 42px !important;
  }
  ::v-deep .el-table .cell {
    line-height: 88px !important;
  }
  ::v-deep .el-tag {
    font-size: 42px !important;
    height: 48px !important;
    line-height: 48px !important;
  }
  ::v-deep .el-table .el-checkbox__inner {
    height: 48px !important;
    width: 48px !important;
  }
  ::v-deep .el-button i {
    font-size: 42px !important;
  }
  ::v-deep .el-input--small .el-input__inner {
    height: 48px !important;
    line-height: 48px !important;
    font-size: 42px !important;
  }
  ::v-deep .el-input__inner {
    height: 48px !important;
    line-height: 48px !important;
    font-size: 42px !important;
  }
  ::v-deep .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 688px !important;
  }
  ::v-deep .el-input__inner i {
    font-size: 48px !important;
    line-height: 44px !important;
    height: 44px !important;
  }
  ::v-deep .el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 42px !important;
    line-height: 44px !important;
    height: 44px !important;
    width: 200px !important;
    margin-left: 40px !important;
  }
  .time {
    color: red;
    font-size: 28px;
  }
}
@media (min-width: 1025px) {
  ::v-deep.el-table {
    font-size: 24px !important;
    width: 90% !important;
    margin: auto;
  }
}
</style>