<template>
  <div class="findpass-body">
    <sideframe></sideframe>
    <div class="LOGO">
      <img
      src="https://sunledge.com/apis/pic/20231222/e25f0505-d971-4bda-b347-42f5b0049e0f.png"
        alt="益学方策"
        @click="goLogin"
      />
    </div>
    <div class="input-boxBody">
      <el-steps :active="activeStep" inish-status="success" simple>
        <el-step title="确认账号" icon="el-icon-user"></el-step>
        <el-step title="重置密码" icon="el-icon-edit"></el-step>
        <el-step title="重置成功" icon="el-icon-circle-check"></el-step>
      </el-steps>
      <!-- 确认账号 -->
      <div class="input-box" v-if="activeStep == 0">
        <el-form
          style="margin-top: 50px"
          :model="basicUserEntityDTO.basicUserEntity"
          :rules="rules1"
          ref="rulebasicUserEntity"
        >
          <el-form-item prop="phone">
            <el-input
              v-model="basicUserEntityDTO.basicUserEntity.phone"
              placeholder="请输入绑定的手机号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="next(1)"
              >确 认</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 重置密码 -->
      <div class="input-box" v-if="activeStep == 1">
        <el-form
          :model="form2"
          status-icon
          :rules="rules2"
          ref="ruleForm2"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="新密码" prop="password">
            <el-input
              type="password"
              v-model="form2.password"
              placeholder="请输入新密码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <div>
            <el-form-item label="确认密码" prop="checkpass">
              <el-input
                type="password"
                v-model="form2.checkpass"
                placeholder="请重新输入新密码"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label="手机：">
            <el-row>
              <el-col :span="19">
                {{ basicUserEntityDTO.basicUserEntity.phone | showphone }}
              </el-col>
              <el-col :span="2">
                <el-button type="text" @click="changePhone()">修改</el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="验证：" prop="code">
            <el-row>
              <el-col :span="15">
                <el-input
                  v-model="form2.telephoneCode"
                  placeholder="请输入短信验证码"
                ></el-input>
              </el-col>
              <el-col :span="7" :offset="2">
                <el-button type="primary" @click="getCode()" v-if="available"
                  >获取验证码</el-button
                >
                <el-button v-else type="primary" disabled>
                  {{ times }}秒后重新获取
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="next(2)"
              >确 认 修 改</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 重置成功 -->
      <div class="input-box" v-show="activeStep == 3">
        <h2 style="color: #409eff; text-align: center">密码修改成功</h2>
        <p style="color: #909399; text-align: center">
          密码修改成功！即将跳转到登陆页面 {{ times2 }} s
        </p>
        <el-button
          type="primary"
          style="width: 100%; margin-top: 50px"
          @click="goLogin()"
          >去 登 陆</el-button
        >
      </div>
    </div>
    <myflooter></myflooter>
    <el-dialog title="验证" :visible.sync="dialogVisible" width="30%">
      <slidervalidation
        v-if="dialogVisible"
        @success="goNext()"
      ></slidervalidation>
    </el-dialog>
  </div>
</template>

<script>
import Flooter from "./flooter.vue";
import SliderValidation from "../components/slidervalidation.vue";
import Header from "./Header.vue";
import Sideframe from "../components/sideframe.vue";
export default {
  data() {
    //自定义密码验证
    // 密码本身
    const validatePass = (rule, value, callback) => {
      const reg = /^[a-z|A-Z]+\d+$/;
      const falg = reg.test(value);
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (value.length < 6 || value.length > 18) {
        callback(new Error("密码长度在6-18位"));
      } else if (!falg) {
        callback(new Error("密码请以字母开头数字结尾"));
      } else {
        if (this.rules2.checkpass !== "") {
          this.$refs.ruleForm2.validateField("checkpass");
        }
        callback();
      }
    };
    // 再次输入密码
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form2.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      activeStep: 0,
      userData: {},
      basicUserEntityDTO: {
        basicUserEntity: {
          phone: "",
        },
        limit: 5,
        page: 1,
      },
      form2: {
        password: "",
        checkpass: "",
        telephoneCode: "",
      },
      //验证码按钮
      times: 0,
      //可以发送验证码
      available: true,
      timer: null,

      times2: 15,
      timer2: null,
      rules1: {
        phone: [
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
      rules2: {
        password: [{ validator: validatePass, trigger: "blur" }],
        checkpass: [{ validator: validatePass2, trigger: "blur" }],
        telephoneCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //获取验证码
    async getCode() {
      await this.axios
        .get("/Val/getCode", {
          params: {
            telephoneNumber: this.basicUserEntityDTO.basicUserEntity.phone,
          },
        })
        .then((res) => {
          if (res.code !== 0) {
            this.$message({
              message: res.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      // getPhoneCode(this.basicUserEntityDTO.basicUserEntity.phone).then((res) => {
      //   this.form2.verifyName = res.result.verifyName;
      //   this.$message({
      //     message: "发送成功",
      //     type: "success",
      //   });
      // });
      this.times = 60;
      this.available = false;
      this.timer = setInterval(() => {
        this.times--;
        if (this.times == 0) {
          this.available = true;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    async next(num) {
      if (num == 1) {
        this.$refs["rulebasicUserEntity"].validate((valid) => {
          if (valid) {
            //  this.activeStep = 2;
            this.dialogVisible = true;
          } else {
            return false;
          }
        });
      } else if (num == 2) {
        this.$refs["ruleForm2"].validate((valid) => {
          if (valid) {
            this.updatepassword();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    async updatepassword() {
      const res = await this.axios.get("/basicUser/forgetPassword", {
        params: {
          password: this.form2.password,
          phone: this.basicUserEntityDTO.basicUserEntity.phone,
          telephoneCode: this.form2.telephoneCode,
        },
      });
      if (res.code == 200) {
        this.$message({
          showClose: true,
          message: "修改成功",
          type: "success",
        });
        setTimeout(() => {
          this.userData = null;
          this.$router.push({ path: "/home" });
        }, 1000);
      } else if (res.code == 400) {
        this.$message({
          showClose: true,
          message: "该手机号已被注册！",
          type: "error",
        });
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async goNext() {
      await this.axios
        .get("/basicUser/selectByPhone", {
          params: {
            telephoneNumber: this.basicUserEntityDTO.basicUserEntity.phone,
          },
        })
        .then((res) => {
          if (res.object !== null) {
            this.userData = res.object;
            this.dialogVisible = false;
            this.activeStep = 1;
          } else {
            this.dialogVisible = false;
            this.$alert("该手机号未注册,请先进行注册", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.$router.push({ path: "/logonmessage" });
              },
            });
          }
        });
    },
    changePhone() {
      this.activeStep = 0;
    },
    //回到登录页面
    goLogin() {
      this.$router.push({ path: "/login" });
    },
  },
  components: {
    myflooter: Flooter,
    slidervalidation: SliderValidation,
    Sideframe,
    Header,
  },
  filters: {
    showphone: function (value) {
      return value.substring(0, 3) + "****" + value.substring(7, 11);
    },
  },
};
</script>

<style  scoped>
.findpass-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.input-boxBody {
  width: 50%;
  margin: auto;
  padding: 20px 0 0 0;
}
.input-box {
  width: 30%;
  margin: auto;
  padding: 100px 0 0 0;
}
.LOGO {
  color: #dab96b;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  margin-bottom: 50px;
}
.LOGO img {
  cursor: pointer;
  margin-left: 50px;
  height: 70px;
  padding: 10px;
}
::v-deep .el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #fff;
  background-color: rgba(218, 185, 107, 0.7);
  border-color: #dab96b;
}
::v-deep .el-steps--simple {
  padding: 13px 8%;
  border-radius: 4px;
  background: #ffffff;
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #dab96b;
  border-color: #dab96b;
}

::v-deep .el-step__title.is-finish {
  color: #dab96b;
}

::v-deep .el-step__head.is-finish {
  color: #dab96b;
  border-color: #409eff;
}
#headpage {
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
@media only screen and (min-width: 1279px) and (max-width: 1535px) {
  .LOGO img {
    height: 140px;
  }
  .input-boxBody {
    width: 50%;
    margin: auto;
    height: 70%;
    padding: 20px 0 0 0;
  }

  .input-box {
    width: 30%;
    margin: auto;
    padding: 100px 0 0 0;
  }
  .el-step__icon .is-icon {
    font-size: 24px !important;
  }
  ::v-deep .el-step.is-simple .el-step__title {
    font-size: 24px !important;
  }
  ::v-deep .el-input {
    font-size: 24px !important;
  }
  ::v-deep .el-button span {
    height: 40px !important;
    line-height: 40px !important;
    font-size: 24px !important;
  }
  ::v-deep .el-input__inner {
    height: 60px !important;
    line-height: 60px !important;
    padding: 0 20px !important;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1280px) {
  .LOGO img {
    height: 180px;
  }
  .input-boxBody {
    width: 70%;
    margin: auto;
    height: 70%;
    padding: 20px 0 0 0;
  }
  .input-box {
    width: 30%;
    margin: auto;
    padding: 100px 0 0 0;
  }
  ::v-deep .el-step.is-simple .el-step__title {
    font-size: 32px !important;
  }
  ::v-deep .el-input {
    font-size: 32px !important;
  }
  ::v-deep .el-button span {
    height: 40px !important;
    line-height: 40px !important;
    font-size: 32px !important;
  }
  ::v-deep .el-input__inner {
    height: 60px !important;
    line-height: 60px !important;
    padding: 0 40px !important;
  }
}
@media only screen and (min-width: 661px) and (max-width: 959px) {
  .LOGO img {
    height: 200px;
  }
  .input-boxBody {
    width: 90%;
    margin: auto;
    height: 75%;
    padding: 20px 0 0 0;
  }
  .input-box {
    width: 30%;
    margin: auto;
    padding: 100px 0 0 0;
  }

  ::v-deep .el-step.is-simple .el-step__title {
    font-size: 38px !important;
  }
  .el-step__icon .is-icon {
    font-size: 38px !important;
  }
  ::v-deep .el-input {
    font-size: 38px !important;
  }
  ::v-deep .el-button span {
    height: 80px !important;
    line-height: 80px !important;
    font-size: 38px !important;
  }
  ::v-deep .el-input__inner {
    height: 80px !important;
    line-height: 80px !important;
    padding: 0 40px !important;
  }
}
@media only screen and (max-width: 660px) {
  .LOGO img {
    height: 240px;
  }
  .input-boxBody {
    width: 100%;
    margin: auto;
    height: 50%;
    padding: 50px 0 0 0;
  }
  .input-box {
    width: 50%;
    margin: auto;
    padding: 200px 0 0 0;
  }
  ::v-deep .el-step__icon-inner[class*=el-icon]:not(.is-status) {
    font-size: 52px !important;
  }
  ::v-deep .el-step.is-simple .el-step__title {
    font-size: 58px;
    padding-left: 40px;
  }
  ::v-deep .el-input {
    font-size: 68px !important;
  }
  ::v-deep .el-button span {
    height: 140px !important;
    line-height: 140px !important;
    font-size: 68px !important;
  }
  ::v-deep .el-input__inner {
    height: 160px !important;
    line-height: 160px !important;
    padding: 0 140px !important;
  }
}
</style>