<template>
  <div class="body">
    <masks :showMask="maskShow"></masks>
    <Header :shoppingCarNum="shoppingCarNum"></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <el-row>
        <div class="course-body" v-show="!payType">
          <el-image class="course-img" :src="coursemsg.courseImg" fit="fill">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div class="course-box">
            <p class="courseName" v-if="coursemsg.courseName !== ''">
              {{ coursemsg.courseName }}
            </p>
            <p class="courseContent">讲师：{{ coursemsg.aboutInstructor }}</p>
            <p class="courseContent">
              课程介绍：{{ coursemsg.courseIntroduction }}
            </p>
            <p class="courseContent">开课时间：{{ coursemsg.courseTime }}</p>
            <p class="content">
              价格：<span style="color: crimson"
                >¥ {{ coursemsg.coursePrice }}</span
              >
            </p>
            <p class="courseContent" style="color: darkgray">
              该产品为虚拟产品购买后不支持退款
            </p>
            <hr />
            <el-button
              class="button"
              v-if="coursemsg.coursePrice === 0 || haveCourse"
              @click="handleClick(coursemsg)"
              type="success"
              >开始学习</el-button
            >
            <div style="text-align: center" v-else>
              <el-button
                class="button"
                @click="payCourse()"
                type="success"
                v-show="!payType"
                icon="el-icon-s-finance"
                >购买</el-button
              >
              <el-button
                class="button"
                @click="addShopping()"
                type="warning"
                v-show="!payType"
                icon="el-icon-shopping-cart-1"
                >加入购物车</el-button
              >
            </div>
          </div>
        </div>
        <div class="payOrder-box" v-show="payType">
          <el-table :data="paycourse" :row-class-name="tableRowClassName">
            <el-table-column prop="courseName" label="课程名">
            </el-table-column>
            <el-table-column prop="aboutInstructor" label="讲师">
            </el-table-column>
            <el-table-column prop="courseTime" label="开课时间">
            </el-table-column>
            <el-table-column prop="courseImg" label="课程图片">
              <template slot-scope="scope">
                <img :src="scope.row.courseImg" style="height: 100px" />
              </template>
            </el-table-column>
            <el-table-column prop="coursePrice" label="价格"> </el-table-column>
          </el-table>
        </div>
        <payCode
          v-if="payType"
          style="padding: 15px"
          :orderNumber="orderNumber"
          :userData="userData"
          :startTime="startTime"
          :endTime="endTime"
        ></payCode>
        <el-button style="margin: 20px" @click="cancelOrder" v-show="payType"
          >取消支付</el-button
        >
      </el-row>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../../flooter.vue";
import Header from "../../Header.vue";
import Sideframe from "@/components/sideframe.vue";
import Cookies from "js-cookie";
import payCode from "@/components/payCode.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import { nanoid } from "nanoid";
import Masks from "@/components/mask.vue";
export default {
  components: { Header, Flooter, Sideframe, payCode, Breadcrumb, Masks },
  data() {
    return {
      coursemsg: {},
      titleData: {
        titleName: "",
        data: [
          {
            id: nanoid(),
            name: "商学院",
            href: "",
          },
        ],
      },
      maskShow: false,
      paycourse: [],
      payType: false,
      codeUrl: "",
      orderEntity: {},
      shoppingCartEntity: {},
      outTradeNo: "",
      payid: 1,
      title: "",
      routerForm: "",
      shoppingCarNum: 0,
      orderNumber: "",
      haveCourse: false,
      startTime: 1,
      endTime: 1,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routerForm = from.path; //获取跳转前路由的name并赋值给this.fromName
    });
  },
  created() {
    this.gettoken();
    this.getuserData();

    let data = JSON.parse(window.localStorage.getItem("courseData"));
    this.coursemsg = data.couresMessage;
    this.title = data.title;
    if (this.coursemsg.courseTime === "Invalid date") {
      this.coursemsg.courseTime = "待定";
    }

    this.paycourse.push(this.coursemsg);
    let pathData = {
      id: nanoid(),
      name: this.title,
      href: data.path,
    };
    let pathData1 = {
      id: nanoid(),
      name: this.coursemsg.courseName,
      href: "",
    };
    this.titleData.titleName = this.coursemsg.courseName;
    this.titleData.data.push(pathData);
    this.titleData.data.push(pathData1);
  },
  mounted() {},
  methods: {
    countdown(val) {},
    async getHaveCourse() {
      this.axios
        .get("/userCourse/selectUserCourse", {
          params: {
            courseId: this.coursemsg.id,
            userId: this.userData.id,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.haveCourse = true;
          }
        });
    },
    cancelOrder() {
      this.payType = false;
      this.axios
        .get("/order/updateOrder", {
          params: {
            orderNumber: this.orderNumber,
          },
        })
        .then((res) => {
          this.outTradeNo = "";
          this.reload();
        });
    },
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.userData = null;
        this.token = null;
      }
    },
    async getuserData() {
      const res = await this.axios.get("/basicUser/tokenByUser", {
        params: {
          token: this.token,
        },
      });
      if (res.code == 200) {
        this.userData = res.object;
        this.orderEntity.commodityName = this.title + "订单";
        this.orderEntity.commodityPrice = this.coursemsg.coursePrice.toString();
        this.orderEntity.basicUserId = this.userData.id;
        this.orderEntity.children = [];
        let commodity = {};
        commodity.commodityName = this.coursemsg.courseName;
        commodity.commodityPrice = this.coursemsg.coursePrice.toString();
        commodity.commodityId = this.coursemsg.id;
        commodity.courseTime = this.coursemsg.courseTime;
        commodity.courseIntroduction = this.coursemsg.courseIntroduction;
        commodity.aboutInstructor = this.coursemsg.aboutInstructor;
        commodity.typeId = 0;
        this.orderEntity.children.push(commodity);
        this.getHaveCourse();
      } else {
        this.userData = null;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 0) {
        return "success-row";
      }
      return "";
    },

    handleClick(msg) {
      this.$router.push({
        path: "/courseVideo",
      });
    },
    async payCourse() {
      if (this.userData == null) {
        this.$message({
          showClose: true,
          message: "请登录！",
          type: "info",
        });
      } else {
        this.maskShow = true;
        this.axios.post("/order/addOrder", this.orderEntity).then((res) => {
          if (res.code === 400) {
            this.$message({
              showClose: true,
              message: "有未完成的订单，请完成上一个订单后再下单",
              type: "error",
            });
            this.maskShow = false;
          } else {
            this.startTime = res.object.generationTime;
            this.endTime = res.object.expirationTime;
            this.orderNumber = res.object.orderNumber;
            const formData = new FormData();
            formData.append("orderNumber", this.orderNumber);
            this.codeUrl = "";
            this.axios
              .post("/AlipayFaceToFaceController/ZFBPreorderAction", formData)
              .then((res) => {
                this.maskShow = false;
                this.codeUrl = res.qrcode;
                this.outTradeNo = res.outTradeNo;
                this.payType = !this.payType;
              });
          }
        });
      }
    },
    async addShopping() {
      if (this.userData == null) {
        this.$message({
          showClose: true,
          message: "请登录！",
          type: "error",
        });
      } else {
        this.shoppingCartEntity.basicUserId = this.userData.id;
        this.shoppingCartEntity.courseName = this.coursemsg.courseName;
        this.shoppingCartEntity.coursePrice =
          this.coursemsg.coursePrice.toString();
        this.shoppingCartEntity.courseId = this.coursemsg.id;
        this.shoppingCartEntity.aboutInstructor =
          this.coursemsg.aboutInstructor;
        this.shoppingCartEntity.courseTime = this.coursemsg.courseTime;
        this.shoppingCartEntity.aboutInstructor =
          this.coursemsg.aboutInstructor;
        const res = await this.axios.post(
          "/shoppingCart/addShoppingCart",
          this.shoppingCartEntity
        );
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "已放入购物车",
            type: "success",
          });
          const res1 = await this.axios.get(
            "/shoppingCart/selectOrderByUserId",
            {
              params: {
                byUserId: this.userData.id,
              },
            }
          );
          if (res1.code == 200) {
            this.shoppingCarNum = res1.object.length;
            console.log(this.shoppingCarNum);
            window.localStorage.setItem("shoppingCarNum", this.shoppingCarNum);
          }
        } else if (res.code == 400) {
          this.$message({
            showClose: true,
            message: "该课程已在购物车！",
            type: "warning",
          });
        } else {
          this.$message({
            showClose: true,
            message: "请求错误，请稍后重试！",
            type: "error",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}

::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.875;
  text-align: left;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
.payOrder-box {
  width: 80%;
  margin: auto;
}
.course-body {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.course-box {
  width: 50%;
  margin: 0 auto 50px auto;
}
.course-box .courseName {
  width: 50%;
  height: fit-content;
}
.course-box .courseContent {
  width: 100%;
}
.course-img {
  width: 20%;
  margin: 0 5%;
  height: 318px;
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .payOrder-box {
    width: 95%;
    margin: auto;
  }
  .course-body {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .course-box {
    width: 80%;
    margin: 150px auto;
  }
  .course-img {
    height: 1618px;
    width: 90%;
  }
  .course-box .courseName {
    font-size: 88px;
    width: 100%;
  }
  .course-box .courseContent {
    font-size: 68px;
    width: 100%;
  }
  .course-box .content {
    font-size: 68px;
    width: 100%;
  }
  .button {
    width: 100%;
    font-size: 68px;
    margin: 10px;
  }
  ::v-deep .el-table {
    font-size: 48px;
  }
  ::v-deep .el-table .cell {
    height: fit-content;
    line-height: 1.875;
    word-wrap: break-word;
  }
  ::v-deep .el-button {
    height: 140px;
    font-size: 68px !important;
    border-radius: 15px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .payOrder-box {
    width: 95%;
    margin: auto;
  }
  .course-body {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .course-box {
    width: 80%;
    margin: 150px auto;
  }
  .course-img {
    height: 918px;
    width: 60%;
    margin: auto;
  }
  .course-box .courseName {
    font-size: 48px;
    width: 100%;
  }
  .course-box .courseContent {
    font-size: 38px;
    width: 100%;
  }
  .course-box .content {
    font-size: 48px;
    width: 100%;
  }
  .button {
    width: 100%;
    font-size: 48px;
    margin: 10px;
  }
  ::v-deep .el-table {
    font-size: 38px;
  }
  ::v-deep .el-table .cell {
    height: fit-content;
    line-height: 1.875;
    word-wrap: break-word;
  }
  ::v-deep .el-button {
    height: 100px;
    font-size: 48px !important;
    border-radius: 10px !important;
  }
}

@media (min-width: 1025px) {
  .button {
    width: 45%;
    margin: auto 10px;
    height: 40px;
    font-size: 16px !important;
  }
}
</style>