import actions from '../modules/actions'
import mutations from '../modules/mutations'

export default{
    namespaced: true,
    state: {
        rows:[],
        total:0,
		  pages: 0,
		  currentPage: 1,
		  pageSize: 4
    },
    mutations,
    actions,
}