/**
* 分页组件
*/ 
<template>
  <div class="page-box">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      :current-page="childMsg.currentPage"
      :page-sizes="[5, 8, 10]"
      :page-size="childMsg.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="childMsg.total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "Pagination",
  props: ["childMsg"],
  data() {
    return {
      pageparm: {
        // 接收并转换父组件的值到子组件属性   传对象到父组件
        currentPage: this.childMsg.currentPage,
        pageSize: this.childMsg.pageSize,
        total: this.childMsg.total,
      },
    };
  },

  methods: {
    handleSizeChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.pageSize = val;
      this.$emit("call", this.pageparm);
    },
    handleCurrentChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.currentPage = val;
      this.$emit("call", this.pageparm);
    },
  },
};
</script>

<style  scoped>
.page-box {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
::v-deep .el-select .el-input .el-select__caret {
  margin: auto;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(218, 185, 107);
  color: #fff;
}
@media (max-width: 768px) {
  .page-box {
    margin: 40px 0;
    width: 100%;
    height: fit-content;
  }
  ::v-deep .el-pagination__total {
    font-size: 68px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  ::v-deep .el-input-group__prepend input {
    width: 390px !important;
  }
  ::v-deep .el-pagination .el-select .el-input input {
    font-size: 68px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  ::v-deep .el-pagination .el-select .el-input {
    width: 350px !important;
  }
  ::v-deep .el-select-dropdown {
    height: 880px !important;
  }
  ::v-deep .el-pagination .el-select .el-input .el-input__inner {
    padding: 0 80px 0 30px !important;
  }
  ::v-deep .el-input__icon {
    width: 80px !important;
    font-size: 68px !important;
  }
  ::v-deep .btn-prev {
    font-size: 68px !important;
    height: 100px !important;
    width: 100px !important;
    line-height: 100px !important;
  }
  ::v-deep .el-pager .number {
    font-size: 68px !important;
    height: 100px !important;
    width: 100px !important;
    line-height: 100px !important;
  }
  ::v-deep .el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
    font-size: 68px !important;
    height: 100px !important;
    width: 100px !important;
    line-height: 100px !important;
  }
  ::v-deep .btn-next {
    font-size: 68px !important;
    height: 100px !important;
    width: 100px !important;
    line-height: 100px !important;
  }

  ::v-deep .btn-next i {
    font-size: 68px !important;
  }
  ::v-deep .btn-prev i {
    font-size: 68px !important;
  }
  ::v-deep .el-input {
    height: 125px !important;
    line-height: 125px !important;
  }
  ::v-deep .el-input i {
    height: 125px !important;
    line-height: 140px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .page-box {
    width: 100%;
    height: fit-content;
  }
  ::v-deep .el-pagination__total {
    font-size: 48px !important;
    height: 80px !important;
    line-height: 80px !important;
  }
  ::v-deep .el-pagination .el-select .el-input input {
    font-size: 48px !important;
    height: 80px !important;
    line-height: 80px !important;
  }
  ::v-deep .el-pagination .el-select .el-input {
    width: 240px !important;
  }
  ::v-deep .el-pagination .el-select .el-input .el-input__inner {
    padding: 0 40px 0 5px !important;
  }
  ::v-deep .el-input__icon {
    width: 40px !important;
    font-size: 48px !important;
  }
  ::v-deep .btn-prev {
    font-size: 48px !important;
    height: 80px !important;
    width: 80px !important;
    line-height: 80px !important;
  }
  ::v-deep .btn-prev i {
    font-size: 48px !important;
  }
  ::v-deep .el-pager .number {
    font-size: 48px !important;
    height: 80px !important;
    width: 80px !important;
    line-height: 80px !important;
  }
  ::v-deep .el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
    font-size: 48px !important;
    height: 80px !important;
    width: 80px !important;
    line-height: 80px !important;
  }
  ::v-deep .btn-next {
    font-size: 48px !important;
    height: 80px !important;
    width: 80px !important;
    line-height: 80px !important;
  }
  ::v-deep .btn-next i {
    font-size: 48px !important;
  }
}

@media (min-width: 1025px) {
  ::v-deep .page-box {
    margin: 10px;
    width: 100%;
  }
  ::v-deep .el-pagination__total {
    font-size: 24px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
  ::v-deep .el-pagination .el-select .el-input input {
    font-size: 24px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
  ::v-deep .el-pagination .el-select .el-input {
    width: 120px !important;
  }
  ::v-deep .el-pagination .el-select .el-input .el-input__inner {
    padding-right: 30px !important;
  }
  ::v-deep .el-input__icon {
    font-size: 24px !important;
    width: 30px !important;
  }
  ::v-deep .btn-prev {
    font-size: 24px !important;
    height: 40px !important;
    width: 40px !important;
    line-height: 40px !important;
  }
  ::v-deep .btn-prev i {
    font-size: 24px !important;
  }

  ::v-deep .el-pager .number {
    font-size: 24px !important;
    height: 40px !important;
    width: 40px !important;
    line-height: 40px !important;
  }
  ::v-deep .btn-next {
    font-size: 24px !important;
    height: 40px !important;
    width: 40px !important;
    line-height: 40px !important;
  }
  ::v-deep .btn-next i {
    font-size: 24px !important;
  }
  ::v-deep .el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
    height: 40px !important;
    width: 40px !important;
  }
}
</style>
