<template>
  <div class="card">
    <p class="price">快捷导航栏</p>
    <ul class="lists">
      <li class="list" v-for="(item, index) in titleNo" :key="index">
        <span
          :style="
            item.herfNo === '1' ||
            item.herfNo === '2' ||
            item.herfNo === '3' ||
            item.herfNo === '10'
              ? 'fontSize:16px'
              : 'fontSize:12px'
          "
          >{{ item.no }}</span
        >
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.name"
          placement="right"
        >
          <a :href="'#' + item.herfNo">
            {{
              item.no.length + item.name.length > 9
                ? item.name.substring(0, 6) + "…"
                : item.name
            }}
          </a>
        </el-tooltip>
      </li>
    </ul>
      <button @click="download()" class="buttonDownload">点击下载报告</button>
  </div>
</template>

<script>
import { List } from "echarts";
export default {
  data() {
    return {};
  },
  props: {
    titleNo: {
      type: Array,
      default: [],
    },
  },
  created() {},
  mounted() {},
  methods: {
    download() {
      this.$emit('download')
    }
  },
};
</script>

<style lang="scss" scoped>
.buttonDownload {
  margin: 10px 0 0 0; 
  display: inline-block;
  position: relative;
  padding: 10px 10px;
  background-color: #4cc713;
  color: white;
  font-family: sans-serif;
  text-decoration: none;
  font-size: 0.9em;
  text-align: center;
  text-indent: 15px;
  border: none;
  cursor: pointer;
}

.buttonDownload:hover {
  background-color: #45a21a;
  color: white;
}

.buttonDownload:before,
.buttonDownload:after {
  content: " ";
  display: block;
  position: absolute;
  left: 15px;
  top: 52%;
}

.buttonDownload:before {
  width: 10px;
  height: 2px;
  border-style: solid;
  border-width: 0 2px 2px;
}

.buttonDownload:after {
  width: 0;
  height: 0;
  margin-left: 3px;
  margin-top: -7px;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: transparent;
  border-top-color: inherit;
  animation: downloadArrow 1s linear infinite;
  animation-play-state: paused;
}

.buttonDownload:hover:before {
  border-color: #cdefbd;
}

.buttonDownload:hover:after {
  border-top-color: #cdefbd;
  animation-play-state: running;
}

@keyframes downloadArrow {
  0% {
    margin-top: -7px;
    opacity: 1;
  }

  0.001% {
    margin-top: -15px;
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    margin-top: 0;
    opacity: 0.4;
  }
}
a {
  text-decoration-line: none;
  text-decoration-color: aqua;
}
a:link {
  text-decoration: none;
  color: #92d050;
}
a:visited {
  color: #92d050;
  text-decoration: none;
}
.card {
  z-index: 999;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  background-color: rgba(51, 51, 51, 0.9);
  padding: 1.5rem;
  height: 60%;
  box-shadow: 0 0 5px 1px rgb(237, 250, 233), 0 0 6px 1px #adf3ad,
    0 0 5px 1px #92d050;
}

.price {
  font-size: 2rem;
  line-height: 1;
  font-weight: 300;
  letter-spacing: -0.025em;
  color: #fff;
}

.lists {
  overflow: auto;
  height: 70%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(255, 255, 255, 1);
  padding: 0;
}
.lists::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.lists::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  height: 20px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #92d050;
}
.lists::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ffffff;
}

.list {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list svg {
  height: 1rem;
  width: 1rem;
}

.list a {
  margin-left: 1rem;
}

.action {
  margin-top: 2rem;
  width: 100%;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 1);
  padding: 0.625rem 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 1);
  outline: none;
  transition: all 0.2s ease;
}

.action:hover {
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
}
</style>