<template>
  <div >
    <div style="min-height: 694px" class="index1">
      <el-row
        id="pdfDom"
        class="pdfDom1"
        style="text-align: center; margin: 20px auto; width: 1200px"
      >
        <div id="num">
          <h2
            class="h2title"
            style="padding: 100px"
            v-if="titleName === '人力资本化提升计划'"
          >
            人力资本化提升计划测评报告
          </h2>
          <h2
            class="h2title"
            style="padding: 100px"
            v-if="titleName === '培训班人力资本化提升计划'"
          >
            培训班人力资本化提升计划测评报告
          </h2>
          <h2
            class="h2title"
            style="padding: 100px"
            v-if="titleName === '组织人力资本化测评'"
          >
            组织人力资本化测评报告
          </h2>
          <div style="page-break-after: always"></div>
          <p class="title" style="margin-top: 50px">测评报告简介</p>
          <p>
            &nbsp;&nbsp;人力资本的新界定，内在指的是人力资源，外在指的是自愿或接受将不断提升的人力资源的所有权通过货币计价转化为社会组织或企业的一定产权（发起股东、创办企业或加盟企业等），通过生产社会产品或提供社会服务实现未来可能的更大收益
            。
          </p>
          <p>
            &nbsp;&nbsp;人力资本化就是人转化为人力资本的意愿（目标）、过程或结果，同时也是从人的奉献和付出转化为社会资本价值的意愿（目标）、过程或结果。
          </p>
          <p>
            &nbsp;&nbsp;本报告分为两大部分，第一部分人生自我规划，主要包含学历规划、专业提升规划、专业跨越规划、职业选择规划、职业职级规划、职业收入规划、退休选择、退休后工作年龄、退休后每天工作时长、终身兴趣或成就规划。第二部分是人力资本投入测评，包含教育时间投入、教育费用投入、培训时间投入、培训费用投入、自学时间投入、自学费用投入、研发时间投入、研发费用投入、创业时间投入、创业费用投入。第三部分是未来核心心智能力测评，包含隐性能力，三大专业能力。
          </p>
          <p style="text-indent: 0cm;">阅读建议</p>
          <li>
            i.
            人力资本化的测评测评遵循匹配原理、推断原理和误差原理。在使用测评结果时，需要与当前与未来的实际情况相结合，并以科学的态度看待测评结果。
          </li>
          <li>
            ii.
            测评结果的准确性和可靠性依赖于被评价者在测验中是否为内心最真实的想法，提交的数据为客观事实，态度是否认真、能否如实作答，是否答完题目以及答题所用时间是否在合理范围内等。
          </li>
          <li>
            iii.
            若您是第一次阅读该类报告，有一些专有名称的理解不到位，最好是在专业人士的指导下阅读，或请专业人士为您进行解释。
          </li>
        </div>
        <div style="page-break-after: always"></div>
        <div id="num1">
          <p class="title">
            <a  name="1">I．测评答题情况</a>
          </p>
          <table>
            <thead>
              <th colspan="5" class="title-white">答题情况</th>
            </thead>
            <tbody>
              <td style="text-align: center">姓名</td>
              <td style="text-align: center">{{ userData.username }}</td>
              <td style="text-align: center">性别</td>
              <td v-if="userData.sex === 1" colspan="2">男</td>
              <td v-if="userData.sex === 0" colspan="2">女</td>
            </tbody>
            <tbody>
              <tr>
                <td
                  rowspan="3"
                  style="text-align: center; vertical-align: middle"
                >
                  答题时间
                </td>
                <td colspan="4" style="text-align: left">
                  第一部分：人生自我规划，{{ userData.username }}答题的时间为{{
                    questionTime
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: left">
                  第二部分：人力资本投入，{{ userData.username }}答题的时间为{{
                    questionTime1
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: left">
                  第三部分：未来核心心智能力，{{
                    userData.username
                  }}答题的时间为{{ questionTime2 }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td
                  rowspan="2"
                  style="text-align: center; vertical-align: middle"
                >
                  答题题数
                </td>
                <td style="text-align: center">总题数</td>
                <td style="text-align: center">{{ score }}</td>
                <td style="text-align: center">实际答题数</td>
                <td style="text-align: center">{{ score }}</td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: left">
                  {{ userData.username }}的答题数量在合理范围内，测评结果有效。
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="page-break-after: always"></div>

        <div id="num2">
          <p class="title">
            <a name="2">II． 人力资本化概况</a>
          </p>
          <li class="title3">人生自我规划得分情况图示</li>
          <div class="radarImg" id="main2"></div>
          <p>
            <span
              v-for="(item, index) in CLAdata"
              :key="item.abilityCategory"
              style="flex-direction: column"
            >
              {{ item.abilityCategory }}得分 {{ item.fraction }} 分
              <span v-if="index !== CLAdata.length - 1">,</span>
            </span>
            。
          </p>
          <li class="title3">人力资本投入得分情况图示</li>
          <div class="radarImg" id="main3"></div>
          <p>
            <span
              v-for="(item, index) in CMdata"
              :key="item.abilityCategory"
              style="flex-direction: column"
            >
              {{ item.abilityCategory }}得分 {{ item.fraction }} 分
              <span v-if="index !== CMdata.length - 1">,</span>
            </span>
            。
          </p>
          <li class="title3">未来核心心智能力得分情况图示</li>
          <div class="radarImg" id="main4"></div>
          <p>
            <span v-for="(item, index) in CCMdata" :key="item.abilityCategory">
              <span
                v-for="it in item.evaluationEntities"
                :key="it.abilityCategory"
                style="flex-direction: column"
              >
                {{ it.abilityCategory }}得分 {{ it.fraction }} 分
                <span v-if="index !== CCMdata.length - 1">,</span>
              </span>
            </span>
            。
          </p>
          <li class="title3">指标综合得分情况</li>
          <p>
            从指标得分上来看，综合得分
            {{ list.totalScore }}。
          </p>
        </div>

        <div style="page-break-after: always"></div>

        <div id="num3">
          <p class="title"><a name="3"></a>III.测评结果分析</p>
          <p class="title2"><a name="4"></a>第一部分：人生自我规划</p>
          <p class="title4">
            <i class="el-icon-bottom-right"></i><a name="41"></a>测评得分一览
          </p>
          <table>
            <tr>
              <th style="color: #fff">维度</th>
              <th style="color: #fff">要素</th>
              <th style="color: #fff">个人得分</th>
            </tr>
            <tr>
              <td :rowspan="CLAlength + 1" style="background-color: #a8d08d">
                人生自我规划
              </td>
            </tr>
            <template v-for="item in CLAdata">
              <tr>
                <td
                  style="
                    background-color: #c5e0b3;
                    text-align: left;
                    vertical-align: middle;
                  "
                >
                  {{ item.abilityCategory }}
                </td>
                <td style="text-align: center">{{ item.fraction }}</td>
              </tr>
            </template>
          </table>
          <div class="LineImg" id="main0"></div>
          <p class="title4">
            <i class="el-icon-bottom-right"></i><a name="42"></a>测评分项分析
          </p>

          <div>
            <li class="title3" style="padding: 20px 0 0 100px">
              人生自我规划综合概览
            </li>
            <div class="radarImg" :id="'main' + 5"></div>
            <table>
              <tr>
                <td bgcolor="#92D050" style="color: #fff; font-weight: bold">
                  人生自我规划
                </td>
                <td colspan="2" bgcolor="#92D050" class="title-white" style="text-align: left; text-indent: 2rem;">
                  得分{{ lifeData.ttraction }}分
                </td>
              </tr>
              <tr>
                <td
                  colspan="3"
                  style="text-align: left"
                  v-html="lifeData.comments"
                ></td>
              </tr>
              <tr v-for="it in CLAdata" :key="it.question">
                <td style="text-align: center;width: 20%">{{ it.question }}</td>
                <td style="text-align: center;width: 15%">{{ it.fraction }}</td>
                <td style="text-align: left" v-html="it.comments"></td>
              </tr>
            </table>
          </div>
          <p class="title2"><a name="5"></a>第二部分：人力资本投入</p>
          <p class="title4">
            <i class="el-icon-bottom-right"></i><a name="51"></a>测评得分一览
          </p>
          <table>
            <tr>
              <th style="color: #fff">维度</th>
              <th style="color: #fff">要素</th>
              <th style="color: #fff">个人得分</th>
            </tr>
            <tr>
              <td :rowspan="CMlength + 1" style="background-color: #a8d08d">
                人力资本投入
              </td>
            </tr>
            <template v-for="item in CMdata">
              <tr>
                <td
                  style="
                    background-color: #c5e0b3;
                    text-align: left;
                    vertical-align: middle;
                  "
                >
                  {{ item.abilityCategory }}
                </td>
                <td style="text-align: center">{{ item.fraction }}</td>
              </tr>
            </template>
          </table>
          <div class="LineImg" id="main1"></div>
          <p class="title4">
            <i class="el-icon-bottom-right"></i><a name="52"></a>测评分项分析
          </p>

          <div>
            <li class="title3" style="padding: 20px 0 0 100px">
              人力资本投入综合概览
            </li>
            <div class="radarImg" :id="'main' + 6"></div>
            <table>
              <tr>
                <td bgcolor="#92D050" style="color: #fff; font-weight: bold;">
                  人力资本投入
                </td>
                <td colspan="2" bgcolor="#92D050" class="title-white" style="text-align: left;  text-indent: 2rem;">
                  得分{{ humanData.ttraction }}分
                </td>
              </tr>
              <tr>
                <td
                  colspan="3"
                  style="text-align: left"
                  v-html="humanData.comments"
                ></td>
              </tr>
              <tr v-for="it in CMdata" :key="it.abilityCategory">
                <td style="text-align: center;width: 20%">{{ it.abilityCategory }}</td>
                <td style="text-align: center;width: 15%">{{ it.fraction }}</td>
                <td style="text-align: left" v-html="it.comments"></td>
              </tr>
            </table>
          </div>
        </div>

        <p class="title2"><a name="6"></a>第三部分：未来核心心智能力</p>
        <p class="title4">
          <i class="el-icon-bottom-right"></i><a name="61"></a>期望得分一览
        </p>
        <table>
          <tr>
            <th style="color: #fff">能力一类</th>
            <th style="color: #fff">能力类别二类</th>
            <th style="color: #fff">能力种类</th>
            <th style="color: #fff">测评得分</th>
            <th style="color: #fff">期望得分</th>
            <th style="color: #fff">提升指数</th>
          </tr>
          <template v-for="item in CCMdata">
            <tr>
              <td
                :rowspan="item.evaluationEntities.length * 6 + 1"
                style="
                  background-color: #92d050;
                  text-align: center;
                  vertical-align: middle;
                "
              >
                {{ item.abilityCategory }}
              </td>
            </tr>
            <template v-for="ite in item.evaluationEntities">
              <tr>
                <td
                  :rowspan="ite.evaluationEntities.length + 1"
                  style="
                    background-color: #a8d08d;
                    text-align: center;
                    vertical-align: middle;
                  "
                >
                  {{ ite.abilityCategory }}
                </td>
              </tr>
              <template v-for="it in ite.evaluationEntities">
                <tr>
                  <td style="background-color: #c5e0b3">
                    {{ it.abilityCategory }}
                  </td>
                  <td style="background-color: #e2efd9">{{ it.newScore }}</td>
                  <td style="background-color: #e2efd9">{{ it.oldScore }}</td>
                  <td style="background-color: #e2efd9">{{ it.fraction }}</td>
                </tr>
              </template>
            </template>
          </template>
        </table>
        <p class="title4">
          <i class="el-icon-bottom-right"></i><a name="62"></a>期望差距分析
        </p>
        <div v-for="(it, index) in CCCMdata" :key="it.abilityCategory">
          <p style="padding: 20px 0 0 50px;text-indent: 0em;">
            {{ index + 1 }}.{{ it.abilityCategory }}综合概览
          </p>
          <div class="radarImg" :id="'main' + (7 + index)"></div>
          <table>
            <tr v-for="its in it.evaluationEntities" :key="its.abilityCategory">
              <td style="text-align: center;width: 20%">{{ its.abilityCategory }}</td>
              <td style="text-align: center;width: 15%">{{ its.fraction }}</td>
              <td style="text-align: left" v-html="its.comments"></td>
            </tr>
          </table>
        </div>

        <div style="page-break-after: always"></div>
        <div id="num4">
          <p class="title"><a name="7"></a>IV． 人力资本化建议</p>
          <p>
            你的综合得分是 {{ list.totalScore }} 分，当前人生自我规划得分
            {{ list.planFs }} 分，人力资本投入得分
            {{ list.investment1Fs }} 分，未来核心心智能力得分
            {{ list.scores }} 分。
          </p>
          <p>
            你在当前人生自我规划方面当前的绝对进取项为
            {{ list.entity.planAbsoluteAdvantage }} ,
            相对进取项为
            {{ list.entity.planRelativeAdvantage }}；
          </p>
          <p>
            你在人力资本投入方面当前的绝对进取项为
            {{ list.entity.iabsoluteAdvantage }} ,
            相对进取项为
            {{ list.entity.irelativeAdvantage }};
          </p>
          <p>
            你在未来核心心智能力方面当前的绝对进取项为
            {{ list.entity.absoluteAdvantage }} ,相对进取项项为
            {{ list.entity.relativeAdvantage }}。
          </p>
          <p>
            你在当前人生自我规划方面当前的绝对保守项为
            {{ list.entity.planAbsoluteDisadvantage }},
            相对保守项为
            {{ list.entity.planRelativeDisadvantage }}；
          </p>
          <p>
            你在人力资本投入方面当前的绝对保守项为
            {{ list.entity.iabsoluteDisadvantage }},
            相对保守项为
            {{ list.entity.irelativeDisadvantage }}；
          </p>
          <p>
            你在未来核心心智能力方面当前的绝对保守项为
            {{ list.entity.absoluteDisadvantage }},相对保守项项为
            {{ list.entity.relativeDisadvantage }}。
          </p>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import Sideframe from "../../../components/sideframe.vue";
import Flooter from "../../flooter.vue";
import Header from "../../Header.vue";
import Cookies from "js-cookie";
import * as echarts from "echarts";
import { getPdf } from "@/utils/htmlToPdf.js";
import html2Canvas from "html2canvas";
import { setTimeout } from "timers";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
const myMap = new Map();
export default {
  components: { Header, Flooter, Sideframe },
  name: "",
  data() {
    return {
      CLAdata: [],
      CLAlength: 1,
      CMdata: [],
      value: 0,
      token: "",
      score: "", //题库
      riskId: [], //选项id
      optionScoreStr: [], //选项分值
      questiondata1: [],
      userData: {},
      CCCMdata: [],
      questionTime: "",
      questionTime1: "",
      htmlTitle: "测试",
      windowWidth: 0,
      titleName: "",
      padding: "10% 10px 10px 10px",
      padding1: "50% 10px 10px 10px",
    };
  },
  props: { dataList: Object, falg: Boolean },
  watch: {
    windowWidth(val) {
      this.windowWidth = val;
    },
    falg(val) {},
  },
  created() {
    const CCMScores = [];
    this.userData = JSON.parse(window.localStorage.getItem("userData"));
    console.log(this.userData);
    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = document.documentElement.clientWidth;
    });
    this.titleName = window.localStorage.getItem("title");
    if (window.localStorage.getItem("questionResult")) {
      this.list = JSON.parse(window.localStorage.getItem("questionResult"));
      if (window.localStorage.getItem("questionTest")) {
        if (
          JSON.parse(window.localStorage.getItem("questionTest")).length > 5
        ) {
          this.oldScores = JSON.parse(
            window.localStorage.getItem("questionTest")
          )[5];

          this.oldScores.forEach((it) => {
            CCMScores.push(it.split(","));
          });
        }
      } else {
        this.list.evaluationWeiEntities.forEach((it) => {
          it.evaluationEntities.forEach((item) => {
            item.evaluationEntities.forEach((its) => {
              CCMScores.push(its.newScore);
              CCMScores.push(its.oldScore);
            });
          });
        });
      }
      this.score = window.localStorage.getItem("questionlength");
      this.questionTime = window.localStorage.getItem("questionTime");
      this.questionTime1 = window.localStorage.getItem("questionTime1");
      this.questionTime2 = window.localStorage.getItem("questionTime2");
      //对于开头，结尾的特殊字符，用正则字符去转换
      this.list.entity.absoluteAdvantage =
        this.list.entity.absoluteAdvantage.replace(/^(\s|,)+|(\s|,)+$/g, "");
      this.list.entity.absoluteDisadvantage =
        this.list.entity.absoluteDisadvantage.replace(/^(\s|,)+|(\s|,)+$/g, "");
      this.list.entity.iabsoluteAdvantage =
        this.list.entity.iabsoluteAdvantage.replace(/^(\s|,)+|(\s|,)+$/g, "");
      this.list.entity.iabsoluteDisadvantage =
        this.list.entity.iabsoluteDisadvantage.replace(
          /^(\s|,)+|(\s|,)+$/g,
          ""
        );
      this.list.entity.irelativeAdvantage =
        this.list.entity.irelativeAdvantage.replace(/^(\s|,)+|(\s|,)+$/g, "");
      this.list.entity.irelativeDisadvantage =
        this.list.entity.irelativeDisadvantage.replace(
          /^(\s|,)+|(\s|,)+$/g,
          ""
        );
      this.list.entity.planAbsoluteAdvantage =
        this.list.entity.planAbsoluteAdvantage.replace(
          /^(\s|,)+|(\s|,)+$/g,
          ""
        );
      this.list.entity.planAbsoluteDisadvantage =
        this.list.entity.planAbsoluteDisadvantage.replace(
          /^(\s|,)+|(\s|,)+$/g,
          ""
        );
      this.list.entity.planRelativeAdvantage =
        this.list.entity.planRelativeAdvantage.replace(
          /^(\s|,)+|(\s|,)+$/g,
          ""
        );
      this.list.entity.planRelativeDisadvantage =
        this.list.entity.planRelativeDisadvantage.replace(
          /^(\s|,)+|(\s|,)+$/g,
          ""
        );
      this.list.entity.relativeAdvantage =
        this.list.entity.relativeAdvantage.replace(/^(\s|,)+|(\s|,)+$/g, "");
      this.list.entity.relativeDisadvantage =
        this.list.entity.relativeDisadvantage.replace(/^(\s|,)+|(\s|,)+$/g, "");
      this.list.evaluationEntityList2.forEach((item) => {
        for (const key in this.list.map) {
          if (key === item.abilityCategory) {
            item.rank = this.list.map[key];
          }
        }
      });
      this.CLAdata = this.list.evaluationEntityList2;
      this.CLAlength = this.CLAdata.length;
      this.list.evaluationEntities.forEach((it) => {
        if (it.abilityCategory === "人生自我规划") {
          this.lifeData = it;
        } else if (it.abilityCategory === "人力资本投入") {
          this.humanData = it;
        }
      });
      this.list.evaluationEntityList3.forEach((item) => {
        for (const key in this.list.map) {
          if (key === item.abilityCategory) {
            item.rank = this.list.map[key];
          }
        }
      });
      this.CMdata = this.list.evaluationEntityList3;
      this.CMlength = this.CMdata.length;
      this.list.evaluationWeiEntities.forEach((item) => {
        item.evaluationEntities.forEach((it) => {
          for (const key in this.list.map) {
            if (key === it.abilityCategory) {
              it.rank = this.list.map[key];
            }
          }
          this.CCCMdata.push(it);
        });
      });
      let CCCCMdata = [];
      this.CCCMdata.forEach((it, index) => {
        it.evaluationEntities.forEach((its) => {
          CCCCMdata.push(its);
        });
      });

      // CCCCMdata.forEach((it,index) =>{
      //   it.oldScores = CCMScores[index][0]
      //   it.newScores = CCMScores[index][1]
      // })

      this.CCMdata = this.list.evaluationWeiEntities;
    }
  },
  mounted() {
    this.getLineChart1();
    this.getLineChart();
    this.getRadar1();
    this.getRadar2();
    this.getRadar3();
    this.getRadar4();
    this.getRadar5();
    this.getRadar6();
  },
  methods: {
    getLineChart1() {
      const CMindicator = [];
      const CMscorce = [];
      this.CMdata.forEach((it) => {
        CMscorce.push(it.fraction);
        CMindicator.push(it.abilityCategory);
      });
      const chartDom = document.getElementById("main1");
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: false,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            max: 100,
            min: -100,
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: CMindicator,
            axisLabel: {
              textStyle: {
                color: "#060606",
              },
            },
          },
        ],
        series: [
          {
            name: "人力资本投入",
            type: "bar",
            itemStyle: {
              normal: {
                color: function (params) {
                  //根据数值大小设置相关颜色
                  if (params.value > 0) {
                    return "#E2EFD9";
                  } else {
                    return "#66DDF2";
                  }
                },
              },
            },
            label: {
              show: true,
            },
            data: CMscorce,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    getLineChart() {
      const CLAindicator = [];
      const CLAscorce = [];
      this.CLAdata.forEach((it) => {
        CLAscorce.push(it.fraction);
        CLAindicator.push(it.abilityCategory);
      });
      const chartDom = document.getElementById("main0");
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: false,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            max: 100,
            min: -100,
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisTick: {
              show: false,
            },
            data: CLAindicator,
            axisLabel: {
              textStyle: {
                color: "#060606",
              },
            },
          },
        ],
        series: [
          {
            name: "人生自我规划",
            type: "bar",
            itemStyle: {
              normal: {
                color: function (params) {
                  //根据数值大小设置相关颜色
                  if (params.value > 0) {
                    return "#E2EFD9";
                  } else {
                    return "#66DDF2";
                  }
                },
              },
            },
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: CLAscorce,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async getRadar1() {
      const CLAindicator = [];
      const CLAscorce = [];
      this.CLAdata.forEach((it) => {
        const CLAname = {
          text: it.abilityCategory,
          max: 100,
        };
        CLAscorce.push(it.fraction);
        CLAindicator.push(CLAname);
      });
      const chartDom = document.getElementById("main2");
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          // text: 'Multiple Radar'
        },
        tooltip: {
          // trigger: 'axis'
        },
        legend: {
          show: false,
        },
        radar: [
          {
            indicator: CLAindicator,
            radius: 80,
            center: ["50%", "60%"],
            axisName: {
              color: "#060606",
            },
          },
        ],
        series: [
          {
            type: "radar",
            data: [
              {
                value: CLAscorce,
                name: "人力资本投入得分情况",
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async getRadar2() {
      const CMindicator = [];
      const CMscorce = [];
      this.CMdata.forEach((it) => {
        const CMname = {
          text: it.abilityCategory,
          max: 100,
        };
        CMscorce.push(it.fraction);
        CMindicator.push(CMname);
      });
      const chartDom = document.getElementById("main3");
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          // text: 'Multiple Radar'
        },
        tooltip: {
          // trigger: 'axis'
        },
        legend: {
          show: false,
        },
        radar: [
          {
            indicator: CMindicator,
            radius: 80,
            center: ["50%", "60%"],
            axisName: {
              color: "#060606",
            },
          },
        ],
        series: [
          {
            type: "radar",
            data: [
              {
                value: CMscorce,
                name: "人力资本投入得分情况",
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async getRadar5() {
      const CCMindicator = [];
      const CCMscorce = [];
      let CCMinDATA = [];
      this.CCMdata.forEach((it) => {
        it.evaluationEntities.forEach((its) => {
          CCMinDATA.push(its);
        });
      });
      CCMinDATA.forEach((it) => {
        const CCMname = {
          text: "",
          max: 100,
        };
        CCMname.text = it.abilityCategory;
        CCMscorce.push(it.fraction);
        CCMindicator.push(CCMname);
      });
      const chartDom = document.getElementById("main4");
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          // text: 'Multiple Radar'
        },
        tooltip: {
          // trigger: 'axis'
        },
        legend: {
          show: false,
        },
        radar: [
          {
            indicator: CCMindicator,
            radius: 80,
            center: ["50%", "60%"],
            axisName: {
              color: "#060606",
            },
          },
        ],
        series: [
          {
            type: "radar",
            data: [
              {
                value: CCMscorce,
                name: "未来核心心智能力得分情况",
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async getRadar3() {
      const CLAindicator = [];
      const CLAscorce = [];
      this.CLAdata.forEach((it) => {
        const CLAname = {
          text: "",
          max: 100,
        };
        CLAname.text = it.abilityCategory;
        CLAscorce.push(it.fraction);
        CLAindicator.push(CLAname);
      });
      const chartDom = document.getElementById("main" + 5);
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          // text: 'Multiple Radar'
        },
        tooltip: {
          // trigger: 'axis'
        },
        legend: {
          show: false,
        },
        radar: [
          {
            indicator: CLAindicator,
            radius: 80,
            center: ["50%", "60%"],
            axisName: {
              color: "#060606",
            },
          },
        ],
        series: [
          {
            type: "radar",
            data: [
              {
                value: CLAscorce,
                name: "人生自我规划得分情况",
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async getRadar4() {
      const CMindicator = [];
      const CMscorce = [];
      this.CMdata.forEach((it) => {
        const CMname = {
          text: "",
          max: 100,
        };
        CMname.text = it.abilityCategory;
        CMscorce.push(it.fraction);
        CMindicator.push(CMname);
      });

      const chartDom = document.getElementById("main" + 6);
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          // text: 'Multiple Radar'
        },
        tooltip: {
          // trigger: 'axis'
        },
        legend: {
          show: false,
        },
        radar: [
          {
            indicator: CMindicator,
            radius: 80,
            center: ["50%", "60%"],
            axisName: {
              color: "#060606",
            },
          },
        ],
        series: [
          {
            type: "radar",
            data: [
              {
                value: CMscorce,
                name: "人力资本投入得分情况",
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async getRadar6() {
      for (let i = 0; i < this.CCCMdata.length; i++) {
        const CCMindicator = [];
        const CCMscorce = [];
        let CCMinDATA = [];
        CCMinDATA.push(this.CCCMdata[i]);
        CCMinDATA.forEach((it) => {
          it.evaluationEntities.forEach((its) => {
            const CCMname = {
              text: "",
              max: 100,
            };
            CCMname.text = its.abilityCategory;
            CCMscorce.push(its.fraction);
            CCMindicator.push(CCMname);
          });
        });
        const chartDom = document.getElementById("main" + (i + 7));
        const myChart = echarts.init(chartDom);
        var option;

        option = {
          title: {
            // text: 'Multiple Radar'
          },
          tooltip: {
            // trigger: 'axis'
          },
          legend: {
            show: false,
          },
          radar: [
            {
              indicator: CCMindicator,
              radius: 80,
              center: ["50%", "60%"],
              axisName: {
                color: "#060606",
              },
            },
          ],
          series: [
            {
              type: "radar",
              data: [
                {
                  value: CCMscorce,
                  name: "未来核心心智能力得分情况",
                },
              ],
            },
          ],
        };
        option && myChart.setOption(option);
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      }
    },
  },
};
</script>
<style scoped>
.card1 {
  position: fixed;
  left: 10px;
  top: 6rem;
}
.titel-white {
  text-align: left;
  padding: 10px;
  color: #fff;
}
.sss {
  text-align: right;
  margin: 10px;
}

.lll {
  text-align: right;
  margin: 10px;
}

.el-radio__label {
  font-size: 14px;
  padding-left: 10px;
}

.tit1 {
  font-weight: bold;
  margin: 0 auto;
}

.broder {
  height: 800px;
  font-size: 18px;
}

.radarImg {
  width: 80%;
  height: 480px;
  margin: auto;
}

.LineImg {
  width: 80%;
  height: 960px;
  margin: auto;
}

#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}

html,
body {
  height: 100%;
  margin: auto;
}

.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

@page {
  size: auto;
  /* auto is the initial value */
  margin: 0mm;
  /* this affects the margin in the printer settings */
}

.page-footer {
  position: fixed;
  bottom: 0mm;
  width: 100%;
  border-top: 1px solid black;
  /* for demo */
}

.page-header {
  position: fixed;
  text-align: center;
  top: 0mm;
  width: 100%;
  border-bottom: 1px solid black;
  /* for demo */
}

.namesetting {
  text-align: left;
  padding: 20px 0 20px 0;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

.h2title {
  text-align: left;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
  background: url('../../../assets/image/starfield.jpg') no-repeat center center;
  opacity: 0.8;
  background-size:cover;
  color: #fff;
  margin: auto;
  text-align: center;
  font-family: '方正大标宋简体';
}

.background {
  background-color: #f7f7f7;
  height: 100px;
}

::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}

.index1 {
  margin: 20px 0 0 0;
}

.pdfDom {
  background: #fff;
  box-sizing: border-box;
  padding: 2rem 3rem 4rem;
}

/* h5:before
{
	counter-increment:section;
  content:"第" counter(section) "页";
} */
hr {
  margin: 0;
  margin-bottom: 8px;
}

.corporate {
  display: block;
  width: 165px;
  height: 165px;
  margin-left: 400px;
  float: left;
}

.corporatename {
  width: 400px;
  padding-left: 20px;
  font-weight: normal;
  font-size: 42px;
  margin: 100px 150px 10px 60px;
  text-align: left;
  position: absolute;
}

p {
  width: 80%;
  padding: 10px 0 20px 20px;
  text-align: left;
  margin: auto;
  line-height: 35px;
  font-size: 14px;
  font-weight: normal;
  text-indent: 2em;
}

.title {
  padding: 50px 0 20px 0;
  text-align: left;
  color: #00b071;
  font-size: 28px;
  font-weight: normal;
  margin: auto;
  font-family: "方正大标宋简体";
  text-indent: 0cm;
}

.title2 {
  color: #00b071;
  padding: 20px 0 20px 0;
  text-align: left;
  font-weight: normal;
  font-size: 22px;
  margin: auto;
  text-indent: 0cm;
}

.title4 {
  color: #00b071;
  padding: 20px 0 20px 0;
  font-weight: normal;
  font-size: 20px;
  margin: auto;
  text-indent: 0cm;
}

.title3 {
  padding: 20px 0 0 50px;
  text-align: left;
  font-size: 18px;
  color: #00b071;
  margin: auto;
  text-indent: 0cm;
}

.wrap2 {
  margin: 0 auto;
  width: 40%;
  display: grid;
  justify-content: center;
}

.wrap {
  margin: 0 auto;
  padding: 10px;
  width: 40%;
  display: grid;
  justify-content: center;
}

h3 {
  line-height: 40px;
  text-align: left;
  padding: 0px 0 0 42%;
}

li {
  width: 80%;
  text-align: left;
  padding: 10px 0 0 20px;
  line-height: 35px;
  font-size: 14px;
  font-weight: normal;
  margin: auto;
}

table {
  width: 80%;
  border: 1px solid #ccc;
  /* 合并单元格的线条 */
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  vertical-align: middle;
  margin: auto;
}

th,
td {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
  text-align: center;
  padding: 12px 0;
  color: #666;
  font-size: 14px;
}

th {
  font-weight: normal;
  background-color: #92d050;
  font-weight: bold;
  color: #fff;
}
</style>