<template>
  <div style="position: relative">
      <Header></Header>
	  <sideframe></sideframe>
       <div style="min-height:694px" class="index1">
        <breadcrumb :titleData="titleData"></breadcrumb>
	<hr style="min-width:1200px;">
	<el-link :underline="false" class="share" @click="shareOut()">点击分享  <i class="el-icon-share" ></i></el-link>
	<el-row style="text-align: left;width:100%;height:30px;margin:0 0 30px 0">
		<el-row :gutter="12" style="text-align: left;width:100%;margin-left:5px;height:30px;margin:30px 0 30px 0">
  <el-col :span="6" >
    <el-card shadow="never" style="height:20px;background-color:#9DD461">
    </el-card>
  </el-col>
  <el-col :span="6" >
    <el-card shadow="never" style="height:20px;background-color:#00B050">
    </el-card>
  </el-col>
  <el-col :span="6" >
    <el-card shadow="never" style="height:20px;background-color:#007CA9">
    </el-card>
  </el-col>
  <el-col :span="6" >
    <el-card shadow="never" style="height:20px;background-color:#0070C0">
    </el-card>
  </el-col>
		</el-row>
		<template v-for="items in humancvmsgs">
		<div :key="items.title" style="text-align: center;width:25%;float: left;" v-if="items.name==undefined">
			<span class="title">{{items.title}}</span>
			<div style="margin:20px 0 50px 0;text-align: left;width:80%">
				<li style="margin:20px 0 0 130px;" v-if="items.conent!==''&&items.conent!==undefined">{{items.conent}}</li>
				<li style="margin:20px 0 0 130px;" v-if="items.con1!==''&&items.con1!==undefined">{{items.con1}}</li>
				<li style="margin:20px 0 0 130px;" v-if="items.con2!==''&&items.con2!==undefined">{{items.con2}}</li>
				<li style="margin:20px 0 0 130px;" v-if="items.con3!==''&&items.con3!==undefined">{{items.con3}}</li>
			</div>
		</div>
		</template>
		<hr style="height:10%;width:60%;margin:120px 0 50px 20%;background-color:#A1C8D7;border:0;">
		<el-link :underline="true" style="margin-left:50%"  @click="toquestion()"><strong class="strong-title">点击进入测评</strong>  <i class="el-icon-caret-right" ></i></el-link>
	</el-row>
     </div>
      <flooter></flooter>
  </div>
</template>

<script>
import Sideframe from "../../../components/sideframe.vue";
import Flooter from "../../flooter";
import Header from "../../Header";
import Breadcrumb from "@/components/breadcrumb.vue";
import { nanoid } from "nanoid";
export default {
  components: { Header, Flooter, Sideframe, Breadcrumb },
  data() {
    return {
      humancvmsgs: [],
      titleData: {
        titleName: "",
        data: [
          {
            id: nanoid(),
            name: "人力资本化产品",
            href: "/IntroductionToHumanCapitalProducts",
          },
          {
            id: nanoid(),
            name: "",
            href: "",
          },
        ],
      },
    };
  },
  created() {
    const humancvmsg = JSON.parse(this.$route.query.humancvmsg);
    this.humancvmsgs = humancvmsg;
    this.titleData.titleName = this.humancvmsgs[0].name
    this.titleData.data[1].name = this.humancvmsgs[0].name
    
  },
  mounted() {
    
  },
  methods: {
    async shareOut() {
      const res = await this.axios.get();
      if (res.code == 200) {
        const { data } = res;
        wxShare.getJSSDK(data, dataForWeixin);
      }
    },
    toquestion() {
      this.$router.push({
        path: this.humancvmsgs[0].routerIndex
      });
    }
  }
};
</script>

<style>
.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
    font-weight: normal !important;
    text-decoration: none;
    -webkit-transition: color .2s cubic-bezier(.645,.045,.355,1);
    transition: color .2s cubic-bezier(.645,.045,.355,1);
    color: #303133;
}
</style>
<style lang="scss" scoped>
$base-color: rgb(51, 51, 51);
li {
  font-size: 14px;
}
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: $base-color;
}
::v-deep span:hover {
  color: rgb(218, 185, 107);
}
html,
body {
  height: 100%;
}
.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}
.share {
  float: right;
  margin-right: 50px;
  line-height: 40px;
  font-family: '宋体'
}
.background {
  background-color: #f7f7f7;
  height: 100px;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
h3 {
  margin: 0;
  text-align: left;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.875;
}
img {
  display: inline-block;
  margin-top: 20px;
}
.strong-title {
  font-size: 16px;
  font-weight: normal;
}

p {
  display: inline-block;
  text-indent: 2em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.875;
}
.aside {
  display: inline-block;
  width: 260px;
  position: absolute;
  top: 200px;
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
.el-row {
  display: inline-block;
  margin-right: 30px;
  margin-top: 40px;
  margin-bottom: 100px;
}
.title {
  margin: 0;
  font-size: 24px;
  font-weight: inherit;
}
::v-deep .el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  float: left;
}
.link {
  cursor: pointer;
  text-decoration: none;
  float: left;
  color: #555555;
  font-weight: lighter;
}
</style>