<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <div class="dow" v-if="pageListShow">
        <div class="content-body">
          <template v-for="items in pageData">
            <div
              class="content-box"
              v-if="items.imgPath !== null && items.imgPath !== undefined"
              :key="items.id"
            >
              <img :key="items.id + '1'" :src="items.imgPath" />
              <h4 :key="items.id + '2'">
                <a @click="router(items.articleTitle)"
                  >&nbsp;{{
                    items.articleTitle.length > 15
                      ? items.articleTitle.slice(0, 15) + "..."
                      : items.articleTitle
                  }}</a
                >
              </h4>
            </div>
            <div class="content-box" v-else :key="items.id">
              <h4
                :key="items.id + '2'"
                style="width: 100%"
                v-if="items.articleTitle !== ''"
              >
                <a @click="router(items.articleTitle)"
                  >&nbsp;{{ items.articleTitle }}</a
                >
              </h4>
            </div>
          </template>
          <el-empty v-if="pageData.length == 0" :image-size="200"></el-empty>
          <Pagination
            v-if="pageListShow"
            v-bind:child-msg="pageparm"
            @call="callFather"
          ></Pagination>
        </div>
        <asidetitle :asideData="asideData"></asidetitle>
      </div>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import sideframe from "../../components/sideframe.vue";
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Pagination from "../../components/Pagination";
import asidetitle from "../../components/asidetitle.vue";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";
marked.setOptions({
  renderer: new marked.Renderer(),
  highlight: function (code) {
    return hljs.highlightAuto(code).value;
  },
  pedantic: false,
  gfm: true,
  tables: true,
  breaks: true,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: true,
});
export default {
  components: {
    Header,
    Flooter,
    sideframe,
    asidetitle,
    Breadcrumb,
    Pagination,
  },
  data() {
    return {
      titleData: {
        titleName: "混合式项目设计",
        data: [
          {
            id: nanoid(),
            name: "管理咨询",
            href: "",
          },
          {
            id: nanoid(),
            name: "混合式项目设计",
            href: "",
          },
        ],
      },
      asideData: {
        asideName: "",
        data: [],
      },
      content: "",
      pageData: [],
      pageparm: {
        currentPage: 1,
        pageSize: 5,
        total: 10,
      },
      formInline: {
        page: 1,
        limit: 5,
        articleEntity: {
          articleTitle: "",
          articleContent: "",
          articleTypeName: "混合式项目设计",
          articleAuthor: "",
          startTime: "",
          endTime: "",
          del: "1",
          state: "1",
        },
      },
      pageListShow: false,
    };
  },
  mounted() {
    this.getData();
    this.getAsideData();
  },
  methods: {
    getAsideData() {
      this.axios
        .get("/menu/selectAllMenu", {
          params: {
            startpage: 1,
            pagesize: 600,
            state: 1,
          },
        })
        .then((res) => {
          res.object.records.forEach((item) => {
            if (item.indexName === "管理咨询") {
              this.asideData.asideName = item.indexName;
              item.menuEntity.forEach((it) => {
                let childData = {
                  _id: nanoid(),
                  title: "",
                  to: "",
                };
                childData.title = it.indexName;
                childData.to = it.routerIndex;
                this.asideData.data.push(childData);
              });
            }
          });
          window.localStorage.setItem(
            "asideData",
            JSON.stringify(this.asideData)
          );
        });
    },
    async getData() {
      const res = await this.axios.post("/article/search", this.formInline);

      if (res.object.list.length > 1) {
        this.pageListShow = true;
        this.pageData = res.object.list;
        this.pageparm.currentPage = this.formInline.page;
        this.pageparm.pageSize = this.formInline.limit;
        this.pageparm.total = res.object.total - 0;
      } else {
        const title = this.titleData.titleName;
        this.$router.push({
          path: "/Article",
          query: {
            title: title,
            titleName: this.titleData.data[0].name,
            name: res.object.list[0].articleTitle,
            href: this.$route.path,
          },
        });
      }
    },
    callFather(parm) {
      this.formInline.page = parm.currentPage;
      this.formInline.limit = parm.pageSize;
      this.getData();
    },
    router(val) {
      const title = this.titleData.titleName;
      window.localStorage.setItem("asideData", JSON.stringify(this.asideData));
      this.$router.push({
        path: "/Article",
        query: {
          title: title,
          titleName: this.titleData.data[0].name,
          name: val,
          href: this.$route.path,
        },
      });
    },
  },
};
</script>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
.dow {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin: auto;
}
.content-body {
  width: fit-content;
  height: fit-content;
}
.content-box {
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-box img {
  display: inline-block;
}
h4 {
  height: fit-content;
  margin: auto;
  text-align: left;
  font-weight: 100;
  background-color: #eeeeee;
  cursor: pointer;
}

a:hover {
  color: rgb(218, 185, 107);
}
hr {
  margin: 0;
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
@media (max-width: 768px) {
  .dow {
    width: 100%;
    height: fit-content;
  }
  .content-box {
    height: 456px;
    min-width: 1846px;
    margin: 30px;
  }
  .content-box img {
    height: 456px;
    width: 456px;
  }
  h4 {
    width: 1256px;
    font-size: 48px;
    margin: auto 20px;
    line-height: 1.875;
  }
  ::v-deep .el-empty {
    min-width: 1846px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .dow {
    width: 90%;
    height: fit-content;
  }
  .content-box {
    height: 256px;
    min-width: 1446px;
    margin: 30px;
  }
  .content-box img {
    height: 256px;
    width: 256px;
  }
  h4 {
    margin: auto 20px;
    width: 1156px;
    font-size: 38px;
    line-height: 1.875;
  }
  ::v-deep .el-empty {
    min-width: 1446px;
  }
}

@media (min-width: 1025px) {
  .dow {
    width: 80%;
    height: fit-content;
  }
  .content-box {
    height: 140px;
    min-width: 846px;
    margin: 30px;
  }
  .content-box img {
    height: 100%;
    width: 156px;
  }
  h4 {
    width: 656px;
    font-size: 20px;
    margin: auto 20px;
    line-height: 1.875;
  }
  ::v-deep .el-empty {
    min-width: 846px;
  }
}
</style>