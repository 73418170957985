import Vue from 'vue'
import Vuex from 'vuex'
import Uquality from './modules/Uquality'

Vue.use(Vuex)
const actions = {
  getuserData(context,value) {
    context.commit('GetUserData',value)
  },
  getQuestionList(context,value) {
    context.commit('GetQuestionList',value)
  },
  getQuestionTitle(context,value) {
    context.commit('GetQuestionTitle',value)
  },
  getQuestionTypeId(context,value) {
    context.commit('GetQuestionTypeId',value)
  }
}

const mutations = {
  GetUserData(state,value) {
    state.userData = value
  },
  GetQuestionList(state,value) {
    state.questionData = value
  },
  GetQuestionTitle(state,value) {
    state.questionTitle = value
  },
  GetQuestionTypeId(state,value) {
    state.questionTypeId = value
  }
}

const state = {
  userData:{},
  questionData:[],
  questionTitle:'',
  questionTypeId:[]
}


export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    Uquality
  }
})
