<template>
  <div class="body">
    <Header :shoppingCarNum="shoppingCarNum"></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <el-row style="text-align: center;margin:20px 0">
        <!-- 搜索筛选 -->
        <el-form
          :inline="true"
          :model="shoppingCartEntity"
          class="user-search"
          v-if="!payType"
        >
          <el-form-item label="搜索：">
            <el-input
              size="small"
              v-model="shoppingCartEntity.courseName"
              placeholder="输入课程名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" icon="el-icon-search" @click="search"
              >搜索</el-button
            >
            <el-button size="small" icon="el-icon-delete-solid" @click="empty"
              >清空</el-button
            >
            <el-button
              size="small"
              icon="el-icon-remove"
              @click="deleteRow"
              :disabled="multipleSelection.length === 0"
              >删除</el-button
            >
          </el-form-item>
        </el-form>
        <el-empty
          description="你的购物车空空如也"
          v-if="shoppingchartData.length === 0 && !payType"
        >
          <el-button type="warning" plain @click="toCourse()">去添加</el-button>
        </el-empty>
        <el-table
          v-if="shoppingchartData.length !== 0 && !payType"
          :data="shoppingchartData"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="商品名称">
                  <span>{{ props.row.courseName }}</span>
                </el-form-item>
                <el-form-item label="商品 ID">
                  <span>{{ props.row.id }}</span>
                </el-form-item>
                <el-form-item label="开课时间">
                  <span>{{ props.row.courseTime }}</span>
                </el-form-item>
                <el-form-item label="讲师名称">
                  <span>{{ props.row.aboutInstructor }}</span>
                </el-form-item>
                <el-form-item label="课程简介">
                  <span>{{ props.row.courseIntroduction }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="商品 ID" prop="id"> </el-table-column>
          <el-table-column label="商品名称" prop="courseName">
          </el-table-column>
          <el-table-column label="价格" prop="coursePrice"> </el-table-column>
        </el-table>

        <span
          v-if="shoppingchartData.length !== 0 && !payType"
          class="spanstyle"
          >总价：{{ CountPrice }} ¥</span
        >
        <el-button
          v-if="shoppingchartData.length !== 0 && !payType"
          @click="PayClick()"
          style="margin: 20px"
          round
          :disabled="multipleSelection.length === 0"
          >结算</el-button
        >
        <payCode
          v-if="payType"
          style="padding: 15px"
          :codeUrl="codeUrl"
          :outTradeNo="outTradeNo"
          :userData="userData"
          :endTime="endTime"
        ></payCode>
        <el-button v-if="payType" @click="payType = !payType">返回</el-button>
      </el-row>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Sideframe from "../../components/sideframe.vue";
import Cookies from "js-cookie";
import Pagination from "../../components/Pagination";
import payCode from "../../components/payCode.vue";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";
export default {
  inject: ["reload"],
  components: { Header, Flooter, Sideframe, Pagination, payCode, Breadcrumb },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      titleData: {
        titleName: "购物车",
        data: [
          {
            id: nanoid(),
            name: "个人中心",
            href: "",
          },
          {
            id: nanoid(),
            name: "购物车",
            href: "",
          },
        ],
      },
      options: [
        {
          value: 0,
          label: "未完成",
        },
        {
          value: 1,
          label: "已完成",
        },
      ],
      value1: "", //时间搜索的值
      userData: [],
      orderEntity: {},
      payType: false,
      outTradeNo: "",
      codeUrl: "",
      multipleSelection: [],
      shoppingCarNum: 0,
      pageparm: {
        currentPage: 1,
        pageSize: 5,
        total: 10,
      },
      shoppingCartEntity: {
        addTime: "",
        basicUserId: "",
        courseId: "",
        courseName: "",
        id: "",
      },
      token: "",
      byUserId: "",
      shoppingchartData: [],
      formline: {
        byUserId: "",
        orderEntityList: [],
      },
    };
  },
  created() {
    this.gettoken();
    this.getuserData();
  },
  computed: {
    CountPrice() {
      let count = 0;
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach((item) => {
          count = count + item.coursePrice;
        });
        return count;
      }
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.orderEntity.commodityName = "订单";
      this.orderEntity.commodityPrice = this.CountPrice;
      this.orderEntity.basicUserId = this.userData.id;
      this.orderEntity.children = [];
      this.multipleSelection.forEach((it) => {
        let commodity = {};
        commodity.commodityName = it.courseName;
        commodity.commodityPrice = it.coursePrice;
        commodity.commodityId = it.id;
        commodity.courseTime = it.courseTime;
        commodity.courseIntroduction = it.courseIntroduction;
        commodity.aboutInstructor = it.aboutInstructor;
        commodity.typeId = 0;
        this.orderEntity.children.push(commodity);
      });
    },
    toCourse() {
      this.$router.push({ path: "/courselist" });
    },
    async PayClick() {
      this.axios.post("/order/addOrder", this.orderEntity).then((res) => {
        this.orderEntity = [];
        if (res.code === 400) {
          this.$message({
            showClose: true,
            message: "有未完成的订单，请完成上一个订单后再下单",
            type: "error",
          });
        } else {
          this.deleteRow();
          this.startTime = res.object.generationTime;
          this.endTime = res.object.expirationTime;
          this.orderNumber = res.object.orderNumber;
          const formData = new FormData();
          formData.append("orderNumber", this.orderNumber);
          this.codeUrl = "";
          this.axios
            .post("/AlipayFaceToFaceController/ZFBPreorderAction", formData)
            .then((res) => {
              this.codeUrl = res.qrcode;
              this.outTradeNo = res.outTradeNo;
              this.payType = !this.payType;
              this.getshoppingchartData();
            });
        }
      });
    },
    async deleteRow() {
      const ids = [];
      this.multipleSelection.forEach((it) => {
        ids.push(it.id);
      });
      this.axios.post("/shoppingCart/deleteShoppingCar", ids).then(
        (res) => {
          this.getshoppingchartData();
        },
        (error) => {
          this.$message({
            showClose: true,
            message: error.message,
            type: "error",
          });
        }
      );
    },
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    async search() {
      this.shoppingCartEntity = this.userData.id;
      this.axios.post("/shoppingCart/search", this.shoppingCartEntity).then(
        (res) => {
          this.shoppingchartData = res.object;
        },
        (error) => {
          this.$message({
            showClose: true,
            message: error.message,
            type: "error",
          });
        }
      );
    },
    empty() {
      this.shoppingCartEntity.commodityName = "";
      this.value1 = "";
      this.shoppingCartEntity.payment = "";
      this.callFather(this.pageparm);
    },
    async getuserData() {
      const res = await this.axios.get("/basicUser/tokenByUser", {
        params: {
          token: this.token,
        },
      });
      if (res.code == 200) {
        this.userData = res.object;
        this.getshoppingchartData();
      } else {
        this.userData = null;
      }
    },
    async getshoppingchartData() {
      const res = await this.axios.get("/shoppingCart/selectOrderByUserId", {
        params: {
          byUserId: this.userData.id,
        },
      });
      if (res.code == 200) {
        this.formline.byUserId = this.userData.id;
        this.shoppingchartData = res.object;
        this.shoppingCarNum = this.shoppingchartData.length;
        window.localStorage.setItem("shoppingCarNum", this.shoppingCarNum);
        this.formline.orderEntityList = res.object;
      } else if (res.code == 400) {
        this.shoppingchartData = [];
        this.shoppingCarNum = this.shoppingchartData.length;
        window.localStorage.setItem("shoppingCarNum", this.shoppingCarNum);
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
::v-deep .demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
::v-deep .demo-table-expand .el-form-item {
  margin-left: 50px;
  margin-bottom: 0;
  width: 80%;
}
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
html,
body {
  height: 100%;
}
.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
::v-deep.el-table {
  width: 90% !important;
  margin: auto;
}
.spanstyle {
  font-size: 18px !important;
  margin-right: 20px;
  margin-top: 20px;
}
.btostyle {
  background-color: rgb(218, 185, 107);
  border-color: rgb(218, 185, 107);
  margin-top: 20px;
}
@media (max-width: 768px) {
  ::v-deep .demo-table-expand .el-form-item {
    margin-left: 200px !important;
  }
  ::v-deep .el-form-item__content span{
    font-size: 64px !important;
    height: 150px;
    line-height: 150px !important;
  }
  ::v-deep .demo-table-expand label {
    width: 300px;
    color: #99a9bf;
  }
  ::v-deep .el-table__expand-icon {
    font-size: 64px !important;
    height: 150px;
    line-height: 230px !important;
  }
  ::v-deep .el-form-item {
    line-height: 150px !important;
    margin: 50px auto !important;
    height: 150px;
  }
  ::v-deep .el-form-item__content {
    height: 150px;
    line-height: 230px !important;
  }
  ::v-deep.el-table {
    font-size: 64px !important;
    width: 90% !important;
    margin: auto;
  }
  ::v-deep.el-table .cell {
    line-height: 168px !important;
  }
  ::v-deep.el-tag {
    font-size: 64px !important;
    height: 68px !important;
    line-height: 68px !important;
  }
  ::v-deep.el-table .el-checkbox__inner {
    height: 64px !important;
    width: 64px !important;
  }
  ::v-deep.el-button i {
    font-size: 42px !important;
  }
  ::v-deep.el-descriptions__body {
    padding: 40px !important;
  }
  ::v-deep.el-input--small .el-input__inner {
    height: 88px !important;
    line-height: 88px !important;
    font-size: 64px;
    margin: 50px 10px;
    width: 600px;
  }
  ::v-deep .el-button--small{
    height: 88px!important;
    line-height: 88px!important;
  }
  ::v-deep .el-button--small span{
    height: 88px!important;
    line-height: 60px!important;
  }
  ::v-deep.el-input__inner {
    padding: 20px;
    height: 88px !important;
    line-height: 88px !important;
    font-size: 64px !important;
    width: 1088px !important;
  }
  ::v-deep.el-input__inner i {
    font-size: 64px !important;
    line-height: 88px !important;
    height: 88px !important;
  }
  ::v-deep.el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 64px !important;
    line-height: 70px !important;
    height: 70px !important;
    width: 360px !important;
  }
  .spanstyle {
    font-size: 68px !important;
    margin-right: 20px;
    margin-top: 20px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  ::v-deep .demo-table-expand .el-form-item {
    margin-left: 200px !important;
  }
  ::v-deep .el-form-item__content span{
    font-size: 42px !important;
    height: 150px;
    line-height: 150px !important;
  }
  ::v-deep .demo-table-expand label {
    width: 300px;
    color: #99a9bf;
  }
  ::v-deep .el-table__expand-icon {
    font-size: 32px !important;
    height: 48px !important;
    line-height: 48px !important;
  }
  ::v-deep.el-table {
    font-size: 42px !important;
  }
  ::v-deep.el-table .cell {
    line-height: 88px !important;
  }
  ::v-deep.el-tag {
    font-size: 42px !important;
    height: 48px !important;
    line-height: 48px !important;
  }
  ::v-deep.el-table .el-checkbox__inner {
    height: 48px !important;
    width: 48px !important;
  }
  ::v-deep .el-form-item__label {
    height: 80px!important;
    line-height: 80px!important;
  }
  ::v-deep .el-button--small{
    height: 80px!important;
    line-height: 80px!important;
  }
  ::v-deep .el-button--small span{
    height: 80px!important;
    line-height: 50px!important;
  }
  ::v-deep.el-button i {
    font-size: 28px !important;
  }
  ::v-deep.el-input--small .el-input__inner {
    height: 80px !important;
    line-height: 48px !important;
  }
  ::v-deep.el-input__inner {
    height: 48px !important;
    line-height: 48px !important;
    font-size: 42px !important;
    width: 688px !important;
  }
  ::v-deep.el-input__inner i {
    font-size: 28px !important;
    line-height: 44px !important;
    height: 44px !important;
  }
  ::v-deep .el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 42px !important;
    line-height: 44px !important;
    height: 44px !important;
    width: 200px !important;
    margin-left: 40px !important;
  }
  .spanstyle {
    font-size: 42px !important;
    margin-right: 20px;
    margin-top: 20px;
  }
  ::v-deep.el-table {
    width: 90% !important;
    margin: auto;
  }
}
@media (min-width: 1025px) {
  ::v-deep.el-table {
    font-size: 24px !important;
  }
  ::v-deep.el-table {
    width: 90% !important;
    margin: auto;
  }
}
</style>