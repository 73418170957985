import axios from "../axios";
import { UQ } from '../const'

const UqualityApi ={
    getUqualityApi(params){
        return axios({
            url: UQ+ '',
            params
        })
    }
}

export default UqualityApi;