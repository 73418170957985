<template>
    <div>
      <loading v-if="loadingisShow" />
      <el-row style="text-align: center">
        <!-- 搜索筛选 -->
        <el-empty
          description="你还没有完成测评"
          v-if="ResultsData.length === 0"
        >
          <el-button type="warning" plain @click="toCourse()">去测评</el-button>
        </el-empty>
        <div v-else>
          <el-form :inline="true" :model="formInline" class="user-search">
            <el-form-item label="搜索：">
              <el-input
                size="small"
                v-model="formInline.paperEntity.testTypeName"
                placeholder="输入测评项目名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="value1"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button size="small" icon="el-icon-search" @click="search"
                >搜索</el-button
              >
              <el-button size="small" icon="el-icon-delete-solid" @click="empty"
                >清空</el-button
              >
              <el-button
                @click="downloadFileOrDirectory(multipleSelection)"
                :disabled="this.multipleSelection.length === 0"
                icon="el-icon-download"
                size="small"
              >
                下载报告</el-button
              >
              <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button> -->
            </el-form-item>
          </el-form>
          <el-table
            :data="ResultsData"
            style="width: 100%"
            max-height="500"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection"> </el-table-column>
            <el-table-column prop="testTypeName" label="测评项目">
            </el-table-column>
            <el-table-column prop="addTime" label="测评时间"> </el-table-column>
            <el-table-column prop="state" label="测评状态">
              <template slot-scope="scope">
                <p v-if="scope.row.state == '1'">
                  <el-tag type="success">已完成</el-tag>
                </p>
                <p v-if="scope.row.state == '0'">
                  <el-tag type="danger">未完成</el-tag>
                </p>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  @click="PayClick(scope.row)"
                  v-if="scope.row.state == 0"
                  type="text"
                  size="small"
                  >继续测评</el-button
                >
                <el-button
                  @click="ShowClick(scope.row)"
                  v-if="scope.row.state == 1"
                  type="text"
                  size="small"
                  >查看结果</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <Pagination
          v-if="ResultsData.length !== 0"
          :child-msg="pageparm"
          @call="callFather"
        ></Pagination>
      </el-row>
    </div>
</template>

<script>
import Header from "../Header.vue";
import Sideframe from "../../components/sideframe.vue";
import Cookies from "js-cookie";
import Pagination from "../../components/Pagination";
import loading from "@/components/loading.vue";
export default {
  components: { Header, Sideframe, Pagination, loading },
  data() {
    return {
      loadingisShow: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options: [
        {
          value: 0,
          label: "未完成",
        },
        {
          value: 1,
          label: "已完成",
        },
      ],
      userData: [],
      token: "",
      value1: "", //时间搜索的值
      byUserId: "",
      ResultsData: [],
      pickerOptions: "",
      options: [
        { label: "已完成", value: "1" },
        { label: "未完成", value: "0" },
      ],
      multipleSelection: [],
      pageparm: {
        currentPage: 1,
        pageSize: 5,
        total: 10,
      },
      wordByPDF: {
        basicUserEntity: {},
        paperIds: [],
      },
      formInline: {
        page: 1,
        limit: 5,
        paperEntity: {
          paperNumber: "",
          state: "",
          testTypeName: "",
          addTime: "",
          userId: "",
        },
      },
      state: "",
    };
  },
  created() {
    this.gettoken();
    this.getuserData();
    // this.getResultsData();
  },
  methods: {
    empty() {
      this.formInline.paperEntity.testTypeName = "";
      this.value1 = "";
    },
    async search() {
      const res = await this.axios.post("/paper/search", this.formInline);
      if (res.code == 200) {
        res.object.list.forEach((it) => {
          const a = this.moment(it.addTime).format("YYYY-MM-DD HH:mm:ss");
          it.addTime = a;
        });
        this.ResultsData = res.object.list;
        this.pageparm.total = Number(res.object.total);
        this.pageparm.pageNum = res.object.pageNum;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    toCourse() {
      this.$router.push({
        path: "/HumanCapitalizationAssessment",
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async downloadFileOrDirectory(val) {
      this.$message({
        showClose: true,
        message: "下载中,请稍后",
        type: "info",
      });
      setTimeout(() => {
        this.loadingisShow = !this.loadingisShow;
      }, 1000);
      const ids = val.map((item) => item.id).join(","); //获取所有选中行的id组成的字符串，以逗号分隔
      const names = val.map((item) => item.testTypeName).join(","); //获取所有选中行的id组成的字符串，以逗号分隔
      const times = val.map((item) => item.addTime).join(",");
      const arr = ids.split(",");
      const name = names.split(",");
      const time = times.split(",");
      this.wordByPDF.paperIds = arr;
      this.axios
        .get("/downloadPDF/preview2/", {
          params: {
            basicUserEntity: JSON.stringify(this.wordByPDF.basicUserEntity),
            paperIds: this.wordByPDF.paperIds.toString(),
          },
        })
        .then((res) => {
          this.loadingisShow = !this.loadingisShow;
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "下载成功",
              type: "success",
            });

            res.object.forEach((it, index) => {
              let blob = this.base64ToBlob(it);
              // const blob = new Blob([it], {
              //   type: "application/vnd.ms-excel",
              // }); //type这里表示xlsx类型
              const downloadElement = document.createElement("a");
              // const href = window.URL.createObjectURL(blob); //创建下载的链接
              const href = window.URL.createObjectURL(blob); //创建下载的链接
              downloadElement.href = href;
              downloadElement.download =
                name[index] + " " + time[index] + ".pdf"; //下载后文件名
              document.body.appendChild(downloadElement);
              downloadElement.click(); //点击下载
              document.body.removeChild(downloadElement); //下载完成移除元素
              window.URL.revokeObjectURL(href); //释放掉blob对象
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((res) => {
          this.loadingisShow = !this.loadingisShow;
          this.$message({
            message: res,
            type: "error",
          });
        });
    },
    base64ToBlob(code) {
      var raw = window.atob(code);
      let rawLength = raw.length;
      //转换成pdf.js能直接解析的Uint8Array类型
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: "application/pdf" });
    },
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.token = null;
        this.$router.push({
          path: "/login",
        });
      }
    },
    async getuserData() {
      const res = await this.axios.get("/basicUser/tokenByUser", {
        params: {
          token: this.token,
        },
      });
      if (res.code == 200) {
        this.userData = res.object;
        this.$store.dispatch("getuserData", res.object);
        this.formInline.paperEntity.userId = this.userData.id;
        this.wordByPDF.basicUserEntity.username = this.userData.username;
        this.wordByPDF.basicUserEntity.sex = this.userData.sex;
        this.wordByPDF.basicUserEntity.enterpriseName =
          this.userData.enterpriseName;
        this.wordByPDF.basicUserEntity.department = this.userData.department;
        this.getResultsData();
      } else {
        this.userData = null;
        this.$router.push({
          path: "/login",
        });
      }
    },
    async getResultsData(val) {
      const res = await this.axios.post("/paper/search", this.formInline);
      if (res.code == 200) {
        this.pageparm.total = Number(res.object.total);
        res.object.list.forEach((it) => {
          const a = this.moment(it.addTime).format("YYYY-MM-DD HH:mm:ss");
          it.addTime = a;
        });
        this.ResultsData = res.object.list;
      } else if (res.code == 400) {
        this.ResultsData = null;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async callFather(parm) {
      this.formInline.page = parm.currentPage;
      this.formInline.limit = parm.pageSize;
      const res = await this.axios.post("/paper/search", this.formInline);
      if (res.code == 200) {
        res.object.list.forEach((it) => {
          const a = this.moment(it.addTime).format("YYYY-MM-DD HH:mm:ss");
          it.addTime = a;
        });
        this.ResultsData = res.object.list;
        this.pageparm.total = Number(res.object.total);
        this.pageparm.pageNum = res.object.pageNum;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    deleteRow(index, rows) {
      console.log(rows);
      rows.splice(index, 1);
    },
    PayClick(row) {
      console.log(row);
    },
    async ShowClick(row) {
      if (
        row.testTypeName === "个体人力资本测评" ||
        row.testTypeName === "培训班人力资本测评" ||
        row.testTypeName === "组织人力资本测评"
      ) {
        await this.axios
          .get("/paper/preview", {
            params: {
              paperId: row.id,
            },
          })
          .then((res) => {
            window.localStorage.setItem("questionlength", res.object.number);
            window.localStorage.setItem("questionTime", res.object.htime);
            window.localStorage.setItem("questionTime1", res.object.lifeTime);
            window.localStorage.setItem(
              "questionResult",
              JSON.stringify(res.object)
            );
            window.localStorage.setItem("title", row.testTypeName);
            this.$router.push({
              name: "result",
            });
          });
      } else if (
        row.testTypeName === "人力资本化提升计划" ||
        row.testTypeName === "培训班人力资本化提升计划" ||
        row.testTypeName === "组织人力资本化测评"
      ) {
        await this.axios
          .get("/paper/promotePreview", {
            params: {
              paperId: row.id,
            },
          })
          .then((res) => {
            window.localStorage.setItem("questionlength", res.object.number);
            window.localStorage.setItem("questionTime", res.object.gtime);
            window.localStorage.setItem("questionTime1", res.object.ttime);
            window.localStorage.setItem("questionTime2", res.object.htime);
            window.localStorage.setItem(
              "questionResult",
              JSON.stringify(res.object)
            );
            window.localStorage.setItem("title", row.testTypeName);
            this.$router.push({
              name: "result",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
html,
body {
  height: 100%;
}
.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
.el-breadcrumb {
  font-size: 16px;
  margin-right: 40%;
  float: right;
  height: 90px;
  line-height: 90px;
}
::v-deep.el-table {
    width: 90% !important;
    margin: auto;
  }
h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}
.background {
  background-color: #f7f7f7;
  height: 100px;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  ::v-deep .el-form-item {
    line-height: 100px !important;
    margin: auto !important;
  }
  ::v-deep.el-table {
    font-size: 64px !important;
  }
  ::v-deep.el-table .cell {
    line-height: 168px !important;
  }
  ::v-deep.el-tag {
    font-size: 64px !important;
    height: 68px !important;
    line-height: 68px !important;
  }
  ::v-deep.el-table .el-checkbox__inner {
    height: 64px !important;
    width: 64px !important;
  }
  ::v-deep .el-checkbox__inner::after {
    height: 24px !important;
    width: 24px !important;
    left: 25%;
    top: 15%;
  }
  ::v-deep.el-button i {
    font-size: 42px !important;
  }
  ::v-deep.el-descriptions__body {
    padding: 40px !important;
  }
  ::v-deep.el-input--small .el-input__inner {
    height: 88px !important;
    line-height: 88px !important;
    font-size: 64px;
  }
  ::v-deep.el-input__inner {
    height: 88px !important;
    line-height: 88px !important;
    font-size: 64px !important;
    width: 1088px !important;
  }
  ::v-deep.el-input__inner i {
    font-size: 64px !important;
    line-height: 88px !important;
    height: 88px !important;
  }
  ::v-deep.el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 64px !important;
    line-height: 70px !important;
    height: 70px !important;
    width: 360px !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  ::v-deep.el-table {
    font-size: 42px !important;
  }
  ::v-deep.el-table .cell {
    line-height: 88px !important;
  }
  ::v-deep.el-tag {
    font-size: 42px !important;
    height: 48px !important;
    line-height: 48px !important;
  }
  ::v-deep.el-table .el-checkbox__inner {
    height: 48px !important;
    width: 48px !important;
  }
  ::v-deep.el-button i {
    font-size: 42px !important;
  }
  ::v-deep.el-input--small .el-input__inner {
    height: 48px !important;
    line-height: 48px !important;
    font-size: 42px !important;
  }
  ::v-deep.el-input__inner {
    padding: 20px;
    height: 48px !important;
    line-height: 48px !important;
    font-size: 42px !important;
    width: 688px !important;
  }
  ::v-deep.el-input__inner i {
    font-size: 48px !important;
    line-height: 44px !important;
    height: 44px !important;
  }
  ::v-deep.el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 42px !important;
    line-height: 44px !important;
    height: 44px !important;
    width: 200px !important;
    margin-left: 40px !important;
  }
}
@media (min-width: 1025px) {
  ::v-deep.el-table {
    font-size: 24px !important;
    width: 90% !important;
    margin: auto;
  }
}
</style>