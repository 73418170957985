/*
 * @Description: 
 * @Version: 2.0
 * @Author: jiyang.fu
 * @Date: 2022-01-17 11:16:27
 * @LastEditors: jiyang.fu
 * @LastEditTime: 2023-01-06 10:58:59
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from './http/axios.js'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import storage from './http/Storage'
import './utils/rem'
import moment from 'moment'
import htmlToPdf from './utils/htmlToPdf'
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/languages/zh_cn.js";
import "froala-editor/js/plugins.pkgd.min.js";
import VueFroala from "vue-froala-wysiwyg";
import "../public/fonts/iconfont.js"
import BaiduMap from 'vue-baidu-map'
// import 'default-passive-events'

import 'font-awesome/css/font-awesome.min.css'
import 'element-ui/lib/theme-chalk/index.css'

// Vue.use( BaiduMap, { ak : 'qDGbzl58RVRYeRGi7Ee8ixDnhX4708RS' } )
Vue.use(VueFroala);
Vue.use(htmlToPdf)
Vue.prototype.moment  =  moment; 
Vue.filter('formatDate', (input, formatString = "YYYY-MM-DD HH:mm:ss") => {
 if(!input){
   return '-'
 }
 // moment(input) 把时间字符串转成时间对象
 // format(formatString) 把时间对象，按照指定格式，格式化成符合条件的字符串
 return moment(input).format(formatString)
})

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.axios = axios;
Vue.prototype.H5_IMG_HOST = `http://192.168.110.103:8888/`
// Vue.prototype.H5_IMG_HOST = `apis`

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
}).$mount('#app')
