<!--
 * @Description: 
 * @Version: 2.0
 * @Author: jiyang.fu
 * @Date: 2022-03-23 16:49:30
 * @LastEditors: jiyang.fu
 * @LastEditTime: 2022-11-18 14:50:06
-->
<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <div>
        <h1>人力资本化应用场景</h1>
        <div class="product-box">
          <el-card shadow="always">
            <strong style="color: #1078c3; font-size: 20px"
              >个人人力资本化</strong
            >
            <el-divider></el-divider>
            <li>当前人生积累估值</li>
            <li>核心心智能力测评</li>
            <li>制定并实施个体人力资本化水平提升</li>
          </el-card>

          <el-card shadow="always">
            <strong style="color: #1078c3; font-size: 20px"
              >组织人力资本化</strong
            >
            <el-divider></el-divider>
            <li>人力资源基础计算</li>
            <li>人力资本投入核算</li>
            <li>人力资本化直接成果-知识产权分析</li>
            <li>人力资本化间接经营成果分析</li>
          </el-card>

          <el-card shadow="always">
            <strong style="color: #1078c3; font-size: 20px"
              >地区人力资本化</strong
            >
            <el-divider></el-divider>
            <li>
              可以测量人力资本产生的直接结果(知识产权)、间接结果(产业发展）和衍生结果,诸如产业发展带来的相关影响
            </li>
          </el-card>

          <el-card shadow="always">
            <strong style="color: #1078c3; font-size: 20px"
              >国家人力资本化</strong
            >
            <el-divider></el-divider>
            <li>
              可以测量人力资本Ⅱ产生的百接结果(知识产权)、间接结果(产业发展)和衍生结果(产业发展带来的相关影响）
            </li>
          </el-card>
        </div>
        <h1>益学方策人力资本化产品</h1>
        <div class="product-box">
          <el-card
            :body-style="{ padding: '0px', cursor: 'pointer' }"
            shadow="always"
            v-for="item in contentBoxData"
            :key="item.id"
          >
            <el-image
              :src="item.imgUrl"
              width="100px"
              height="300px"
              @click="Tohumancv(item.routerData)"
              fit="fill"
            ></el-image>
            <div class="contentName-box" @click="Tohumancv(item.routerData)">
              <span>{{ item.titleName }}</span>
              <div class="bottomImg"></div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Sideframe from "../../components/sideframe.vue";
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Cookies from "js-cookie";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";

export default {
  components: { Header, Flooter, Sideframe, Breadcrumb },
  data() {
    return {
      titleData: {
        titleName: "人力资本化产品",
        data: [
          {
            id: nanoid(),
            name: "人力资本化",
            href: "",
          },
          {
            id: nanoid(),
            name: "人力资本化产品",
            href: "",
          },
        ],
      },
      contentBoxData: [
        {
          id: nanoid(),
          titleName: "个体人力资本测评",
          imgUrl: require("../../assets/image/个体人力资本测评.jpg"),
          routerData: [
            {
              name: "个体人力资本测评介绍",
              routerIndex: "/HumanCapitalizationAssessment",
            },
            {
              title: "产品类型",
              conent: "个体人力资本化测评",
              img: require("../../assets/image/1648103640(1).jpg"),
            },
            {
              title: "产品说明",
              conent:
                "测评个体人力资本情况，通过与收入水平相匹配,从而建立起人力资本水平与收入水平的关系，促进个体的自我学习提升",
              img: require("../../assets/image/1648103702(1).png"),
            },
            {
              title: "测量要求",
              conent: "对象:个人",
              con1: "应用场景:个体提升、组织人才盘点、职业生涯规划",
              con2: "测量形式：形式:迫选量表,评定量表",
              con3: "时间：约40分钟",
              img: require("../../assets/image/1648103702(1).png"),
            },
            {
              title: "产品优势",
              conent:
                "有助于认清当前的个体价值.在今的成长中找清楚方向智助认识到自身的著距",
              con1: "帮助认识到自身的差距",
              con2: "精准度高，有助于帮助被测评人员进行反思",
              img: require("../../assets/image/1648103702(1).png"),
            },
          ],
        },
        {
          id: nanoid(),
          titleName: "个体人力资本化提升计划",
          imgUrl: require("../../assets/image/个体人力资本化提升计划.jpg"),
          routerData: [
            {
              name: "个体人力资本化提升计划介绍",
              routerIndex: "/ManpowerCapitalizationImprovementPlan",
            },
            {
              title: "产品类型",
              conent: "个体人力资本化提升计划",
              con1: "",
              img: "",
            },
            {
              title: "产品说明",
              conent:
                "个人依据“人生自我规划.人力资本投入,未来核心心智能力”三个维度制定成长计划",
              con1: "定期的对成长计划进行复盘和总结",
              img: "",
            },
            {
              title: "测量要求",
              conent: "对象∶个人",
              con1: "应用场景:个体提升",
              con2: "时间:约20分钟",
              img: "",
            },
            {
              title: "产品优势",
              conent:
                "帮助个人做好成长计划，从不同的维度去思考问题，并制定严密的成长计划",
              con1: "成长计划与个人的时间、金钱投入进行匹配",
              img: "",
            },
          ],
        },
        {
          id: nanoid(),
          titleName: "组织人力资本测评",
          imgUrl: require("../../assets/image/组织人力资本测评.jpg"),
          routerData: [
            {
              name: "组织人力资本测评介绍",
              routerIndex: "/OrganizeHumanCapitalEvaluation",
            },
            {
              title: "产品类型",
              conent: "组织人力资本测评产品介绍",
              img: require("../../assets/image/图片1.png"),
            },
            {
              title: "产品说明",
              conent:
                "通过个体的人力资本测评得出组织的人力资本水平。进而评估组织的人力资本水平，从而对人才的选用育留进行清晰的分析和应用",
              img: "",
            },
            {
              title: "测量要求",
              conent: "对象:组织全体成员",
              con1: "应用场景:个体提升、组织人才盘点、职业生涯规划",
              con2: "时间:约50分钟",
              img: "",
            },
            {
              title: "产品优势",
              conent:
                "帮助组纷进行人才盛点。人才潜力挖提，人才的接心心智能力识别",
              con1: "快速的认清织中的人才现状，从而做好人才的战略规划",
              img: "",
            },
          ],
        },
        {
          id: nanoid(),
          titleName: "组织人力资本化测评",
          imgUrl: require("../../assets/image/组织人力资本化测评.jpg"),
          routerData: [
            {
              name: "组织人力资本化测评介绍",
              routerIndex: "/EvaluationOfOrganizationalManpowerCapitalization",
            },
            {
              title: "产品类型",
              conent: "组织人力资本化测评",
              img: require("../../assets/image/图片2.png"),
            },
            {
              title: "产品说明",
              conent:
                "帮助组织从“人力资源基础、人力资本投入,人力资本化或果:知识产权.人力资本化的直接经营成果和人力资本化的间接经营成果五个维度帮助组织进行测量，并认清差距",
              img: "",
            },
            {
              title: "测量要求",
              conent: "对象:组织",
              con1: "应用场景:人力资源战略规划、人才盘点、人力成本核算.人力瓷本投入排名",
              con2: "时间:约120分钟",
              img: "",
            },
            {
              title: "产品优势",
              conent: "帮助组织认清自身组织在行业之中的排名",
              con1: "帮助组织从人力资本管理、战略风险管理、战略执行管理、战略支持管理、数字化变革管理等维度进行反思，并找到博引弱环节,进行专项提升",
              img: "",
            },
          ],
        },
        {
          id: nanoid(),
          titleName: "人力资本化指数及报告",
          imgUrl: require("../../assets/image/人力资本化指数及报告.png"),
          routerData: [
            { name: "人力资本化指数及报告介绍" },
            {
              title: "产品类型",
              conent: "人力资本化指数及报告",
              img: require("../../assets/image/图片5.png"),
            },
            {
              title: "产品说明",
              conent: "提供行业、国家、区域的人力资本化专业报告",
              img: "",
            },
            {
              title: "适用说明",
              conent: "对象:国家、区域的主导机构，或研究人员",
              con1: "应用场景∶人力资本化水平研究、投资决策",
              img: "",
            },
            {
              title: "产品优势",
              conent:
                "从数据的角度来分析所在行业里的企业的人力资本化水平，以及国家、区域所在地的人力资本化水平，对于投资，发展有很强的指导意见",
              img: "",
            },
          ],
        },
      ],
    };
  },
  // 创建完毕状态(里面是操作)
  created() {
    this.gettoken();
    this.getuserData();
  },
  // 挂载结束状态(里面是操作)
  mounted() {},
  // 里面的函数只有调用才会执行
  methods: {
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.userData = null;
        this.token = null;
      }
    },
    async getuserData() {
      if (this.token == null) {
      } else {
        const res = await this.axios.get("/basicUser/tokenByUser", {
          params: {
            token: this.token,
          },
        });
        if (res.code == 220) {
          this.userData = res.object;
        } else {
          this.userData = null;
        }
      }
    },
    Tohumancv(res) {
      this.$router.push({
        path: "/humancv",
        query: {
          humancvmsg: JSON.stringify(res),
        },
      });
    },
  },
};
</script>

<style scoped>
.product-box {
  display: flex;
  flex-wrap: wrap;
  justify-items: left;
  width: 100%;
  padding: 50px 0;
}
.contentName-box {
  cursor: pointer;
  margin: auto;
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-weight: normal;
  font-family: "方正大标宋简体";
}
.bottomImg {
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 2s infinite;
  width: 188px;
  height: 30px;
  margin: auto auto 0;
  border-radius: 25px 25px 0 0;
  flex-shrink: 0;
}
@keyframes load89234 {
  100% {
    background-position: -100% 0;
  }
}
::v-deep .el-card__body {
  padding: 20px;
  height: 280px;
  margin: auto;
}
::v-deep .el-image__inner {
  vertical-align: top;
  width: 140px;
  height: 140px;
  margin-top: 20px;
  border-radius: 50%;
}
li {
  text-align: left;
  font-size: 16px;
  color: #6e6e6e;
  font-weight: lighter;
}
strong {
  font-weight: 700;
  font-family: "微软雅黑";
  font-size: 24px;
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
h1 {
  color: #6e6e6e;
  font-weight: lighter;
  font-family: "方正大标宋简体";
}
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  ::v-deep .el-card {
    height: 880px;
    width: 820px;
    margin: 20px auto;
  }
  ::v-deep .el-card__body {
    height: 100%;
    width: 95%;
  }
  li {
    font-size: 68px !important;
  }
  ::v-deep .el-image__inner {
    vertical-align: top;
    width: 630px;
    height: 630px;
    margin-top: 20px;
    border-radius: 50%;
  }
  .contentName-box {
    font-size: 68px;
    height: 24%;
  }
  .contentName-box span {
    font-size: 68px;
  }
  .bottomImg {
    width: 480px;
    height: 80px;
  }
  h1 {
    font-size: 108px;
  }
  strong {
    font-size: 88px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  ::v-deep .el-card {
    height: 480px;
    width: 420px;
    margin: 20px auto;
  }
  ::v-deep .el-card__body {
    height: 100%;
    width: 95%;
  }
  li {
    font-size: 28px !important;
  }
  ::v-deep .el-image__inner {
    vertical-align: top;
    width: 240px;
    height: 240px;
    margin-top: 20px;
    border-radius: 50%;
  }
  .contentName-box {
    font-size: 48px;
    height: 40%;
  }
  .contentName-box span {
    font-size: 38px;
  }
  .bottomImg {
    width: 280px;
    height: 60px;
  }
  h1 {
    font-size: 68px;
  }
  strong {
    font-size: 38px !important;
  }
}

@media (min-width: 1025px) {
  ::v-deep .el-card {
    height: 380px;
    width: 340px;
    margin: 20px auto;
  }
  ::v-deep .el-card__body {
    height: 100%;
    width: 90%;
  }
  li {
    font-size: 18px !important;
    margin: 10px;
  }
  ::v-deep .el-image__inner {
    vertical-align: top;
    width: 180px;
    height: 180px;
    margin-top: 20px;
    border-radius: 50%;
  }
  .contentName-box {
    font-size: 28px;
    height: 45%;
  }
  .contentName-box span {
    font-size: 24px;
  }
  .bottomImg {
    width: 220px;
    height: 40px;
  }
  h1 {
    font-size: 28px;
  }
  strong {
    font-size: 28px !important;
  }
}
</style>