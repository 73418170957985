<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <el-row class="dow">
        <div class="content" v-html="content"></div>
        <asidetitle :asideData="asideData"></asidetitle>
      </el-row>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../../flooter.vue";
import Header from "../../Header.vue";
import Pagination from "@/components/Pagination";
import asidetitle from "@/components/asidetitle.vue";
import Sideframe from "@/components/sideframe.vue";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import breadcrumb from "../../../components/breadcrumb.vue";
import { nanoid } from "nanoid";
marked.setOptions({
  renderer: new marked.Renderer(),
  highlight: function (code) {
    return hljs.highlightAuto(code).value;
  },
  pedantic: false,
  gfm: true,
  tables: true,
  breaks: true,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: true,
});
export default {
  components: {
    Header,
    Flooter,
    Pagination,
    Sideframe,
    breadcrumb,
    asidetitle,
  },
  data() {
    return {
      essay: [],
      asideData: {},
      titleData: {
        titleName: "",
        data: [
          {
            id: nanoid(),
            name: "",
            href: "",
          },
          {
            id: nanoid(),
            name: "",
            href: "",
          },
          {
            id: nanoid(),
            name: "",
            href: "",
          },
        ],
      },
      formInline: {
        page: 1,
        limit: 5,
        articleEntity: {
          articleTitle: "",
          articleContent: "",
          articleTypeName: "",
          articleAuthor: "",
          startTime: "",
          endTime: "",
          del: "",
          state: "",
        },
      },
      content: "",
      name: "",
    };
  },
  created() {
    this.getParams();
    this.getData();
    this.asideData = JSON.parse(window.localStorage.getItem("asideData"));
  },
  watch: {},
  methods: {
    getParams() {
      const ti = this.$route.query.title;
      const name = this.$route.query.name;
      const href = this.$route.query.href;
      this.titleData.titleName = name;
      this.titleData.data[0].name = this.$route.query.titleName;
      this.titleData.data[1].name = ti;
      this.titleData.data[1].href = href;
      this.titleData.data[2].name = name;
      this.formInline.articleEntity.articleTitle = name;
      this.name = name;
    },
    async getData() {
      const res = await this.axios.post("/article/search", this.formInline);
      this.content = marked(res.object.list[0].articleContent, {
        breaks: true,
      }).replace(/<pre>/g, "<pre class='hljs'>");
    },
  },
};
</script>

<style scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgb(29, 25, 16);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
.dow {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 30px auto ;
  width: 80%;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
.content {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}
::v-deep .content h2 {
  text-align: left;
  margin: 0;
  font-weight: lighter;
  width: 100%;
}
::v-deep .content li {
  text-align: left;
  margin: 20px;
  font-weight: lighter;
  width: 100%;
}
::v-deep .content ol {
  width: 100%;
}
::v-deep .content h3 {
  margin: 0;
  text-align: left;
  background-color: #eeeeee;
  font-weight: normal;
  width: 100%;
  height: fit-content;
}
::v-deep .content h4 {
  font-weight: normal;
  color: inherit;
  background-color: #eeeeee;
  background: #eee;
  text-align: left;
  width: 100%;
}
::v-deep .content h5 {
  font-weight: normal;
  color: inherit;
  height: fit-content;
  background-color: #eeeeee;
  background: #eee;
  text-align: left;
  padding: 0 20px;
}
::v-deep .content img {
  margin: 10px;
}
::v-deep .content p {
  display: inline-block;
  text-indent: 2em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: left;
  line-height: 1.875;
}
::v-deep .content span {
  line-height: 1.875;
}
.content >>> strong {
  padding: 5px;
  width: 100%;
}
::v-deep .content table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  vertical-align: middle;
  margin-top: 30px;
}
::v-deep .content th {
  width: 15%;
  background-color: #dff0d8;
}
::v-deep .content td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}
::v-deep .content td p {
  text-indent: 0em;
}

::v-deep .content table td,
table th {
  position: static;
  float: none;
  display: table-cell;
  padding: 8px;
  line-height: 1.42857143;
  border: 1px solid #ddd;
  border-bottom-width: 2px;
}
@media (max-width: 768px) {
  .content {
    width: 90%;
    min-height: 600px;
  }
  .content >>> h3 {
    font-size: 4rem;
    line-height: 1.875;
  }
  .content >>> h4 {
    font-size: 3rem;
    line-height: 1.875;
  }
  .content >>> p {
    text-indent: 2em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1.875;
    font-size: 3rem;
    width: 100%;
  }
  .content >>> li {
    font-size: 3rem;
    width: 100%;
  }
  .content >>> h5 {
    width: 100%;
    font-size: 3rem;
    line-height: 1.875;
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .content {
    width: 90%;
    min-height: 600px;
  }
  .content >>> h3 {
    font-size: 4rem;
    line-height: 1.875;
    width: 100%;
  }
  .content >>> p {
    text-indent: 2em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1.875;
    font-size: 3rem;
    width: 100%;
  }
  .content >>> h4 {
    font-size: 3rem;
    line-height: 1.875;
    width: 100%;
  }
  .content >>> li {
    font-size: 3rem;
  }
  .content >>> img {
    padding: 0px;
    width: 80%;
  }
  .content >>> h5 {
    font-size: 3rem;
    line-height: 1.875;
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .body-box {
    width: 90%;
  }
  hr {
    margin-bottom: 8px;
  }
  .content {
    width: 70%;
    min-height: 600px;
  }
  .content >>> h3 {
    font-size: 28px;
    line-height: 1.875;
  }
  .content >>> img {
    margin: 10px;
  }
  .content >>> h4 {
    font-size: 24px;
    line-height: 1.875;
  }

  .content >>> h5 {
    font-size: 20px;
    line-height: 1.875;
    width: 100%;
  }

  .content >>> p {
    text-indent: 2em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1.875;
    width: 100%;
  }
}
</style>