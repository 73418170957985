/*
 * @Description: 
 * @Version: 2.0
 * @Author: jiyang.fu
 * @Date: 2022-01-17 11:16:27
 * @LastEditors: jiyang.fu
 * @LastEditTime: 2023-01-06 10:58:54
 */
import axios from "axios";
import { MessageBox } from "element-ui";
import { text } from "body-parser";

const instance = axios.create({
    // baseURL: 'http://192.168.110.103:8888/',//内网接口
    baseURL: 'apis/',//代理接口
    // baseURL:'https://sunledge.com/apis'//外网接口
    // timeout: 30000
})

//axios响应拦截器
instance.interceptors.response.use(res => {
    return res.data;
}, err => {
    return err;
})

export default instance;