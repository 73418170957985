<template>
  <div class="body">
    <myheader></myheader>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <div>
        <transition-group
          tag="div"
          name="animate__animated animate__bounce animate__delay-1s"
          enter-active-class="animate__fadeIn"
          leave-active-class="animate__fadeDown"
          class="teacherMsg-body"
          appear
        >
          <template v-for="(msg, index) in teacherData">
            <div class="teacherMsg-box" :key="msg.id" v-if="index < showCount">
                <el-image
                  :src="msg.teacherPhoto"
                  fit="fill"
                  style="cursor: pointer"
                  id="img"
                  @click="teachermessage(msg)"
                >
                </el-image
                ><br />
                <span
                  class="title"
                  id="name"
                  style="cursor: pointer"
                  @click="teachermessage(msg)"
                  >{{ msg.teacherName }}</span
                ><br />
            </div>
          </template>
        </transition-group>
      </div>
    </div>
    <myflooter> </myflooter>
  </div>
</template>
<script>
import Header from "../Header.vue";
import Flooter from "../flooter.vue";
import Sideframe from "../../components/sideframe.vue";
import { nanoid } from "nanoid";
import breadcrumb from "../../components/breadcrumb.vue";
import Template from "../../../../yixuefangce-BackgroundManagement/src/components/template.vue";
export default {
  data() {
    return {
      num: 24,
      loading: true,
      routerView: false,
      teacherData: [],
      showCount: 0,
      titleData: {
        titleName: "名师推荐",
        data: [
          {
            id: nanoid(),
            name: "企业培训",
            href: "",
          },
          {
            id: nanoid(),
            name: "名师推荐",
            href: "",
          },
        ],
      },
    };
  },
  components: {
    myheader: Header,
    myflooter: Flooter,
    Sideframe,
    breadcrumb,
    Template,
  },
  mounted() {

  },
  created() {
    if (localStorage.getItem("teacherData") !== null) {
      let storedData = JSON.parse(localStorage.getItem("teacherData"));
      let currentTime = new Date().getTime();
      if (currentTime > storedData.expiration) {
        // 数据已过期，清除
        localStorage.removeItem("teacherData");
        storedData = null;
        this.getTeachers();
      } else {
        this.teacherData = storedData.value;
        this.showCount = 0;
        this.timer = setInterval(() => {
          if (this.showCount < this.teacherData.length) {
            this.showCount++;
          } else {
            clearInterval(this.timer);
          }
        }, 400);
      }
    } else {
      this.getTeachers();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async getTeachers() {
      // console.log(err);
      const res = await this.axios.post("/teacher/selectAllState");
      if (res.code == 200) {
        this.teacherData = res.object;
        let expiration = new Date().getTime() + 1 * 60 * 60 * 1000;
        localStorage.setItem(
          "teacherData",
          JSON.stringify({ value: this.teacherData, expiration: expiration })
        );
        this.showCount = 0;
        this.timer = setInterval(() => {
          if (this.showCount < this.teacherData.length) {
            this.showCount++;
          } else {
            clearInterval(this.timer);
          }
        }, 400);
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    teachermessage(teachermessage) {
      this.routerView = true;
      this.$router.push({
        name: "teacherMessage",
        params: {
          teachermessage: JSON.stringify(teachermessage),
        },
      });
    },
  },
};
</script>
<style  scoped>
.list-enter-active,
.list-leave-active {
  transition: opacity 0.5s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.animated {
  margin: 15px 0 20px 0;
}

#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}

::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

::v-deep .el-image__inner {
  vertical-align: top;
  height: 330px;
  width: 260px;
}



#name:hover {
  color: #dab96b;
  transform: scale(1.4);
}

#name {
  font-size: 20px;
  color: #626262;
  font-family: "Microsoft YaHei";
  font-weight: lighter;
  transition: all 1s ease 0s;
}

::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(218, 185, 107);
  color: #fff;
}

.el-breadcrumb {
  font-size: 16px;
  margin-right: 40%;
  float: right;
  height: 90px;
  line-height: 90px;
}

h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}
.teacherMsg-body {
  width: 98%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: auto;
}

hr {
  margin: 0;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  ::v-deep .el-image__inner {
    width: 800px;
    height: 900px;
  }
  #name {
    font-size: 68px;
  }
  .teacherMsg-box {
    width: 48%;
    margin: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  ::v-deep .el-image__inner {
    width: 350px;
    height: 450px;
  }
  #name {
    font-size: 48px;
  }
  .teacherMsg-box {
    width: 23%;
    margin: 10px;
  }
}

@media (min-width: 1025px) {
  .teacherMsg-box {
    width: 15%;
    margin: 15px;
  }
}
</style>