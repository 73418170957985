<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr  />
      <div class="company-body">
        <div class="content-body">
          <img :src="corporate.img" class="corporate" />
          <div class="content-box">
            <h1 class="corporatename">{{ corporate.name }}</h1>
            <p class="content">&nbsp;&nbsp;&nbsp;{{ corporate.content }}</p>
          </div>
        </div>
        <div class="content-body">
          <el-tabs
            style="float: left; margin-left: 10%; width: 80%"
            v-model="activeName"
            type="card"
            @tab-click="handleClick"
          >
            <el-tab-pane label="我们的领域" name="first">
              <img :src="Ourfield.img" class="Ourfield" />
              <div class="content-box">
                <h1 class="Ourfieldname">{{ Ourfield.name }}</h1>
                <p class="tcontent">&nbsp;&nbsp;&nbsp;{{ Ourfield.content }}</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="我们的理念" name="second">
              <img :src="Philosophy.img" class="Ourfield" />
              <div class="content-box">
                <h1 class="Ourfieldname">{{ Philosophy.name }}</h1>
                <p class="tcontent">
                  &nbsp;&nbsp;&nbsp;{{ Philosophy.content }}
                </p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="我们的优势" name="third">
              <div class="content-1">
                <h1 class="title-1">系统项目运作</h1>
                <li>IBM战略高管团</li>
                <li>世界五百强中高管</li>
                <li>华为高管团队</li>
                <li>清华、北京大学博士团队</li>
              </div>
              <div class="content-2">
                <h1 class="title-2">携手共创</h1>
                <li>公益人才持续培养</li>
                <li>与乙方共创项目</li>
                <li>与合作伙伴共创</li>
                <li>与出版社共创</li>
                <li>与行业共创</li>
              </div>
              <div class="content-3">
                <h1 class="title-3">强大的咨询团队</h1>
                <li>项目设计</li>
                <li>项目包装</li>
                <li>品牌打造</li>
                <li>持续追踪</li>
              </div>
            </el-tab-pane>
            <el-tab-pane label="我们的客户" name="fourth">
              <img :src="customers.img" class="Ourfield" />
              <div class="content-box">
                <h1 class="Ourfieldname">{{ customers.name }}</h1>
                <p class="tcontent">{{ customers.content }}</p>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Sideframe from "../../components/sideframe.vue";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";
export default {
  components: { Header, Flooter, Sideframe, Breadcrumb },
  data() {
    return {
      titleData: {
        titleName: "公司介绍",
        data: [
          {
            id: nanoid(),
            name: "关于我们",
            href: "",
          },
          {
            id: nanoid(),
            name: "公司介绍",
            href: "",
          },
        ],
      },
      activeName: "first",
      corporate: {
        _id: 5,
        name: "益学方策",
        img: "https://sunledge.com/apis/pic/202401/20240109155058036.jpg",
        content:
          "北京益学方策管理咨询有限公司（简称北京益学方策），Beijing Sunshine Knowledge Consulting Co., Ltd.（简称：Sunshine Knowledge）。自开业以来，公司以企业中高层管理人员的培训为主，利用自身在领导力、学习力、销售力和创新力等领域的专长，为众多一线企业提供了优质专业的管理咨询、各类管理培训和内训师队伍建设等服务，拥有多名专职与兼职教师，充分利用线上微信课堂（每周一次）和线下沙龙（每月一次），开拓了广大的客户群，积累了良好的课程资源，拥有良好的市场声誉。公司设立了益学未来商学院，着重探讨未来企业、未来金融与未来个人把握全球风险与机遇、进行全球财富管理的培训项目，定期发布研究报告和中国人才培养报告，目前正在大力开发专业的线上培训课程，不断巩固和扩展在企业管理和财富管理领域的培训内容。",
      },
      Ourfield: {
        _id: 1,
        name: "我们的领域",
        img: "https://sunledge.com/apis/pic/202401/20240109151223581.jpg",
        content:
          "益学方策创立于2011年，是国内人才发展专业服务机构。公司恪守“为学日益、知行合一”的核心理念，践行“发展关键人才，提升组织绩效”的使命，以业界最佳实践经验、最优服务团队和最高品质资源，在学习力构建、领导力发展、绩优销售、在线学习平台构建、APP开发、微课开发等领域为客户提供培训、咨询辅导和跨界学习等一体化人才培养解决方案，致力于打造国内最具实战价值的组织学习力共创平台。",
      },
      Philosophy: {
        id: 2,
        name: "我们的理念",
        img: "https://sunledge.com/apis/pic/202401/20240109151247675.jpg",
        content:
          "公司开创性的提出了高竞争行业人才培养的一体化解决方案，着重解决 “规划人才发展”、“创新销售模式”、“优化组织管理”、“构建学习平台”四大问题，通过人才盘点，针对核心人群、关键任务和知识技能，以训练、辅导为手段，通过行业最佳实践传授、内部组织智慧萃取，形成契合业务发展需要的营销方法论和最佳实务流程，实现“赋能于企”改善组织绩效的目标。",
      },
      OurAdvantages: {
        _id: 4,
        img: require("../../assets/image/zixuntuandui.png"),
        img2: require("../../assets/image/zixuntuandui (2).png"),
        img3: require("../../assets/image/xieshougongchuang.png"),
        img4: require("../../assets/image/xieshougongchuang (2).png"),
        img5: require("../../assets/image/xitonghuaxiangmuyunzuo.png"),
        img6: require("../../assets/image/xitonghuaxiangmuyunzuo (2).png"),
      },
      customers: {
        id: 3,
        name: "我们的客户",
        img: "https://sunledge.com/apis/pic/202401/20240109152648813.jpg",
        content:
          "我们立足北京，服务全国。凭借专业顾问式培训和贴心嵌入式服务，不仅赢得了包括中国移动、光大银行、中国人寿、新华保险、泰康人寿、核工业建设集团、中国石化、万科、顺丰速运、京东商城和天津金耀集团等诸多“灯塔客户”的信赖与认可，还取得了包括清华同方、世纪互联、海科化工、瑞士康达、雅昌文化、去哪儿网和亚宝药业等众多高成长性企业的深入持续合作。",
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
.content-1 {
  height: 400px;
  width: 320px;
  float: left;
  text-align: left;
  padding: 0 70px 0 150px;
}
.title-1:after {
  content: "";
  position: absolute;
  left: 150px;
  top: 85px;
  bottom: 0px;
  right: 240px;
  height: 3px;
  width: 310px;
  background-color: #00b0f0;
}
.title-1:before {
  content: "";
  position: absolute;
  left: 150px;
  top: 50px;
  bottom: 0px;
  right: 240px;
  height: 36px;
  width: 60px;
  background-color: #00b0f0;
}
.title-1::first-letter {
  font-size: 48px;
  color: #00b0f0;
  margin-left: 60px;
}
li {
  font-size: 24px;
  margin-top: 20px;
}
.content-2 {
  height: 400px;
  width: 320px;
  float: left;
  text-align: left;
  padding: 0 70px 0 70px;
}
.title-2:after {
  content: "";
  position: absolute;
  left: 600px;
  top: 85px;
  bottom: 0px;
  right: 230px;
  height: 3px;
  width: 310px;
  background-color: #00b050;
}
.title-2:before {
  content: "";
  position: absolute;
  left: 600px;
  top: 50px;
  bottom: 0px;
  right: 230px;
  height: 36px;
  width: 60px;
  background-color: #00b050;
}
.title-2::first-letter {
  font-size: 48px;
  color: #00b050;
  margin-left: 60px;
}
.content-3 {
  height: 400px;
  width: 320px;
  float: left;
  text-align: left;
  padding: 0 70px 0 70px;
}
.title-3:after {
  content: "";
  position: absolute;
  left: 1060px;
  top: 84px;
  bottom: 0;
  right: 230px;
  height: 3px;
  width: 330px;
  background-color: #92d050;
}
.title-3:before {
  content: "";
  position: absolute;
  left: 1060px;
  top: 50px;
  bottom: 0px;
  right: 230px;
  height: 36px;
  width: 60px;
  background-color: #92d050;
}
.title-3::first-letter {
  font-size: 48px;
  color: #92d050;
  margin-left: 60px;
}
.corporate {
  display: block;
  width: 50%;
  height: 50%;
  margin: 0;
  float: left;
}
.Ourfield {
  display: block;
  width: 40%;
  height: 50%;
  margin: 0;
  float: left;
  padding-left: 45px;
}
.Ourfieldname {
  float: left;
  width: 100%;
  padding-left: 20px;
  font-weight: normal;
  font-size: 28px;
  text-align: left;
  margin: 0 4% 2% 4%;
}
.company-body {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.content-body {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: auto;
  padding: 20px;
}
.content-box {
  display: flex;
  flex-wrap: wrap;
  width: 40%;
  height: fit-content;
}
.content {
  float: left;
  line-height: 1.875;
  width: 100%;
  padding: 0 20px;
  text-indent: 2rem;
  text-align: justify;
}
.tcontent {
  float: left;
  width: 100%;
  line-height: 1.875;
  padding: 20px;
  text-indent: 2rem;
  text-align: justify;
  margin: 0;
}
.corporatename {
  font-family: "方正大标宋简体";
  float: left;
  height: fit-content;
  width: 100%;
  font-weight: normal;
  font-size: 42px;
  padding: 0 20px;
  text-align: left;
}

::v-deep .el-tabs__item.is-active {
  color: rgb(218, 185, 107);
}
::v-deep .el-tabs__item:hover {
  color: rgb(218, 185, 107);
  cursor: pointer;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  transform: translateX(80%) !important;
}
@media (max-width: 768px) {
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    transform: translateX(0px) !important;
  }
  .corporate {
    width: 100%;
  }
  .content-box {
    width: 90%;
    margin: auto;
  }
  .Ourfieldname {
    font-size: 58px;
  }
  .content {
    font-size: 52px;
  }
  .tcontent {
    font-size: 52px;
  }
  .corporatename {
    font-size: 58px;
  }
  .Ourfield {
    width: 100%;
  }
  .content-1 {
    height: 520px;
    width: 360px;
    float: left;
    text-align: left;
    padding: 20px 40px;
  }
  .title-1:after {
    content: "";
    position: absolute;
    left: 20px;
    top: 100px;
    bottom: 0px;
    right: 240px;
    height: 3px;
    width: 310px;
    background-color: #00b0f0;
  }
  .title-1:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 70px;
    bottom: 0px;
    right: 240px;
    height: 36px;
    width: 60px;
    background-color: #00b0f0;
  }
  .title-1::first-letter {
    font-size: 48px;
    color: #00b0f0;
    margin-left: 60px;
  }
  li {
    font-size: 38px;
    margin-top: 20px;
  }
  .content-2 {
    height: 520px;
    width: 360px;
    float: left;
    text-align: left;
    padding: 20px 40px;
  }
  .title-2:after {
    content: "";
    position: absolute;
    left: 450px;
    top: 100px;
    bottom: 0px;
    right: 230px;
    height: 3px;
    width: 310px;
    background-color: #00b050;
  }
  .title-2:before {
    content: "";
    position: absolute;
    left: 450px;
    top: 70px;
    bottom: 0px;
    right: 230px;
    height: 36px;
    width: 60px;
    background-color: #00b050;
  }
  .title-2::first-letter {
    font-size: 48px;
    color: #00b050;
    margin-left: 60px;
  }
  .content-3 {
    height: 520px;
    width: 320px;
    float: left;
    text-align: left;
    padding: 20px 40px;
  }
  .title-3:after {
    content: "";
    position: absolute;
    left: 900px;
    top: 100px;
    bottom: 0;
    right: 230px;
    height: 3px;
    width: 330px;
    background-color: #92d050;
  }
  .title-3:before {
    content: "";
    position: absolute;
    left: 900px;
    top: 70px;
    bottom: 0px;
    right: 230px;
    height: 36px;
    width: 60px;
    background-color: #92d050;
  }
  .title-3::first-letter {
    font-size: 48px;
    color: #92d050;
    margin-left: 60px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    transform: translateX(25%) !important;
  }
  .Ourfieldname {
    font-size: 38px;
  }
  .content {
    font-size: 32px;
  }
  .tcontent {
    font-size: 32px;
  }
  .corporatename {
    font-size: 38px;
  }
  .content-1 {
    height: 400px;
    width: 320px;
    float: left;
    text-align: left;
    padding: 0 70px 0 150px;
  }
  .title-1:after {
    content: "";
    position: absolute;
    left: 150px;
    top: 85px;
    bottom: 0px;
    right: 240px;
    height: 3px;
    width: 310px;
    background-color: #00b0f0;
  }
  .title-1:before {
    content: "";
    position: absolute;
    left: 150px;
    top: 50px;
    bottom: 0px;
    right: 240px;
    height: 36px;
    width: 60px;
    background-color: #00b0f0;
  }
  .title-1::first-letter {
    font-size: 48px;
    color: #00b0f0;
    margin-left: 60px;
  }
  li {
    font-size: 24px;
    margin-top: 20px;
  }
  .content-2 {
    height: 400px;
    width: 320px;
    float: left;
    text-align: left;
    padding: 0 70px 0 70px;
  }
  .title-2:after {
    content: "";
    position: absolute;
    left: 600px;
    top: 85px;
    bottom: 0px;
    right: 230px;
    height: 3px;
    width: 310px;
    background-color: #00b050;
  }
  .title-2:before {
    content: "";
    position: absolute;
    left: 600px;
    top: 50px;
    bottom: 0px;
    right: 230px;
    height: 36px;
    width: 60px;
    background-color: #00b050;
  }
  .title-2::first-letter {
    font-size: 48px;
    color: #00b050;
    margin-left: 60px;
  }
  .content-3 {
    height: 400px;
    width: 320px;
    float: left;
    text-align: left;
    padding: 0 70px 0 70px;
  }
  .title-3:after {
    content: "";
    position: absolute;
    left: 1060px;
    top: 84px;
    bottom: 0;
    right: 230px;
    height: 3px;
    width: 330px;
    background-color: #92d050;
  }
  .title-3:before {
    content: "";
    position: absolute;
    left: 1060px;
    top: 50px;
    bottom: 0px;
    right: 230px;
    height: 36px;
    width: 60px;
    background-color: #92d050;
  }
  .title-3::first-letter {
    font-size: 48px;
    color: #92d050;
    margin-left: 60px;
  }
}

hr {
  margin: 0;
  margin-bottom: 8px;
}
</style>