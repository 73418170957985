<!--
 * @Description: 
 * @Version: 2.0
 * @Author: jiyang.fu
 * @Date: 2022-11-23 13:49:09
 * @LastEditors: jiyang.fu
 * @LastEditTime: 2022-11-23 18:00:17
-->
<template>
  <div class="ue">
    <vue-ueditor-wrap
      v-model="data"
      :config="myConfig"
      @ready="ready"
    ></vue-ueditor-wrap>
  </div>
</template>
   
  <script>
import VueUeditorWrap from "vue-ueditor-wrap";

export default {
  components: {
    VueUeditorWrap,
  },
  props: {
    message: String,
  },
  data() {
    return {
      data: "",
      myConfig: {
        // 设置编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: "100%",
        // 初始容器宽度
        initialFrameWidth: "99.9%",
        // 可以放后台存放路径
        serverUrl: "",
        // UEditor 是文件的存放路径，
        UEDITOR_HOME_URL: "/UEditor/",
      },
    };
  },
  watch: {
    data(val) {
      this.$emit("childFn", val);
    },
  },
  methods: {
    clearMsg() {
      this.data = "";
    },
    ready(editorInstance) {
      //回车监听
      const that = this
      UE.dom.domUtils.on(editorInstance.body, "keydown", function (oEvent) {
        var oEvent = oEvent || window.oEvent;
        //获取键盘的keyCode值
        var nKeyCode = oEvent.keyCode || oEvent.which || oEvent.charCode;
         if (nKeyCode == 13 && oEvent.ctrlKey) {
          console.log(oEvent)
          oEvent.cancelBubble = false;
        }else if(nKeyCode == 13) {
          that.$emit("callFather")
          oEvent.cancelBubble = true;
          oEvent.preventDefault();
          oEvent.stopPropagation();
        }
      });
    },
  },
};
</script>

<style >
.edui-default .edui-editor-bottomContainer table {
  display: none;
}
</style>