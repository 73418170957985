<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <div class="background">
        <breadcrumb :titleData="titleData"></breadcrumb>
      </div>
      <hr />
      <div class="dow">
        <div class="content-body">
          <template v-for="items in pageData">
            <div v-if="!loaderShow" :key="items.id">
              <div
                class="content-box"
                v-if="items.imgPath !== null && items.imgPath !== undefined"
                :key="items.id"
              >
                <img :key="items.id + '1'" :src="items.imgPath" />
                <h4 :key="items.id + '2'">
                  <a @click="router(items.articleTitle)"
                    >&nbsp;{{
                      items.articleTitle.length > 15
                        ? items.articleTitle.slice(0, 15) + "..."
                        : items.articleTitle
                    }}</a
                  >
                </h4>
              </div>
              <div class="content-box" v-else :key="items.id">
                <h4
                  :key="items.id + '2'"
                  style="width: 100%"
                  v-if="items.articleTitle !== ''"
                >
                  <a @click="router(items.articleTitle)"
                    >&nbsp;{{ items.articleTitle }}</a
                  >
                </h4>
              </div>
            </div>
          </template>
          <div class="loader-box" v-if="loaderShow">
            <loader></loader>
          </div>
          <el-empty
            v-if="pageData.length == 0 && !loaderShow"
            :image-size="200"
          ></el-empty>
          <Pagination
            v-if="pageData.length > 0"
            v-bind:child-msg="pageparm"
            @call="callFather"
          ></Pagination>
        </div>
        <asidetitle :asideData="asideData"></asidetitle>
      </div>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Pagination from "../../components/Pagination";
import Sideframe from "../../components/sideframe.vue";
import { nanoid } from "nanoid";
import breadcrumb from "../../components/breadcrumb.vue";
import asidetitle from "../../components/asidetitle.vue";
import Loader from "@/components/loader.vue";
export default {
  components: {
    Header,
    Flooter,
    Pagination,
    Sideframe,
    breadcrumb,
    asidetitle,
    Loader,
  },
  data() {
    return {
      loaderShow: false,
      asideData: {
        asideName: "",
        data: [],
      },
      pageparm: {
        currentPage: 1,
        pageSize: 5,
        total: 10,
      },
      formInline: {
        page: 1,
        limit: 5,
        articleEntity: {
          articleTitle: "",
          articleContent: "",
          articleTypeName: "销售管理",
          articleAuthor: "",
          startTime: "",
          endTime: "",
          del: "1",
          state: "1",
        },
      },
      pageData: [],
      titleData: {
        titleName: "销售管理",
        data: [
          {
            id: nanoid(),
            name: "企业培训",
            href: "",
          },
          {
            id: nanoid(),
            name: "销售管理",
            href: "",
          },
        ],
      },
    };
  },
  // computed: {
  //   pageData() {
  //     return this.listData.slice(
  //       (this.formInline.page - 1) * this.formInline.limit,
  //       (this.formInline.page - 1) * this.formInline.limit +
  //         this.formInline.limit
  //     );
  //   },
  // },
  created() {
    this.getData();
    this.getAsideData();
  },
  methods: {
    //   ...mapActions(['getUQ']),
    //   handleCurrentChange(val){
    // 	  console.log(val)
    //   },
    router(e) {
      const title = this.titleData.titleName;
      window.localStorage.setItem("asideData", JSON.stringify(this.asideData));
      this.$router.push({
        path: "/Article",
        query: {
          title: title,
          titleName: this.titleData.data[0].name,
          name: e,
          href: this.$route.path,
        },
      });
    },
    getAsideData() {
      this.axios
        .get("/menu/selectAllMenu", {
          params: {
            startpage: 1,
            pagesize: 600,
            state: 1,
          },
        })
        .then((res) => {
          res.object.records.forEach((item) => {
            if (item.indexName === "企业培训") {
              this.asideData.asideName = item.indexName;
              item.menuEntity.forEach((it) => {
                let childData = {
                  _id: nanoid(),
                  title: "",
                  to: "",
                };
                childData.title = it.indexName;
                childData.to = it.routerIndex;
                this.asideData.data.push(childData);
              });
            }
          });
        });
    },
    getData() {
      this.loaderShow = !this.loaderShow;
      this.axios
        .post("/article/search", this.formInline)
        .then((res) => {
          if (res.code === 200) {
            this.loaderShow = !this.loaderShow;
            this.pageData = res.object.list;
            this.pageparm.currentPage = this.formInline.page;
            this.pageparm.pageSize = this.formInline.limit;
            this.pageparm.total = res.object.total - 0;
          } else {
            this.loaderShow = !this.loaderShow;
          }
        })
        .catch((err) => {
          this.loaderShow = !this.loaderShow;
        });
    },
    callFather(parm) {
      this.formInline.page = parm.currentPage;
      this.formInline.limit = parm.pageSize;
      this.getData();
    },
  },
};
</script>

<style scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgb(29, 25, 16);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
hr {
  margin: 0;
  margin-bottom: 8px;
}

.dow {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin: auto;
}
.content-body {
  width: fit-content;
  height: fit-content;
}
.content-box {
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-box img {
  display: inline-block;
}
h4 {
  height: fit-content;
  margin: auto;
  text-align: left;
  font-weight: 100;
  background-color: #eeeeee;
  cursor: pointer;
}

a:hover {
  color: rgb(218, 185, 107);
}
@media (max-width: 768px) {
  .dow {
    width: 100%;
    height: fit-content;
  }
  .content-box {
    height: 456px;
    min-width: 1846px;
    margin: 30px;
  }
  .content-box img {
    height: 456px;
    width: 456px;
  }
  h4 {
    width: 1256px;
    font-size: 48px;
    margin: auto 20px;
    line-height: 1.875;
  }
  ::v-deep .el-empty {
    min-width: 1846px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .dow {
    width: 90%;
    height: fit-content;
  }
  .content-box {
    height: 256px;
    min-width: 1446px;
    margin: 30px;
  }
  .content-box img {
    height: 256px;
    width: 256px;
  }
  h4 {
    margin: auto 20px;
    width: 1156px;
    font-size: 38px;
    line-height: 1.875;
  }
  ::v-deep .el-empty {
    min-width: 1446px;
  }
}

@media (min-width: 1025px) {
  .dow {
    width: 80%;
    height: fit-content;
  }
  .loader-box {
    min-width: 846px;
    min-height: 664px;
  }
  .content-box {
    height: 140px;
    min-width: 846px;
    margin: 30px;
  }
  .content-box img {
    height: 100%;
    width: 156px;
  }
  h4 {
    width: 656px;
    font-size: 20px;
    margin: auto 20px;
    line-height: 1.875;
  }
  ::v-deep .el-empty {
    min-width: 846px;
  }
}
</style>