<!--
 * @Description: 
 * @Version: 2.0
 * @Author: jiyang.fu
 * @Date: 2022-03-28 13:39:10
 * @LastEditors: jiyang.fu
 * @LastEditTime: 2023-01-31 10:05:09
-->
<template>
  <div class="body">
    <Header :shoppingCarNum="shoppingCarNum"></Header>
    <sideframe></sideframe>
    <el-dialog
      title="提示"
      :visible.sync="showbox"
      width="30%"
      :before-close="handleClose"
    >
      <span
        >发现你在{{ cacheTypeName }}模块有未完成的试卷，是否继续完成答题？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteCachePaper()">取 消</el-button>
        <el-button type="primary" @click="getCachePaper()">确 定</el-button>
      </span>
    </el-dialog>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <el-row class="el-row_body">
        <el-button
          class="but"
          @click="humanca"
          style="padding: 10px 50px 10px 50px"
          v-show="!drawer"
          >开始测评</el-button
        >
        <div v-show="drawer && !paystate">
          <div
            v-for="(item, index) in payList"
            :key="item.id"
            style="display: inline-block"
          >
            <div
              style="cursor: pointer"
              @click="onClick(item, index)"
              :class="payTimeIndex === index ? 'changes' : 'change'"
            >
              <!--通过点击事件索引获取套餐选项item，判断套餐样式选择、以及为计算总价使用准备-->
              <div class="payName">{{ item.name }}</div>
              <!--套餐名称-->
              <div
                :class="
                  item.actualTotalAmount !== item.totalAmount
                    ? 'payata'
                    : 'payatCenter'
                "
              >
                {{ "￥" + item.actualTotalAmount }}
              </div>
              <!--套餐实际付款价格-->
              <div
                class="payta"
                v-if="item.actualTotalAmount !== item.totalAmount"
              >
                {{ "￥" + item.totalAmount }}
              </div>
              <!--套餐原价-->
            </div>
          </div>

          <div class="demo-drawer__footer">
            <el-button @click="addOrder()" style="margin-top: 100px"
              >支付</el-button
            >
            <p class="courseContent" style="color: darkgray">
              该产品为虚拟产品购买后不支持退款
            </p>
          </div>
        </div>
        <payCode
          v-if="paystate"
          :codeUrl="codeUrl"
          :outTradeNo="outTradeNo"
          :endTime="expirationTime"
          :userData="userData"
          @payid="pay"
        ></payCode>
        <el-button style="margin: 20px" @click="cancelOrder" v-show="paystate"
          >取消支付</el-button
        >
      </el-row>
    </div>
    <flooter style="bottom: 0"></flooter>
  </div>
</template>

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Sideframe from "../../components/sideframe.vue";
import qrcode from "./components/Qrcode.vue";
import Cookies from "js-cookie";
import { Loading } from "element-ui";
import payCode from "../../components/payCode.vue";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";

export default {
  components: { Header, Flooter, Sideframe, qrcode, payCode, Breadcrumb },
  data() {
    return {
      title: "人力资本化提升计划",
      titleData: {
        titleName: "人力资本化提升计划",
        data: [
          {
            id: nanoid(),
            name: "人力资本化",
            href: "",
          },
          {
            id: nanoid(),
            name: "人力资本化提升计划",
            href: "",
          },
        ],
      },
      payType: "1",
      drawer: false,
      innerDrawer: false,
      isok: false,
      totalFee: "",
      dynamicTags: [],
      listData: [],
      srcdoc: "",
      payList: [],
      timer: "",
      payTimeIndex: 0, // 充值套餐的索引
      payTitle: "支付宝支付",
      codeUrl: "",
      outTradeNo: "",
      orderNumber: "",
      expirationTime: "",
      orderEntity: {},
      loading: false,
      paystate: false,
      cacheTypeName: "",
      userData: {},
      showbox: false,
      shoppingCarNum: 0,
      dataList: {
        ids: [],
        userId: 0,
      },
    };
  },
  watch: {},
  created() {
    this.getuserData();
    this.gethumanCAData();
    this.shoppingCarNum = window.localStorage.getItem("shoppingCarNum");
  },
  methods: {
    cancelOrder() {
      this.paystate = false;
    },
    async addOrder() {
      this.axios
        .get("/EvaluationTestQuestion/selectStateTestQuestion")
        .then((res) => {
          const a = res.object.filter(
            (item) => item.testQuestions === this.title
          );
          if (a) {
            this.orderEntity.commodityName = "测评订单";
            this.orderEntity.commodityPrice = this.totalFee;
            this.orderEntity.basicUserId = this.userData.id;
            this.orderEntity.children = [];
            const commodity = {};
            commodity.commodityName = a[0].testQuestions;
            commodity.commodityPrice = this.totalFee;
            commodity.commodityId = a[0].id;
            commodity.typeId = 1;
            this.orderEntity.children.push(commodity);
            this.axios.post("/order/addOrder", this.orderEntity).then((res) => {
              if (res.code === 400) {
                this.$message({
                  showClose: true,
                  message: "有未完成的订单，请完成上一个订单后再下单",
                  type: "error",
                });
              } else if (this.totalFee === 0) {
                this.axios
                  .get("/basicUser/tokenByUser", {
                    params: {
                      token: this.token,
                    },
                  })
                  .then((res) => {
                    this.userData = res.object;
                    this.humanca();
                  });
              } else {
                this.orderNumber = res.object.orderNumber;
                this.expirationTime = res.object.expirationTime;
                const formData = new FormData();
                formData.append("orderNumber", this.orderNumber);
                this.codeUrl = "";
                this.axios
                  .post(
                    "/AlipayFaceToFaceController/ZFBPreorderAction",
                    formData
                  )
                  .then((res) => {
                    this.codeUrl = res.qrcode;
                    this.outTradeNo = res.outTradeNo;
                    this.paystate = !this.paystate;
                  });
              }
            });
          }
        });
    },
    async pay(val) {
      const formData = new FormData();
      formData.append("orderNumber", this.orderNumber);
      if (val === "1") {
        this.codeUrl = "";
        this.axios
          .post("/AlipayFaceToFaceController/ZFBPreorderAction", formData)
          .then((res) => {
            this.codeUrl = res.qrcode;
            this.outTradeNo = res.outTradeNo;
          });
      } else if (val === "2") {
        this.codeUrl = "";
        // this.innerDrawer = true
        alert("微信支付暂时待完善中");
      } else if (val === "3") {
        this.codeUrl = "";
        this.innerDrawer = true;
      }
    },
    onClick(val, index) {
      this.totalFee = val.actualTotalAmount;
    },
    async getuserData() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
        const res = await this.axios.get("/basicUser/tokenByUser", {
          params: {
            token: this.token,
          },
        });
        if (res) {
          this.userData = res.object;
        }
      } else {
        this.token = null;
        this.$message({
          showClose: true,
          message: "请登录后进行操作！",
          type: "info",
        });
        this.$router.push({ path: "/login" });
      }
    },
    async gethumanCAData() {
      const res = await this.axios.get("/humanCapital/selectAll");
      if (this.userData == null) {
        this.$message({
          showClose: true,
          message: "请登录后进行操作！",
          type: "info",
        });
        this.$router.push({ path: "/login" });
      } else {
        if (res.code == 200) {
          this.listData = res.object;
          this.dynamicTags = res.object;
        } else {
          this.$message({
            showClose: true,
            message: "请求错误，请稍后重试！",
            type: "error",
          });
        }
      }
    },

    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    deleteCachePaper() {
      this.axios
        .get("/cachePaper/deleteCachePaper", {
          params: {
            id: JSON.parse(window.localStorage.getItem("questionlist"))
              .cachePaperId,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.humanca();
          }
        });
    },
    getCachePaper() {
      this.$router.push({
        name: "questions",
      });
    },
    async humanca() {
      if (this.userData == null) {
        this.$message({
          showClose: true,
          message: "请登录！",
          type: "info",
        });
      } else {
        if (this.userData.charge1 === "1") {
          const ids = this.dynamicTags.map((item) => item.id);
          const res = await this.axios.get(
            "/EvaluationTestQuestion/selectAllTestQuestion",
            {
              params: {
                startpage: 1,
                pagesize: 50,
              },
            }
          );
          if (res.code == 200) {
            const data = res.object.list;
            data.forEach((it) => {
              if (it.testQuestions === this.title) {
                let a = [];
                it.list.forEach((ite) => {
                  a.push(ite.id);
                });
                this.dataList.evaluationTestQuestionEntity = it;
                this.dataList.ids = a;
                this.dataList.userId = this.userData.id;
                this.axios
                  .post(
                    "/EvaluationQuestion/humanCapitalQuestion",
                    this.dataList
                  )
                  .then((res) => {
                    if (res.code === 200) {
                      const data1 = res.object;
                      if (data1.optionJson !== null) {
                        this.cacheTypeName = data1.typeName;
                        window.localStorage.setItem(
                          "questionlist",
                          JSON.stringify(data1)
                        );
                        window.localStorage.setItem("title", data1.typeName);
                        window.localStorage.setItem("typeId", a);
                        this.showbox = !this.showbox;
                      } else {
                        window.localStorage.setItem(
                          "questionlist",
                          JSON.stringify(data1)
                        );
                        window.localStorage.setItem("title", data1.typeName);
                        window.localStorage.setItem("typeId", a);
                        this.$router.push({
                          name: "questions",
                        });
                      }
                    } else if (res.code === 400) {
                      this.$message.warning("请先完成个体人力资本化测评");
                      setTimeout(() => {
                        let fullscreenLoading = Loading.service({
                          fullscreen: true,
                        });
                        fullscreenLoading.close();
                        this.$router.push({
                          path: "/HumanCapitalizationAssessment",
                        });
                      }, 2000);
                    }
                  });
              }
            });
          } else {
            this.$message({
              showClose: true,
              message: "请求错误，请稍后重试！",
              type: "error",
            });
          }
        } else {
          const res = await this.axios.get(
            "/EvaluationTestQuestion/selectAllTestQuestion",
            {
              params: {
                startpage: 1,
                pagesize: 50,
              },
            }
          );
          let priceData = {
            name: "一次",
            actualTotalAmount: "",
            totalAmount: "",
          };
          if (res.code == 200) {
            this.payList = [];
            const data = res.object.list;
            this.totalFee = data[0].price * (data[0].discount / 10);
            data.forEach((it) => {
              if (it.testQuestions === this.title) {
                priceData.actualTotalAmount = it.price * (it.discount / 10);
                priceData.totalAmount = it.price;
                this.payList.push(priceData);
                this.drawer = true;
              }
            });
          }
        }
      }
    },
  },
};
</script>
<style>
.el-row_body {
  text-align: center;
  margin: 20px auto;
  min-width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-drawer__body {
  text-align: center;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: rgb(218, 185, 107);
  border-color: rgb(218, 185, 107);
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgb(218, 185, 107);
}

.el-checkbox.is-bordered.is-checked {
  border-color: rgb(218, 185, 107);
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: rgb(218, 185, 107);
}
</style>

<style scoped>
.payName {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}
.payata {
  color: #c79745;
  font-size: 24px;
  font-weight: 600;
}
.payatCenter {
  color: #c79745;
  font-size: 24px;
  font-weight: 600;
  line-height: 69px;
}
.payta {
  text-decoration: line-through;
  color: #999999;
}
.change {
  width: 150px;
  height: 90px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 10px;
  margin: 20px;
}

.change:hover {
  background-color: #ffeed0;
  border-color: #ffeed0;
}

.changes {
  width: 150px;
  height: 90px;
  border: 1px solid #c79745;
  border-radius: 10px;
  background-color: #ffeed0;
  margin: 20px;
}
.courseContent {
  display: inline-block;
  text-indent: 0 !important;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.875;
  font-size: 16px;
}
.imgsize {
  height: 60px;
  width: 80px;
}

.icon {
  width: 40px;
  height: 18px;
  display: flex;
}

::v-deep .el-image__inner {
  float: left;
  max-height: 60px;
  max-width: 80px !important;
}

#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}

.but {
  margin: 225px 0 225px 0;
}

::v-deep .el-collapse-item__header:hover {
  color: rgb(218, 185, 107);
}

::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(218, 185, 107);
  color: #fff;
}

::v-deep .el-collapse-item__header {
  font-size: 26px;
}

::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

::v-deep .el-checkbox {
  margin-left: 10px;
  width: 15px;
  float: left;
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  margin-right: 30px;
}

h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}

.background {
  background-color: #f7f7f7;
  height: 100px;
}

hr {
  margin: 0;
  margin-bottom: 8px;
}

h3 {
  margin: 0;
  text-align: left;
  background-color: #eeeeee;
  font-size: 28px;
  font-weight: normal;
  line-height: 1.875;
}

.h4 {
  margin: 10px auto;
  text-align: left;
  background-color: #eeeeee;
  font-size: 20px;
  font-weight: lighter;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: 楷体, sans-serif;
}

.h4:hover {
  color: rgb(218, 185, 107);
  background-color: #333333;
}

img {
  display: inline-block;
  margin-top: 20px;
}

p {
  display: inline-block;
  text-indent: 2em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.875;
  font-size: 16px;
}

.aside {
  display: inline-block;
  width: 260px;
  position: absolute;
  top: 200px;
}

.el-row {
  display: inline-block;
  margin-right: 100px;
  margin-top: 40px;
  margin-bottom: 100px;
}

.title {
  margin: 0;
  font-size: 28px;
}

::v-deep .el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  float: left;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgb(218, 185, 107);
}

.link {
  cursor: pointer;
  text-decoration: none;
  float: left;
  color: #555555;
}

.div {
  line-height: 137px;
  width: 1198px;
  margin: 15px 0px 15px 0px;
  background-color: #f9f9f9;
  right: 0;
}

.el-pagination {
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
  text-align: center;
}

::v-deep .el-image__inner {
  float: left;
  max-height: 137px;
  max-width: 110px;
}

i {
  margin-left: 40px;
}
@media (max-width: 768px) {
  .payName {
    font-size: 68px;
    color: #333333;
    font-weight: 500;
  }
  .payata {
    color: #c79745;
    font-size: 68px;
    font-weight: 600;
  }
  .payatCenter {
    color: #c79745;
    font-size: 68px;
    font-weight: 600;
    line-height: 69px;
  }
  .payta {
    text-decoration: line-through;
    color: #999999;
    font-size: 68px;
  }
  .change {
    width: 450px;
    height: 290px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    margin: 20px;
  }

  .change:hover {
    background-color: #ffeed0;
    border-color: #ffeed0;
  }

  .changes {
    width: 450px;
    height: 290px;
    border: 1px solid #c79745;
    border-radius: 10px;
    background-color: #ffeed0;
    margin: 20px;
  }
  .courseContent {
    font-size: 68px;
  }
  ::v-deep .el-button span {
    font-size: 68px !important;
    line-height: 108px;
    margin: 8% auto;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .payName {
    font-size: 48px;
    color: #333333;
    font-weight: 500;
  }
  .payata {
    color: #c79745;
    font-size: 48px;
    font-weight: 600;
  }
  .payatCenter {
    color: #c79745;
    font-size: 48px;
    font-weight: 600;
    line-height: 69px;
  }
  .payta {
    text-decoration: line-through;
    color: #999999;
    font-size: 48px;
  }
  .change {
    width: 350px;
    height: 220px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    margin: 20px;
  }

  .change:hover {
    background-color: #ffeed0;
    border-color: #ffeed0;
  }

  .changes {
    width: 350px;
    height: 220px;
    border: 1px solid #c79745;
    border-radius: 10px;
    background-color: #ffeed0;
    margin: 20px;
  }
  .courseContent {
    font-size: 48px;
  }
  ::v-deep .el-button span {
    font-size: 48px !important;
    line-height: 108px;
    margin: 8% auto;
  }
}

@media (min-width: 1025px) {
}
</style>