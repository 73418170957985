<template>
  <div class="title-box">
    <el-tooltip
      class="item"
      effect="dark"
      :content="titleData.titleName"
      placement="bottom-end"
      v-if="!wordShow"
    >
      <h2>
        {{ titleData.titleName.substr(0, 5) + "..." }}
      </h2>
    </el-tooltip>
    <h2 v-else>
      {{ titleData.titleName }}
    </h2>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <template v-for="item in titleData.data">
        <el-breadcrumb-item :key="item.id" :to="item.href" v-if="wordShow"
          >{{ item.name }}
        </el-breadcrumb-item>
        <el-tooltip
          :key="item.id"
          class="item"
          effect="dark"
          :content="item.name"
          placement="bottom-end"
          v-else
        >
          <el-breadcrumb-item :key="item.id" :to="item.href"
            >{{ item.name.substr(0, 5) + "..." }}
          </el-breadcrumb-item>
        </el-tooltip>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
import Template from "../../../yixuefangce-BackgroundManagement/src/components/template.vue";
export default {
  components: { Template },
  data() {
    return {
      wordShow: true,
      windowWith: 0,
    };
  },
  props: {
    titleData: {
      type: Object,
      default: {},
    },
  },
  created() {
    this.windowWith = window.innerWidth;
    this.wordShow = this.windowWith > 1024 ? true : false;
  },
  mounted() {
    window.onresize = () => {
      this.windowWith = window.innerWidth;
    };
  },
  watch: {
    windowWith(val) {
      this.wordShow = val > 1024 ? true : false;
    },
  },
  methods: {},
};
</script>

<style  scoped>
.title-box {
  background-color: #f7f7f7;
  height: fit-content;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107) !important;
}
h2 {
  margin: 0;
  font-weight: lighter;
}
.el-breadcrumb {
  margin-right: 15%;
}
@media (max-width: 768px) {
  h2 {
    height: 220px;
    line-height: 220px;
    font-size: 62px;
    padding: 0 0 0 100px;
  }
  .el-breadcrumb {
    font-size: 48px;
    height: 220px;
    line-height: 220px;
    text-align: right;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  h2 {
    height: 140px;
    line-height: 140px;
    font-size: 48px;
    padding: 0 0 0 200px;
  }
  .el-breadcrumb {
    font-size: 28px;
    height: 140px;
    line-height: 140px;
    text-align: right;
  }
}

@media (min-width: 1025px) {
  .el-breadcrumb {
    font-size: 22px;
    margin-right: 15%;
    height: 90px;
    line-height: 90px;
  }
  h2 {
    padding: 0 0 0 200px;
    margin: 0;
    height: 90px;
    line-height: 90px;
    font-size: 38px;
  }
}
</style>