<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="验证"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <slidervalidation
        v-if="!validationFlag"
        @success="validation()"
      ></slidervalidation>
      <div v-if="validationFlag">
        <p>已向{{phone | showphone}}发送手机验证码，请注意查收手机短信</p>
        <div style="position: relative">
          <div class="validationtop" @click="importvalidation()"></div>
          <el-input
            v-model="input1"
            ref="input1"
            maxlength="1"
            @input="changeinput1()"
            style="width: 40px"
          ></el-input>
          <el-input
            v-model="input2"
            ref="input2"
            maxlength="1"
            @input="changeinput2()"
            @keyup.delete.native="deleteinput2()"
            style="width: 40px"
          ></el-input>
          <el-input
            v-model="input3"
            ref="input3"
            maxlength="1"
            @input="changeinput3()"
            @keyup.delete.native="deleteinput3()"
            style="width: 40px"
          ></el-input>
          <el-input
            v-model="input4"
            ref="input4"
            maxlength="1"
            @input="changeinput4()"
            @keyup.delete.native="deleteinput4()"
            style="width: 40px"
          ></el-input>
        </div>
        <p>输入短信验证码，完成注册！</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SliderValidation from "./slidervalidation.vue";
// import {  getPhoneCode } from "@/api/user.js";
export default {
  props: {
    phone: {
      type: String,
      default: ""
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      validationFlag: false,
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      form: {
        verifyName: "",
        code: ""
      }
    };
  },
  watch: {
    flag() {
      this.dialogVisible = this.flag;
      this.validationFlag = false;
    }
  },
  methods: {
    handleClose() {
      this.$emit("flagclose");
    },
    deleteinput2() {
      this.$refs["input1"].focus();
      this.input1 = "";
    },
    deleteinput3() {
      this.$refs["input2"].focus();
      this.input2 = "";
    },
    deleteinput4() {
      this.$refs["input3"].focus();
      this.input3 = "";
    },
    changeinput1() {
      if (this.input1 !== "") {
        this.$refs["input2"].focus();
      }
    },
    changeinput2() {
      if (this.input2 !== "") {
        this.$refs["input3"].focus();
      }
    },
    changeinput3() {
      if (this.input3 !== "") {
        this.$refs["input4"].focus();
      }
    },
    changeinput4() {
      if (this.input4 !== "") {
        this.form.code = this.input1 + this.input2 + this.input3 + this.input4;
        this.$emit("getValidation", this.form);
      }
    },
    validation() {
      getPhoneCode(this.phone).then(res => {
        this.validationFlag = true;
        this.form.verifyName = res.result.verifyName;
      });
    },
    importvalidation() {
      if (this.input1 == "") {
        this.$refs["input1"].focus();
      } else if (this.input2 == "") {
        this.$refs["input2"].focus();
      } else if (this.input3 == "") {
        this.$refs["input3"].focus();
      } else if (this.input4 == "") {
        this.$refs["input4"].focus();
      } else {
        this.$refs["input4"].focus();
      }
    }
  },
  components: {
    slidervalidation: SliderValidation
  },
  filters: {
    showphone: function(value) {
      return value.substring(0, 3) + "****" + value.substring(7, 11);
    }
  }
};
</script>

<style scoped>
.validationtop {
  height: 40px;
  width: 180px;
  background: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9;
}
</style>