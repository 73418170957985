<template>
  <div class="body">
    <sideframe></sideframe>
    <div class="LOGO">
      <img
      src="https://sunledge.com/apis/pic/20231222/e25f0505-d971-4bda-b347-42f5b0049e0f.png"
        alt="益学方策"
        @click="goLogin"
      />
    </div>
    <div class="input-boxBody" v-if="!flag">
      <span class="logon">用户注册</span>
      <el-form
        style="margin-top: 50px"
        :model="form"
        :rules="rules"
        ref="form"
        label-width="120px"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="form.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            placeholder="请再次输入密码"
            v-model="form.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="username">
          <el-input v-model="form.username" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select
            v-model="form.sex"
            placeholder="请选择性别"
            style="float: left"
          >
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-form-item label="所处地区" prop="province" size="small">
            <el-col :span="8">
              <el-select v-model="form.province" placeholder="请选择所在省" w>
                <el-option
                  v-for="item in provinces"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="form.city" placeholder="请选择所在市">
                <el-option
                  v-for="item in cities"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="form.area" placeholder="请选择所在区">
                <el-option
                  v-for="item in areas"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-form-item>
        </el-row>
        <el-form-item label="企业名称" prop="enterpriseName">
          <el-input
            v-model="form.enterpriseName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在部门" prop="department">
          <el-input v-model="form.department" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="出生日期">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.birthDate"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')"
            >立即注册</el-button
          >
          <el-button @click="backLogin()">返回登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div
      style="width: 450px; height: 520px; padding: 100px 0px; margin: 0px auto"
      v-if="flag"
    >
      <h2 style="color: #409eff; text-align: center">账号注册成功</h2>
      <p style="color: #909399; text-align: center">
        账号注册成功！即将跳转到登陆页面 {{ times2 }} s
      </p>
      <el-button
        type="primary"
        style="width: 100%; margin-top: 50px"
        @click="backLogin()"
        >去 登 陆</el-button
      >
    </div>
    <myflooter></myflooter>
    <phonevalidation
      :flag="validationFlag"
      :phone="form.phone"
      @getValidation="getValidation"
      @flagclose="flagchange()"
    ></phonevalidation>
  </div>
</template>

<script>
import Flooter from "./flooter.vue";
import PhoneValidation from "../components/phonevalidation.vue";
import Header from "./Header.vue";
import Sideframe from "../components/sideframe.vue";
// import { regexpasswordword } from "@/api/util/RegexUtils.js";
// import { grade } from "@/api/common.js";
// import { provinces, areas, cities } from "@/api/address.js";
// import {clientRegister} from "@/api/user.js"
export default {
  data() {
    //自定义密码验证
    // 密码本身
    const validatepass = (rule, value, callback) => {
      const reg = /^[a-z|A-Z]+\d+$/;
      const falg = reg.test(value);
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (value.length < 6 || value.length > 18) {
        callback(new Error("密码长度在6-18位"));
      } else if (falg === false) {
        callback(new Error("密码请以字母开头数字结尾"));
      } else {
        if (this.form.checkPass !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    const validatepass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      flag: false,
      times2: 15,
      timer2: null,
      validationFlag: false,
      // 省数据
      provinces: [],
      // 市数据
      cities: [],
      // 区数据
      areas: [],
      provincename: "",
      citiename: "",
      areaname: "",
      form: {
        areaId: "",
        cityId: "",
        provinceId: "",
        phone: "",
        password: "",
        checkPass: "",
        nickname: "", //用户名
        username: "", //真实姓名
        sex: "", //性别
        state: 1,
        birthDate: "", //生日
        province: "", //省
        city: "", //市
        area: "", //区
        address: "",
        enterpriseName: "", //企业名称，
        department: "",
        culturalExchangeNum: "",
      },
      //年级数据
      gradeDatas: [],
      rules: {
        phone: [
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, validator: validatepass, trigger: "blur" },
        ],
        checkPass: [
          { required: true, validator: validatepass2, trigger: "blur" },
        ],
        nickname: [
          { required: true, message: "昵称不可为空", trigger: "blur" },
          { min: 2, max: 8, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        username: [
          { required: true, message: "真实姓名不可为空", trigger: "blur" },
          { min: 2, max: 8, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        // enterpriseName: [
        //   { required: true, message: "企业名称不可为空", trigger: "blur" }
        // ]
      },
    };
  },
  created() {
    this.gertprovinces();
  },
  methods: {
    //回到登录页面
    goLogin() {
      this.$router.push({ path: "/login" });
    },
    getValidation(e) {
      let phone = {
        loginType: 3,
        identity: this.form.phone, //手机号
        certificate: this.form.password, //密码
      };
      let data = {
        phone: this.form.phone,
        address: this.provincename + this.citiename + this.areaname,
        login: [], //注册信息
        birthDate: this.form.birthDate,
        enterpriseName: this.form.enterpriseName,
        userName: this.form.userName,
        nickName: this.form.nickName,
        sex: this.form.sex,
        vCode: {
          verifyCode: e.code,
          verifyName: e.verifyName,
        },
      };
      data.login.push(phone);
      if (this.$route.params.access_token !== null) {
        let wechat = {
          loginType: 5,
          certificate: this.$route.params.access_token, //access_token
        };
        data.login.push(wechat);
      }
      clientRegister(data).then((res) => {
        if (res !== null) {
          this.validationFlag = false;
          this.times2 = 15;
          this.flag = true;
          this.dialogVisible = false;
          this.timer2 = setInterval(() => {
            this.times2--;
            if (this.times2 == 0) {
              clearInterval(this.timer2);
              this.backLogin();
            }
          }, 1000);
        }
      });
    },
    //验证的模态框关闭
    flagchange() {
      this.validationFlag = false;
    },
    //立即注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async submit() {
      if (
        this.form.birthDate !== null &&
        this.form.birthDate instanceof Object
      ) {
        this.form.birthDate =
          this.form.birthDate.getFullYear() +
          "-" +
          (this.form.birthDate.getMonth() + 1) +
          "-" +
          this.form.birthDate.getDate() +
          " " +
          this.form.birthDate.getHours() +
          ":" +
          this.form.birthDate.getMinutes() +
          ":" +
          this.form.birthDate.getSeconds();
      }
      if (
        this.provincename !== "" &&
        this.citiename !== "" &&
        this.areaname !== ""
      ) {
        this.form.address =
          this.provincename + "," + this.citiename + "," + this.areaname;
      }
      const res = await this.axios.post("/basicUser/addBasicUser", this.form);
      if (res.code == 200) {
        this.$message({
          showClose: true,
          message: "注册成功",
          type: "success",
        });
        this.$router.push({ path: "/login" });
      } else if (res.code == 400) {
        this.$message({
          showClose: true,
          message: "该手机号已被注册！",
          type: "error",
        });
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async gertprovinces() {
      const res = await this.axios.get("/city/selectAllProvince");
      if (res.code == 200) {
        this.provinces = res.object;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async getcities(id) {
      const res = await this.axios.get("/city/selectAllCity", {
        params: {
          id: id,
        },
      });
      if (res.code == 200) {
        this.cities = res.object;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    async getareas(id) {
      const res = await this.axios.get("/city/selectAllArea", {
        params: {
          id: id,
        },
      });
      if (res.code == 200) {
        this.areas = res.object;
      } else {
        this.$message({
          showClose: true,
          message: "请求错误，请稍后重试！",
          type: "error",
        });
      }
    },
    //返回登录页面
    backLogin() {
      this.$router.push({ path: "/login" });
    },
  },
  watch: {
    // 监听省
    "form.province"(newName, oldName) {
      // 初始化城市列表
      for (let i = 0; i < this.provinces.length; i++) {
        if (newName == this.provinces[i].id) {
          this.provincename = this.provinces[i].name;
          this.citiename = "";
          this.areaname = "";
          this.form.provinceId = newName;
          this.getcities(newName);
        }
      }
    },
    // 监听市
    "form.city"(newName, oldName) {
      for (let i = 0; i < this.cities.length; i++) {
        if (newName == this.cities[i].id) {
          this.citiename = this.cities[i].name;
          this.form.cityId = newName;
          this.areaname = "";
          this.getareas(newName);
        }
      }
    },
    // 监听区
    "form.area"(newName, oldName) {
      for (let i = 0; i < this.areas.length; i++) {
        if (newName == this.areas[i].id) {
          this.areaname = this.areas[i].name;
          this.form.areaId = newName;
        }
      }
    },
  },
  components: {
    myflooter: Flooter,
    phonevalidation: PhoneValidation,
    Sideframe,
    Header,
  },
};
</script>

<style scoped>
.body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.input-boxBody {
  width: 30%;
  margin: auto;
}
.logon {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  margin: 20px;
}
.LOGO {
  height: fit-content;
  color: #dab96b;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  margin-bottom: 50px;
}
.LOGO img {
  cursor: pointer;
  margin-left: 50px;
  height: 70px;
  padding: 10px;
}
#headpage {
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #dab96b;
  border-color: #dab96b;
}
@media only screen and (min-width: 1279px) and (max-width: 1535px) {
  .LOGO img {
    height: 120px;
  }
  .logon {
    font-size: 24px;
  }
  .input-boxBody {
    width: 50%;
    padding: 50px;
  }
  ::v-deep .el-input__inner {
    height: 40px !important;
    line-height: 40px !important;
    padding:0 40px !important;
  }
  ::v-deep .el-input__icon {
    line-height: 40px !important;
    width: 40px !important;
    
  }
  ::v-deep .el-select .el-input .el-select__caret {
    font-size: 18px;
  }
  ::v-deep .el-form-item__content {
    height: 40px !important;
    line-height: 40px !important;

  }
  ::v-deep .el-button span {
    height: 40px !important;
    line-height: 40px !important;
    font-size: 18px !important;
  }
  ::v-deep .el-input {
    font-size: 18px !important;
  }
  ::v-deep .el-form-item__label {
    font-size: 18px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1280px) {
  .LOGO img {
    height: 160px;
  }
  .logon {
    font-size: 28px;
  }
  .input-boxBody {
    width: 60%;
    padding: 50px;
  }
  ::v-deep .el-input__inner {
    height: 80px !important;
    line-height: 80px !important;
    padding:0 60px !important;
  }
  ::v-deep .el-input__icon {
    line-height: 80px !important;
    width: 60px !important;
    
  }
  ::v-deep .el-select .el-input .el-select__caret {
    font-size: 24px;
  }
  ::v-deep .el-form-item__content {
    height: 60px !important;
    line-height: 60px !important;

  }
  ::v-deep .el-button span {
    height: 60px !important;
    line-height: 60px !important;
    font-size: 24px !important;
  }
  ::v-deep .el-input {
    font-size: 24px !important;
  }
  ::v-deep .el-form-item__label {
    font-size: 24px !important;
    height: 80px !important;
    line-height: 80px !important;
  }
}
@media only screen and (min-width: 661px) and (max-width: 959px) {
  .LOGO img {
    height: 200px;
  }
  .logon {
    font-size: 32px;
  }
  .input-boxBody {
    width: 70%;
    padding: 50px;
  }
  ::v-deep .el-input__inner {
    height: 120px !important;
    line-height: 120px !important;
    padding:0 60px !important;
  }
  ::v-deep .el-input__icon {
    line-height: 120px !important;
    width: 60px !important;
    
  }
  ::v-deep .el-select .el-input .el-select__caret {
    font-size: 32px;
  }
  ::v-deep .el-form-item__content {
    height: 120px !important;
    line-height: 120px !important;

  }
  ::v-deep .el-button span {
    height: 100px !important;
    line-height: 100px !important;
    font-size: 32px !important;
  }
  ::v-deep .el-input {
    font-size: 32px !important;
  }
  ::v-deep .el-form-item__label {
    font-size: 32px !important;
    height: 120px !important;
    line-height: 120px !important;
  }
}
@media only screen and (max-width: 660px) {
  ::v-deep .el-input__inner {
    height: 190px !important;
    line-height: 190px !important;
    padding:0 60px !important;
  }
  ::v-deep .el-input__icon {
    line-height: 190px !important;
    width: 60px !important;
    
  }
  ::v-deep .el-select .el-input .el-select__caret {
    font-size: 58px;
  }
  ::v-deep .el-form-item__content {
    height: 190px !important;
    line-height: 190px !important;

  }
  ::v-deep .el-button span {
    height: 170px !important;
    line-height: 170px !important;
    font-size: 58px !important;
  }
  ::v-deep .el-input {
    font-size: 58px !important;
  }
  ::v-deep .el-form-item__label {
    font-size: 58px !important;
    height: 190px !important;
    line-height: 190px !important;
  }
  .LOGO img {
    height: 240px;
  }
  .logon {
    font-size: 88px;
  }
  .input-boxBody {
    width: 95%;
    padding: 50px;
  }
  ::v-deep .el-form-item__content {
    text-align: left;
  }
}
</style>