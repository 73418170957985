<template>
  <div style="position: relative">
    <loading v-if="loadingisShow" />
    <Header></Header>
    <sideframe></sideframe>
    <Result
      v-if="
        titleName === '人力资本化提升计划' ||
        titleName === '培训班人力资本化提升计划' ||
        titleName === '组织人力资本化测评'
      "
      :dataList="list"
      :falg="falg"
    ></Result>
    <div
      style="min-height: 694px"
      class="index1"
      v-if="
        titleName === '个体人力资本测评' ||
        titleName === '培训班个体人力资本测评' ||
        titleName === '组织人力资本测评'
      "
    >
      <el-row
        id="pdfDom"
        class="pdfDom1"
        style="margin: 20px auto; width: 1200px"
      >
        <div id="num">
          <h2
            class="h2title"
            style="padding: 100px"
            v-if="titleName === '个体人力资本测评'"
          >
            个体人力资本测评报告
          </h2>
          <h2
            class="h2title"
            style="padding: 100px"
            v-if="titleName === '培训班个体人力资本测评'"
          >
            培训班个体人力资本测评报告
          </h2>
          <h2
            class="h2title"
            style="padding: 100px"
            v-if="titleName === '组织人力资本测评'"
          >
            组织个体人力资本测评报告
          </h2>
          <div style="page-break-after: always"></div>
          <p class="title">测评报告简介</p>
          <p>
            &nbsp;&nbsp;人力资本的新界定，内在指的是人力资源，外在指的是自愿或接受将不断提升的人力资源的所有权通过货币计价转化为社会组织或企业的一定产权（发起股东、创办企业或加盟企业等），通过生产社会产品或提供社会服务实现未来可能的更大收益
            。
          </p>
          <p>
            &nbsp;&nbsp;本报告分为两大部分，第一部分是当前人生积累，主要包含年龄健康，教育经历，工作经历与业绩，知识产权或参与知识产权，社会认可度，专业、职业、岗位持久度；第二部分是当前核心心智能力测评，包含隐性能力，三大专业能力。
          </p>
          <p style="text-indent: 0cm;">阅读建议</p>
          <li>
            i.
            人力资本的测评和核心心智能力模型测评遵循匹配原理、推断原理和误差原理。在使用测评结果时，需要与当前的实际情况相结合，并以科学的态度看待测评结果。
          </li>
          <li>
            ii.
            测评结果的准确性和可靠性依赖于被评价者在测验中是否为内心最真实的想法，提交的数据为客观事实，态度是否认真、能否如实作答，是否答完题目以及答题所用时间是否在合理范围内等。
          </li>
          <li>
            iii.
            若您是第一次阅读该类报告，有一些专有名称的理解不到位，最好是在专业人士的指导下阅读，或请专业人士为您进行解释。
          </li>
        </div>
        <div style="page-break-after: always"></div>
        <div id="num1">
          <p class="title">
            <a name="1">I 测评答题情况</a>
          </p>
          <table>
            <thead>
              <th colspan="5" class="titel-white">答题情况</th>
            </thead>
            <tbody>
              <td style="text-align: center">姓名</td>
              <td style="text-align: center">{{ userData.username }}</td>
              <td style="text-align: center">性别</td>
              <td v-if="userData.sex === 1" colspan="2">男</td>
              <td v-if="userData.sex === 0" colspan="2">女</td>
            </tbody>
            <tbody>
              <tr>
                <td
                  rowspan="2"
                  style="text-align: center; vertical-align: middle"
                >
                  答题时间
                </td>
                <td colspan="4" style="text-align: left">
                  第一部分：当前人生积累，{{ userData.username }}答题的时间为{{
                    questionTime
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: left">
                  第二部分：核心心智能力，{{ userData.username }}答题的时间为{{
                    questionTime1
                  }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td
                  rowspan="2"
                  style="text-align: center; vertical-align: middle"
                >
                  答题题数
                </td>
                <td style="text-align: center">总题数</td>
                <td style="text-align: center">{{ score }}</td>
                <td style="text-align: center">实际答题数</td>
                <td style="text-align: center">{{ score }}</td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: left">
                  {{ userData.username }}的答题数量在合理范围内，测评结果有效。
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="page-break-after: always"></div>
        <div id="num2">
          <p class="title">
            <a name="2">II 个体测评概况</a>
          </p>
          <li class="title3">当前人生积累得分情况图示</li>
          <div class="radarImg" id="main2"></div>
          <p>
            人生积累中，
            <span v-for="item in CLAdata" :key="item.abilityCategory">
              {{ item.abilityCategory }}得分 {{ item.fraction }} 分;
            </span>
          </p>
          <li class="title3">当前核心心智能力得分情况图示</li>
          <div class="radarImg" id="main3"></div>
          <p>
            核心心智能力中，
            <span v-for="item in CCMdata" :key="item.abilityCategory">
              {{ item.abilityCategory }}得分
              {{ item.fraction }} 分;
            </span>
          </p>
          <li class="title3">指标综合得分情况</li>
          <p>
            从指标得分上来看，综合得分
            {{ list.totalScore }}。
          </p>
        </div>
        <div style="page-break-after: always"></div>
        <div id="num3">
          <p class="title">
            <a name="3">III 测评结果分析</a>
          </p>
          <p class="title2" style="text-align: center">
            <a name="4">第一部分：当前人生积累</a>
          </p>
          <p class="title4"><a name="5">1. 测评得分一览</a></p>
          <table>
            <tr>
              <th>类别</th>
              <th>维度</th>
              <th>要素</th>
              <th>个人得分</th>
            </tr>
            <tr>
              <td :rowspan="CLAlength" style="background-color: #a8d08d">
                当前人生积累
              </td>
            </tr>
            <template v-for="item in CLAdata">
              <tr>
                <td
                  :rowspan="item.evaluationEntities.length + 2"
                  style="
                    background-color: #c5e0b3;
                    text-align: center;
                    vertical-align: middle;
                  "
                >
                  {{ item.abilityCategory }}
                </td>
              </tr>
              <tr v-for="it in item.evaluationEntities" :key="it.question">
                <td style="background-color: #e2efd9; text-align: left">
                  {{ it.question }}
                </td>
                <td style="text-align: center">{{ it.fraction }}</td>
              </tr>
              <tr>
                <td style="background-color: #e2efd9; text-align: left">
                  综合得分
                </td>
                <td style="text-align: center">{{ item.fraction }}</td>
              </tr>
            </template>
          </table>
          <div class="LineImg" id="main0"></div>
          <p class="title4"><a name="6">2. 测评分项分析</a></p>
          <div v-for="(item, index) in CLAdata" :key="index">
            <div>
              <p class="title4">
                2.{{ index + 1 }} {{ item.abilityCategory }}综合概览
              </p>
              <div class="radarImg" :id="'main' + (index + 4)"></div>
              <table>
                <tr>
                  <td bgcolor="#92D050" style="color: #fff; font-weight: bold">
                    {{ item.abilityCategory }}
                  </td>
                  <td colspan="2" bgcolor="#92D050" class="titel-white">
                    得分{{ item.fraction }}分
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    style="text-align: left"
                    v-html="item.comments"
                  ></td>
                </tr>
                <tr v-for="it in item.evaluationEntities" :key="it.question">
                  <td style="text-align: center;width: 15%;">{{ it.question }}</td>
                  <td style="text-align: center;width: 15%;">{{ it.fraction }}</td>
                  <td style="text-align: left" v-html="it.comments"></td>
                </tr>
              </table>
            </div>
          </div>
          <p class="title2" style="text-align: center">
            <a name="7">第二部分：核心心智能力</a>
          </p>
          <p class="title4"><a name="8">1. 测评得分一览</a></p>
          <table>
            <tr>
              <th>能力一类</th>
              <th>能力类别二类</th>
              <th>能力种类</th>
              <th>个人得分</th>
            </tr>
            <template v-for="item in CMdata">
              <tr>
                <td
                  :rowspan="item.evaluationEntities.length * 6 + 1"
                  style="
                    background-color: #92d050;
                    text-align: center;
                    vertical-align: middle;
                  "
                >
                  {{ item.firstType }}
                </td>
              </tr>
              <template v-for="ite in item.evaluationEntities">
                <tr>
                  <td
                    :rowspan="ite.evaluationEntities.length + 1"
                    style="
                      background-color: #a8d08d;
                      text-align: center;
                      vertical-align: middle;
                    "
                  >
                    {{ ite.abilityCategory }}
                  </td>
                </tr>
                <template v-for="it in ite.evaluationEntities">
                  <tr>
                    <td style="background-color: #c5e0b3">
                      {{ it.abilityCategory }}
                    </td>
                    <td style="background-color: #e2efd9">{{ it.fraction }}</td>
                  </tr>
                </template>
              </template>
            </template>
          </table>
          <div class="LineImg" id="main1"></div>
          <p class="title4"><a name="9">2. 测评分项分析</a></p>
          <div v-for="(item, index) in CMdata" :key="item.id">
            <div>
              <p class="title4">2.{{ index + 1 }} {{ item.firstType }}</p>

              <div v-for="(it, index1) in item.evaluationEntities" :key="it.id">
                <p class="title4">
                  2.{{ index + 1 }}.{{ index1 + 1 }} {{ it.abilityCategory }}
                </p>
                <div
                  class="radarImg"
                  :id="'main' + (index1 + 10)"
                  v-if="index === 0"
                ></div>
                <div
                  class="radarImg"
                  :id="
                    'main' + (index1 + 10 + CMdata[0].evaluationEntities.length)
                  "
                  v-if="index === 1"
                ></div>
                <div
                  class="radarImg"
                  :id="
                    'main' +
                    (index1 +
                      10 +
                      CMdata[1].evaluationEntities.length +
                      CMdata[0].evaluationEntities.length)
                  "
                  v-if="index === 2"
                ></div>
                <table>
                  <tr>
                    <td
                      bgcolor="#92D050"
                      style="color: #fff; font-weight: bold"
                    >
                      {{ it.abilityCategory }}
                    </td>
                    <td colspan="2" bgcolor="#92D050" class="titel-white">
                      得分{{ it.fraction }}分
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      style="text-align: left"
                      v-html="it.comments"
                    ></td>
                  </tr>
                  <tr
                    v-for="i in it.evaluationEntities"
                    :key="i.abilityCategory"
                  >
                    <td style="text-align: center;width: 15%;">{{ i.abilityCategory }}</td>
                    <td style="text-align: center;width: 15%;">{{ i.fraction }}</td>
                    <td style="text-align: left" v-html="i.comments"></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div style="page-break-after: always"></div>
        <div id="num4">
          <p class="title">
            <a name="10">IV 测评综合建议</a>
          </p>
          <P>
            当前人生积累是未来的基础，是可以根据当前的情况进行调整的。核心心智能力是人生必须具备的通用能力、专业能力和隐性能力，是构成自我认知、自我确认、自我成长、自我成就和自我超越的核心能力。
            需要注意的是，不管您当前人生积累和核心心智能力的测评结果是优秀还是其他任何层次，都不代表您的当前人生积累和核心心智能力是完美或者说一无是处。如果您的测评结果是优秀，请谦虚，仍然需要在实践中不断改变自己的人生积累和提升自我的核心心智能力，自我提升没有尽头，自我超越成就大我在前面等着我们；如果您的测评结果不理想，请不要放弃，请自信一点，勇敢地确立自我的位置，力争在实践中脚踏实地地自我改变，不断提升自己的人生积累，完善自己的核心心智能力。
          </P>
          <p>
            我们深信，人生积累和核心心智能力的提升没有尽头，也希望您的人生积累和核心心智能力提升能为大家开启一扇自我成长、自我成就和自我超越的大门，让您的人力资本价值不断升值。
          </p>
          <p class="title2" style="text-align: center">
            <a  name="11">第一部分 调整当前人生积累的建议</a>
          </p>
          <div v-for="(item, index) in CLAdata" :key="item.id">
            <p class="title4">
              <a :name="'11' + (index + 1)"
                >{{ index + 1 }}. {{ item.abilityCategory }}建议</a
              >
            </p>
            <p v-html="item.evaluationRecommendationEntity.recommendation"></p>
            <p>{{ item.evaluationRecommendationEntity.notes }}</p>
          </div>
          <p style="text-align: center">
            <a class="title4" name="12">第二部分 提升核心心智能力的建议</a>
          </p>
          <div v-for="(item, index) in CMdata" :key="item.id">
            <p class="title4">
              <a :name="'12' + (index + 1)"
                >{{ index + 1 }}. &nbsp;&nbsp;提升{{ item.firstType }}建议</a
              >
            </p>
            <div v-for="(it, index1) in item.evaluationEntities" :key="it.id">
              <p class="title4">
                {{ index + 1 }}.{{ index1 + 1 }} &nbsp;&nbsp;提升{{
                  it.abilityCategory
                }}建议
              </p>
              <p v-html="it.evaluationRecommendationEntity.recommendation"></p>
              <p>
                在{{ it.abilityCategory }}测评中， 您的平均得分为{{
                  it.recommendationEntity.avg
                }}， 分能力项来看， 表现优秀的项为{{
                  it.recommendationEntity.eightString.slice(
                    0,
                    it.recommendationEntity.eightString.length - 1
                  )
                }}； 表现的良好的项为{{
                  it.recommendationEntity.sixString.slice(
                    0,
                    it.recommendationEntity.sixString.length - 1
                  )
                }}； 表现一般的项为{{
                  it.recommendationEntity.fourString.slice(
                    0,
                    it.recommendationEntity.fourString.length - 1
                  )
                }}； 表现比较差的项为{{
                  it.recommendationEntity.twoString.slice(
                    0,
                    it.recommendationEntity.twoString.length - 1
                  )
                }}； 表现极为欠缺的项为{{
                  it.recommendationEntity.zeroString.slice(
                    0,
                    it.recommendationEntity.zeroString.length - 1
                  )
                }}。 {{ it.evaluationRecommendationEntity.notes }}
              </p>
            </div>
          </div>
        </div>
      </el-row>
    </div>

    <TopicNavigation
      class="card1"
      :titleNo="titleName1"
      @download="download"
    ></TopicNavigation>
    <flooter></flooter>
  </div>
</template>

<script>
import Sideframe from "../../../components/sideframe.vue";
import Flooter from "../../flooter.vue";
import Header from "../../Header.vue";
import Cookies from "js-cookie";
import * as echarts from "echarts";
import Result from "../components/result.vue";
import "highlight.js/styles/monokai-sublime.css";
import loading from "@/components/loading.vue";
import TopicNavigation from "../../../components/TopicNavigation.vue";
import { exportPDF } from "@/utils/pdfmake";
import { humanTestContent } from "../components/util/humanTest/humanTest";
const myMap = new Map();
export default {
  components: {
    Header,
    Flooter,
    Sideframe,
    Result,
    loading,
    TopicNavigation,
  },
  name: "",
  data() {
    return {
      loadingisShow: false,
      CLAdata: [],
      CLAlength: 1,
      CMdata: [],
      wordByPDF: {
        basicUserEntity: {},
        paperIds: [],
      },
      imgData: [],
      falg: false,
      value: 0,
      token: "",
      score: "", //题库
      riskId: [], //选项id
      optionScoreStr: [], //选项分值
      questiondata1: [],
      userData: {},
      questionTime: "",
      questionTime1: "",
      htmlTitle: "测试",
      windowWidth: 0,
      padding: "10% 10px 10px 10px",
      padding1: "50% 10px 10px 10px",
      titleName: "",
      titleName1: [],
    };
  },
  props: ["pid"],
  watch: {
    windowWidth(val) {
      this.windowWidth = val;
    },
  },
  created() {
    this.gettoken();
    this.getuserData();

    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = document.documentElement.clientWidth;
    });
    this.titleName = window.localStorage.getItem("title");
    if (
      this.titleName === "个体人力资本测评" ||
      this.titleName === "培训班个体人力资本测评" ||
      this.titleName === "组织人力资本测评"
    ) {
      var tn = [
        { name: " 测评答题情况", no: "I", herfNo: "1" },
        { name: " 个体测评概况", no: "II", herfNo: "2" },
        { name: " 测评结果分析", no: "III", herfNo: "3" },
        { name: " 当前人生积累", no: "第一部分", herfNo: "4" },
        { name: "	测评得分一览", no: "1.", herfNo: "5" },
        { name: "	测评分项分析", no: "2.", herfNo: "6" },
        { name: " 核心心智能力", no: "第二部分", herfNo: "7" },
        { name: "	测评得分一览", no: "1.", herfNo: "8" },
        { name: "	测评分项分析", no: "2.", herfNo: "9" },
        { name: " 测评综合建议", no: "IV", herfNo: "10" },
        { name: " 调整当前人生积累的建议", no: "第一部分", herfNo: "11" },
        { name: "	年龄健康建议", no: "1.", herfNo: "111" },
        { name: "	教育经历建议", no: "2.", herfNo: "112" },
        { name: "	工作经历与业绩建议", no: "3.", herfNo: "113" },
        { name: "	知识产权或参与知识产权建议", no: "4.", herfNo: "114" },
        { name: "	社会认可度建议", no: "5.", herfNo: "115" },
        { name: "	专业、职业、岗位持久度", no: "6.", herfNo: "116" },
        { name: " 提升核心心智能力的建议", no: "第二部分", herfNo: "12" },
        { name: "	提升通用能力建议", no: "1.", herfNo: "121" },
        { name: "	提升专业能力", no: "2.", herfNo: "122" },
        { name: "	提升隐性能力建议", no: "3.", herfNo: "123" },
      ];
      this.titleName1 = tn;
      if (window.localStorage.getItem("questionResult")) {
        this.list = JSON.parse(window.localStorage.getItem("questionResult"));
        this.wordByPDF.paperIds.push(this.list.paperId);
        this.score = window.localStorage.getItem("questionlength");
        this.questionTime = window.localStorage.getItem("questionTime");
        this.questionTime1 = window.localStorage.getItem("questionTime1");
        this.list.evaluationEntityList2.forEach((item) => {
          for (const key in this.list.map) {
            if (key === item.abilityCategory) {
              item.rank = this.list.map[key];
            }
          }
        });
        this.CLAdata = this.list.evaluationEntityList2;
        this.CMdata = this.list.evaluationEntities;
        this.CLAdata.forEach((it) => {
          this.CLAlength = this.CLAlength + it.evaluationEntities.length + 2;
        });
        this.CCMdata = [];
        this.CCMlength = 0;
        this.CMdata.forEach((item) => {
          this.CCMlength = this.CCMlength + item.evaluationEntities.length;
          item.evaluationEntities.forEach((it) => {
            for (const key in this.list.map) {
              if (key === it.abilityCategory) {
                it.rank = this.list.map[key];
              }
            }
            this.CCMdata.push(it);
          });
        });
      }
    } else if (
      this.titleName === "人力资本化提升计划" ||
      this.titleName === "培训班人力资本化提升计划" ||
      titleName === "组织人力资本化测评"
    ) {
      var tn = [
        { name: " 测评答题情况", no: "I", herfNo: "1" },
        { name: " 个体测评概况", no: "II", herfNo: "2" },
        { name: " 测评结果分析", no: "III", herfNo: "3" },
        { name: " 人生自我规划", no: "第一部分", herfNo: "4" },
        { name: "	测评得分一览", no: "", herfNo: "41" },
        { name: "	测评分项分析", no: "", herfNo: "42" },
        { name: " 人力资本投入", no: "第二部分", herfNo: "5" },
        { name: "	测评得分一览", no: "", herfNo: "51" },
        { name: "	测评分项分析", no: "", herfNo: "52" },
        { name: " 未来核心心智能力", no: "第三部分", herfNo: "6" },
        { name: "	期望得分一览", no: "", herfNo: "61" },
        { name: "	期望差距分析", no: "", herfNo: "62" },
        { name: " 人力资本化建议", no: "IV", herfNo: "7" },
      ];
      this.titleName1 = tn;
      if (window.localStorage.getItem("questionResult")) {
        this.list = JSON.parse(window.localStorage.getItem("questionResult"));
        this.wordByPDF.paperIds.push(this.list.paperId);
      }
    }
  },
  mounted() {
    if (
      this.titleName === "个体人力资本测评" ||
      this.titleName === "培训班个体人力资本测评" ||
      this.titleName === "组织人力资本测评"
    ) {
      this.getLineChart1();
      this.getLineChart();
      this.getRadar1();
      this.getRadar2();
      this.getRadar3();
      this.getRadar4();
    }
  },
  methods: {
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.token = null;
        this.$router.push({
          path: "/login",
        });
      }
    },
    async getuserData() {
      const res = await this.axios.get("/basicUser/tokenByUser", {
        params: {
          token: this.token,
        },
      });
      if (res.code == 200) {
        this.userData = res.object;
        window.localStorage.setItem("userData", JSON.stringify(this.userData));
        this.wordByPDF.basicUserEntity.username = this.userData.username
        this.wordByPDF.basicUserEntity.sex = this.userData.sex
        this.wordByPDF.basicUserEntity.department = this.userData.department
        this.wordByPDF.basicUserEntity.enterpriseName = this.userData.enterpriseName
      } else {
        this.userData = null;
        this.$router.push({
          path: "/login",
        });
      }
    },
    getLineChart1() {
      const CMindicator = [];
      const CMscorce = [];
      this.CCMdata.forEach((it) => {
        it.evaluationEntities.forEach((item) => {
          CMscorce.push(item.fraction);
          CMindicator.push(item.abilityCategory);
        });
      });
      const chartDom = document.getElementById("main1");
      const myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: false,

        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            max: 100,
          },
        ],
        yAxis: [
          {
            type: "category",
            data: CMindicator,
            inverse: true,
            axisLabel: {
              interval: 0,
              rotate: 15,
              margin: 10,
              textStyle: {
                color: "#060606",
                fontSize: 12,
              },
            },
          },
        ],
        series: [
          {
            name: "核心心智能力",
            type: "bar",
            itemStyle: {
              normal: {
                color: function (params) {
                  //根据数值大小设置相关颜色
                  if (params.value > 0) {
                    return "#E2EFD9";
                  } else {
                    return "#66DDF2";
                  }
                },
              },
            },
            label: {
              show: true,
              textStyle: {
                color: "#060606",
                fontSize: 12,
              },
            },
            data: CMscorce,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    getLineChart() {
      const CLAindicator = [];
      const CLAscorce = [];
      this.CLAdata.forEach((it) => {
        it.evaluationEntities.forEach((item) => {
          CLAscorce.push(item.fraction);
          CLAindicator.push(item.question);
        });
      });
      
      const chartDom = document.getElementById("main0");
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: false,
          textStyle: {
                color: "#060606",
                fontSize: 10,
              },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            max: 100,
            min: -100,
            axisLabel: {
              
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisTick: {
              show: false,
            },
            data: CLAindicator,
            axisLabel: {
              textStyle: {
                color: "#060606",
                fontSize:10
              },
            },
          },
        ],
        series: [
          {
            name: "人生积累",
            type: "bar",
            itemStyle: {
              normal: {
                color: function (params) {
                  //根据数值大小设置相关颜色
                  if (params.value > 0) {
                    return "#E2EFD9";
                  } else {
                    return "#66DDF2";
                  }
                },
              },
            },
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: CLAscorce,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },

    async download() {
      // const testData = {
      //   list: [],
      //   userData: {},
      //   questionTime: "",
      //   questionTime1: "",
      //   questionlength: "",
      //   CLAdata: this.CLAdata,
      //   CCMdata: this.CCMdata,
      //   CMdata: this.CMdata,
      //   questionImg: [],
      // };
      // testData.questionImg = this.imgData;
      // testData.questionlength = this.score;
      // testData.userData = this.userData;
      // testData.list = this.list;
      // testData.questionTime = this.questionTime;
      // testData.questionTime1 = this.questionTime1;
      // this.loadingisShow = !this.loadingisShow;
      // setTimeout(() => {
      //   const a = humanTestContent(testData);
      //   exportPDF(a, this.userData.nickname + this.titleName);
      //   this.loadingisShow = !this.loadingisShow;
      // }, 25000);
      this.$message({
          showClose: true,
          message: "下载中,请稍后",
          type: "info"
        });
      this.loadingisShow = !this.loadingisShow;
      this.axios
        .get("/downloadPDF/preview2", {
          params:{
            basicUserEntity:JSON.stringify(this.wordByPDF.basicUserEntity),
            paperIds:this.wordByPDF.paperIds.toString()
          }
        })
        .then(
          (res) => {
            this.loadingisShow = !this.loadingisShow;
            if (res.code === 200) {
              this.$message({
                showClose: true,
                message: "下载成功",
                type: "success",
              });
              res.object.forEach((it, index) => {
                let blob = this.base64ToBlob(it);
                // const blob = new Blob([it], {
                //   type: "application/vnd.ms-excel",
                // }); //type这里表示xlsx类型
                const downloadElement = document.createElement("a");
                // const name =  //创建下载的链接

                const href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                downloadElement.download =
                  this.userData.username + this.titleName;
                (".pdf"); //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
              });
            } else {
              
              this.$message({
                showClose: true,
                message: "请求错误，请稍后重试！",
                type: "error",
              });
            }
          },
          (err) => {
            this.$message({
              showClose: true,
              message: "请求错误，请稍后重试！",
              type: "error",
            });
          }
        );
    },
    base64ToBlob(code) {
      var raw = window.atob(code);
      let rawLength = raw.length;
      //转换成pdf.js能直接解析的Uint8Array类型
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: "application/pdf" });
    },
    async getRadar1() {
      const CLAindicator = [];
      const CLAscorce = [];
      this.CLAdata.forEach((it) => {
        const CLAname = {
          text: it.abilityCategory,
          max: 100,
        };
        CLAscorce.push(it.fraction);
        CLAindicator.push(CLAname);
      });
      const chartDom = document.getElementById("main2");
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          // text: 'Multiple Radar'
        },
        tooltip: {
          // trigger: 'axis'
        },
        legend: {
          show: false,
        },
        radar: [
          {
            indicator: CLAindicator,
            radius: 80,
            center: ["50%", "60%"],
            axisName: {
              color: "#060606",
            },
          },
        ],
        series: [
          {
            type: "radar",
            data: [
              {
                value: CLAscorce,
                name: "当前人生积累得分情况",
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async getRadar2() {
      const CMindicator = [];
      const CMscorce = [];
      this.CCMdata.forEach((it) => {
        const CMname = {
          text: it.abilityCategory,
          max: 100,
        };
        CMscorce.push(it.fraction);
        CMindicator.push(CMname);
      });
      const chartDom = document.getElementById("main3");
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          // text: 'Multiple Radar'
        },
        tooltip: {
          // trigger: 'axis'
        },
        legend: {
          show: false,
        },
        radar: [
          {
            indicator: CMindicator,
            radius: 80,
            center: ["50%", "60%"],
            axisName: {
              color: "#060606",
            },
          },
        ],
        series: [
          {
            type: "radar",
            data: [
              {
                value: CMscorce,
                name: "当前核心心智能力得分情况",
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async getRadar3() {
      for (let i = 0; i < this.CLAdata.length; i++) {
        const CLAindicator = [];
        const CLAscorce = [];
        this.CLAdata[i].evaluationEntities.forEach((it) => {
          const CLAname = {
            text: it.question,
            max: 100,
          };
          CLAscorce.push(it.fraction);
          CLAindicator.push(CLAname);
        });
        const chartDom = document.getElementById("main" + (i + 4));
        const myChart = echarts.init(chartDom);
        var option;

        option = {
          title: {
            // text: 'Multiple Radar'
          },
          tooltip: {
            // trigger: 'axis'
          },
          legend: {
            show: false,
          },
          radar: [
            {
              indicator: CLAindicator,
              radius: 80,
              center: ["50%", "60%"],
              axisName: {
                color: "#060606",
              },
            },
          ],
          series: [
            {
              type: "radar",
              data: [
                {
                  value: CLAscorce,
                  name: "当前人生积累得分情况",
                },
              ],
            },
          ],
        };
        option && myChart.setOption(option);
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      }
    },
    async getRadar4() {
      for (let i = 0; i < this.CCMlength; i++) {
        const CMindicator = [];
        const CMscorce = [];
        this.CCMdata[i].evaluationEntities.forEach((ie) => {
          const CMname = {
            text: ie.abilityCategory,
            max: 100,
          };
          CMscorce.push(ie.fraction);
          CMindicator.push(CMname);
        });
        const chartDom = document.getElementById("main" + (i + 10));
        const myChart = echarts.init(chartDom);
        var option;

        option = {
          title: {
            // text: 'Multiple Radar'
          },
          tooltip: {
            // trigger: 'axis'
          },
          legend: {
            show: false,
          },
          radar: [
            {
              indicator: CMindicator,
              radius: 80,
              center: ["50%", "60%"],
              axisName: {
                color: "#060606",
              },
            },
          ],
          series: [
            {
              type: "radar",
              data: [
                {
                  value: CMscorce,
                  name: "当前核心心智能力得分情况",
                },
              ],
            },
          ],
        };
        option && myChart.setOption(option);
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      }
    },
  },
};
</script>
<style scoped>
.card1 {
  position: fixed;
  left: 10px;
  top: 6rem;
}
.titel-white {
  text-align: left;
  padding: 10px;
  color: #fff;
}
.sss {
  text-align: right;
  margin: 10px;
}

.lll {
  text-align: right;
  margin: 10px;
}

.el-radio__label {
  font-size: 14px;
  padding-left: 10px;
}

.tit1 {
  font-weight: bold;
  margin: 0 auto;
}

.broder {
  height: 800px;
  font-size: 18px;
}

.radarImg {
  width: 80%;
  height: 480px;
  margin: auto;
}

.LineImg {
  width: 80%;
  height: 980px;
  margin: auto;
}

#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}

html,
body {
  height: 100%;
}

.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

@page {
  size: auto;
  /* auto is the initial value */
  margin: 0mm;
  /* this affects the margin in the printer settings */
}

.page-footer {
  position: fixed;
  bottom: 0mm;
  width: 100%;
  border-top: 1px solid black;
  /* for demo */
}

.page-header {
  position: fixed;
  text-align: center;
  top: 0mm;
  width: 100%;
  border-bottom: 1px solid black;
  /* for demo */
}

.namesetting {
  text-align: left;
  padding: 20px 0 20px 0;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

.h2title {
  text-align: left;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
  background: url('../../../assets/image/starfield.jpg') no-repeat center center;
  background-size:cover;
  opacity: 0.8;
  color: #fff;
  margin: auto;
  text-align: center;
  font-family: '方正大标宋简体';

}

.background {
  background-color: #f7f7f7;
  height: 100px;
}

::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}


.pdfDom {
  background: #fff;
  box-sizing: border-box;
  padding: 2rem 3rem 4rem;
}

/* h5:before
{
	counter-increment:section;
  content:"第" counter(section) "页";
} */
hr {
  margin: 0;
  margin-bottom: 8px;
}

.corporate {
  display: block;
  width: 165px;
  height: 165px;
  margin-left: 400px;
  float: left;
}

.corporatename {
  width: 400px;
  padding-left: 20px;
  font-weight: normal;
  font-size: 42px;
  margin: 100px 150px 10px 60px;
  text-align: left;
  position: absolute;
}

p {
  width: 80%;
  padding: 10px 0 20px 20px;
  text-align: left;
  margin: auto;
  line-height: 35px;
  font-size: 14px;
  font-weight: normal;
  text-indent: 2em;
}

.title {
  padding: 50px 0 20px 0;
  text-align: left;
  color: #00b071;
  font-size: 28px;
  font-weight: normal;
  margin: auto;
  font-family: "方正大标宋简体";
  text-indent: 0cm;
}

.title2 {
  color: #00b071;
  padding: 50px 0 50px 0;
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  margin: auto;
  text-indent: 0cm;
}

.title4 {
  color: #00b071;
  padding: 20px 0 20px 25px;
  font-weight: bold;
  font-size: 20px;
  margin: auto;
  text-indent: 0cm;
}

.title3 {
  padding: 20px 0 0 25px;
  text-align: left;
  font-size: 18px;
  color: #7692d2;
  margin: auto;
  text-indent: 0cm;
}

.wrap2 {
  margin: 0 auto;
  width: 40%;
  display: grid;
  justify-content: center;
}

.wrap {
  margin: 0 auto;
  padding: 10px;
  width: 40%;
  display: grid;
  justify-content: center;
}

h3 {
  line-height: 40px;
  text-align: left;
  padding: 0px 0 0 42%;
}

li {
  width: 80%;
  text-align: left;
  padding: 10px 0 0 20px;
  line-height: 35px;
  font-size: 14px;
  font-weight: normal;
  margin: auto;
}

table {
  width: 80%;
  border: 1px solid #ccc;
  /* 合并单元格的线条 */
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  vertical-align: middle;
  margin: auto;
}

th,
td {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
  text-align: center;
  padding: 12px 0;
  color: #666;
  font-size: 14px;
}

th {
  font-weight: normal;
  background-color: #92d050;
  font-weight: bold;
  color: #fff;
}
</style>