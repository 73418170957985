<!--
 * @Description: 
 * @Version: 2.0
 * @Author: jiyang.fu
 * @Date: 2022-01-17 11:16:27
 * @LastEditors: jiyang.fu
 * @LastEditTime: 2022-12-13 09:29:52
-->
<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },

  data() {
    return {
      isRouterAlive: true,
    };
  },

  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
.body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
h3::before {
  font-family: FontAwesome;
  content: "\f105";
  margin: 0 10px;
}
h4::before {
  font-family: FontAwesome;
  content: "\f105";
  margin: 0 10px;
}
.el-button--text {
  color: #dab96b !important;
}
.index1 {
  padding: 80px 0 0 0;
}
.el-tabs__item.is-active {
  color: #dab96b !important;
}
.el-tabs__item:hover {
  color: #dab96b !important;
}
.el-button--primary:focus,
.el-button--primary:hover {
  background: #332c2b !important;
  border-color: #332c2b !important;
}
.el-tabs__active-bar {
  background: #dab96b !important;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #ffd55e !important;
}
@import "../src/assets/fonts/text.css";
.link:hover,
.link:focus,
.link:active {
  color: #dab96b;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.link::before {
  font-family: FontAwesome;
  content: "\f105";
  margin-left: 8px;
  margin-right: 8px;
}
.Linechart1 {
  width: 100%;
  height: 1480px;
  margin: 0 0 20px 0;
}
.Linechart2 {
  width: 100%;
  height: 2080px;
  margin: 0 0 20px 0;
}
.chart {
  width: 100%;
  height: 380px;
  margin: 0 0 20px 0;
}
.el-select-dropdown__item.selected {
  color: #dab96b !important;
  font-weight: 700;
}
::v-deep .el-select-dropdown__item {
  font-size: 68px !important;
  height: 100px !important;
  line-height: 100px !important;
}
.el-button--primary {
  color: #fff;
  background-color: #dab96b !important;
  border-color: #dab96b !important;
}
.el-button {
  border-radius: 1rem !important;
}
.el-button:focus,
.el-button:hover {
  color: #fff !important;
  background-color: rgba(218, 185, 107, 0.7) !important;
  border-color: #dab96b !important;
}
@media only screen and (min-width: 1279px) and (max-width: 1535px) {
  .el-tabs__item {
    font-size: 24px !important;
    height: 60px !important;
    line-height: 60px !important;
  }
  .el-form-item__label {
    font-size: 24px !important;
  }
  .el-form-item {
    margin-bottom: 28px !important;
  }
  .el-form-item__error {
    font-size: 20px !important;
  }
  .el-link.el-link--info {
    color: #dab96b;
    line-height: 20px;
    font-size: 13px;
    float: left;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .el-tabs__item {
    font-size: 24px !important;
    height: 80px !important;
    line-height: 80px !important;
  }
  .el-input {
    font-size: 24px !important;
  }
  .el-form-item__label {
    font-size: 24px !important;
  }
  .el-input__inner {
    height: 80px !important;
    line-height: 80px !important;
  }
  .el-input__icon {
    line-height: 60px !important;
    width: 30px !important;
  }
  .el-form-item {
    margin-bottom: 28px !important;
  }
  .el-form-item__error {
    font-size: 20px !important;
  }
  .el-link.el-link--info {
    color: #dab96b;
    line-height: 20px;
    font-size: 13px;
    float: left;
  }
}
@media (max-width: 768px) {
  .el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 68px !important;
    line-height: 88px !important;
    height: 88px !important;
    width: 200px !important;
  }
  .index1 {
    padding: 330px 0 0 0;
  }
  .el-select-dropdown__item {
    font-size: 68px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  .el-tabs__item {
    font-size: 58px !important;
    height: 190px !important;
    line-height: 190px !important;
    padding: 10px;
  }
  .el-form-item__label {
    font-size: 58px !important;
    height: 190px !important;
    line-height: 190px !important;
  }
  .el-input__icon {
    width: 80px !important;
  }
  .el-button span {
    font-size: 58px !important;
  }
  .el-form-item {
    margin-bottom: 58px !important;
  }
  .el-form-item__error {
    font-size: 54px !important;
  }

  .el-link.el-link--info {
    color: #dab96b;
    line-height: 20px;
    font-size: 13px;
    float: left;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    font-size: 42px !important;
    line-height: 44px !important;
    height: 44px !important;
  }
  .index1 {
    padding: 185px 0 0 0;
    width: 100%;
  }
  .el-select-dropdown__item {
    font-size: 48px !important;
    height: 80px !important;
    line-height: 80px !important;
  }
  .el-tabs__item {
    font-size: 38px !important;
    height: 120px !important;
    line-height: 120px !important;
  }
  .el-input {
    font-size: 32px !important;
  }
  .el-form-item__label {
    font-size: 32px !important;
  }
  .el-form-item__label {
    font-size: 32px !important;
  }
  ::v-deep .el-input__icon {
    line-height: 90px !important;
    width: 80px !important;
  }
  .el-button span {
    height: 70px !important;
    line-height: 70px !important;
    font-size: 32px !important;
  }
  .el-form-item {
    margin-bottom: 32px !important;
  }
  .el-form-item__error {
    font-size: 28px !important;
  }
  .el-link.el-link--info {
    color: #dab96b;
    line-height: 20px;
    font-size: 13px;
    float: left;
  }
}

@media (min-width: 1025px) {
  .el-select-dropdown__item {
    font-size: 24px !important;
  }
}
</style>
<style lang="scss">
#app {
  -webkit-user-select: none; /*谷歌 /Chrome*/
  -moz-user-select: none; /*火狐/Firefox*/
  -ms-user-select: none; /*IE 10+*/
  user-select: none;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
</style>
