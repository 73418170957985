<template>
<div class="card">
    <div class="card_load"><span style="font-size: 12px;color: black;">会员</span></div>
</div>
</template>

<script>
export default {
props:{

}
}
</script>

<style lang="scss" scoped>
.card {
    width: fit-content;
    height: fit-content;
    background: rgba(0,0,0,0.0);
    padding: 12px 10px;
  }
  
  .card_load {
    width: 30px;
    height: 13px;
    line-height: 13px!important;
    padding: 5px;
    // display: none;
    border-radius: 5px;
    background: linear-gradient(120deg, gold 30%, rgba(255,215,0) 38%, rgba(255,255,255,0.9)
    40%, gold 48%);
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: load89234 2s infinite;
  }
  
  @keyframes load89234 {
    100% {
      background-position: -100% 0;
    }
  }
</style>