<!--
 * @Description: 
 * @Version: 2.0
 * @Author: jiyang.fu
 * @Date: 2022-01-20 10:40:47
 * @LastEditors: jiyang.fu
 * @LastEditTime: 2022-12-09 17:16:17
-->
<template>
  <div class="aside">
    <h2>{{ asideData.asideName }}</h2>
    <div v-for="item in asideData.data" :key="item._id">
      <router-link class="link" :to="item.to"
        >&nbsp;&nbsp;{{ item.title }}</router-link
      >
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    asideData: {
      type: Object,
      default: {},
    },
  },
};
</script>
<style lang="scss" scoped>
h2 {
  font-weight: lighter;
  margin: 0;
  text-align: left;
}
.link {
  cursor: pointer;
  text-decoration: none;
  float: left;
  color: #555555;
  font-weight: lighter;
}
::v-deep .el-divider--horizontal {
  display: block;
  float: left;
}
@media (max-width: 768px) {
  .aside {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .aside {
    display: none;
  }
}

@media (min-width: 1025px) {
  ::v-deep .el-divider--horizontal {
    height: 1px;
    width: 100%;
    margin: 10px 0;
  }
  .aside {
    width: 260px;
  }
  h2 {
    height: 90px;
    line-height: 90px;
    font-size: 30px;
  }
  .link {
    font-size: 22px;
  }
}
</style>