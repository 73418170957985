<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <el-row class="content-body">
        <div class="content" v-html="content"></div>
      </el-row>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../../flooter.vue";
import Header from "../../Header.vue";
import Sideframe from "@/components/sideframe.vue";
import breadcrumb from "../../../components/breadcrumb.vue";
import { marked } from "marked";
import { nanoid } from "nanoid";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
marked.setOptions({
  renderer: new marked.Renderer(),
  highlight: function (code) {
    return hljs.highlightAuto(code).value;
  },
  pedantic: false,
  gfm: true,
  tables: true,
  breaks: true,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: true,
});
export default {
  components: { Header, Flooter, Sideframe, breadcrumb },
  data() {
    return {
      teachermsg: {
        id: "",
        teacherName: "",
        teacherPhoto: "",
        teacherIntroduce: "",
        experience: "",
        field: "",
      },
      content: "",
      titleData: {
        titleName: "",
        data: [
          {
            id: nanoid(),
            name: "企业培训",
            href: "",
          },
          {
            id: nanoid(),
            name: "名师推荐",
            href: "",
          },
          {
            id: nanoid(),
            name: "",
            href: "",
          },
        ],
      },
    };
  },
  created() {
    if (this.$route.params.teachermessage) {
      const teachermessage = JSON.parse(this.$route.params.teachermessage);
      this.teachermsg.id = teachermessage.id;
      this.teachermsg.teacherName = teachermessage.teacherName;
      this.teachermsg.teacherPhoto = teachermessage.teacherPhoto;
      this.teachermsg.teacherIntroduce = teachermessage.teacherIntroduce;
      this.content = marked(this.teachermsg.teacherIntroduce, {
        breaks: true,
      }).replace(/<pre>/g, "<pre class='hljs'>");
      this.titleData.titleName = teachermessage.teacherName;
      this.titleData.data[1].href = "/teachercertification";
      this.titleData.data[2].name = teachermessage.teacherName;
    } else {
      this.$router.push({ path: "/teachercertification" });
    }
  },
  mounted() {},
};
</script>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
hr {
  margin: 0;
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
.content-body {
  width: 80%;
  margin: auto;
}
.content {
  padding: 40px 0;
}
::v-deep .content p img {
  border-radius: 50%;
  padding: 0 50px;
}
::v-deep .content h3::before {
  font-family: FontAwesome;
  content: "\f105";
  margin-left: 25px;
  margin-right: 8px;
}
::v-deep .content strong {
  line-height: 1.875;
  text-align: left;
}
::v-deep .content p {
  margin: 10px 0;
  line-height: 1.875;
  text-indent: 2rem;
  text-align: left;
}
::v-deep .content span {
  line-height: 1.875;
  text-align: left;
}
::v-deep .content li {
  text-align: left;
  line-height: 1.875;
}
::v-deep .content h3 {
  text-align: left;
  background-color: #eeeeee;
  font-size: 36px;
  font-weight: normal;
  line-height: 1.875;
  width: 100%;
}

@media (max-width: 768px) {
  ::v-deep .content p img {
    border-radius: 50%;
    padding: 10px;
    width: 100%;
    height: 1264px;
  }
  ::v-deep .content h3::before {
    margin-left: 25px;
    margin-right: 8px;
  }
  ::v-deep .content p {
    font-size: 58px;
    flex-wrap: wrap;
  }
  ::v-deep .content h3 {
    font-size: 68px;
    line-height: 1.875;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  ::v-deep .content p img {
    border-radius: 50%;
    padding: 0 50px;
  }
  ::v-deep .content h3::before {
    margin-left: 25px;
    margin-right: 8px;
  }
  ::v-deep .content p {
    font-size: 38px;
  }
  ::v-deep .content h3 {
    font-size: 42px;
    line-height: 1.875;
  }
}

@media (min-width: 1025px) {
  ::v-deep .content p img {
    border-radius: 50%;
    padding: 0 50px;
  }
  ::v-deep .content h3::before {
    margin-left: 25px;
    margin-right: 8px;
  }
  ::v-deep .content h3 {
    font-size: 36px;
    line-height: 1.875;
  }
}
</style>