<template>
  <div>
    <div class="side">
      <el-popover placement="left" trigger="hover">
        <img
          class="imgStlye2"
          src="../assets/image/1、益学方策公众号二维码.jpg"
        />
        <div
          class="hand"
          slot="reference"
          @click="goPage()"
          @mouseenter="changeActive($event)"
          @mouseleave="removeActive($event)"
        >
          <div v-show="showTitle">关注我们</div>
          <div v-show="!showTitle"><i class="el-icon-full-screen"></i></div>
        </div>
      </el-popover>
      <div
        class="hand"
        @click="goPage2()"
        @mouseenter="changeActive($event)"
        @mouseleave="removeActive($event)"
      >
        <div v-show="showTitle2">智能客服</div>
        <div v-show="!showTitle2"><i class="el-icon-phone"></i></div>
      </div>

      <div class="hand1" v-if="visible" @click.stop="handleClick">
        <div><i class="el-icon-arrow-up"></i></div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogShow" width="50%" :modal="false">
      <div class="head">
        <p class="title">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-ziyuan114"></use>
          </svg>
          益学方策智能助手
        </p>
      </div>
      <div class="body" ref="body">
        <div v-for="(item, index) in bodyMsg" :key="index">
          <div
            style="text-align: center; margin: 5px 0 0 0"
            v-if="item.nowMinutes !== ''"
          >
            <p style="color: #a3a1a1; text-align: center; margin: 0">
              {{ item.nowHours }}:{{ item.nowMinutes }}
            </p>
          </div>
          <!-- <div class="sendMsg" style="margin-bottom: 10px">
            <template v-if="userData !== null">
              <svg class="icon" aria-hidden="true" v-if="userData.sex === 0">
                <use xlink:href="#icon-a-ziyuan98"></use>
              </svg>
              <svg class="icon" aria-hidden="true" v-if="userData.sex === 1">
                <use xlink:href="#icon-a-ziyuan106"></use>
              </svg>
            </template>
            <svg class="icon" aria-hidden="true" v-if="userData === null">
              <use xlink:href="#icon-a-ziyuan106"></use>
            </svg>
            <p style="padding: 5px; margin: 0">我</p>
          </div> -->
          <div class="sendMsg">
            <div
              style="
                height: 100%;
                width: 45%;
                padding: 0 5px 0 0;
                display: flex;
                justify-content: flex-end;
              "
            >
              <div
                v-html="item.isay"
                style="
                  width: fit-content;
                  background-color: aliceblue;
                  border-radius: 5px;
                  padding: 5px;
                  text-align: left;
                "
              ></div>
            </div>
          </div>
          <!-- <div class="getMsg" style="margin-bottom: 10px">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-ziyuan114"></use>
            </svg>
            <p style="padding: 5px; margin: 0">益学方策智能助手</p>
          </div> -->
          <div class="getMsg" style="margin-bottom: 20px">
            <div style="width: 45%; text-align: left">
              <div
                style="
                  width: fit-content;
                  background-color: rgba(242, 213, 144, 0.7);
                  border-radius: 5px;
                  padding: 5px;
                "
                v-if="item.hesay !== ''"
              >
                {{ item.hesay }}
              </div>
              <div
                style="
                  width: fit-content;
                  background-color: aliceblue;
                  border-radius: 5px;
                  padding: 5px;
                "
                v-else
              >
                <div class="spinner">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setFoot">
        <div class="setMes">
          <UEditor
            ref="froalaEditor"
            v-if="dialogShow"
            @childFn="parentFn"
            @callFather="onSubmit"
            id="s"
          ></UEditor>
        </div>
        <div class="footer">
          <button class="btn">
            <div class="wrapper">
              <p class="text" @click="onSubmit()">发 送</p>

              <div class="flower flower1">
                <div class="petal one"></div>
                <div class="petal two"></div>
                <div class="petal three"></div>
                <div class="petal four"></div>
              </div>
              <div class="flower flower2">
                <div class="petal one"></div>
                <div class="petal two"></div>
                <div class="petal three"></div>
                <div class="petal four"></div>
              </div>
              <div class="flower flower3">
                <div class="petal one"></div>
                <div class="petal two"></div>
                <div class="petal three"></div>
                <div class="petal four"></div>
              </div>
              <div class="flower flower4">
                <div class="petal one"></div>
                <div class="petal two"></div>
                <div class="petal three"></div>
                <div class="petal four"></div>
              </div>
              <div class="flower flower5">
                <div class="petal one"></div>
                <div class="petal two"></div>
                <div class="petal three"></div>
                <div class="petal four"></div>
              </div>
              <div class="flower flower6">
                <div class="petal one"></div>
                <div class="petal two"></div>
                <div class="petal three"></div>
                <div class="petal four"></div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import throttle from "throttle-debounce/throttle";
import UEditor from "@/components/UEditor.vue";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      el: null,
      container: null,
      visible: false,
      target: "",
      textarea: "",
      message: "",
      dialogVisible: false,
      dialogShow: false,
      bodyMsg: [],
      nowMinutes: "",
      nowHours: "",
      bodyHeight: "",
      userData: {},
      showTitle: false,
      showTitle2: false
    };
  },
  components: {
    UEditor,
  },
  destroyed() {
    //取消键盘监听事件
    document.onkeydown = null;
  },
  created() {},
  mounted() {
    this.init();
    this.throttledScrollHandler = throttle(300, this.onScroll);
    this.container.addEventListener("scroll", this.throttledScrollHandler);
  },
  methods: {
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;

        this.getuserData();
      } else {
        this.userData = null;
        this.token = null;
      }
    },
    async getuserData() {
      if (this.token == null) {
      } else {
        const res = await this.axios.get("/basicUser/tokenByUser", {
          params: {
            token: this.token,
          },
        });
        if (res.code == 200) {
          this.userData = res.object;
        } else {
          this.userData = null;
        }
      }
    },
    parentFn(payload) {
      this.message = payload;
    },
    changeActive($event) {
      if($event.target.innerHTML.includes('客服')) {
        this.showTitle2 = !this.showTitle2
      }
      if($event.target.innerHTML.includes('关注')) {
        this.showTitle = !this.showTitle
      }
      $event.currentTarget.className = "hand2";
    },
    removeActive($event) {
      if($event.target.innerHTML.includes('客服')) {
        this.showTitle2 = !this.showTitle2
      }
      if($event.target.innerHTML.includes('关注')) {
        this.showTitle = !this.showTitle
      }
      $event.currentTarget.className = "hand";
    },
    goPage() {
      this.dialogVisible = true;
    },
    goPage2() {
      this.dialogShow = true;
      this.$nextTick(() => {
        document.getElementById("s").addEventListener("keydown", this.handle);
      });
      this.gettoken();
    },
    handle(e) {
      console.log(e);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    //回到最上方
    init() {
      this.container = document;
      this.el = document.documentElement;
      if (this.target) {
        this.el = document.querySelector(this.target);
        if (!this.el) {
          throw new Error(`target is not existed: ${this.target}`);
        }
        this.container = this.el;
      }
    },
    // 提交   目前只显示文本域的html
    onSubmit() {
      if (this.message === "" || this.message === null) {
        this.$message.info("输入不能为空");
        return false;
      } else {
        let msg = {
          hesay: "",
          isay: "",
          nowMinutes: "",
          nowHours: "",
        };
        msg.isay = this.message;
        setTimeout(() => {
          if (msg.isay.includes("你好") || msg.isay.includes("您好")) {
            msg.hesay = "你好，我是益学方策智能助手，您有问题都可以向我提问哦~";
          } else if (msg.isay.includes("龚秋枫")) {
            msg.hesay = "秋枫，我勒烤肠来了哟~";
          } else {
            msg.hesay =
              "我暂时不太懂您的意思呢，我这就去学习，有什么业务问题都可以继续问我哟~";
          }

          this.$nextTick(() => {
            this.$refs.body.scrollTop = this.$refs.body.scrollHeight;
          });
        }, 2000);
        this.nowMinutes = new Date().getMinutes();
        this.nowHours = new Date().getHours();
        if (this.bodyMsg.length > 0) {
          if (
            this.bodyMsg.find((item) => item.nowMinutes === this.nowMinutes)
          ) {
            this.bodyMsg.push(msg);
          } else {
            if (this.nowMinutes < 10) {
              msg.nowMinutes = "0" + this.nowMinutes;
            } else {
              msg.nowMinutes = this.nowMinutes;
            }
            msg.nowHours = this.nowHours;
            this.bodyMsg.push(msg);
          }
        } else {
          if (this.nowMinutes < 10) {
            msg.nowMinutes = "0" + this.nowMinutes;
          } else {
            msg.nowMinutes = this.nowMinutes;
          }
          msg.nowHours = this.nowHours;
          this.bodyMsg.push(msg);
        }
        this.$refs.froalaEditor.clearMsg();
        this.$nextTick(() => {
          this.$refs.body.scrollTop = this.$refs.body.scrollHeight;
        });
      }
    },
    onScroll() {
      const scrollTop = this.el.scrollTop;
      this.visible = scrollTop >= 200; //200为滚动出现时的高度
    },
    handleClick(e) {
      this.scrollToTop();
      this.$emit("click", e);
    },
    scrollToTop() {
      let el = this.el;
      let step = 0;
      let interval = setInterval(() => {
        if (el.scrollTop <= 0) {
          clearInterval(interval);
          return;
        }
        step += 10;
        el.scrollTop -= step;
      }, 20);
    },
  },
};
</script>
<style>
.el-icon-close:hover {
  color: #dab96b !important;
}

.el-dialog__wrapper {
  overflow: hidden;
}
</style>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 28px;
  padding-right: 5px;
}
.hand {
  cursor: pointer;
  padding: 10px;
  margin: 8px 2px 2px 2px;
  background-color: #fff;
  color: #606266;
  font-size: 24px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.hand1 {
  cursor: pointer;
  padding: 10px;
  margin: 8px 2px 2px 2px;
  background-color: #fff;
  color: #606266;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}

.hand1:hover {
  cursor: pointer;
  padding: 10px;
  margin: 8px 2px 2px 2px;
  background: #dab96b;
  color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}

.hand2 {
  cursor: pointer;
  padding: 10px;
  background: #dab96b;
  color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  margin: 8px 2px 2px 2px;
}

.head {
  border: 0.5px solid#a3a1a1;
  height: 40px;
}
.title {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body {
  border: 0.5px solid#a3a1a1;
  height: 400px;
  border-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

::v-deep.el-dialog__wrapper {
  z-index: 10 !important;
}
::v-deep .el-dialog {
  background-color: rgba(255, 255, 255, 0.95);
}
.setFoot {
  border-top: 0;
  height: fit-content;
}

.title {
  font-size: 16px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 0 0;
  height: 45px;
  line-height: 45px;
  vertical-align: middle;
}

.spinner {
  --clr: rgb(0, 113, 128);
  --gap: 6px;
  /* gap between each circle */
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}

.spinner span {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
}

.spinner span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
}

.spinner span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
}

.spinner span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}
.btn {
  height: 4em;
  width: 12em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0px solid black;
  z-index: 9999;
}

.wrapper {
  height: 2em;
  width: 8em;
  position: relative;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  cursor: pointer;
  font-size: 17px;
  z-index: 1;
  color: #000;
  padding: 4px 12px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.7);
  transition: all 0.5s ease;
}

.flower {
  display: grid;
  grid-template-columns: 1em 1em;
  position: absolute;
  transition: grid-template-columns 0.8s ease;
}

.flower1 {
  top: -12px;
  left: -13px;
  transform: rotate(5deg);
}

.flower2 {
  bottom: -5px;
  left: 8px;
  transform: rotate(35deg);
}

.flower3 {
  bottom: -15px;
  transform: rotate(0deg);
}

.flower4 {
  top: -14px;
  transform: rotate(15deg);
}

.flower5 {
  right: 11px;
  top: -3px;
  transform: rotate(25deg);
}

.flower6 {
  right: -15px;
  bottom: -15px;
  transform: rotate(30deg);
}

.petal {
  height: 1em;
  width: 1em;
  border-radius: 40% 70% / 7% 90%;
  background: linear-gradient(#07a6d7, #93e0ee);
  border: 0.5px solid #96d1ec;
  z-index: 0;
  transition: width 0.8s ease, height 0.8s ease;
}

.two {
  transform: rotate(90deg);
}

.three {
  transform: rotate(270deg);
}

.four {
  transform: rotate(180deg);
}

.btn:hover .petal {
  background: linear-gradient(#0761d7, #93bdee);
  border: 0.5px solid #96b4ec;
}

.btn:hover .flower {
  grid-template-columns: 1.5em 1.5em;
}

.btn:hover .flower .petal {
  width: 1.5em;
  height: 1.5em;
}

.btn:hover .text {
  background: rgba(255, 255, 255, 0.4);
}

.btn:hover div.flower1 {
  animation: 15s linear 0s normal none infinite running flower1;
}

@keyframes flower1 {
  0% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(365deg);
  }
}

.btn:hover div.flower2 {
  animation: 13s linear 1s normal none infinite running flower2;
}

@keyframes flower2 {
  0% {
    transform: rotate(35deg);
  }

  100% {
    transform: rotate(-325deg);
  }
}

.btn:hover div.flower3 {
  animation: 16s linear 1s normal none infinite running flower3;
}

@keyframes flower3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn:hover div.flower4 {
  animation: 17s linear 1s normal none infinite running flower4;
}

@keyframes flower4 {
  0% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(375deg);
  }
}

.btn:hover div.flower5 {
  animation: 20s linear 1s normal none infinite running flower5;
}

@keyframes flower5 {
  0% {
    transform: rotate(25deg);
  }

  100% {
    transform: rotate(-335deg);
  }
}

.btn:hover div.flower6 {
  animation: 15s linear 1s normal none infinite running flower6;
}

@keyframes flower6 {
  0% {
    transform: rotate(30deg);
  }

  100% {
    transform: rotate(390deg);
  }
}

.side {
  position: fixed;
  left: 95%;
  top: 60%;
  z-index: 999999;
  height: 100px;
  width: 50px;
  text-align: center;
  font-size: 12px;
}

.imgStyle {
  height: 100%;
  width: 100%;
}

.imgStlye2 {
  width: 200px;
  height: 200px;
}

::v-deep p {
  margin: 0px !important;
}

hr {
  color: #f2f6fc;
}

.sendMsg {
  font-size: 16px;
  width: 99.9%;
  min-height: 10%;
  display: flex;
  justify-content: flex-end;
  padding: 0 5px 0 0;
}

.getMsg {
  font-size: 16px;
  width: 99.9%;
  min-height: 10%;
  display: flex;
  padding: 0 0 0 5px;
}
@media (max-width: 768px) {
  ::v-deep .el-dialog {
    width: 90% !important;
    height: 805;
  }
  .side {
    display: none;
  }
  .head p {
    font-size: 80px;
  }
  .text {
    font-size: 68px !important;
  }
  .head {
    border: 0.5px solid#a3a1a1;
    height: 180px !important;
  }
  .icon {
    width: 3em;
    height: 3em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    font-size: 28px;
    padding-right: 5px;
  }
  .body {
    height: 1000px !important;
    border-top: 0;
  }
  .sendMsg {
    font-size: 48px !important;
  }

  .getMsg {
    font-size: 48px !important;
    width: 99.9%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .side {
    display: none;
  }
  .hand {
    font-size: 32px !important;
    margin: 8px 2px 2px 2px !important;
    width: 40px;
    height: 40px;
  }
  .hand1 {
    font-size: 32px !important;
    margin: 8px 2px 2px 2px !important;
    width: 40px;
    height: 40px;
  }
  .hand2 {
    font-size: 32px !important;
    margin: 8px 2px 2px 2px !important;
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1025px) {
  ::v-deep .el-divider--horizontal {
    height: 1px;
    width: 100%;
    margin: 10px 0;
  }
  .aside {
    width: 260px;
  }
  h2 {
    height: 90px;
    line-height: 90px;
    font-size: 30px;
  }
  .link {
    font-size: 22px;
  }
}
</style>