<template>
  <div style="position: relative">
      <Header></Header>
	  <sideframe></sideframe>
       <div style="min-height:694px" class="index1">
        <breadcrumb :titleData="titleData"></breadcrumb>
	<hr style="min-width:1200px;">
	<el-row style="text-align: left;width:877px;">
		<div v-for="items in humancpmsgs" :key="items.titile">
		<h3  v-if="items.titile !== ''&&items.titile!==undefind">&nbsp;{{items.titile}}</h3>
		<img :key="items.titile+'1'" :src="items.img" v-if="items.img !==''" style="">
		<p>{{items.conent}}</p>
		</div>
        <el-steps   align-center>
          <div v-for="items in humancpmsgs" :key="items.conent1">
  <el-step   :title="items.titile" v-if="items.conent1!==''&&items.conent1!==undefind" :description="items.conent1"></el-step>
          </div>
        </el-steps>
	</el-row>
     </div>
      <flooter></flooter>
  </div>
</template>

<script>
import Sideframe from "../../../components/sideframe";
import Flooter from "../../flooter";
import Header from "../../Header";
import Breadcrumb from "@/components/breadcrumb.vue";
import { nanoid } from "nanoid";
export default {
  components: { Header, Flooter, Sideframe, Breadcrumb },
  data() {
    return {
      humancpmsgs: [],
      titleData: {
        titleName: "组织人力资本化测评",
        data: [
          {
            id: nanoid(),
            name: "人力资本化",
            href: "",
          },
          {
            id: nanoid(),
            name: "组织人力资本化测评",
            href: "",
          },
        ],
      },
    };
  },
  mounted() {
    const humancpmsg = JSON.parse(this.$route.query.humancpmsg);
    this.humancpmsgs = humancpmsg;
    this.titleData.titleName = this.humancpmsgs[0]
    this.titleData.data[1] = this.humancpmsgs[1]
    console.log(this.humancpmsgs);
  }
};
</script>

<style>
.el-step.is-center .el-step__description {
  padding-left: 20%;
  padding-right: 20%;
  margin-top: 30px;
}
</style>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
html,
body {
  height: 100%;
}
.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.index1 {
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
::v-deep .el-step__line {
  position: absolute;
  border-color: inherit;
  background-color: #000 !important;
}
::v-deep .el-step__head.is-wait {
  color: #000 !important;
  border-color: #000 !important;
}
::v-deep .el-step__description.is-wait {
  color: #000 !important;
}
h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}
.background {
  background-color: #f7f7f7;
  height: 100px;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
img {
  max-width: 665px;
  max-height: 667px;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
h3 {
  margin: 0;
  text-align: left;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.875;
}
img {
  display: inline-block;
  margin-top: 20px;
}
p {
  display: inline-block;
  text-indent: 2em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.875;
}
.aside {
  display: inline-block;
  width: 260px;
  position: absolute;
  top: 200px;
}
.el-row {
  display: inline-block;
  margin-right: 30px;
  margin-top: 40px;
  margin-bottom: 100px;
}
.title {
  margin: 0;
  font-size: 28px;
}
::v-deep .el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  float: left;
}
.link {
  cursor: pointer;
  text-decoration: none;
  float: left;
  color: #555555;
  font-weight: lighter;
}
</style>