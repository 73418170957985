<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <loading v-if="loadingisShow" />
    <div class="index1">
      <h1>{{ titlename }}</h1>
      <hr />
      <el-row class="questions-body">
        <template>
          <div>
            <el-steps :active="active" align-center finish-status="success">
              <el-step
                v-if="
                  titlename === '个体人力资本测评' ||
                  titlename === '组织人力资本测评' ||
                  titlename === '培训班个体人力资本测评'
                "
                title="当前人生积累"
                :description="index + 1 + '/' + questiondata1.length"
              ></el-step>
              <el-step
                v-if="
                  titlename === '个体人力资本测评' ||
                  titlename === '组织人力资本测评' ||
                  titlename === '培训班个体人力资本测评'
                "
                title="核心心智能力"
                :description="index1 + 1 + '/' + list.length"
              ></el-step>
              <el-step
                v-if="
                  titlename === '人力资本化提升计划' ||
                  titlename === '组织人力资本化测评' ||
                  titlename === '培训班人力资本化提升计划'
                "
                title="人生自我规划"
                :description="index + 1 + '/' + questiondata1.length"
              ></el-step>
              <el-step
                v-if="
                  titlename === '人力资本化提升计划' ||
                  titlename === '组织人力资本化测评' ||
                  titlename === '培训班人力资本化提升计划'
                "
                title="人力资本投入"
                :description="index2 + 1 + '/' + questiondata2.length"
              ></el-step>
              <el-step
                v-if="
                  titlename === '人力资本化提升计划' ||
                  titlename === '组织人力资本化测评' ||
                  titlename === '培训班人力资本化提升计划'
                "
                title="未来核心心智能力"
                :description="index1 + 1 + '/' + list.length"
              ></el-step>
            </el-steps>
            <div>
              <template
                v-if="
                  (index <= questiondata1.length - 1 &&
                    active == 0 &&
                    titlename === '个体人力资本测评') ||
                  (index <= questiondata1.length - 1 &&
                    active == 0 &&
                    titlename === '组织人力资本测评') ||
                  (index <= questiondata1.length - 1 &&
                    active == 0 &&
                    titlename === '培训班个体人力资本测评')
                "
              >
                <p class="tit1">
                  {{ questiondata1[index].question }}
                  <span v-if="questiondata1[index].doub == 1">(多选)</span>
                </p>
                <div
                  v-if="
                    questiondata1[index].remarks !== '' &&
                    questiondata1[index].remarks !== null &&
                    questiondata1[index].remarks !== undefined
                  "
                  style="width: 100%; height: 20px; margin-top: 0"
                >
                  <p class="tit2">
                    <span
                      style="cursor: pointer"
                      @click="showRemakes = !showRemakes"
                      >提示&nbsp;<i
                        :class="
                          showRemakes
                            ? 'el-icon-arrow-up'
                            : 'el-icon-arrow-down'
                        "
                      ></i
                    ></span>
                  </p>
                  <template
                    v-if="
                      showRemakes && questiondata1[index].remarks.includes('；')
                    "
                  >
                    <p
                      class="content"
                      v-for="(item, index) in questiondata1[
                        index
                      ].remarks.split('；')"
                      :key="index"
                    >
                      {{ item }}<br />
                    </p>
                  </template>
                  <p
                    class="content"
                    v-if="
                      showRemakes &&
                      !questiondata1[index].remarks.includes('；')
                    "
                  >
                    {{ questiondata1[index].remarks }}
                  </p>
                </div>
                <el-radio-group
                  style="text-align: left"
                  @change="onChange(index, questiondata1[index].question)"
                  v-if="questiondata1[index].doub == 0"
                  v-model="form.radioArray1[index]"
                  size="small"
                >
                  <el-radio
                    v-for="(item1, index) in questiondata1[index].test"
                    :key="index"
                    class="lll"
                    :id="item1.scores.toString()"
                    :label="item1.options + ',' + item1.scores"
                    :value="item1.scores"
                    border
                  >
                    {{ item1.options }}
                  </el-radio>
                </el-radio-group>
                <el-checkbox-group
                  @change="nextradio(index, questiondata1[index].question)"
                  v-if="questiondata1[index].doub == 1 && special === 0"
                  v-model="form.radioArray1[index]"
                  size="small"
                  class="checkBoxcss"
                >
                  <el-checkbox
                    v-for="(item1, index) in questiondata1[index].test"
                    :key="index"
                    class="lll"
                    :id="item1.scores.toString()"
                    :label="item1.options + ',' + item1.scores"
                    :value="item1.scores"
                    border
                  >
                    {{ item1.options }}
                  </el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group
                  @change="nextradio(index, questiondata1[index].question)"
                  v-if="questiondata1[index].doub == 1 && special === 1"
                  v-model="form.radioArray1[index]"
                  size="small"
                  class="checkBoxcss"
                >
                  <template v-for="(item1, index) in questiondata1[index].test">
                    <el-checkbox
                      v-if="item1.special === 1"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="item1.special === 0"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      disabled
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                  </template>
                </el-checkbox-group>
                <el-checkbox-group
                  @change="nextradio(index, questiondata1[index].question)"
                  v-if="questiondata1[index].doub == 1 && special === 2"
                  v-model="form.radioArray1[index]"
                  size="small"
                  class="checkBoxcss"
                >
                  <template v-for="(item1, index) in questiondata1[index].test">
                    <el-checkbox
                      v-if="item1.special === 1"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      disabled
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="item1.special === 0"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                  </template>
                </el-checkbox-group>
              </template>
              <template
                v-if="
                  (index <= questiondata1.length - 1 &&
                    active == 0 &&
                    titlename === '人力资本化提升计划') ||
                  (index <= questiondata1.length - 1 &&
                    active == 0 &&
                    titlename === '组织人力资本化测评') ||
                  (index <= questiondata1.length - 1 &&
                    active == 0 &&
                    titlename === '培训班人力资本化提升计划')
                "
              >
                <p class="tit1">
                  {{ questiondata1[index].question }}
                  <span v-if="questiondata1[index].doub == 1">(多选)</span>
                </p>
                <div
                  v-if="
                    questiondata1[index].remarks !== '' &&
                    questiondata1[index].remarks !== null &&
                    questiondata1[index].remarks !== undefined
                  "
                  style="width: 100%; height: 20px; margin-top: 0"
                >
                  <p class="tit2">
                    <span
                      style="cursor: pointer"
                      @click="showRemakes = !showRemakes"
                      >提示&nbsp;<i
                        :class="
                          showRemakes
                            ? 'el-icon-arrow-up'
                            : 'el-icon-arrow-down'
                        "
                      ></i
                    ></span>
                  </p>
                  <template
                    v-if="
                      showRemakes && questiondata1[index].remarks.includes('；')
                    "
                  >
                    <p
                      class="content"
                      v-for="(item, index) in questiondata1[
                        index
                      ].remarks.split('；')"
                      :key="index"
                    >
                      {{ item }}<br />
                    </p>
                  </template>
                  <p
                    class="content"
                    v-if="
                      showRemakes &&
                      !questiondata1[index].remarks.includes('；')
                    "
                  >
                    {{ questiondata1[index].remarks }}
                  </p>
                </div>
                <el-radio-group
                  style="text-align: left"
                  @change="onChange(index, questiondata1[index].question)"
                  v-if="questiondata1[index].doub == 0"
                  v-model="form.radioArray1[index]"
                  size="small"
                >
                  <el-radio
                    v-for="(item1, index) in questiondata1[index].test"
                    :key="index"
                    class="lll"
                    :id="item1.scores.toString()"
                    :label="item1.options + ',' + item1.scores"
                    :value="item1.scores"
                    border
                  >
                    {{ item1.options }}
                  </el-radio>
                </el-radio-group>
                <el-checkbox-group
                  @change="nextradio(index, questiondata1[index].question)"
                  v-if="questiondata1[index].doub == 1 && special === 0"
                  v-model="form.radioArray1[index]"
                  size="small"
                  class="checkBoxcss"
                >
                  <el-checkbox
                    v-for="(item1, index) in questiondata1[index].test"
                    :key="index"
                    class="lll"
                    :id="item1.scores.toString()"
                    :label="item1.options + ',' + item1.scores"
                    :value="item1.scores"
                    border
                  >
                    {{ item1.options }}
                  </el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group
                  @change="nextradio(index, questiondata1[index].question)"
                  v-if="questiondata1[index].doub == 1 && special === 1"
                  v-model="form.radioArray1[index]"
                  size="small"
                  class="checkBoxcss"
                >
                  <template v-for="(item1, index) in questiondata1[index].test">
                    <el-checkbox
                      v-if="item1.special === 1"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="item1.special === 0"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      disabled
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                  </template>
                </el-checkbox-group>
                <el-checkbox-group
                  @change="nextradio(index, questiondata1[index].question)"
                  v-if="questiondata1[index].doub == 1 && special === 2"
                  v-model="form.radioArray1[index]"
                  size="small"
                  class="checkBoxcss"
                >
                  <template v-for="(item1, index) in questiondata1[index].test">
                    <el-checkbox
                      v-if="item1.special === 1"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      disabled
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="item1.special === 0"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                  </template>
                </el-checkbox-group>
              </template>
              <template
                v-if="
                  (index2 <= questiondata2.length - 1 &&
                    active == 1 &&
                    titlename === '人力资本化提升计划') ||
                  (index2 <= questiondata2.length - 1 &&
                    active == 1 &&
                    titlename === '组织人力资本化测评') ||
                  (index2 <= questiondata2.length - 1 &&
                    active == 1 &&
                    titlename === '培训班人力资本化提升计划')
                "
              >
                <p class="tit1">
                  {{ questiondata2[index2].question }}
                </p>
                <div
                  v-if="
                    questiondata2[index2].remarks !== '' &&
                    questiondata2[index2].remarks !== null &&
                    questiondata2[index2].remarks !== undefined
                  "
                  style="width: 100%; height: 20px; margin-top: 0"
                >
                  <p class="tit2">
                    <span
                      style="cursor: pointer"
                      @click="showRemakes = !showRemakes"
                      >提示&nbsp;<i
                        :class="
                          showRemakes
                            ? 'el-icon-arrow-up'
                            : 'el-icon-arrow-down'
                        "
                      ></i
                    ></span>
                  </p>
                  <template
                    v-if="
                      showRemakes &&
                      questiondata2[index2].remarks.includes('；')
                    "
                  >
                    <p
                      class="content"
                      v-for="(item, index) in questiondata2[
                        index2
                      ].remarks.split('；')"
                      :key="index"
                    >
                      {{ item }}<br />
                    </p>
                  </template>
                  <p
                    class="content"
                    v-if="
                      showRemakes &&
                      !questiondata2[index2].remarks.includes('；')
                    "
                  >
                    {{ questiondata2[index2].remarks }}
                  </p>
                </div>
                <el-radio-group
                  style="text-align: left"
                  @change="onChange(index, questiondata2[index2].question)"
                  v-if="questiondata2[index2].doub == 0"
                  v-model="form.radioArray2[index2]"
                  size="small"
                >
                  <el-radio
                    v-for="(item1, index) in questiondata2[index2].test"
                    :key="index"
                    class="lll"
                    :id="item1.scores.toString()"
                    :label="item1.options + ',' + item1.scores"
                    :value="item1.scores"
                    border
                  >
                    {{ item1.options }}
                  </el-radio>
                </el-radio-group>
                <el-checkbox-group
                  @change="nextradio(index, questiondata2[index2].question)"
                  v-if="questiondata2[index2].doub === 1 && special === 0"
                  v-model="form.radioArray2[index2]"
                  size="small"
                  class="checkBoxcss"
                >
                  <el-checkbox
                    v-for="(item1, index) in questiondata2[index2].test"
                    :key="index"
                    class="lll"
                    :id="item1.scores.toString()"
                    :label="item1.options + ',' + item1.scores"
                    :value="item1.scores"
                    border
                  >
                    {{ item1.options }}
                  </el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group
                  @change="nextradio(index, questiondata2[index2].question)"
                  v-if="questiondata2[index2].doub == 1 && special === 1"
                  v-model="form.radioArray2[index2]"
                  size="small"
                  class="checkBoxcss"
                >
                  <template
                    v-for="(item1, index) in questiondata2[index2].test"
                  >
                    <el-checkbox
                      v-if="item1.special === 1"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="item1.special === 0"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      disabled
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                  </template>
                </el-checkbox-group>
                <el-checkbox-group
                  @change="nextradio(index, questiondata2[index2].question)"
                  v-if="questiondata2[index2].doub == 1 && special === 2"
                  v-model="form.radioArray2[index2]"
                  size="small"
                  class="checkBoxcss"
                >
                  <template v-for="(item1, index) in questiondata1[index].test">
                    <el-checkbox
                      v-if="item1.special === 1"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      disabled
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="item1.special === 0"
                      :key="index"
                      class="lll"
                      :id="item1.scores.toString()"
                      :label="item1.options + ',' + item1.scores"
                      :value="item1.scores"
                      border
                    >
                      {{ item1.options }}
                    </el-checkbox>
                  </template>
                </el-checkbox-group>
              </template>
              <template
                v-if="
                  (index >= questiondata1.length - 1 &&
                    active === 1 &&
                    titlename === '个体人力资本测评') ||
                  (index >= questiondata1.length - 1 &&
                    active === 1 &&
                    titlename === '组织人力资本测评') ||
                  (index >= questiondata1.length - 1 &&
                    active === 1 &&
                    titlename === '培训班个体人力资本测评') ||
                  (index2 >= questiondata2.length - 1 && active === 3) ||
                  (active === 2 && titlename === '人力资本化提升计划') ||
                  (active === 2 && titlename === '培训班人力资本化提升计划') ||
                  (active === 2 && titlename === '组织人力资本化测评') ||
                  (active === 2 &&
                    titlename === '个体人力资本测评' &&
                    index1 >= list.length - 1) ||
                  (active === 2 &&
                    titlename === '组织人力资本测评' &&
                    index1 >= list.length - 1) ||
                  (active === 2 &&
                    titlename === '培训班个体人力资本测评' &&
                    index1 >= list.length - 1)
                "
              >
                <p
                  v-if="
                    titlename === '个体人力资本测评' ||
                    titlename === '组织人力资本测评' ||
                    titlename === '培训班个体人力资本测评'
                  "
                  class="tit1"
                >
                  {{ list[index1].question }}
                </p>
                <div
                  v-if="
                    list[index1].remarks !== '' &&
                    list[index1].remarks !== null &&
                    list[index1].remarks !== undefined &&
                    (titlename === '个体人力资本测评' ||
                      titlename === '组织人力资本测评' ||
                      titlename === '培训班个体人力资本测评')
                  "
                  style="width: 100%; height: 20px; margin-top: 0"
                >
                  <p class="tit2">
                    <span
                      style="cursor: pointer"
                      @click="showRemakes = !showRemakes"
                      >提示&nbsp;<i
                        :class="
                          showRemakes
                            ? 'el-icon-arrow-up'
                            : 'el-icon-arrow-down'
                        "
                      ></i
                    ></span>
                  </p>
                  <template
                    v-if="showRemakes && list[index1].remarks.includes('；')"
                  >
                    <p
                      class="content"
                      v-show="
                        titlename === '个体人力资本测评' ||
                        titlename === '组织人力资本测评' ||
                        titlename === '培训班个体人力资本测评'
                      "
                      v-for="(item, index) in list[index1].remarks.split('；')"
                      :key="index"
                    >
                      {{ item }}<br />
                    </p>
                  </template>
                  <p
                    class="content"
                    v-show="
                      titlename === '个体人力资本测评' ||
                      titlename === '组织人力资本测评' ||
                      titlename === '培训班个体人力资本测评'
                    "
                    v-if="showRemakes && !list[index1].remarks.includes('；')"
                  >
                    {{ list[index1].remarks }}
                  </p>
                </div>
                <el-radio-group
                  v-if="
                    titlename === '个体人力资本测评' ||
                    titlename === '组织人力资本测评' ||
                    titlename === '培训班个体人力资本测评'
                  "
                  style="text-align: left"
                  @change="onChange(index1, list[index1].question)"
                  v-model="form.radioArray[index1]"
                >
                  <el-radio
                    v-for="item1 in list[index1].test"
                    :key="item1.scores + 1"
                    class="lll"
                    :id="item1.scores.toString()"
                    :label="item1.options + ',' + item1.scores"
                    :value="item1.scores"
                    border
                  >
                    {{ item1.options }}
                  </el-radio>
                </el-radio-group>
                <p
                  v-if="
                    titlename === '人力资本化提升计划' ||
                    titlename === '培训班人力资本化提升计划' ||
                    titlename === '组织人力资本化测评'
                  "
                  class="tit1"
                >
                  {{ list[index1].question }}
                </p>
                <div
                  v-if="
                    list[index1].remarks !== '' &&
                    list[index1].remarks !== null &&
                    list[index1].remarks !== undefined &&
                    (titlename === '人力资本化提升计划' ||
                      titlename === '培训班人力资本化提升计划' ||
                      titlename === '组织人力资本化测评')
                  "
                  style="width: 100%; height: 20px; margin-top: 0"
                >
                  <p class="tit2">
                    <span
                      style="cursor: pointer"
                      @click="showRemakes = !showRemakes"
                      >提示&nbsp;<i
                        :class="
                          showRemakes
                            ? 'el-icon-arrow-up'
                            : 'el-icon-arrow-down'
                        "
                      ></i
                    ></span>
                  </p>
                  <template
                    v-if="showRemakes && list[index1].remarks.includes('；')"
                  >
                    <p
                      class="content"
                      v-show="
                        titlename === '人力资本化提升计划' ||
                        titlename === '培训班人力资本化提升计划' ||
                        titlename === '组织人力资本化测评'
                      "
                      v-for="(item, index) in list[index1].remarks.split('；')"
                      :key="index"
                    >
                      {{ item }}<br />
                    </p>
                  </template>
                  <p
                    v-show="
                      titlename === '人力资本化提升计划' ||
                      titlename === '培训班人力资本化提升计划' ||
                      titlename === '组织人力资本化测评'
                    "
                    class="content"
                    v-if="showRemakes && !list[index1].remarks.includes('；')"
                  >
                    {{ list[index1].remarks }}
                  </p>
                </div>

                <el-input-number
                  v-if="
                    titlename === '人力资本化提升计划' ||
                    titlename === '培训班人力资本化提升计划' ||
                    titlename === '组织人力资本化测评'
                  "
                  v-model="form.radioArray[index1]"
                  controls-position="right"
                  :precision="2"
                  :step="0.1"
                  :min="Number(this.list[index1].scores)"
                  :max="100"
                  @input="getNumber"
                ></el-input-number>
                <p
                  v-if="
                    titlename === '人力资本化提升计划' ||
                    (index1 === form.radioArray.length - 1 &&
                      titlename === '组织人力资本化测评') ||
                    titlename === '培训班人力资本化提升计划'
                  "
                  class="prompt"
                >
                  最高填写分数为100分,填写的值超过100分将自动填写为100分
                </p>
              </template>
            </div>
            <el-divider></el-divider>
            <el-button
              class="style-but"
              @click="previous1"
              v-if="
                active === 2 &&
                this.index1 === 0 &&
                index2 === questiondata2.length - 1
              "
              :disabled="index === 0"
              round
              >上一题</el-button
            >
            <el-button
              class="style-but"
              @click="previous2"
              v-if="
                active === 1 &&
                this.index === questiondata1.length - 1 &&
                index2 === 0 &&
                falg !== false
              "
              :disabled="index === 0"
              round
              >上一题</el-button
            >
            <el-button
              class="style-but"
              @click="previous"
              :disabled="index === 0"
              round
              v-if="falg === false"
              >上一题</el-button
            >
            <el-button
              class="style-but"
              @click="next"
              v-if="
                (active != 2 && titlename === '个体人力资本测评') ||
                (active != 2 && titlename === '组织人力资本测评') ||
                (active != 2 && titlename === '培训班个体人力资本测评')
              "
              round
              >下一题</el-button
            >
            <el-button
              class="style-but"
              @click="next"
              v-if="
                (active != 3 && titlename === '人力资本化提升计划') ||
                (active != 3 && titlename === '组织人力资本化测评') ||
                (active != 3 && titlename === '培训班人力资本化提升计划')
              "
              round
              >下一题</el-button
            >
            <el-button
              @click="submit"
              type="success"
              class="style-but"
              v-if="
                (active == 2 &&
                  form.radioArray[index1] !== null &&
                  titlename === '个体人力资本测评') ||
                (active == 2 &&
                  form.radioArray[index1] !== null &&
                  titlename === '组织人力资本测评') ||
                (active == 2 &&
                  form.radioArray[index1] !== null &&
                  titlename === '培训班个体人力资本测评')
              "
              round
              >提交</el-button
            >
            <el-button
              @click="submit"
              type="success"
              class="style-but"
              v-if="
                (index1 === form.radioArray.length - 1 &&
                  titlename === '人力资本化提升计划') ||
                (index1 === form.radioArray.length - 1 &&
                  titlename === '组织人力资本化测评') ||
                (index1 === form.radioArray.length - 1 &&
                  titlename === '培训班人力资本化提升计划')
              "
              round
              >提交</el-button
            >
          </div>
        </template>
      </el-row>
    </div>
    <flooter></flooter>
  </div>
</template>


<script>
import Sideframe from "@/components/sideframe.vue";
import flooter from "../../flooter.vue";
import Header from "../../Header.vue";
import Cookies from "js-cookie";
import { setTimeout } from "timers";
import loading from "@/components/loading.vue";
const myMap = new Map();
export default {
  components: { Header, flooter, Sideframe, loading },
  name: "",
  data() {
    return {
      falg: false,
      doub: 0,
      loadingisShow: false,
      active: 0,
      CPentity: {
        basicUserEntity: {
          userId: "",
          username: "",
          enterpriseName: "",
          department: "",
        },
        paperEntity: {},
        timeList: [],
        idList: [],
        list: [],
      },
      form: {
        //单选部分渲染数据
        radioArray: [],
        radioArray1: [],
        radioArray2: [],
      },
      value: "十分准确,5",
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      showRemakes: true,
      index: 0,
      index1: 0,
      index2: 0,
      special: 0,
      typeId: [],
      list: [], //题库
      riskId: [], //选项id
      optionScoreStr: [], //选项分值
      questiondata1: [],
      questiondata2: [],
      paperEntity: {},
      titlename: "",
      startTime: "",
      endTime: "",
      startTime1: "",
      endTime1: "",
      questionTime: "",
      questionTime1: "",
      cacheData: {
        cachePaperId: 0,
        id: null,
        jsonOption: "",
      },
    };
  },
  props: ["pid"],
  mounted() {},
  created() {
    this.startTime = new Date().getTime();
    this.titlename = window.localStorage.getItem("title");
    this.typeId = window.localStorage.getItem("typeId").split(",");
    this.gettoken();
    this.getuserData();
    if (this.titlename === "个体人力资本测评") {
      this.paperEntity = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).paperEntity;
      
      var listdata = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).returnQuestionList;
      this.handCheck(listdata);
    } else if (this.titlename === "人力资本化提升计划") {
      this.paperEntity = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).paperEntity;
      var listdata = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).returnQuestionList;
      this.getTest(listdata);
    } else if (this.titlename === "组织人力资本测评") {
      this.paperEntity = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).paperEntity;
      var listdata = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).returnQuestionList;
      this.handCheck(listdata);
    } else if (this.titlename === "组织人力资本化测评") {
      this.paperEntity = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).paperEntity;
      
      var listdata = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).returnQuestionList;
      this.getTest(listdata);
    } else if (this.titlename === "培训班个体人力资本测评") {
      this.paperEntity = this.$store.state.questionData.list[0].paperEntity;
      this.paperEntity.trainingId = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).trainingId;
      var listdata = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).returnQuestionList;
      this.handCheck(listdata);
    } else if (this.titlename === "培训班人力资本化提升计划") {
      this.paperEntity = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).paperEntity;
      this.paperEntity.trainingId = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).trainingId;
      var listdata = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).returnQuestionList;
      this.getTest(listdata);
    }
  },
  watch: {
    //侦听器本质上是一个函数，要监听哪个数据的变化，就把数据作为方法名即可
    //新值在前,旧值在后
    active(newStr, OldStr) {
      if (newStr == 1 && OldStr == 0) {
        this.endTime = new Date().getTime();
        const staytimeGap = this.endTime - this.startTime;
        let leave1 = staytimeGap % (3600 * 1000);
        let stayMin = Math.floor(leave1 / (60 * 1000)); // 分钟
        let leave2 = leave1 % (60 * 1000);
        let staySec = Math.floor(leave2 / 1000); // 秒
        this.questionTime = stayMin + "分钟" + staySec + "秒";
        this.startTime1 = new Date().getTime();
      } else if (newStr == 2 && OldStr == 1) {
        this.endTime = new Date().getTime();
        const staytimeGap = this.endTime - this.startTime1;
        let leave1 = staytimeGap % (3600 * 1000);
        let stayMin = Math.floor(leave1 / (60 * 1000)); // 分钟
        let leave2 = leave1 % (60 * 1000);
        let staySec = Math.floor(leave2 / 1000); // 秒
        this.questionTime2 = stayMin + "分钟" + staySec + "秒";
        this.startTime2 = new Date().getTime();
      }
    },
  },
  methods: {
    //获取输入的值
    getNumber(e) {},
    async getuserData() {
      const res = await this.axios.get("/basicUser/tokenByUser", {
        params: {
          token: this.token,
        },
      });
      if (res.code == 200) {
        this.CPentity.basicUserEntity = res.object;
      } else {
        this.userData = null;
        // this.$message({
        // 	showClose: true,
        // 	message: '请求错误，请稍后重试！',
        // 	type: 'error'
        // 	});
      }
    },
    next() {
      this.showRemakes = true;
      if (Array.isArray(this.form.radioArray1[this.index + 1])) {
        if (this.form.radioArray1[this.index + 1].length === 0) {
          this.special = 0;
        } else {
          this.form.radioArray1[this.index + 1].forEach((it) => {
            if (it.includes("啥也不干，享受天伦之乐")) {
              this.special = 1;
            } else if (it.split(",")[0] === "健康") {
              this.special = 1;
            } else if (it.includes("正常作息和饮食")) {
              this.special = 1;
            } else {
              this.special = 2;
            }
          });
        }
      }
      if (
        this.titlename === "个体人力资本测评" ||
        this.titlename === "组织人力资本测评" ||
        this.titlename === "培训班个体人力资本测评"
      ) {
        if (this.index <= this.questiondata1.length - 1) {
          if (this.form.radioArray1[this.index].length == 0) {
            this.$message.warning("本题还未作答");
          } else {
            this.index++;
          }
        }
        if (this.active == 1 && this.index1 < this.list.length - 1) {
          if (this.form.radioArray[this.index1] == null) {
            this.$message.warning("本题还未作答");
          } else {
            this.index1++;
            this.falg = false;
          }
        }
        if (this.index > this.questiondata1.length - 1) {
          if (this.active !== 1) {
            this.active = 1;
            this.falg = true;
          }
          this.index = this.questiondata1.length - 1;
        }
        if (this.index < this.questiondata1.length - 1) {
          this.active = 0;
        }
        if (this.index1 >= this.list.length - 1) {
          this.active = 2;
        }
      } else if (
        this.titlename === "人力资本化提升计划" ||
        this.titlename === "培训班人力资本化提升计划" ||
        this.titlename === "组织人力资本化测评"
      ) {
        if (this.index <= this.questiondata1.length - 1) {
          if (this.form.radioArray1[this.index].length == 0) {
            this.$message.warning("本题还未作答");
          } else {
            this.index++;
          }
        }
        if (this.active == 1 && this.index2 <= this.questiondata2.length - 1) {
          if (this.form.radioArray2[this.index2].length == 0) {
            this.falg = false;
            this.$message.warning("本题还未作答");
            return false;
          } else {
            this.index2++;
            this.falg = false;
            this.index = this.questiondata1.length - 1;
          }
        }
        if (this.active == 2 && this.index1 < this.list.length - 1) {
          if (this.form.radioArray[this.index1] == null) {
            this.falg = false;
            this.$message.warning("本题还未作答");
            return false;
          } else {
            this.index1++;
            this.falg = false;
            this.index = this.questiondata1.length - 1;
          }
        }
        if (this.index > this.questiondata1.length - 1) {
          this.active = 1;
          this.falg = true;
          this.index = this.questiondata1.length - 1;
        }
        if (this.index2 > this.questiondata2.length - 1) {
          this.active = 2;
          this.falg = true;
          this.index2 = this.questiondata2.length - 1;
          this.index = this.questiondata1.length - 1;
        }
        if (this.index < this.questiondata1.length - 1) {
          this.active = 0;
        }
        if (this.index2 > 0) {
          if (this.index2 < this.questiondata2.length - 1) {
            this.active = 1;
          }
        }
        if (this.form.radioArray.length - 1 === this.index1) {
          this.active = 3;
        }
      }
    },
    previous1() {
      this.active = 1;
      this.falg = false;
    },
    previous2() {
      if (this.active !== 0) this.active = 0;
      this.falg = false;
    },
    previous() {
      this.showRemakes = true;
      if (Array.isArray(this.form.radioArray1[this.index - 1])) {
        this.form.radioArray1[this.index - 1].forEach((it) => {
          if (it.includes("啥也不干，享受天伦之乐")) {
            this.special = 1;
          } else if (it.split(",")[0] === "健康") {
            this.special = 1;
          } else if (it.includes("正常作息和饮食")) {
            this.special = 1;
          } else {
            this.special = 2;
          }
        });
      }
      if (
        this.titlename === "个体人力资本测评" ||
        this.titlename === "组织人力资本测评" ||
        this.titlename === "培训班个体人力资本测评"
      ) {
        if (this.index >= this.questiondata1.length - 1 && this.index1 > 0) {
          this.active = 1;
        } else if (
          this.index == this.questiondata1.length - 1 &&
          this.active != 0 &&
          this.index1 == 0
        ) {
          this.previous2();
          return;
        } else {
          this.active = 0;
        }

        if (this.index1 == 0) {
          console.log(this.active);
          if (this.index == 0) {
            uni.showToast({
              title: "已经是第一题了",
              icon: "none",
            });
          } else {
            this.index--;
          }
        } else {
          this.index1--;
        }
      } else if (
        this.titlename === "人力资本化提升计划" ||
        this.titlename === "培训班人力资本化提升计划" ||
        this.titlename === "组织人力资本化测评"
      ) {
        if (this.index2 === 0) {
          if (this.index === 0) {
            this.$message.warning("已经是第一题了!");
          } else {
            this.index--;
          }
        } else if (
          this.index1 !== 0 &&
          this.index2 === this.questiondata2.length - 1
        ) {
          this.index1--;
          if (this.index1 === 0) {
            this.falg = true;
          }
        } else if (
          this.index2 !== 0 &&
          this.index === this.questiondata1.length - 1
        ) {
          this.index2--;
          if (this.index2 === 0) {
            this.falg = true;
          }
        }
        if (
          (this.index2 === this.questiondata2.length - 1 &&
            this.index1 >= 0 &&
            this.active === 2) ||
          this.active === 3
        ) {
          this.active = 2;
        } else if (
          this.index === this.questiondata1.length - 1 &&
          this.index2 <= this.questiondata2.length - 1
        ) {
          this.active = 1;
        } else {
          this.active = 0;
        }
      }
    },
    nextradio(e, value) {
      let optionList = [];
      this.cacheData.cachePaperId = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).cachePaperId;
      if (
        this.titlename === "个体人力资本测评" ||
        this.titlename === "组织人力资本测评" ||
        this.titlename === "培训班个体人力资本测评"
      ) {
        optionList.push(this.form.radioArray1);
        optionList.push(this.form.radioArray);
        this.cacheData.jsonOption = JSON.stringify(optionList);
        this.axios
          .post("/cacheOption/addCacheOption", this.cacheData)
          .then((res) => {});
      } else {
        optionList.push(this.form.radioArray1);
        optionList.push(this.form.radioArray2);
        optionList.push(this.form.radioArray);
        this.cacheData.jsonOption = JSON.stringify(optionList);
        this.axios
          .post("/cacheOption/addCacheOption", this.cacheData)
          .then((res) => {
            console.log(res);
          });
      }
      if (this.form.radioArray1[this.index].length !== 0) {
        this.form.radioArray1[this.index].forEach((it) => {
          if (it.includes("啥也不干，享受天伦之乐")) {
            this.special = 1;
          } else if (it.split(",")[0] === "健康") {
            this.special = 1;
          } else if (it.includes("正常作息和饮食")) {
            this.special = 1;
          } else {
            this.special = 2;
          }
        });
      } else if (this.form.radioArray1[this.index].length === 0) {
        this.special = 0;
      }
      myMap.set(value, e);
      this.doub = 1;
    },
    onChange(e, value) {
      let optionList = [];
      this.cacheData.cachePaperId = JSON.parse(
        window.localStorage.getItem("questionlist")
      ).cachePaperId;
      if (
        this.titlename === "个体人力资本测评" ||
        this.titlename === "组织人力资本测评" ||
        this.titlename === "培训班个体人力资本测评"
      ) {
        optionList.push(this.form.radioArray1);
        optionList.push(this.form.radioArray);
        this.cacheData.jsonOption = JSON.stringify(optionList);
        this.axios
          .post("/cacheOption/addCacheOption", this.cacheData)
          .then((res) => {});
      } else {
        optionList.push(this.form.radioArray1);
        optionList.push(this.form.radioArray2);
        optionList.push(this.form.radioArray);
        this.cacheData.jsonOption = JSON.stringify(optionList);
        this.axios
          .post("/cacheOption/addCacheOption", this.cacheData)
          .then((res) => {});
      }

      myMap.set(value, e);
      if (
        (this.form.radioArray[this.form.radioArray.length - 1] !== null &&
          this.titlename === "人力资本化提升计划") ||
        (this.form.radioArray[this.form.radioArray.length - 1] !== null &&
          this.titlename === "培训班人力资本化提升计划") ||
        (this.form.radioArray[this.form.radioArray.length - 1] !== null &&
          this.titlename === "组织人力资本化测评")
      ) {
        this.active = 3;
      }
    },
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.$router.replace({
          path: "/login",
        });
      }
    },
    async getTest(val) {
      const aaa = [];
      const bbb = [];
      const ccc = [];

      this.questiondata1 = val[0];
      this.list = val[2];
      this.questiondata2 = val[1];

      if (
        JSON.parse(window.localStorage.getItem("questionlist")).optionJson !==
        null
      ) {
        this.list.forEach((item, index) => {
          //循环时判断如果aaa的长度等于index加1.代表循环到的这一项被选中，就不动，如果不等于，代表没有选择，给null
          if (aaa.length !== index + 1) {
            aaa.push(null);
          }
        });
        this.form.radioArray = aaa;
        this.list.forEach((item) => {
          if (item !== null) {
            this.active = 2;
            if (item.scores === "100.0") {
              if (this.index1 !== this.form.radioArray.length - 1) {
                this.index1++;
              } else {
                this.index1 = this.form.radioArray.length - 1;
                this.active = 3;
              }
            }
          }
        });
        this.form.radioArray1 = JSON.parse(
          JSON.parse(window.localStorage.getItem("questionlist")).optionJson
        )[0];
        this.form.radioArray1.forEach((item) => {
          if (item.length > 0) {
            this.active = 0;
            if (this.index !== this.form.radioArray1.length - 1) {
              this.index++;
            } else {
              this.index = this.form.radioArray1.length - 1;
              this.active = 1;
            }
          }
        });
        this.form.radioArray2 = JSON.parse(
          JSON.parse(window.localStorage.getItem("questionlist")).optionJson
        )[1];
        this.form.radioArray2.forEach((item) => {
          if (item.length > 0) {
            this.active = 1;
            if (this.index2 !== this.form.radioArray2.length - 1) {
              this.index2++;
            } else {
              this.index2 = this.form.radioArray2.length - 1;
              this.active = 2;
            }
          }
        });
      } else {
        //循环每一项
        this.list.forEach((item, index) => {
          //循环时判断如果aaa的长度等于index加1.代表循环到的这一项被选中，就不动，如果不等于，代表没有选择，给null
          if (aaa.length !== index + 1) {
            aaa.push(null);
          }
        });
        this.questiondata1.forEach((item, index) => {
          if (bbb.length !== index + 1) {
            bbb.push([]);
          }
        });
        this.questiondata2.forEach((item, index) => {
          if (ccc.length !== index + 1) {
            ccc.push([]);
          }
        });
        //过滤一遍后给到form.radioArray
        this.form.radioArray = aaa;
        this.form.radioArray1 = bbb;
        this.form.radioArray2 = ccc;
      }
    },
    handCheck(val) {
      const aaa = [];
      const bbb = [];
      this.questiondata1 = val[0];
      this.list = val[1];
      if (
        JSON.parse(window.localStorage.getItem("questionlist")).optionJson !==
        null
      ) {
        this.form.radioArray1 = JSON.parse(
          JSON.parse(window.localStorage.getItem("questionlist")).optionJson
        )[0];
        this.form.radioArray1.forEach((item) => {
          if (item.length > 0) {
            this.active = 0;
            if (this.index !== this.form.radioArray1.length - 1) {
              this.index++;
            } else {
              this.index = this.form.radioArray1.length - 1;
              this.active = 1;
            }
          }
        });
        this.form.radioArray = JSON.parse(
          JSON.parse(window.localStorage.getItem("questionlist")).optionJson
        )[1];
        this.form.radioArray.forEach((item) => {
          if (item !== null) {
            this.active = 1;
            if (this.index1 !== this.form.radioArray.length) {
              this.index1++;
            } 
          }
        });
        if(this.index1 == this.form.radioArray.length) {
          this.index1 = 249
        }
        console.log(this.index1);
      } else {
        //循环每一项
        this.list.forEach((item, index) => {
          if (aaa.length !== index + 1) {
            aaa.push(null);
          }
        });
        this.questiondata1.forEach((item, index) => {
          if (bbb.length !== index + 1) {
            bbb.push([]);
          }
        });
        //过滤一遍后给到form.radioArray
        this.form.radioArray = aaa;
        this.form.radioArray1 = bbb;
      }
      //测试使用
      // this.form.radioArray.forEach((item, index) => {
      //   this.form.radioArray[index] = "十分准确,5";
      // });
    },
    //提交测评
    async submit() {
      this.$message({
        message: "提交成功,正在生产报告请稍等",
        type: "success",
      });
      setTimeout(() => {
        this.loadingisShow = true;
      }, 2000);
      if (
        this.titlename === "个体人力资本测评" ||
        this.titlename === "组织人力资本测评" ||
        this.titlename === "培训班个体人力资本测评"
      ) {
        this.endTime1 = new Date().getTime();
        const staytimeGap = this.endTime1 - this.startTime1;
        let leave1 = staytimeGap % (3600 * 1000);
        let stayMin = Math.floor(leave1 / (60 * 1000)); // 分钟
        let leave2 = leave1 % (60 * 1000);
        let staySec = Math.floor(leave2 / 1000); // 秒
        this.questionTime1 = stayMin + "分钟" + staySec + "秒";
        for (const [key, value] of myMap.entries()) {
          this.riskId.push(key);
        }
        let sum = 0;
        const quedata1 = [];
        const quesocr1 = [];
        const quelsit1 = [];
        const quelsit2 = [];
        const quelsit3 = [];
        const quelsit4 = [];
        const quelsit5 = [];
        const quelsit6 = [];
        const quedata = [];
        const quesocr = [];
        const data = [];
        let a1;
        let a2;
        let a3;
        //获取两个类型试题的题目
        this.list.forEach((item) => {
          quedata.push(item.question);
        });
        this.questiondata1.forEach((item) => {
          quedata1.push(item.question);
        });
        // data.push(this.titlename)
        data.push(quedata1);
        data.push(this.form.radioArray1);
        data.push(quedata);
        data.push(this.form.radioArray);
        this.CPentity.list = data;
        this.CPentity.timeList.push(this.questionTime);
        this.CPentity.timeList.push(this.questionTime1);
        this.CPentity.idList = this.typeId;
        this.CPentity.paperEntity = this.paperEntity;
        window.localStorage.setItem("questionTest", JSON.stringify(data));
        const res = await this.axios.post(
          "/EvaluationQuestion/calculateScore",
          this.CPentity
        );
        if (res) {
          window.localStorage.setItem(
            "questionResult",
            JSON.stringify(res.object)
          );
          window.localStorage.setItem(
            "questionlength",
            this.list.length - 0 + (this.questiondata1.length - 0)
          );
          window.localStorage.setItem("questionTime1", this.questionTime1);
          window.localStorage.setItem("questionTime", this.questionTime);
          this.$router.replace({
            name: "result",
          });
          this.loadingisShow = false;
        }
      } else if (
        this.titlename === "人力资本化提升计划" ||
        this.titlename === "培训班人力资本化提升计划" ||
        this.titlename === "组织人力资本化测评"
      ) {
        if (
          (this.index1 === this.list.length - 1 &&
            this.form.radioArray[this.index1] === null) ||
          this.form.radioArray[this.index1] === ""
        ) {
          this.$message.warning("请完成本题后再提交");
        } else {
          this.endTime1 = new Date().getTime();
          const staytimeGap = this.endTime1 - this.startTime2;
          let leave1 = staytimeGap % (3600 * 1000);
          let stayMin = Math.floor(leave1 / (60 * 1000)); // 分钟
          let leave2 = leave1 % (60 * 1000);
          let staySec = Math.floor(leave2 / 1000); // 秒
          this.questionTime1 = stayMin + "分钟" + staySec + "秒";
          for (const [key, value] of myMap.entries()) {
            this.riskId.push(key);
          }
          let sum = 0;
          const quedata1 = [];
          const quedata2 = [];
          const quesocr1 = [];
          const quelsit1 = [];
          const quelsit2 = [];
          const quelsit3 = [];
          const quelsit4 = [];
          const quelsit5 = [];
          const quelsit6 = [];
          const quedata = [];
          const quesocr = [];
          const data = [];
          let a1;
          let a2;
          let a3;
          //获取两个类型试题的题目
          this.list.forEach((item) => {
            quedata.push(item.question);
          });
          this.questiondata1.forEach((item) => {
            quedata1.push(item.question);
          });
          this.questiondata2.forEach((item) => {
            quedata2.push(item.question);
          });
          this.form.radioArray.forEach((it, index) => {
            quesocr.push(this.list[index].scores + "," + it);
          });
          data.push(quedata1);
          data.push(this.form.radioArray1);
          data.push(quedata2);
          data.push(this.form.radioArray2);
          data.push(quedata);
          data.push(quesocr);
          this.CPentity.list = data;
          this.CPentity.timeList.push(this.questionTime);
          this.CPentity.timeList.push(this.questionTime2);
          this.CPentity.timeList.push(this.questionTime1);
          this.CPentity.idList = this.typeId;
          this.CPentity.paperEntity = this.paperEntity;
          window.localStorage.setItem("questionTest", JSON.stringify(data));
          const res = await this.axios.post(
            "/EvaluationQuestion/promoteScore",
            this.CPentity
          );
          if (res.code === 200) {
            window.localStorage.setItem(
              "questionResult",
              JSON.stringify(res.object)
            );
            window.localStorage.setItem(
              "questionlength",
              Number(this.list.length) +
                Number(this.questiondata1.length) +
                Number(this.questiondata2.length)
            );
            window.localStorage.setItem("questionTime1", this.questionTime1);
            window.localStorage.setItem("questionTime2", this.questionTime2);
            window.localStorage.setItem("questionTime", this.questionTime);
            this.$router.replace({
              name: "result",
              params: {
                data: JSON.stringify(res.object),
                questionlength:
                  Number(this.list.length) +
                  Number(this.questiondata1.length) +
                  Number(this.questiondata2.length),
                questionTime: this.questionTime,
                questionTime1: this.questionTime1,
                questionTime2: this.questionTime2,
              },
            });
            this.loadingisShow = false;
          } else {
            this.$message.warning("服务器内部错误，请骚后重试！");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.questions-body {
  width: 70%;
  margin: auto;
}
::v-deep.el-step__title.is-process {
  /* font-weight: 700; */
  color: #303133;
}
::v-deep .el-radio__input {
  display: none !important;
}
::v-deep .el-radio__input:hover {
  color: rgb(218, 185, 107) !important;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(255, 255, 255);
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(255, 255, 255);
  background: #10d4d0;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgb(255, 255, 255);
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: rgb(255, 255, 255);
  background: #10d4d0;
}
::v-deep .el-button:focus,
::v-deep .el-button:hover {
  color: rgb(218, 185, 107);
  border-color: rgb(218, 185, 107, 0.8);
  background-color: rgb(218, 185, 107, 0.2);
}
::v-deep .el-radio-group {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: fit-content;
}
::v-deep .el-radio__label {
  font-size: 24px !important;
}
::v-deep .el-checkbox__label {
  font-size: 24px !important;
  line-height: 35px !important;
  margin: auto;
}
::v-deep .el-radio.is-bordered {
  padding: 0 !important;
}
::v-deep .el-radio--small.is-bordered {
  padding: 0 !important;
}
.style-but {
  margin: 12px 5px 12px 0;
  padding: 10px 50px 10px 50px;
}
::v-deep .el-step__icon.is-text {
  text-align: left;
}
.checkBoxcss {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: fit-content;
}
.lll:hover {
  text-shadow: 2px 4px 1px #9e30a9, 2px 2px 1px #4090b5,
    0 0 20px rgba(255, 255, 255, 0.616);
  color: rgb(255, 255, 255);
  animation: colorchange 0.3s ease;
  background-image: linear-gradient(
    -225deg,
    #77ffd2 0%,
    #6297db 48%,
    #1eecff 100%
  );
  border: 0;
}
.lll {
  min-width: 590px;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-style: italic;
  font-weight: bold;
  background: transparent;
  position: relative;
  overflow: hidden;
  height: 60px !important;
  line-height: 60px !important;
  gap: 2em;
  transition: all 2s ease-in-out;
  -webkit-clip-path: polygon(
    6% 0,
    93% 0,
    100% 8%,
    100% 86%,
    90% 89%,
    88% 100%,
    5% 100%,
    0% 85%
  );
  clip-path: polygon(
    6% 0,
    93% 0,
    100% 8%,
    100% 86%,
    90% 89%,
    88% 100%,
    5% 100%,
    0% 85%
  );
  margin: 10px;
  color: rgb(255, 255, 255);
  background-image: linear-gradient(to right, #0acffe 0%, #495aff 100%);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
::v-deep .el-radio.is-bordered.is-checked {
  background-image: linear-gradient(to right, #00dbde 0%, #fc00ff 100%);
  text-shadow: 2px 4px 1px #9e30a9, 2px 2px 1px #4090b5,
    0 0 20px rgba(255, 255, 255, 0.616);
  color: rgb(255, 255, 255);
  animation: colorchange 0.3s ease;
  border: 0;
}
::v-deep .el-checkbox.is-bordered.is-checked {
  background-image: linear-gradient(to right, #00dbde 0%, #fc00ff 100%);
  text-shadow: 2px 4px 1px #9e30a9, 2px 2px 1px #4090b5,
    0 0 20px rgba(255, 255, 255, 0.616);
  color: rgb(255, 255, 255);
  animation: colorchange 0.3s ease;
  border: 0;
}
.tit1 {
  margin: 6% auto 2% auto;
  width: 100%;
  font-size: 38px;
  height: auto;
  line-height: 50px;
  padding: 0px 10px 0px 10px;
  text-align: left;
  border-bottom: 10px solid #0acffe;
}
.tit2 {
  padding: 0;
  margin: 0 0 10px 0;
  width: 100%;
  color: red;
  font-size: 18px;
}
.content {
  padding: 0;
  margin: 0 0 10px 0;
  width: 100%;
  font-size: 18px;
  float: left;
}

.prompt {
  color: red;
  width: 100%;
  margin: 10px;
  text-align: center;
  padding: 0;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
hr {
  margin: 0;
  margin-bottom: 8px;
}

p {
  width: 500px;
  padding-left: 55px;
  text-align: left;
  margin-top: 160px;
  line-height: 30px;
  font-size: 16px;
}
@media (max-width: 768px) {
  hr {
    margin: 0;
    margin-bottom: 80px;
  }
  .questions-body {
    width: 90%;
  }
  .tit1 {
    font-size: 88px;
    line-height: 140px;
    height: auto;
    border-bottom: 20px solid #0acffe;
  }
  .tit2 {
    font-size: 58px;
  }
  .lll {
    padding: 0.5em 5em;
    min-width: 820px;
    height: 125px !important;
    line-height: 125px !important;
    gap: 2em;
  }
  ::v-deep .el-input__inner {
    height: 200px;
    width: 460px;
    font-size: 68px !important;
    border-radius: 5%;
  }
  ::v-deep .el-input-number.is-controls-right .el-input__inner {
    padding-right: 110px;
  }
  ::v-deep .el-input-number {
    width: 450px;
    height: 200px;
    line-height: 200px;
  }
  ::v-deep .el-input-number__increase {
    right: 5px;
    width: 90px;
    top: 15px;
    font-size: 82px;
  }
  ::v-deep .el-input-number__decrease {
    width: 90px;
    font-size: 82px;
  }
  ::v-deep .el-input-number.is-controls-right .el-input-number__decrease {
    right: 5px;
    bottom: 15px;
  }
  ::v-deep .el-radio__label {
    font-size: 58px !important;
    line-height: 80px;
  }
  ::v-deep .el-checkbox__label {
    font-size: 58px !important;
  }
  h1 {
    font-size: 68px;
  }
  ::v-deep .el-step.is-center .el-step__description {
    font-size: 58px !important;
  }
  ::v-deep .el-step__title {
    font-size: 58px !important;
    line-height: 80px;
  }
  ::v-deep .el-step__icon {
    font-size: 68px !important;
    height: 100px;
    line-height: 60px;
    width: 100px;
  }
  ::v-deep .el-step__icon-inner {
    line-height: 140px;
  }
  ::v-deep .el-button {
    font-size: 108px;
    margin: 8% auto;
  }

  ::v-deep .el-step.is-horizontal .el-step__line {
    top: 40px;
  }
  .content {
    line-height: 1.875;
    width: 100%;
    font-size: 58px;
    float: left;
  }
  .prompt {
    margin: 5% auto;
    font-size: 58px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .questions-body {
    width: 80%;
  }
  .questions-body {
    width: 90%;
  }
  .tit1 {
    font-size: 38px;
    line-height: 140px;
    height: 140px;
  }
  .tit2 {
    font-size: 28px;
  }
  .lll {
    padding: 0.5em 5em;
    min-width: 820px;
    height: 125px !important;
    line-height: 125px !important;
    gap: 2em;
  }
  ::v-deep .el-radio__label {
    font-size: 28px !important;
    line-height: 80px;
  }
  ::v-deep .el-checkbox__label {
    font-size: 28px !important;
  }
  h1 {
    font-size: 38px;
  }
  ::v-deep .el-step.is-center .el-step__description {
    font-size: 28px !important;
  }
  ::v-deep .el-step__title {
    font-size: 28px !important;
    line-height: 80px;
  }
  ::v-deep .el-step__icon {
    font-size: 28px !important;
    height: 50px;
    width: 50px;
  }
  ::v-deep .el-step.is-horizontal .el-step__line {
    top: 25px;
  }
  .content {
    line-height: 1.875;
    width: 100%;
    font-size: 28px;
    float: left;
  }
}

@media (min-width: 1025px) {
  ::v-deep .el-step.is-center .el-step__description {
    font-size: 28px !important;
  }
  ::v-deep .el-step__title {
    font-size: 28px !important;
    line-height: 80px;
  }
  ::v-deep .el-step__icon {
    font-size: 28px !important;
    height: 50px;
    width: 50px;
  }
  ::v-deep .el-step.is-horizontal .el-step__line {
    top: 25px;
  }
}
</style>