<template>
    <div id="footerPage">
      <div class="footer-content">
        <div class="footer-content-box">
          <ul>
            <li class="title-box"><span style="color: #fff">联系方式</span></li>
            <li>地址：北京市海淀区善缘街1号立方庭大厦</li>
            <li>电话：010-8260 2616</li>
          </ul>
        </div>
        <div class="footer-content-box">
          <ul>
            <li class="title-box"><span style="color: #fff">益学方策公众号</span></li>
            <li><img class="erweima" :src="courseImage8" /></li>
          </ul>
        </div>
        <div class="footer-content-box">
          <ul>
            <li class="title-box"><span style="color: #fff">关于</span></li>
            <li>关于网页</li>
            <li>版权声明</li>
            <li>关于隐私</li>
            <li>免责声明</li>
            <li>加入我们</li>
          </ul>
        </div>
        <div class="footer-content-box">
          <ul>
            <li class="title-box"><span style="color: #fff">版权信息</span></li>
            <li>联系电话：13011015353 (余老师)</li>
            <li>技术支持：四川易铁必肯科技有限公司</li>
            <li>Copyright © 2021 益学方策</li>
            <li>版权所有：北京益学方策管理咨询有限公司</li>
            <li>
              <a target="_blank" href="https://beian.miit.gov.cn/">
                京ICP备15062349号-1
                <a
                  target="_blank"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033446"
                >
                  <img
                    src="../assets/image/备案图标.png"
                    width="12px"
                    height="12px"
                  />
                </a>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

</template>

<script>
export default {
  data() {
    return {
      courseImage8: require("../assets/image/1、益学方策公众号二维码.jpg"),
    };
  },
};
</script>

<style scoped>
.footer-content {
  width: fit-content;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
}
#footerPage {
  height: fit-content;
  width: 100%;
  background: linear-gradient(to bottom, #323232 0%, #3f3f3f 40%, #1c1c1c 150%),
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(0, 0, 0, 0.25) 200%
    );
  background-blend-mode: multiply;
  margin: auto auto 0 auto;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}
.erweima {
  width: 100px;
}
a {
  color: #d0d0d0;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
} /* 未被访问的链接  */
a:visited {
  color: #d0d0d0;
} /* 已被访问过的链接  */
a:hover {
  color: #d0d0d0;
} /* 鼠标悬浮在上的链接  */
a:active {
  color: #d0d0d0;
} /* 鼠标点中激活链接 蓝色 */
li {
  list-style: none;
  color: #d0d0d0;
  padding: 10px;
}
span {
  font-size: 20px;
}
.title-box {
  padding: 10px 10px 25px 10px
}
ul {
  padding: 40px;
}
@media only screen and (min-width: 1279px) and (max-width: 1535px) {
  .footer-content-box {
    font-size: 20px;
  }
  span {
    font-size: 24px;
  }
  .erweima {
    width: 150px;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1280px) {
  .footer-content-box {
    font-size: 22px;
  }
  span {
    font-size: 26px;
  }
  .erweima {
    width: 200px;
  }
}
@media only screen and (min-width: 661px) and (max-width: 959px) {
  .footer-content-box {
    font-size: 26px;
  }
  span {
    font-size: 30px;
  }
  .erweima {
    width: 230px;
  }
}
@media only screen and (max-width: 660px) {
  .footer-content-box {
    font-size: 72px;
    width: 48%;
  }
  span {
    font-size: 78px;
  }
  .erweima {
    width: 400px;
  }
}
</style>