<!--
 * @Description: 
 * @Version: 2.0
 * @Author: jiyang.fu
 * @Date: 2022-01-17 11:16:27
 * @LastEditors: jiyang.fu
 * @LastEditTime: 2022-11-30 14:52:56
-->
<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <div class="content-body">
        <div v-for="(items, index) in humancpmsgs" :key="index">
          <h3 v-if="items.name !== ''">&nbsp;{{ items.name }}</h3>
          <div v-if="items.name === '人力资本能动性'">
            <el-steps align-center>
              <el-step
                v-for="items in humancpmsgs.filter((it) => {
                  return it.conent1 !== undefined;
                })"
                :key="items.conent1"
                :description="items.conent1"
                icon="el-icon-s-comment"
              ></el-step>
            </el-steps>
          </div>
          <div class="titles-box" v-if="items.titile !== ''">
            &nbsp;{{ items.titile }}
          </div>
          <div
            class="img-box"
            :style="{ backgroundImage: `url(${items.img})` }"
            v-if="items.img !== ''"
          />
          <p>{{ items.conent }}</p>
        </div>
      </div>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Sideframe from "../../components/sideframe.vue";
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import * as echarts from "echarts";
import Cookies from "js-cookie";
import "echarts-gl";
import courseMessageVue from "../learning/courseMessage/courseMessage.vue";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";

export default {
  components: { Header, Flooter, Sideframe, Breadcrumb },
  data() {
    return {
      titleData: {
        titleName: "人力资本化价值",
        data: [
          {
            id: nanoid(),
            name: "人力资本化",
            href: "",
          },
          {
            id: nanoid(),
            name: "人力资本化价值",
            href: "",
          },
        ],
      },
      courseImage2: require("../../assets/image/人力资本和人力资本化的界定.jpg"),
      courseImage3: require("../../assets/image/人力资本能动性.jpg"),
      courseImage5: require("../../assets/image/人力资本和人力资本化四个层次.jpg"),
      courseImage6: require("../../assets/image/人口红利四大阶段与人力资本化革命.jpg"),
      courseImage7: require("../../assets/image/教育培训人力资本化革命.jpg"),
      courseImage8: require("../../assets/image/中国正处于人力资本化关键阶段.jpg"),
      courseImage9: require("../../assets/image/人力资本化双钻石模型.jpg"),
      humancpmsgs: [
        { name: "人力资本和人力资本化的界定", titile: "", conent: "", img: "" },
        {
          name: "",
          name: "",
          titile: "人力资本化Ⅰ",
          conent: "",
          img: require("../../assets/image/界定1.jpg"),
        },
        {
          name: "",
          titile: "人力资本化Ⅱ",
          conent: "",
          img: require("../../assets/image/界定2.png"),
        },
        { name: "人力资本能动性", titile: "", conent: "", img: "" },
        {
          name: "",
          titile: "",
          conent: "",
          conent1: "可以遮择通过教育、培训、自学等提升向己的能为和技能",
          img: "",
        },
        {
          name: "",
          titile: "",
          conent: "",
          conent1: "与土地、资源等作为一种生产要素不一样，它以自身为资源",
          img: "",
        },
        {
          name: "",
          titile: "",
          conent: "",
          conent1: "通过迁移或选择或更换不同岗位获得报酬",
          img: "",
        },
        {
          name: "",
          titile: "",
          conent: "",
          conent1:
            "与拥有土地、资源、财离等转化为资本的主体不一样，它以促进和实现组织或企业的资本化为方向",
          img: "",
        },
        {
          name: "",
          titile: "",
          conent: "",
          conent1: "通过努力在商位上或独立的剧新创造创业而最大化自身的价值",
          img: "",
        },
        {
          name: "",
          titile: "",
          conent: "",
          conent1: "通过创新创造创业把砌己变成责本化，即从人方责源成为人力责本",
          img: "",
        },
        { name: "人力资本化四大价值", titile: "", conent: "", img: "" },
        {
          name: "",
          titile: "",
          conent: "",
          img: require("../../assets/image/人力资本化四大价值.png"),
        },
        {
          name: "人力资本和人力资本化四个层次",
          titile: "",
          conent: "",
          img: "",
        },
        {
          name: "",
          titile: "",
          conent: "",
          img: require("../../assets/image/人力资本和人力资本化的层次.png"),
        },
        {
          name: "人口红利四大阶段与人力资本化革命",
          titile: "",
          conent: "",
          img: "",
        },
        {
          name: "",
          titile: "",
          conent: "",
          img: require("../../assets/image/人口红利四大阶段与人力资本化革命.jpg"),
        },
        { name: "教育培训人力资本化革命", titile: "", conent: "", img: "" },
        {
          name: "",
          titile: "",
          conent: "",
          img: require("../../assets/image/教育培训的人力资本化革命.png"),
        },
        {
          name: "中国正处于人力资本化关键阶段",
          titile: "",
          conent: "",
          img: "",
        },
        {
          name: "",
          titile: "世界银行人口红利国家的分类( 2017年)",
          conent: "",
          img: require("../../assets/image/红利分类.png"),
        },
        {
          name: "",
          titile: "",
          conent: "",
          img: require("../../assets/image/关键阶段.png"),
        },
        { name: "人力资本化双钻石模型", titile: "", conent: "", img: "" },
        {
          name: "",
          titile: "",
          conent: "",
          img: require("../../assets/image/人力资本化双钻石模式.png"),
        },
      ],
      msg1: "h1",
      msg2: "h2",
      msg3: "h3",
      msg4: "h4",
      msg5: "h5",
      msg6: "h6",
      msg7: "h7",
      msg8: "h8",
    };
  },
  // 创建完毕状态(里面是操作)
  created() {
    this.gettoken();
    this.getuserData();
  },
  // 挂载结束状态(里面是操作)
  mounted() {},
  // 里面的函数只有调用才会执行
  methods: {
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.userData = null;
        this.token = null;
      }
    },
    async getuserData() {
      if (this.token == null) {
      } else {
        const res = await this.axios.get("/basicUser/tokenByUser", {
          params: {
            token: this.token,
          },
        });
        if (res.code == 200) {
          this.userData = res.object;
        } else {
          this.userData = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.content-body {
  width: 75%;
  margin: auto;
}
.el-step.is-center .el-step__description {
  padding-left: 20%;
  padding-right: 20%;
  margin-top: 30px;
}
.el-step.is-horizontal.is-center {
  max-width: 100% !important;
}
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
.titles-box {
  font-size: 24px;
  width: 100%;
  text-align: left;
  font-weight: normal;
  font-family: "方正大标宋简体";
}
.img-box {
  height: 450px;
  width: 80%;
  margin: 40px auto;
  background-size: contain;
  background-repeat: no-repeat;
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
::v-deep .el-step__line {
  position: absolute;
  border-color: inherit;
  background-color: #000 !important;
}
::v-deep .el-step__head.is-wait {
  color: #000 !important;
  border-color: #000 !important;
}
::v-deep .el-step__description.is-wait {
  color: #000 !important;
}
h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}
.background {
  background-color: #f7f7f7;
  height: 100px;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
h3 {
  margin: 0 0 20px 0;
  text-align: left;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.875;
}
img {
  display: inline-block;
  margin-top: 20px;
  width: 100%;
  height: 100%;
}
p {
  display: inline-block;
  text-indent: 2em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.875;
}
.aside {
  display: inline-block;
  width: 260px;
  position: absolute;
  top: 200px;
}
.el-row {
  display: inline-block;
  margin-right: 30px;
  margin-top: 40px;
  margin-bottom: 100px;
}
.title {
  margin: 0;
  font-size: 28px;
}
::v-deep .el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  float: left;
}
::v-deep.el-step.is-center .el-step__description {
  text-align: justify;
  font-weight: bold;
  font-family: 楷体, sans-serif;
  font-size: 18px;
}
.link {
  cursor: pointer;
  text-decoration: none;
  float: left;
  color: #555555;
  font-weight: lighter;
}
</style>