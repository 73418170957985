<template>
  <div class="body">
    <myheader></myheader>
    <Sideframe></Sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <div class="qrCode-body">
        <div class="qrCode-box">
          <h1 class="h1style">打开微信，扫码学习</h1>
          <img :src="QRcode" />
        </div>
      </div>
    </div>
    <myflooter> </myflooter>
  </div>
</template>
<script>
import Header from "../Header.vue";
import Flooter from "../flooter.vue";
import Sideframe from "../../components/sideframe.vue";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";
export default {
  data() {
    return {
      num: 24,
      titleData: {
        titleName: "自选课程",
        data: [
          {
            id: nanoid(),
            name: "商学院",
            href: "",
          },
          {
            id: nanoid(),
            name: "自选课程",
            href: "",
          },
        ],
      },
      QRcode: require("../../assets/image/3、自选课程二维码.png"),
    };
  },
  mounted() {},
  components: {
    myheader: Header,
    myflooter: Flooter,
    Sideframe,
    Breadcrumb,
  },
};
</script>
<style  scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
img {
  height: 300px;
  width: 300px;
  margin: 0 auto !important;
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

#name:hover {
  color: #dab96b;
  transform: scale(1.4);
}
.h1style {
  width: 100%;
  margin: 10px 0;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
img {
  margin-left: 450px;
  margin: auto;
}

.qrCode-body {
  min-height: 648px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .h1style {
    font-size: 58px;
  }

  img {
    width: 500px;
    height: 500px;
  }
  .qrCode-box {
    margin: 20px;
  }

}

@media (min-width: 769px) and (max-width: 1024px) {
  .h1style {
    font-size: 38px;
  }

  img {
    width: 500px;
    height: 500px;
  }
  .qrCode-box {
    margin: 20px;
  }

}

@media (min-width: 1025px) {
}
</style>