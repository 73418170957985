<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <el-dialog
      title="提示"
      :visible.sync="showbox"
      width="30%"
      :before-close="handleClose"
    >
      <span
        >发现你在{{ cacheTypeName }}模块有未完成的试卷，是否继续完成答题？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteCachePaper()">取 消</el-button>
        <el-button type="primary" @click="getCachePaper()">确 定</el-button>
      </span>
    </el-dialog>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <div class="bodystyle">
        <el-form ref="form" :rules="rules" :model="ruleForm" label-width="80px">
          <el-form-item label="测评账号" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="测评密码" prop="password">
            <el-input v-model="ruleForm.password" show-password></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button class="but" @click="humanca">开始测评</el-button>
        </div>
      </div>
    </div>
    <flooter style="bottom: 0"></flooter>
  </div>
</template>

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Sideframe from "../../components/sideframe.vue";
import Cookies from "js-cookie";
import { Loading } from "element-ui";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";
export default {
  components: { Header, Flooter, Sideframe, Breadcrumb },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      title: "培训班人力资本化测评",
      titleData: {
        titleName: "培训班人力资本化测评",
        data: [
          {
            id: nanoid(),
            name: "人力资本化",
            href: "",
          },
          {
            id: nanoid(),
            name: "培训班人力资本化测评",
            href: "",
          },
        ],
      },
      isok: false,
      dynamicTags: [],
      listData: [],
      dataList: {
        ids: [],
        userId: 0,
        phone: "",
        password: "",
      },
      ruleForm: {
        phone: "",
        password: "",
      },
      cacheTypeName: "",
      showbox: false,
      rules: {
        phone: [
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请填写测评密码", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.gettoken();
    this.getuserData();
    this.gethumanCAData();
  },
  methods: {
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.userData = null;
        this.token = null;
      }
    },
    async getuserData() {
      if (this.token == null) {
      } else {
        const res = await this.axios.get("/basicUser/tokenByUser", {
          params: {
            token: this.token,
          },
        });
        if (res.code == 200) {
          this.userData = res.object;
          this.dynamicTags = res.object;
          this.ruleForm.phone = this.userData.phone;
        } else {
          this.userData = null;
        }
      }
    },
    async gethumanCAData() {
      const res = await this.axios.get("/humanCapital/selectAll");
      if (this.userData == null) {
        this.$message({
          showClose: true,
          message: "请登录后进行操作！",
          type: "info",
        });
        this.$router.push({ path: "/login" });
      } else {
        if (res.code == 200) {
          this.listData = res.object;
          this.dynamicTags = res.object;
        } else {
          this.$message({
            showClose: true,
            message: "请求错误，请稍后重试！",
            type: "error",
          });
        }
      }
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    deleteCachePaper() {
      this.axios
        .get("/cachePaper/deleteCachePaper", {
          params: {
            id: JSON.parse(window.localStorage.getItem("questionlist"))
              .cachePaperId,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.humanca();
          }
        });
    },
    getCachePaper() {
      this.$router.push({
        name: "questions",
      });
    },
    async humanca() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getTestQuestion();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async login(val) {
      await this.axios.post("/training/login", val).then(
        (res) => {
          if (res.code === 200) {
            this.$message({
              showClose: true,
              message: "验证成功，可以开始测试",
              type: "success",
            });
            if (data1.optionJson !== null) {
              this.cacheTypeName = JSON.parse(
                window.localStorage.getItem("questionlist")
              ).typeName;
              window.localStorage.setItem(
                "questionlist",
                JSON.stringify(data1)
              );
              window.localStorage.setItem("title", data1.typeName);
              window.localStorage.setItem("typeId", a);
              this.showbox = !this.showbox;
            } else {
              window.localStorage.setItem(
                "questionlist",
                JSON.stringify(data1)
              );
              window.localStorage.setItem("title", data1.typeName);
              window.localStorage.setItem("typeId", a);
              this.$router.push({
                name: "questions",
              });
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "info",
            });
          }
        },
        (err) => {
          this.$message({
            showClose: true,
            message: "请求错误，请稍后重试！",
            type: "error",
          });
        }
      );
    },
    async getTestQuestion() {
      const res = await this.axios.get(
        "/EvaluationTestQuestion/selectAllTestQuestion",
        {
          params: {
            startpage: 1,
            pagesize: 50,
          },
        }
      );
      if (res.code == 200) {
        const data = res.object.list;
        data.forEach((it) => {
          if (it.testQuestions === this.title) {
            let a = [];
            it.list.forEach((ite) => {
              a.push(ite.id);
            });
            let id = this.userData.id;
            let phones = this.ruleForm.phone;
            let passwords = this.ruleForm.password;
            let datalist = {
              evaluationTestQuestionEntity: it,
              ids: a,
              userId: id,
              phone: phones,
              password: passwords,
              type: 0,
            };
            this.login(datalist);
          }
        });
      }
    },
  },
};
</script>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: rgb(218, 185, 107);
  border-color: rgb(218, 185, 107);
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgb(218, 185, 107);
}

.el-checkbox.is-bordered.is-checked {
  border-color: rgb(218, 185, 107);
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: rgb(218, 185, 107);
}
</style>

<style scoped>
.bodystyle {
  text-align: center;
  margin: 0;
  min-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 50px;
}

.imgsize {
  height: 60px;
  width: 80px;
}

::v-deep .el-image__inner {
  float: left;
  max-height: 60px;
  max-width: 80px !important;
}

#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}

html,
body {
  height: 100%;
}

.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

::v-deep .el-collapse-item__header:hover {
  color: rgb(218, 185, 107);
}

::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(218, 185, 107);
  color: #fff;
}

::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

::v-deep .el-collapse-item__header {
  font-size: 26px;
}

::v-deep .el-checkbox {
  margin-left: 10px;
  width: 15px;
  float: left;
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  margin-right: 30px;
}

h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}

.background {
  background-color: #f7f7f7;
  height: 100px;
}

hr {
  margin: 0;
  margin-bottom: 8px;
}

h3 {
  margin: 0;
  text-align: left;
  background-color: #eeeeee;
  font-size: 28px;
  font-weight: normal;
  line-height: 1.875;
}

.h4 {
  margin: 10px auto;
  text-align: left;
  background-color: #eeeeee;
  font-size: 20px;
  font-weight: lighter;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: 楷体, sans-serif;
}

.h4:hover {
  color: rgb(218, 185, 107);
  background-color: #333333;
}

img {
  display: inline-block;
  margin-top: 20px;
}

p {
  display: inline-block;
  text-indent: 2em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.875;
  font-size: 16px;
}

.aside {
  display: inline-block;
  width: 260px;
  position: absolute;
  top: 200px;
}

.el-row {
  display: inline-block;
  margin-right: 100px;
  margin-top: 40px;
  margin-bottom: 100px;
}

.title {
  margin: 0;
  font-size: 28px;
}

::v-deep .el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  float: left;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgb(218, 185, 107);
}

.link {
  cursor: pointer;
  text-decoration: none;
  float: left;
  color: #555555;
}

.div {
  line-height: 137px;
  width: 1198px;
  margin: 15px 0px 15px 0px;
  background-color: #f9f9f9;
  right: 0;
}

.el-pagination {
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
  text-align: center;
}

::v-deep .el-image__inner {
  float: left;
  max-height: 137px;
  max-width: 110px;
}

i {
  margin-left: 40px;
}
</style>