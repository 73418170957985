<template>
  <div class="body">
    <Header></Header>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <el-row style="text-align: left; width: 100%">
        <div style="width: 100%">
          <div style="width: 100%; background: #ffffff">
            <el-tabs
              tab-position="left"
              @tab-click="handleClick"
              style="padding: 20px"
            >
              <el-tab-pane label="用户管理"
                ><user-message></user-message
              ></el-tab-pane>
              <el-tab-pane label="益学课程"
                ><user-couser></user-couser
              ></el-tab-pane>
              <el-tab-pane label="用户订单"
                ><user-oder></user-oder
              ></el-tab-pane>
              <el-tab-pane label="测评记录"
                ><EvaluationResults></EvaluationResults
              ></el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </el-row>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import EvaluationResults from "./EvaluationResults.vue";
import userMessage from "./userMessage.vue";
import userCouser from "./userCouser.vue";
import userOder from "./userOder.vue";
import Breadcrumb from "../../components/breadcrumb.vue";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import { nanoid } from "nanoid";
marked.setOptions({
  renderer: new marked.Renderer(),
  highlight: function (code) {
    return hljs.highlightAuto(code).value;
  },
  pedantic: false,
  gfm: true,
  tables: true,
  breaks: true,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: true,
});
export default {
  components: {
    Header,
    Flooter,
    userMessage,
    userCouser,
    userOder,
    EvaluationResults,
    Breadcrumb,
  },
  data() {
    return {
      title: "",
      titleData: {
        titleName: "用户管理",
        data: [
          {
            id: nanoid(),
            name: "个人中心",
            href: "",
          },
          {
            id: nanoid(),
            name: "用户管理",
            href: "",
          },
        ],
      },
      formInline: {
        page: 1,
        limit: 5,
        articleEntity: {
          articleTitle: "",
          articleContent: "",
          articleTypeName: "",
          articleAuthor: "",
          startTime: "",
          endTime: "",
          del: "",
          state: "",
        },
        // token: localStorage.getItem("logintoken"),
      },
    };
  },
  mounted() {},
  methods: {
    handleClick(tab, event) {
      this.title = tab.label;
      this.titleData.titleName = this.title;
      this.titleData.data[this.titleData.data.length - 1].name = this.title;
    },
  },
};
</script>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
hr {
  margin: 0;
  margin-bottom: 8px;
}

</style>