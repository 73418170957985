import { render, staticRenderFns } from "./phonevalidation.vue?vue&type=template&id=59a7a10c&scoped=true"
import script from "./phonevalidation.vue?vue&type=script&lang=js"
export * from "./phonevalidation.vue?vue&type=script&lang=js"
import style0 from "./phonevalidation.vue?vue&type=style&index=0&id=59a7a10c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a7a10c",
  null
  
)

export default component.exports