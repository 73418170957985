<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <div class="videoTitle-box">
        <p class="courseName">
          {{ courseData.courseName }}
        </p>
        <p>开课时间：{{ courseData.courseTime }}</p>
      </div>
      <div class="video-box">
        <video
          :preload="preload"
          :poster="videoImg"
          :height="height"
          :width="width"
          align="center"
          :controls="controls"
          :autoplay="autoplay"
        >
          <source :src="videoSrc" type="video/mp4" />
        </video>
      </div>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../../flooter.vue";
import Header from "../../Header";
import Sideframe from "@/components/sideframe.vue";
import Cookies from "js-cookie";
import Breadcrumb from "@/components/breadcrumb.vue";
import { nanoid } from "nanoid";
export default {
  components: { Header, Flooter, Sideframe, Breadcrumb },
  data() {
    return {
      courseData: {},
      titleData: {
        titleName: "",
        data: [
          {
            id: nanoid(),
            name: "商学院",
            href: "",
          },
        ],
      },
      videoSrc:
        "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4",
      videoImg:
        "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4",
      playStatus: "",
      muteStatus: "",
      isMute: true,
      isPlay: false,
      width: "100%", // 设置视频播放器的显示宽度（以像素为单位）
      height: "100%", // 设置视频播放器的显示高度（以像素为单位）
      preload: "auto", //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      controls: true, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      autoplay: false,
      routerForm: "",
      title: "",
    };
  },
  created() {
    this.gettoken();
    let data = JSON.parse(window.localStorage.getItem("courseData"));
    this.courseData = data.couresMessage;
    this.title = data.title;
    let pathData = {
      id: nanoid(),
      name: this.title,
      href: data.path,
    };
    let pathData1 = {
      id: nanoid(),
      name: this.courseData.courseName,
      href: "",
    };
    this.titleData.titleName = this.courseData.courseName;
    this.titleData.data.push(pathData);
    this.titleData.data.push(pathData1);
  },
  mounted() {},
  methods: {
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.token = null;
        this.$router.push({
          path: "/login",
        });
      }
    },
  },
};
</script>

<style scoped>
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
.el-breadcrumb {
  font-size: 16px;
  margin-right: 40%;
  float: right;
  height: 90px;
  line-height: 90px;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
.video-box {
  width: 80%;
  height: 800px;
  margin:20px auto;
  background: rgba(51, 51, 51);
}
.videoTitle-box {
  text-align: left;
  width: 80%;
  font-size: 18px;
  max-height: fit-content;
  margin: 20px auto;
  color: gray;
}
.videoTitle-box p {
  margin: 0;
}
.videoTitle-box .courseName {
  font-size: 24px;
  color: black;
}
@media (max-width: 768px) {
  .video-box {
    margin: 20px auto 150px auto;
    background: rgba(51, 51, 51);
  }
  .videoTitle-box .courseName {
    font-size: 88px;
    color: black;
  }
  .videoTitle-box {
    text-align: left;
    max-width: 1600px;
    font-size: 68px;
    max-height: fit-content;
    margin: 50px auto;
    color: gray;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .videoTitle-box .courseName {
    font-size: 68px;
    color: black;
  }
  .videoTitle-box {
    text-align: left;
    max-width: 1600px;
    font-size: 48px;
    max-height: fit-content;
    margin: 50px auto;
    color: gray;
  }
}

@media (min-width: 1025px) {
}
</style>